import { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Theme } from '@mui/material';
import { styled } from '@mui/material';
import { IconBolt } from '@tabler/icons-react';

import intentRoutingIcon from '../../assets/icons/intent-routing.svg';
import { useGetBuilderQueryParams } from '../../hooks';
import { CircleIcon } from './StyledComponents';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';
import {
  getVariablesFromAction,
  replaceActionIdInRoute,
} from 'src/utils/actionBuilder/helpers';
import { Routes } from 'src/utils/enums';
import { ContextVariableTypes } from 'src/utils/enums';

export const ACCEPTABLE_CV_TYPES: (keyof typeof ContextVariableTypes)[] = [
  'SHORT_TEXT',
  'LONG_TEXT',
  'CHECKBOX',
  'NUMBER',
  'EMAIL',
  'PHONE',
  'DATE',
];

export const getIconForTool = (tool: AutoflowTool, theme: Theme) => {
  switch (tool.tool_type) {
    case 'demo':
    case 'api_call':
    case 'template_api_call':
    case 'system_built_in':
      return (
        <CircleIcon bgcolor={theme.palette.colors.blue[500]}>
          <IconBolt size={20} />
        </CircleIcon>
      );
    case 'hand_off':
    case 'intent_routing':
      return (
        <CircleIcon bgcolor='#1F866A'>
          <Icon src={intentRoutingIcon} />
        </CircleIcon>
      );
    default:
      return null;
  }
};

export const getNameForTool = (toolName: string) => {
  switch (toolName) {
    case 'search_help_center_articles':
      return 'Search Help Center Articles';
    case 'forward_phone_call':
      return 'Forward Phone Call';
    case 'create_ticket_in_helpdesk':
      return 'Create Ticket in Helpdesk';
    default:
      return toolName;
  }
};

export const useGetHrefForTool = (tool: AutoflowTool) => {
  const { view } = useGetBuilderQueryParams();
  const { data } = useGetIntentsQueryWithProduct();
  const { tool_id: toolId, tool_type: toolType } = tool;

  const isToolIdIntentId =
    toolType === 'hand_off' || toolType === 'intent_routing';

  const intent = useMemo(() => {
    if (!isToolIdIntentId) {
      return;
    }

    return data?.intents.find(intent => intent.intent_definition_id === toolId);
  }, [data?.intents, isToolIdIntentId, toolId]);

  if (toolType === 'api_call' || toolType === 'template_api_call') {
    return replaceActionIdInRoute(toolId);
  }

  if (isToolIdIntentId && intent) {
    const params = new URLSearchParams({
      intentId: toolId,
      view,
      workflowId: intent.intent_workflow_id,
    });

    if (view === 'email') {
      params.set('emailMode', 'Interactive');
    }

    return `${Routes.WORKFLOW_BUILDER_EDIT}?${params}`;
  }

  return null;
};

export const useGetActionFieldsForTool = (
  tool: AutoflowTool,
): {
  description: string;
  dynamicListOutputVariables?: ContextVariable[];
  inputVariables: ContextVariable[];
  isDisabled: boolean;
  outputVariables: ContextVariable[];
} => {
  const defaults = {
    description: '',
    dynamicListOutputVariables: [],
    inputVariables: [],
    isDisabled: false,
    outputVariables: [],
  };

  const { data } = useGetActionBuilderActionsQuery({});
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldIncludeTemplateContextVariables: true,
  });

  if (tool.tool_type === 'api_call' || tool.tool_type === 'template_api_call') {
    const action = data?.actions.find(
      action => action.action_id === tool.tool_id,
    );
    if (!action) {
      return defaults;
    }
    const variables = getVariablesFromAction(action, contextVariables);

    const hasAcceptableInputVariables = variables.inputVariables.every(
      inputVariable =>
        ACCEPTABLE_CV_TYPES.includes(inputVariable.context_variable_type),
    );

    return {
      description: action?.action_description,
      ...variables,
      isDisabled:
        !hasAcceptableInputVariables ||
        Boolean(variables.dynamicListOutputVariables?.length),
    };
  }

  return defaults;
};

// eslint-disable-next-line
const Icon = styled(ReactSVG)`
  svg {
    [fill] {
      fill: ${props => props.theme.palette.colors.white};
    }
    [stroke] {
      stroke: ${props => props.theme.palette.colors.white};
    }
  }
`;
