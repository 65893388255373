import React from 'react';
import { useDrag } from 'react-dnd';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import articleSuggestionIcon from '../../assets/images/article-suggestion-action.svg';
import { ActionSvg } from './ForethoughtActionsList';
import actionIcon from 'src/assets/images/action-2.svg';
import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { type ForethoughtAction } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { dashboardApi } from 'src/services/dashboard-api';
import {
  setIsSqueezingStep,
  setMode,
  setShouldSqueezeIntoEntry,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  startAddingActionThunk,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderActionTypes, RevampedDragNDrops } from 'src/utils/enums';

const ForethoughtAction: React.FC<
  React.PropsWithChildren<ForethoughtAction>
> = ({
  action,
  disabled,
  hasWorkflowConflict,
  setActionPanelVisibilityParameters,
  tooltip = '',
}) => {
  const { contextVariables } = useGetContextVariables();

  const dispatch = useAppDispatch();
  const [{ isDragging }, drag] = useDrag(() => ({
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop() && !hasWorkflowConflict) {
        // to sync with action builder endpoints

        const payload = {
          action: item,
          data: {
            action_id: item.action_id,
          },
        };

        if (
          item.action_type === ActionBuilderActionTypes.HYPERLINK_REDIRECT ||
          item.action_type === ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2 ||
          item.action_type ===
            ActionBuilderActionTypes.ZENDESK_TICKET_CREATION ||
          item.action_type ===
            ActionBuilderActionTypes.SALESFORCE_CASE_CREATION ||
          item.action_type ===
            ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF ||
          item.action_type === ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING ||
          item.action_type ===
            ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD ||
          item.action_type ===
            ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF ||
          item.action_type === ActionBuilderActionTypes.SET_CONTEXT_VARIABLE ||
          item.action_type === ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF ||
          item.action_type ===
            ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION ||
          item.action_type === ActionBuilderActionTypes.INTERCOM_TICKET_CREATION
        ) {
          dispatch(startAddingActionThunk(payload));
          dispatch(
            setMode({ contextVariables, mode: CanvasModes.ACTION_EDIT }),
          );
          setActionPanelVisibilityParameters(
            item.action_type as ActionPanelMode,
          );
        } else {
          dispatch(addAction(payload)).then(() => {
            dispatch(dashboardApi.util.invalidateTags(['ActionUsages']));
          });
          dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
          dispatch(setIsSqueezingStep(false));
          dispatch(setShouldSqueezeIntoEntry(false));
        }
        // to sync with action builder endpoints
      }
    },
    item: action,
    type: RevampedDragNDrops.REVAMPED_FT_ACTION,
  }));

  const isArticleSuggestionAction =
    action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION;

  return (
    <Action disabled={disabled} isDragging={isDragging} ref={drag}>
      <ActionSvg height={20} marginRight={13} width={14}>
        <ReactSVG
          src={isArticleSuggestionAction ? articleSuggestionIcon : actionIcon}
        />
      </ActionSvg>
      {action.action_name}
      <Tooltip tooltipText={tooltip} />
    </Action>
  );
};

export default ForethoughtAction;

const Action = styled('button')<{ isDragging: boolean }>`
  background: ${Colors.ui.backgrounds.default};
  border: 1px solid ${theme.palette.colors.grey[200]};
  border-radius: 4px;
  padding: 12px 8px;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  opacity: ${props => (props.isDragging ? 0.4 : 1)};
  display: flex;
  align-items: center;

  &:hover:not(:disabled) {
    border: 1px solid ${theme.palette.colors.purple[500]};
  }
`;
