import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  Skeleton,
  styled,
  useTheme,
} from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import { IdleCanvasComponentProps } from 'src/components/email-builder/types';
import { useGetOrCreateAutopilotWorkflowQuery } from 'src/services/email-autopilot/emailAutopilotApi';
import { selectEmailBuilderState } from 'src/slices/email-builder/emailBuilderSlice';

const IntentEmailAutopilotComponent = ({}: IdleCanvasComponentProps) => {
  const theme = useTheme();
  const [, setSearchParams] = useSearchParams();
  const { intentEmailConfiguration } = useSelector(selectEmailBuilderState);
  const { data: autopilotWorkflow, isLoading: isLoadingAutopilotWorkflow } =
    useGetOrCreateAutopilotWorkflowQuery(
      {
        configurationId: intentEmailConfiguration?.configuration_id ?? '',
        version: intentEmailConfiguration?.version ?? -1,
      },
      {
        skip: !intentEmailConfiguration,
      },
    );

  if (!intentEmailConfiguration) {
    return <></>;
  }

  const workflowHasPolicyDescription =
    autopilotWorkflow && autopilotWorkflow.policy_description.length > 0;

  const openAutopilotPolicyView = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearchParams(params => {
      params.set('autopilot', 'true');
      return params;
    });
  };

  return (
    <Box padding='16px'>
      <Box
        border='1px solid'
        borderColor={theme.palette.colors.purple[200]}
        borderRadius='8px'
        display='flex'
        flexDirection='column'
        gap='16px'
        margin='0 auto'
        padding='24px'
        width='100%'
      >
        <div>
          <Box alignItems='center' display='flex' gap='8px' marginBottom='8px'>
            <Typography variant='font18Bold'>Autoflow email policy</Typography>
          </Box>
          <Typography color={theme.palette.colors.grey[800]} variant='font14'>
            Create an Autoflow policy to manage automated responses in line with
            your guidelines. Include a personalized greeting and any essential
            standard messages, while Autoflow efficiently takes care of the rest
            based on your rules.
          </Typography>
        </div>
        <Box display='flex' flexDirection='column' gap='8px'>
          <div>
            {isLoadingAutopilotWorkflow ? (
              <Skeleton height={48} variant='rectangular' width='100%' />
            ) : workflowHasPolicyDescription ? (
              <GradientButton onClick={openAutopilotPolicyView}>
                <img alt='' src={aaBadgeSrc} width='24px' />
                <Typography
                  color={theme.palette.colors.black}
                  variant='font16Bold'
                >
                  Autoflow policy: {intentEmailConfiguration.name}
                </Typography>
              </GradientButton>
            ) : (
              <Button
                fullWidth
                onClick={openAutopilotPolicyView}
                size='large'
                variant='secondary'
              >
                <IconPlus size='18px' />
                Create Autoflow policy
              </Button>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

const GradientButton = styled(MuiButton)(({ theme }) => ({
  '&:hover': {
    background:
      'linear-gradient(270deg, rgba(189, 0, 255, 0.15) 0%, rgba(56, 102, 245, 0.15) 100%)',
    border: `1px solid ${theme.palette.colors.slate[300]}`,
  },

  alignItems: 'center',
  alignSelf: 'stretch',
  background:
    'linear-gradient(270deg, rgba(189, 0, 255, 0.05) 0%, rgba(56, 102, 245, 0.05) 100%)',
  border: `1px solid ${theme.palette.colors.slate[100]}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'flex-start',
  padding: '16px',
  textTransform: 'none',
  transition: 'all 0.2s ease-in-out',
  width: '100%',
}));

export default IntentEmailAutopilotComponent;
