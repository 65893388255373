import { SOLVE_AUTOPILOT_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { AddToolRequest, AutopilotWorkflowModel } from './types';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';

export interface GetOrCreateAutopilotWorkflowParams {
  configurationId: string;
  version: number;
}

export const emailAutopilotApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    addContextVariable: builder.mutation<
      AutopilotWorkflowModel,
      { autopilotWorkflow: AutopilotWorkflowModel; contextVariableId: string }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      async onQueryStarted(
        { autopilotWorkflow, contextVariableId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          emailAutopilotApi.util.updateQueryData(
            'getOrCreateAutopilotWorkflow',
            {
              configurationId: autopilotWorkflow.intent_email_configuration_id,
              version: autopilotWorkflow.intent_email_configuration_version,
            },
            draft => {
              draft.context_variables = [
                ...draft.context_variables,
                {
                  context_variable_id: contextVariableId,
                  context_variable_name: '',
                },
              ];
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ autopilotWorkflow, contextVariableId }) => ({
        method: 'PUT',
        params: { context_variable_id: contextVariableId },
        url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflow.autopilot_workflow_id}/context-variables`,
      }),
    }),

    addToolDefinition: builder.mutation<
      AutopilotWorkflowModel,
      { autopilotWorkflow: AutopilotWorkflowModel; request: AddToolRequest }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      async onQueryStarted(
        { autopilotWorkflow, request },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          emailAutopilotApi.util.updateQueryData(
            'getOrCreateAutopilotWorkflow',
            {
              configurationId: autopilotWorkflow.intent_email_configuration_id,
              version: autopilotWorkflow.intent_email_configuration_version,
            },
            draft => {
              draft.tool_definitions = [
                ...draft.tool_definitions,
                {
                  description: '',
                  name: '',
                  tool_fields: {},
                  tool_id: request.tool_id,
                  tool_type: request.tool_type,
                },
              ];
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ autopilotWorkflow, request }) => ({
        body: request,
        method: 'PUT',
        url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflow.autopilot_workflow_id}/tools`,
      }),
    }),
    getAvailableTools: builder.query<AutoflowTool[], void>({
      query: () => ({
        url: `${SOLVE_AUTOPILOT_BASE_URL}/available-tools`,
      }),
    }),
    getOrCreateAutopilotWorkflow: builder.query<
      AutopilotWorkflowModel,
      GetOrCreateAutopilotWorkflowParams
    >({
      providesTags: ['AutopilotWorkflow'],
      query: ({ configurationId, version }) => ({
        url: `${SOLVE_AUTOPILOT_BASE_URL}/email-configuration/${configurationId}/version/${version}/autopilot-workflow`,
      }),
    }),
    removeContextVariable: builder.mutation<
      AutopilotWorkflowModel,
      { autopilotWorkflow: AutopilotWorkflowModel; contextVariableId: string }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      async onQueryStarted(
        { autopilotWorkflow, contextVariableId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          emailAutopilotApi.util.updateQueryData(
            'getOrCreateAutopilotWorkflow',
            {
              configurationId: autopilotWorkflow.intent_email_configuration_id,
              version: autopilotWorkflow.intent_email_configuration_version,
            },
            draft => {
              draft.context_variables = draft.context_variables.filter(
                variable => variable.context_variable_id !== contextVariableId,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ autopilotWorkflow, contextVariableId }) => ({
        method: 'DELETE',
        params: { context_variable_id: contextVariableId },
        url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflow.autopilot_workflow_id}/context-variables`,
      }),
    }),
    removeToolDefinition: builder.mutation<
      AutopilotWorkflowModel,
      { autopilotWorkflow: AutopilotWorkflowModel; toolId: string }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      async onQueryStarted(
        { autopilotWorkflow, toolId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          emailAutopilotApi.util.updateQueryData(
            'getOrCreateAutopilotWorkflow',
            {
              configurationId: autopilotWorkflow.intent_email_configuration_id,
              version: autopilotWorkflow.intent_email_configuration_version,
            },
            draft => {
              draft.tool_definitions = draft.tool_definitions.filter(
                tool => tool.tool_id !== toolId,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      query: ({ autopilotWorkflow, toolId }) => ({
        method: 'DELETE',
        params: { tool_id: toolId },
        url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflow.autopilot_workflow_id}/tools`,
      }),
    }),
    updateAutopilotPolicyDescription: builder.mutation<
      AutopilotWorkflowModel,
      { autopilotWorkflowId: string; policyDescription: string }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      query: ({ autopilotWorkflowId, policyDescription }) => {
        return {
          body: { policy_description: policyDescription },
          method: 'PUT',
          url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflowId}/policy-description`,
        };
      },
    }),
    updateToolConfigurationFields: builder.mutation<
      AutopilotWorkflowModel,
      {
        autopilotWorkflowId: string;
        configurationFields: Record<string, unknown>;
        toolId: string;
      }
    >({
      invalidatesTags: ['AutopilotWorkflow'],
      query: ({ autopilotWorkflowId, configurationFields, toolId }) => ({
        body: { configuration_fields: configurationFields },
        method: 'PUT',
        url: `${SOLVE_AUTOPILOT_BASE_URL}/autopilot-workflow/${autopilotWorkflowId}/tools/${toolId}/configuration-fields`,
      }),
    }),
  }),
});

export const {
  useAddContextVariableMutation,
  useAddToolDefinitionMutation,
  useGetAvailableToolsQuery,
  useGetOrCreateAutopilotWorkflowQuery,
  useRemoveContextVariableMutation,
  useRemoveToolDefinitionMutation,
  useUpdateAutopilotPolicyDescriptionMutation,
  useUpdateToolConfigurationFieldsMutation,
} = emailAutopilotApi;
