import { sendAuthRequest } from 'src/services/api';
import { SOLVE_V2_BASE_URL } from 'src/services/constants';
import { SolveApiKeyResponse } from 'src/types/workflowBuilderAPITypes';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

export const getVoicePreviewToken = (): Promise<SolveApiKeyResponse> => {
  return sendAuthRequest(
    `${API_URL}${SOLVE_V2_BASE_URL}/solve-voice/preview-token`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};
