import ReactDOMServer from 'react-dom/server';
import { Palette } from '@mui/material';

import {
  SANKEY_DIAGRAM_COLORS,
  SANKEY_DIAGRAM_NEGATIVE_COLOR,
} from './constants';
import { SankeyDiagramData, SankeyPoint } from './types';
import merge from 'lodash/merge';

const getTargetColor = (item: SankeyDiagramData, index: number) => {
  if (item.negative) {
    return SANKEY_DIAGRAM_NEGATIVE_COLOR;
  }
  return (
    item.color || SANKEY_DIAGRAM_COLORS[index % SANKEY_DIAGRAM_COLORS.length]
  );
};

const getId = (item: SankeyDiagramData) => {
  if (item.id) {
    return item.id;
  }
  return item.negative ? item.to : item.from;
};

const createDataColor = (item: SankeyDiagramData) => {
  if (!item.transitionColor) {
    return undefined;
  }
  return {
    linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
    stops: [
      [0, item.transitionColor[0]],
      [1, item.transitionColor[1]],
    ],
  };
};

export const createNodes = (data: SankeyDiagramData[]) => {
  let colorIndex = 0;

  return data.map(item => {
    const dataItem = {
      color: getTargetColor(item, colorIndex),
      column: item.column,
      id: getId(item),
      offset: item.offset,
    };
    if (!item.negative && !item.color) {
      colorIndex++;
    }
    return dataItem;
  });
};

export const createData = (data: SankeyDiagramData[]) => {
  let colorIndex = 0;
  return data.map(item => {
    const transitionTargetColor = getTargetColor(item, colorIndex + 1);
    if (!item.negative && !item.color) {
      colorIndex++;
    }
    const previousColor =
      SANKEY_DIAGRAM_COLORS[colorIndex === 0 ? 0 : colorIndex - 1];
    return [
      item.from,
      item.to,
      item.weight,
      createDataColor(
        merge(
          {
            transitionColor: item.transitionColor || [
              previousColor + 'CC',
              transitionTargetColor + 'EE',
            ],
          },
          item,
        ),
      ),
    ];
  });
};

export const renderSankeyTooltip = ({
  dateRange,
  palette,
  point,
}: {
  dateRange: string;
  palette: Palette;
  point: SankeyPoint;
}) => {
  const tooltipComponent = (
    <div
      style={{
        border: `1px solid ${palette.colors.slate[200]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflow: 'hidden',
        padding: '8px',
      }}
    >
      <span style={{ fontSize: '14px', fontWeight: 600 }}>{point.name}</span>
      <div style={{ alignItems: 'center', columnGap: '4px', display: 'flex' }}>
        <span style={{ color: point.color }}>■</span>
        <span style={{ color: palette.colors.grey[600], fontSize: '12px' }}>
          {dateRange}
        </span>
      </div>
      <div
        style={{
          alignItems: 'center',
          columnGap: '4px',
          display: 'flex',
          marginLeft: '16px',
        }}
      >
        <span style={{ fontWeight: 600 }}>{point.sum?.toLocaleString()}</span>
        <span style={{ color: palette.colors.grey[600] }}>tickets</span>
      </div>
    </div>
  );
  return ReactDOMServer.renderToString(tooltipComponent);
};
