import { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import {
  BotTTSTextData,
  RTVIEvent,
  TranscriptData,
} from '@pipecat-ai/client-js';
import {
  useRTVIClientEvent,
  useRTVIClientTransportState,
} from '@pipecat-ai/client-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import set from 'lodash/fp/set';
import { AgathaMessageContainer } from 'src/pages/intent-conversation-analytics/ConversationTranscript/StyledComponents';
import { VoiceTranscript } from 'src/slices/workflow-preview/types';

export function VoiceInfoConsole() {
  const endRef = useRef<HTMLDivElement>(null);
  const transportState = useRTVIClientTransportState();
  const [transcripts, setTranscripts] = useState<VoiceTranscript[]>([]);

  useEffect(() => {
    if (endRef?.current) {
      endRef.current.scrollIntoView();
    }
  }, [transcripts]);

  useEffect(() => {
    if (transportState === 'initializing' || transportState === 'connecting') {
      setTranscripts([]);
    }
  }, [transportState]);

  const updateLatestBotTranscript = useCallback((botVoice: string) => {
    setTranscripts(prevTranscripts => {
      const latestTranscript = prevTranscripts[prevTranscripts.length - 1];

      if (!latestTranscript || latestTranscript.from === 'user') {
        return prevTranscripts;
      }

      const newLatestTranscript = {
        ...latestTranscript,
        text: latestTranscript.text.concat(` ${botVoice}`),
      };

      const updatedTranscripts: VoiceTranscript[] = set(
        [prevTranscripts.length - 1],
        newLatestTranscript,
        prevTranscripts,
      );

      return updatedTranscripts;
    });
  }, []);

  const updateLatestUserTranscript = useCallback(
    (userVoice: string, isFinal: boolean) => {
      setTranscripts(prevTranscripts => {
        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];

        if (!latestTranscript || latestTranscript.from === 'bot') {
          return prevTranscripts;
        }

        // if latestTranscript msg is a user transcript and has isFinal true, we should create a new user transcript
        if (latestTranscript.isFinal) {
          const newLatestTranscript: VoiceTranscript = {
            from: 'user',
            isFinal: isFinal,
            text: userVoice,
          };

          return prevTranscripts.concat([newLatestTranscript]);
        }

        // prev transcript is not a final user transcript, modify the transcript directly
        const newLatestTranscript = {
          ...latestTranscript,
          isFinal: isFinal,
          text: userVoice,
        };

        const updatedTranscripts: VoiceTranscript[] = set(
          [prevTranscripts.length - 1],
          newLatestTranscript,
          prevTranscripts,
        );

        return updatedTranscripts;
      });
    },
    [],
  );

  const handleStartSpeaking = useCallback((from: 'user' | 'bot') => {
    setTranscripts(prevTranscripts => {
      const newLatestTranscript = { from: from, text: '' };

      return prevTranscripts.concat([newLatestTranscript]);
    });
  }, []);

  useRTVIClientEvent(
    RTVIEvent.BotStartedSpeaking,
    useCallback(() => {
      handleStartSpeaking('bot');
    }, [handleStartSpeaking]),
  );

  useRTVIClientEvent(
    RTVIEvent.UserStartedSpeaking,
    useCallback(() => {
      handleStartSpeaking('user');
    }, [handleStartSpeaking]),
  );

  useRTVIClientEvent(
    RTVIEvent.BotTtsText,
    useCallback(
      (data: BotTTSTextData) => {
        updateLatestBotTranscript(data.text);
      },
      [updateLatestBotTranscript],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.UserTranscript,
    useCallback(
      (data: TranscriptData) => {
        updateLatestUserTranscript(data.text, data.final);
      },
      [updateLatestUserTranscript],
    ),
  );

  return (
    <VoiceInfoConsoleContainer>
      <Typography variant='font16Bold'>Conversation Transcript</Typography>
      <TranscriptContainer>
        {transcripts.map(
          (transcript, index) =>
            transcript.text && (
              <AgathaMessageContainer
                alignSelf={
                  transcript.from === 'user' ? 'flex-end' : 'flex-start'
                }
                backgroundColor={transcript.from === 'user' ? 'black' : 'white'}
                key={index}
                maxWidth='90%'
                minHeight='fit-content'
              >
                <Typography
                  color={transcript.from === 'user' ? 'white' : 'black'}
                  variant='font16'
                >
                  {transcript.text}
                </Typography>
              </AgathaMessageContainer>
            ),
        )}
        <div ref={endRef} />
      </TranscriptContainer>
    </VoiceInfoConsoleContainer>
  );
}

const VoiceInfoConsoleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background-color: rgb(55, 55, 55, 0.3);
  color: white;
  padding: 24px;

  box-shadow: ${props => props.theme.shadows[3]};
`;

const TranscriptContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  overflow: auto;
  padding: 0 8px 0 0;
`;
