import { useMemo } from 'react';

export const useGetCurrentTab = ({
  articleId,
  isParentTopic,
  tab,
  tableTab,
  topicId,
  workflowId,
}: {
  articleId?: string;
  isParentTopic?: boolean;
  tab?: string;
  tableTab?: string;
  topicId?: string;
  workflowId?: string;
}) => {
  return useMemo(() => {
    if (topicId || isParentTopic) {
      return 'topic';
    }
    if (workflowId || tab === '3') {
      return 'workflow';
    }
    if (articleId || tableTab === '1' || tab === '1') {
      return 'article';
    }
    return 'chat';
  }, [articleId, isParentTopic, tab, tableTab, topicId, workflowId]);
};
