import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import EmptyTabState from '../components/EmptyTabState';
import BrandFilter from '../discover-recommendation-tab/BrandFilter';
import { filterByBrand, handleBrandColumn } from '../helpers';
import { TABLE_COLUMNS } from './constants';
import DashboardTable from 'src/components/dashboard-table';
import {
  KnowledgeGapArticleRecommendation,
  KnowledgeGapArticleRecommendations,
} from 'src/reducers/discoverReducer/types';

interface KnowledgeGapTableProps {
  data?: KnowledgeGapArticleRecommendations['articles'];
  hasBrands?: boolean;
  isError: boolean;
  loading: boolean;
  onMouseEnter?: (topicName: string) => void;
  onScroll?: (scroll: number) => void;
  onSelect: (row: { original: { article_id: string } }) => void;
}

const KnowledgeGapTable = ({
  data,
  hasBrands,
  isError,
  loading,
  onScroll,
  onSelect,
}: KnowledgeGapTableProps) => {
  const [searchText, setSearchText] = useState('');
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const rows = useMemo(() => {
    return data?.filter(row =>
      filterByBrand(selectedBrands, row.api_data_filter),
    );
  }, [data, selectedBrands]);

  const columns = useMemo(
    () =>
      handleBrandColumn<KnowledgeGapArticleRecommendation>(
        data,
        TABLE_COLUMNS,
        hasBrands,
      ),
    [hasBrands, data],
  );

  return (
    <DashboardTable<KnowledgeGapArticleRecommendation>
      columns={columns}
      data={rows || []}
      downloadExportType='discover_article_recommendations_table'
      downloadRequestData={{}}
      FilterComponent={
        <Box display='flex' gap={1}>
          {hasBrands && (
            <BrandFilter
              data={data || []}
              onChange={setSelectedBrands}
              selectedTab='knowledge'
              value={selectedBrands}
            />
          )}
        </Box>
      }
      initialSorting={[{ desc: true, id: 'num_of_tickets_in_article' }]}
      isError={isError}
      isLoading={loading}
      onClick={onSelect}
      onSearch={setSearchText}
      searchFields={['generated_title']}
      searchPlaceholderText='Search article title'
      searchText={searchText}
      stickyHeaderHeight={0}
      tableOptions={{
        muiTableContainerProps: () => ({
          onScroll: event => onScroll?.(event.currentTarget.scrollTop),
          sx: {
            height: 'calc(100vh - 300px)',
          },
        }),
        renderEmptyRowsFallback: () => <EmptyTabState type='knowledge' />,
      }}
    />
  );
};

export default KnowledgeGapTable;
