import { Box, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  ContextVariableError,
  EmailWorkflowValidationError,
  ResponseCountError,
} from 'src/pages/workflow-builder-edit/types';

const CONTEXT_VARIABLE_MISSING = 'context_variable_missing';
const RESPONSE_COUNT_INVALID = 'response_count_invalid';
const TERMINAL_RULE_STEP = 'terminal_rule_step';
const TRANSITION_STEP_MISSING = 'transition_step_missing';
const ACTION_MISSING = 'action_missing';

interface ValidationErrorModalProps {
  stepValidationErrors: EmailWorkflowValidationError[];
}

const ValidationErrorModal = ({
  stepValidationErrors,
}: ValidationErrorModalProps) => {
  const { palette } = useTheme();

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldIncludeTriageContextVariables: true,
  });

  const getContextVariableName = (contextVariableId: string) => {
    return contextVariables.find(
      cv => cv.context_variable_id === contextVariableId,
    )?.context_variable_name;
  };

  const contextVarMissingErrors = stepValidationErrors.filter(
    error => error.error_type === CONTEXT_VARIABLE_MISSING,
  );

  const responseCountInvalidError = stepValidationErrors.find(
    error => error.error_type === RESPONSE_COUNT_INVALID,
  );

  const terminalRuleStepError = stepValidationErrors.find(
    error => error.error_type === TERMINAL_RULE_STEP,
  );

  const transitionStepError = stepValidationErrors.find(
    error => error.error_type === TRANSITION_STEP_MISSING,
  );

  const missingActionError = stepValidationErrors.find(
    error => error.error_type === ACTION_MISSING,
  );

  const interactiveEmailError = stepValidationErrors.find(
    error => error.error_type === 'interactive_email_workflow_invalid',
  );

  const isAutopilotError = stepValidationErrors.some(
    error => error.error_type === 'autopilot_configuration_invalid',
  );

  return (
    <Box
      sx={{
        color: palette.colors.white,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      {responseCountInvalidError && (
        <Box>
          <Typography variant='font12'>
            {(responseCountInvalidError?.error_data as ResponseCountError)
              .num_response_steps_in_path === 0
              ? 'Journey map paths must have one email response. Please add a response from the panel on the right.'
              : 'Journey map paths can only have one email response. Please remove additional responses before publishing.'}
          </Typography>
        </Box>
      )}

      {contextVarMissingErrors?.length > 0 && (
        <Box>
          <Typography variant='font12'>Input not defined:</Typography>

          <UnorderderList>
            {contextVarMissingErrors.map(error => {
              const errorData = error.error_data as ContextVariableError;
              const contextVarId = errorData.context_variable_id;
              return (
                <li key={contextVarId}>
                  <Typography variant='font12'>{`$${getContextVariableName(
                    contextVarId,
                  )}`}</Typography>
                </li>
              );
            })}
          </UnorderderList>
        </Box>
      )}

      {terminalRuleStepError && (
        <Box>
          <Typography variant='font12'>
            Journey map paths cannot end in a rule step.
          </Typography>
        </Box>
      )}

      {transitionStepError && (
        <Box>
          <Typography variant='font12'>
            Each path must have a step attached. Either add a step or delete the
            path(s) without a step attached.
          </Typography>
        </Box>
      )}

      {missingActionError && (
        <Box>
          <Typography variant='font12'>
            The action for this step has been deleted, delete this step and
            publish new changes
          </Typography>
        </Box>
      )}

      {interactiveEmailError && (
        <Box>
          <Typography variant='font12'>
            The Interactive Email workflow is invalid. Please navigate to the
            &apos;Interactive&apos; tab to fix any issues.
          </Typography>
        </Box>
      )}

      {isAutopilotError && (
        <Box>
          <Typography variant='font12'>
            The Autopilot workflow is invalid. Please navigate to the Autopilot
            workflow for this response to fix any issues.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ValidationErrorModal;

const UnorderderList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
`;
