import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconCircleFilled } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { textStyles } from './constants';
import TicketTimeStamp from './TicketTimeStamp';
import capitalize from 'lodash/capitalize';

interface TicketResponseProps {
  authorType?: string;
  body: string;
  timeStamp: string;
}

const TicketResponse = ({
  authorType,
  body,
  timeStamp,
}: TicketResponseProps) => {
  const { palette } = useTheme();

  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={1.5}
      sx={{ svg: { color: palette.colors.grey[700] }, wordWrap: 'break-word' }}
    >
      <TicketTimeStamp dateCreated={timeStamp as string}>
        {Boolean(authorType) && (
          <>
            <Typography color={palette.colors.grey[700]} variant='font14'>
              {capitalize(authorType)}
            </Typography>
            <IconCircleFilled size={4} />
          </>
        )}
      </TicketTimeStamp>
      <Box
        dangerouslySetInnerHTML={{ __html: body || '' }}
        sx={textStyles}
      ></Box>
    </Box>
  );
};

export default TicketResponse;
