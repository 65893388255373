import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { TriageTestPredictionResult } from 'src/reducers/triageSettingsReducer/types';

interface PredictionReasoningProps {
  prediction: TriageTestPredictionResult[];
}

const PredictionReasoning = ({ prediction }: PredictionReasoningProps) => {
  const { palette } = useTheme();

  if (!prediction.length) {
    return null;
  }

  const data = prediction[0];

  if (!data.prediction_reasoning) {
    return null;
  }

  return (
    <Box
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      display='flex'
      flexDirection='column'
      gap={1}
      mb={4}
      pb={3}
    >
      <Typography variant='font14Medium'>Prediction reasoning</Typography>
      <Typography variant='font14'>{data.prediction_reasoning}</Typography>
    </Box>
  );
};

export default PredictionReasoning;
