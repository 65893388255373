import { useMemo } from 'react';
import Box from '@mui/material/Box';

import {
  MultiSelectFilter,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import StatusIcon from '../StatusIcon';
import { createOptions, createWidth } from './helpers';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetInsightModelMappingQuery } from 'src/services/triage/triageApi';

interface TicketFilterProps {
  isTableFilter?: boolean;
  onChange: (value: string[]) => void;
  value: string[];
}

const TicketFilter = ({
  isTableFilter,
  onChange,
  value,
}: TicketFilterProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const { data, isFetching, isLoading } = useGetInsightModelMappingQuery();
  const options = useMemo(
    () =>
      createOptions(data?.model_labels || [], value, ({ isEnabled }) => (
        <StatusIcon isEnabled={isEnabled} />
      )),
    [data?.model_labels, value],
  );

  const menuWidth = useMemo(
    () => createWidth(data?.model_labels),
    [data?.model_labels],
  );

  if (isLoading) {
    return (
      <Box sx={{ '&>span': { transform: 'scale(1)' } }}>
        <Skeleton height='33px' width={140} />
      </Box>
    );
  }

  const isDisabled = isFetching || !data?.model_labels.length;

  const handleChange = (values: string[]) => {
    const eventType = isTableFilter
      ? 'triage-insights-table-filter-changed'
      : 'triage-insights-header-filter-changed';
    emitTrackingEventCallback(eventType, {
      model: values,
    });

    onChange(values);
  };

  return (
    <Box
      sx={isDisabled ? { opacity: '0.75', pointerEvents: 'none' } : undefined}
    >
      <MultiSelectFilter
        icon={<img src={filterIcon} />}
        isMenuSearchable
        menuWidth={menuWidth}
        onChange={handleChange}
        options={options}
        placeholder='Filter by'
        value={value}
        variant='secondary'
      />
    </Box>
  );
};

export default TicketFilter;
