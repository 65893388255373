import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  NonIdealState,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import emptyIntegrations from 'src/assets/images/empty-integrations.svg';

export default function IntegrationsEmptyState() {
  return (
    <Container>
      <NonIdealState icon={<img src={emptyIntegrations} />} title=''>
        <Box marginTop={4}>
          <Typography color={theme.palette.colors.grey[500]} variant='font14'>
            No integrations connected yet. Let&apos;s get started by connecting
            an integration.
          </Typography>
        </Box>
      </NonIdealState>
    </Container>
  );
}

const Container = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 66px;
`;
