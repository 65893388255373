import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { Box } from '@mui/material';

import {
  Button,
  Checkbox,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { LoadingSkeleton } from '../../../components/reusable-components/loading-skeleton';
import KustomerChatHandoffOnlyConfiguration from '../handoff-configuration/kustomer/chat-handoff-only';
import { Icon, Label, Row } from '../handoff-configuration/styles';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import { getKustomerCustomAttributesData } from './utils';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { hasAtLeastOneError } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import {
  initializeFieldTouchedArr,
  validateZendeskOrSalesforceContextVariables,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  KustomerChatHandoffCustomization,
  KustomerHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type KustomerChatHandoffCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

const KustomerChatHandoffCustomizationPanel: FC<
  React.PropsWithChildren<KustomerChatHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const theme = useTheme();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultKustomerHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<KustomerHandoffCustomization>;
  const defaultKustomerChatHandoffCustomizationSettings = useMemo(
    () => ({
      agent_chat_handoff_settings: {
        confirmation_message: '',
        custom_attributes: [],
        handoff_form_prompt: '',
        kustomer_custom_attributes: [],
        write_transcript_to_note: true,
        ...defaultKustomerHandoffCustomizationSettings?.agent_chat_handoff_settings,
      },
      email: null,
      jwt: null,
      name: null,
      one_chat: false,
      question: 'free_form_query',
    }),
    [defaultKustomerHandoffCustomizationSettings?.agent_chat_handoff_settings],
  );
  const handoffCustomization = addingAction
    ? defaultKustomerChatHandoffCustomizationSettings
    : (canvasState?.actionSettings
        .configuration_fields as KustomerChatHandoffCustomization);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const dispatch = useDispatch();

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<KustomerChatHandoffCustomization>(handoffCustomization);

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  const errorMessages = validateZendeskOrSalesforceContextVariables({
    customizationData: customizationData,
    shouldAllowEmptyName: true,
  });

  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const isPristine = isEqual(customizationData, handoffCustomization);

  const { custom_attributes = [], kustomer_custom_attributes = [] } =
    customizationData?.agent_chat_handoff_settings || {};

  const kustomerCustomAttributesData = getKustomerCustomAttributesData(
    custom_attributes,
    kustomer_custom_attributes,
  );

  const kustomerAttributesContainErrors = kustomerCustomAttributesData.some(
    attribute => attribute.error,
  );

  const isSaveDisabledFromForm =
    hasWorkflowConflict ||
    Boolean(emailErrorMessage) ||
    Boolean(questionErrorMessage) ||
    kustomerAttributesContainErrors ||
    (!addingAction && isPristine);

  useEffect(() => {
    setAreActionSettingsUnsaved(!isPristine);
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(
        addingAction
          ? defaultKustomerChatHandoffCustomizationSettings
          : handoffCustomization,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    handoffCustomization,
    addingAction,
    defaultKustomerChatHandoffCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF),
      );
    }
  }, [dispatch, addingAction]);

  if (
    canvasState.isLoadingCustomization ||
    !handoffCustomization ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
        height: '100%',
      }}
    >
      {/* title */}
      <Box sx={{ display: 'flex', padding: '0 15px' }}>
        <Typography component='h1' variant='font20Medium'>
          Kustomer Chat Handoff
        </Typography>
      </Box>
      {/* handoff settings */}
      <KustomerChatHandoffOnlyConfiguration
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      {/* one chat specific settings */}
      {featureFlags.includes('kustomer_one_chat') && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.colors.grey[100]}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            padding: '28px 15px 15px 15px',
          }}
        >
          <Typography component='h2' variant='font18Bold'>
            Kustomer One Chat Settings
          </Typography>
          <Checkbox
            checked={customizationData.one_chat}
            label='Enable Kustomer One Chat'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                one_chat: !customizationData.one_chat,
              });
            }}
          />
          {/* JWT CV */}
          <Box>
            <Row>
              <Label>Login JWT Context Variable</Label>
              <Tooltip
                tooltipContent={
                  'Optional field which performs Kustomer User Authentication when provided.'
                }
              >
                <Icon src={tooltipInfoIcon} />
              </Tooltip>
            </Row>
            <ContextVariableSelectDropdown
              aria-label='context variable'
              isClearable
              onChange={value => {
                setCustomizationData({
                  ...customizationData,
                  jwt: value,
                });
              }}
              value={customizationData.jwt ?? undefined}
            />
          </Box>
        </Box>
      )}
      {/* save and cancel buttons */}
      <Box display='flex' gap={2} padding='15px'>
        <Button
          onClick={() => {
            onDiscard();
          }}
          size='large'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          disabled={isSaveDisabledFromForm}
          fullWidth
          onClick={() => {
            setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

            if (hasAtLeastOneError(errorMessages)) {
              return;
            }

            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields: customizationData,
                    },
                  })
                : updateActionSettings({
                    action_type: canvasState.actionSettings
                      .action_type as string,
                    configuration_fields: customizationData,
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default KustomerChatHandoffCustomizationPanel;
