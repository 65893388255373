import React, { useState } from 'react';
import Box from '@mui/material/Box';

import {
  DateRangeFilterButton,
  DateRangeListOption,
  SearchBar,
  SearchWithDropdown,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedCall } from 'src/pages/events/audit/hooks';
import { DateRange } from 'src/types/types';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

export interface DateRangeFilter {
  initialValue?: DateRange;
  onChange: (value: DateRange) => void;
  options?: DateRangeListOption[];
  value: DateRange;
}

const TableFilters = ({
  dateRangeFilter,
  FilterComponent,
  isLoading,
  onSearch,
  onSearchTypeChange,
  placeholder = 'Search',
  searchDisabled,
  searchOptions,
  searchText,
  searchType,
}: {
  dateRangeFilter?: DateRangeFilter;
  FilterComponent: React.ReactNode;
  isLoading: boolean;
  onSearch?: (value: string) => void;
  onSearchTypeChange?: (value: string) => void;
  placeholder?: string;
  searchDisabled?: boolean;
  searchOptions?: { text: string; value: string }[];
  searchText?: string;
  searchType?: string;
}) => {
  const [uiSearch, setUiSearch] = useState(searchText);
  const debounceCall = useDebouncedCall();
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (onSearch) {
      const text = event.target.value;

      setUiSearch(text);
      debounceCall(() => onSearch(text));
    }
  };

  const handleTypeChange = (typeValue: string) => {
    if (onSearchTypeChange) {
      setUiSearch('');
      onSearchTypeChange(typeValue);
    }
  };

  const isSearchWithDropdown =
    onSearchTypeChange &&
    searchType &&
    searchOptions &&
    Boolean(searchOptions?.length);

  return (
    <Box alignItems='center' columnGap={1} display='flex'>
      <Box width='260px'>
        {isSearchWithDropdown && (
          <SearchWithDropdown
            disabled={searchDisabled || isLoading}
            onChange={handleChange}
            options={searchOptions}
            placeholder={placeholder}
            selectChange={handleTypeChange}
            selectedOption={searchType}
            value={uiSearch}
          />
        )}
        {!isSearchWithDropdown && (
          <SearchBar
            disabled={searchDisabled}
            onChange={handleChange}
            onClear={
              onSearch
                ? () => {
                    setUiSearch('');
                    onSearch('');
                  }
                : undefined
            }
            placeholder={placeholder}
            size='small'
            value={uiSearch}
          />
        )}
      </Box>
      {dateRangeFilter && (
        <DateRangeFilterButton
          initialValue={dateRangeFilter.initialValue}
          onChange={dateRangeFilter.onChange}
          options={dateRangeFilter.options || datePickerRangeOptionsRevamp}
          value={dateRangeFilter.value}
        />
      )}
      {FilterComponent}
    </Box>
  );
};

export default TableFilters;
