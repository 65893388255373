import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconPhotoCancel } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DynamicCardSelectionTranscriptComponent } from './types';

const MAX_IMAGE_COMPONENT_IMAGES = 3;
const IMAGE_HEIGHT = '160px';
const SLIDE_SPACING = 8;
const SLIDE_PERCENT_OF_CONTAINER = '90%';

export const DynamicCardSelectionStep = ({
  transcriptComponent,
}: {
  transcriptComponent: DynamicCardSelectionTranscriptComponent;
}) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        flex: `0 0 ${SLIDE_PERCENT_OF_CONTAINER}`,
        marginLeft: 'auto',
        minWidth: 0,
        paddingLeft: `${SLIDE_SPACING}px`,
      }}
    >
      <Box
        borderRadius='8px'
        component='button'
        display='flex'
        flexDirection='column'
        gap={1}
        p={2}
        sx={[
          {
            backgroundColor: 'white',
            border: `1px solid ${palette.colors.slate[300]}`,
            boxShadow: '1px 0px 8px 0px rgba(0, 0, 0, 0.08)',
            textAlign: 'left',
            transition: 'all 0.2s ease-in-out',
            width: '100%',
          },
        ]}
      >
        {transcriptComponent.fields.displayable_dynamic_sections.map(
          (section, index) => (
            <Section key={index} section={section} />
          ),
        )}
      </Box>
    </Box>
  );
};

const Section = ({
  section,
}: {
  section: DynamicCardSelectionTranscriptComponent['fields']['displayable_dynamic_sections'][number];
}) => {
  const { palette } = useTheme();

  switch (section.type) {
    case 'content':
      return (
        <Box display='flex' flexDirection='column' gap={0.5}>
          {section.components.map((component, index) => (
            <Box key={index}>
              {component.label && (
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  {component.label}
                </Typography>
              )}
              <Typography color={palette.colors.black} variant='font14'>
                {' '}
                {component.value}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    case 'image':
      return section.components.length ? (
        <Box display='flex' gap={1} width='100%'>
          {section.components
            .slice(0, MAX_IMAGE_COMPONENT_IMAGES)
            .map((section, key) => (
              <Box
                key={key}
                sx={{
                  backgroundColor: palette.colors.white,
                  border: `1px solid ${palette.colors.slate[200]}`,
                  borderRadius: '8px',
                  height: IMAGE_HEIGHT,
                  img: { height: '100%', objectFit: 'contain', width: '100%' },
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                <img alt='' src={section.value} />
              </Box>
            ))}
        </Box>
      ) : (
        <EmptyImage />
      );
    case 'title':
      return (
        <Box display='flex' flexDirection='column' gap={0.5}>
          {section.components.map((component, index) => (
            <Box key={index}>
              <Typography color={palette.colors.black} variant='font16Bold'>
                {component.label} {component.value}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    default:
      return null;
  }
};

const EmptyImage = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[200]}
      borderRadius='8px'
      display='flex'
      height={IMAGE_HEIGHT}
      justifyContent='center'
      width='100%'
    >
      <Box alignItems='center' display='flex' flexDirection='column' gap={1.5}>
        <IconPhotoCancel color={palette.colors.grey[600]} size={24} />
        <Typography color={palette.colors.grey[600]} variant='font14'>
          No image available
        </Typography>
      </Box>
    </Box>
  );
};
