import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import OverviewItem from './OverviewItem';
import { SankeyOverviewData } from './types';

export interface SankeyOverviewProps {
  data: SankeyOverviewData[];
  gridTemplateColumns?: string;
  loading: boolean;
}

const SankeyOverview = ({
  data,
  gridTemplateColumns = 'repeat(5, 1fr)',
  loading,
}: SankeyOverviewProps) => {
  return (
    <Box
      sx={{
        containerType: 'inline-size',
        width: '100%',
      }}
    >
      <ContainerBox
        display='grid'
        gridTemplateColumns={gridTemplateColumns}
        sx={{
          marginLeft: '10px',
          width: '96%',
        }}
      >
        {data.map((item, index) => (
          <OverviewItem item={item} key={index} loading={loading} />
        ))}
      </ContainerBox>
    </Box>
  );
};

const ContainerBox = styled(Box)`
  @container (min-width: 1100px) {
    width: 96.5%;
  }
  @container (min-width: 1200px) {
    width: 97%;
  }
  @container (min-width: 1300px) {
    width: 97.5%;
  }
  @container (min-width: 1600px) {
    width: 97.8%;
  }
  @container (min-width: 1800px) {
    width: 98%;
  }
  @container (min-width: 2050px) {
    width: 98.3%;
  }
  @container (min-width: 2300px) {
    width: 98.5%;
  }
`;

export default SankeyOverview;
