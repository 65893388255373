import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import CsatTabContent from './CsatTabContent';
import QuickFeedbackTabContent from './QuickFeedbackTabContent';
import Spinner from 'src/components/spinner';
import { URLParamTabs } from 'src/components/url-param-tabs/URLParamTabs';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

const CsatQuickFeedbackParentTabContent = () => {
  const { data: featureFlagsData, isLoading: isFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('sub-tab') ?? '';

  const page = useMemo(() => {
    switch (query) {
      case 'quick feedback':
        return <QuickFeedbackTabContent />;
      case 'csat':
      default:
        return <CsatTabContent />;
    }
  }, [query]);

  if (isFeatureFlagsLoading) {
    return <Spinner />;
  }

  if (!isQuickFeedbackEnabled) {
    return <CsatTabContent />;
  }

  return (
    <Box>
      <Box mb={3}>
        <URLParamTabs
          ariaLabel='solve channel tabs'
          paramName='sub-tab'
          tabs={['CSAT', 'Quick Feedback']}
          typographyVariant='font24'
        />
      </Box>
      {page}
    </Box>
  );
};

export default CsatQuickFeedbackParentTabContent;
