import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import { InsightPeriod } from '../../solve-insights/types';
import { INSIGHT_MODEL_PARAMS_NAME, Y_AXIS_OPTIONS } from '../constants';
import EmptyState from '../EmptyState';
import { INITIAL_LEGENDS } from './constants';
import HeaderFilters from './HeaderFilters';
import {
  createLineChartData,
  createOverviewMetrics,
  createSankeyData,
} from './helpers';
import TableSection from './TableSection';
import DashboardChartSection from 'src/components/dashboard-chart-section';
import SankeyDiagramSection from 'src/components/sankey-diagram-section/SankeyDiagramSection';
import { timeFilterOptions } from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { LineGraphDataType } from 'src/services/insights/types';
import { createTimeStampObject } from 'src/services/triage/helpers';
import {
  useGetInsightModelMappingQuery,
  useGetModelInsightsChartQuery,
  useGetModelInsightsMetricsQuery,
  useGetModelInsightsTicketCountQuery,
} from 'src/services/triage/triageApi';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const TriageInsightsModelsTab = () => {
  const [enabledLegends, setEnabledLegends] = useState(INITIAL_LEGENDS);
  const { data: models, isLoading: isModelsLoading } =
    useGetInsightModelMappingQuery();
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: 'triage-insights-time-range',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [periodicalFilter, setPeriodicalFilter] =
    useState<InsightPeriod>('daily');

  const [selectedModel, setSelectedModel] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: INSIGHT_MODEL_PARAMS_NAME,
    serialize: value => value,
  });

  const selectedModelData = models?.model_labels.find(
    item => item.model_name === selectedModel,
  );
  const {
    data: metricChartData,
    isError: isChartError,
    isFetching: isChartFetching,
    isLoading: isChartLoading,
  } = useGetModelInsightsChartQuery(
    {
      endDate: Number(dateRange.to),
      interval: periodicalFilter,
      modelName: selectedModelData?.model_name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !selectedModelData },
  );

  const {
    data: ticketCountMetrics,
    isError: isTicketCountError,
    isFetching: ticketMetricDataIsFetching,
  } = useGetModelInsightsTicketCountQuery(
    {
      endDate: Number(dateRange.to),
      interval: periodicalFilter,
      modelName: selectedModelData?.model_name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !enabledLegends.find(item => item === 'Total tickets') },
  );
  const {
    data,
    isError: isMetricDataError,
    isFetching: metricDataIsFetching,
    isLoading: metricDataIsLoading,
  } = useGetModelInsightsMetricsQuery(
    {
      endDate: Number(dateRange.to),
      modelName: selectedModelData?.model_name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !selectedModelData },
  );

  useEffect(() => {
    if (!selectedModel && models?.model_labels.length) {
      setSelectedModel(models.model_labels[0].model_name);
    }
  }, [isModelsLoading, models, selectedModel, setSelectedModel]);

  const overviewMetrics = useMemo(() => {
    return createOverviewMetrics({
      data: isMetricDataError ? undefined : data?.overview,
      isLoading: metricDataIsLoading,
    });
  }, [data?.overview, metricDataIsLoading, isMetricDataError]);

  const sankeyData = useMemo(() => {
    if (isMetricDataError) {
      return [];
    }
    return createSankeyData(data?.overview);
  }, [data?.overview, isMetricDataError]);

  const handleToggleVisibility = (legend: string) => {
    const isEnabled = enabledLegends.includes(legend);
    setEnabledLegends(
      isEnabled
        ? enabledLegends.filter(item => item !== legend)
        : [...enabledLegends, legend],
    );
  };

  const lineGraphData = useMemo(() => {
    if (isChartError || isTicketCountError) {
      return undefined;
    }
    return createLineChartData({
      enabledLegends,
      metricChartData,
      ticketCountMetrics,
    });
  }, [
    metricChartData,
    ticketCountMetrics,
    enabledLegends,
    isChartError,
    isTicketCountError,
  ]);

  const dataFilter = {
    ...createTimeStampObject(Number(dateRange.from), Number(dateRange.to)),
    model_name: selectedModelData?.model_name || '',
  };

  const labels = useMemo(() => {
    if (isMetricDataError) {
      return [];
    }
    return data?.labels || [];
  }, [data, isMetricDataError]);

  if (!models?.model_labels.length && !isModelsLoading) {
    return <EmptyState />;
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <HeaderFilters
        models={models?.model_labels || []}
        modelsLoading={isModelsLoading}
        onDateRangeChange={setDateRange}
        onModelChange={setSelectedModel}
        selectedDateRange={dateRange}
        selectedModel={selectedModel}
      />
      <SankeyDiagramSection
        data={sankeyData}
        dateRange={dateRange}
        loading={
          metricDataIsLoading ||
          metricDataIsFetching ||
          isModelsLoading ||
          data === undefined
        }
        overview={overviewMetrics}
      />
      <DashboardChartSection
        data={lineGraphData as LineGraphDataType}
        dateRange={dateRange}
        enabledLegends={enabledLegends}
        hasError={isChartError || isTicketCountError}
        isFetching={ticketMetricDataIsFetching || isChartFetching}
        isLoading={isChartLoading || isModelsLoading}
        onToggleVisibility={handleToggleVisibility}
        periodicalFilter={periodicalFilter}
        setPeriodicalFilter={setPeriodicalFilter}
        title='Time series chart'
        yAxis={Y_AXIS_OPTIONS}
      />
      <TableSection
        dataFilter={dataFilter}
        isFetching={metricDataIsFetching}
        isLoading={metricDataIsLoading || isModelsLoading}
        labels={labels}
        models={models?.model_labels || []}
        modelsLoading={isModelsLoading}
        onDateRangeChange={setDateRange}
        onModelChange={setSelectedModel}
        selectedDateRange={dateRange}
        selectedModel={selectedModel}
        selectedModelName={selectedModelData?.model_name || ''}
      />
    </Box>
  );
};

export default TriageInsightsModelsTab;
