import { useCallback } from 'react';
import linkifyHtml from 'linkify-html';

import { TextTranscriptComponent, TranscriptComponent } from './types';

const ignorePatterns = [/\d+\.Select$/, /\w+\.properties$/];

export function safeLinkifyHtml(html: string): string {
  try {
    return linkifyHtml(html, {
      rel: 'noreferrer',
      target: '_blank',
      truncate: 42,
      validate: {
        url: value =>
          !ignorePatterns.some(ignorePattern => ignorePattern.test(value)),
      },
    });
  } catch {
    return html;
  }
}

export const useGetMessageBubblePosition = (
  transcriptComponents: TranscriptComponent[],
) => {
  const getMessageBubblePosition = useCallback(
    (index: number) => {
      const component = transcriptComponents[index];
      const nextComponent = transcriptComponents[index + 1];
      const prevComponent = transcriptComponents[index - 1];

      if (
        !component ||
        component.message_type !== 'text' ||
        component.component_type !== 'output'
      ) {
        return 'lone';
      }

      if (
        prevComponent?.message_type === 'text' &&
        prevComponent.component_type === 'output'
      ) {
        if (
          nextComponent?.message_type === 'text' &&
          nextComponent.component_type === 'output'
        ) {
          return 'middle';
        } else {
          return 'last-in-a-batch';
        }
      } else if (
        nextComponent?.message_type === 'text' &&
        nextComponent.component_type === 'output'
      ) {
        if (nextComponent?.message_type === 'text') {
          return 'first-in-a-batch';
        } else {
          return 'lone';
        }
      }

      return 'lone';
    },
    [transcriptComponents],
  );

  return getMessageBubblePosition;
};

const OUTPUT_MESSAGES_TO_GROUP = ['text', 'image', 'embed'];

export const useGetComponentTopPadding = (
  transcriptComponents: TranscriptComponent[],
) => {
  const getComponentTopPadding = useCallback(
    (index: number) => {
      const component = transcriptComponents[index];
      const prevComponent = transcriptComponents[index - 1];

      if (!prevComponent || !component) {
        return '16px';
      }

      if (OUTPUT_MESSAGES_TO_GROUP.includes(component.message_type)) {
        if ((component as TextTranscriptComponent).message === '') {
          return '0px';
        }
        if (
          OUTPUT_MESSAGES_TO_GROUP.includes(prevComponent.message_type) &&
          prevComponent.component_type === component.component_type
        ) {
          return '4px';
        }
      }

      return '16px';
    },
    [transcriptComponents],
  );

  return getComponentTopPadding;
};
