import { Box, useTheme } from '@mui/material';
import { IconMicrophone } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function VoiceMockContent() {
  const theme = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor='rgba(255, 255, 255, 0.7);'
      display='flex'
      flexDirection='column'
      height='100%'
    >
      <Box
        sx={{
          background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
          fontStyle: 'italic',
          marginTop: '80px',
          textAlign: 'center',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        <Typography variant='font16'>
          Configure voice integration using the menu on the right
        </Typography>
      </Box>

      <Box marginTop='80px'>
        <Typography color={theme.palette.grey[700]} variant='font28'>
          Hello and welcome!
        </Typography>
      </Box>

      <Box marginTop='8px'>
        <Typography color={theme.palette.grey[700]} variant='font14Medium'>
          What can I do to assist you today?
        </Typography>
      </Box>

      <Box
        alignItems='center'
        bgcolor={theme.palette.colors.slate[100]}
        borderRadius='50%'
        display='flex'
        height='80px'
        justifyContent='center'
        mt='24px'
        width='80px'
      >
        <IconMicrophone color={theme.palette.grey[600]} size={40} stroke={1} />
      </Box>
    </Box>
  );
}
