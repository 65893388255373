import { Box, styled } from '@mui/material';

import { ImageTranscriptComponent } from './types';

export const ImageStep = ({
  transcriptComponent,
}: {
  transcriptComponent: ImageTranscriptComponent;
}) => {
  return (
    <Container>
      <Box
        alt={transcriptComponent.description}
        component='img'
        src={decodeURIComponent(transcriptComponent.url)}
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          objectFit: 'contain',
        }}
      />
    </Container>
  );
};

export const Container = styled('div')`
  background-color: transparent;
  margin-left: 4px;
`;
