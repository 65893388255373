import { MRT_Row, MRT_RowData, MRT_TableState } from 'material-react-table';

import { formatUnixTimestampToDateAndTime } from '@forethought-technologies/forethought-elements';
import { toPercentageDecimal } from '../sankey-diagram-section/helpers';
import { TableColumnItem } from './types';
import merge from 'lodash/merge';
import { NA } from 'src/constants/solve';

export const createInitialState = <T,>(
  initialState: undefined | Partial<MRT_TableState<T & MRT_RowData>>,
  options: {
    RowAction?: React.ComponentType<{
      index?: number;
      row: MRT_Row<T & MRT_RowData>;
    }>;
  },
) => {
  if (!options.RowAction) {
    return initialState;
  }
  const rowAction = options.RowAction
    ? {
        right: ['mrt-row-actions'],
      }
    : {};
  return {
    ...initialState,
    columnPinning: merge({ ...rowAction }, initialState?.columnPinning || {}),
  };
};

export const createContainerSx = (row?: number) => {
  if (!row) {
    return undefined;
  }

  return {
    '&>span': {
      display: 'block',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: row,
    width: '100%',
  };
};

export const verifyHasTooltip = (tooltip?: string | boolean, row?: number) => {
  if (tooltip === false) {
    return false;
  }
  return row && row >= 1;
};

export const getValue = <T,>({
  data,
  setValue,
  valueType,
}: {
  data: T;
  setValue?: (row: T) => string;
  valueType?: TableColumnItem<T>['valueType'];
}) => {
  if (setValue) {
    return setValue(data);
  }
  if (data === null || data === undefined) {
    return NA;
  }
  if (valueType === 'number') {
    return Number(data).toLocaleString();
  }
  if (valueType === 'percentage') {
    return toPercentageDecimal(data as number);
  }
  if (valueType === 'timestamp' || valueType === 'timestamp_ms') {
    const date = new Date(data as string);
    if (date.toString() === 'Invalid Date') {
      return NA;
    }
    if (valueType === 'timestamp') {
      return formatUnixTimestampToDateAndTime(Number(date) / 1000);
    }
    return formatUnixTimestampToDateAndTime(Number(date));
  }
  if (valueType === 'currency') {
    return `$${Number(data).toLocaleString()}`;
  }
  if (valueType === 'boolean_yn') {
    return data === true ? 'Yes' : 'No';
  }
  return String(data);
};

export const getColor = (
  value: string,
  colors: { na: string },
  color?: string,
) => {
  if (typeof color === 'string') {
    return color;
  }

  if (value === NA) {
    return colors.na;
  }

  return undefined;
};
