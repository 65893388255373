import { ComponentProps } from 'react';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Typography,
  VerticalTabBar,
} from '@forethought-technologies/forethought-elements';

interface FloatingSidebarProps {
  EmailToggleComponent?: React.ComponentType;
  isActionDrawerExpanded: boolean;
  isOverlayActive: boolean;
  setTabIndex: (index: number) => void;
  tabIndex: number;
  tabs: ComponentProps<typeof VerticalTabBar>['tabs'];
  view?: string;
}

const FloatingSidebar = ({
  isActionDrawerExpanded,
  isOverlayActive,
  setTabIndex,
  tabIndex,
  tabs,
}: FloatingSidebarProps) => {
  const { palette } = useTheme();

  if (!isActionDrawerExpanded && !isOverlayActive) {
    return (
      <FloatingDiv>
        {tabs.map((tab, index) => (
          <FloatingButton key={tab.label} onClick={() => setTabIndex(index)}>
            <tab.Icon color={palette.colors.grey[700]} size={24} />
          </FloatingButton>
        ))}
      </FloatingDiv>
    );
  }

  return (
    <ExpandedContainer isOverlayActive={isOverlayActive}>
      <ContentContainer>
        <TabContent>{tabs[tabIndex]?.component}</TabContent>
        {!isOverlayActive && (
          <Tabs>
            {tabs.map((tab, index) => {
              const { Icon, label } = tab;
              const isActive = index === tabIndex;

              return (
                <TabItem key={label}>
                  <Button onClick={() => setTabIndex(index)}>
                    <Box
                      bgcolor={
                        isActive ? palette.colors.purple[100] : 'transparent'
                      }
                      borderRadius='4px'
                      display='flex'
                      padding='8px'
                    >
                      <Icon
                        color={
                          isActive
                            ? palette.colors.purple[500]
                            : palette.colors.grey[700]
                        }
                        size={24}
                      />
                    </Box>
                    <Typography
                      color={palette.colors.grey[700]}
                      variant='font11'
                    >
                      {label}
                    </Typography>
                  </Button>
                </TabItem>
              );
            })}
          </Tabs>
        )}
      </ContentContainer>
    </ExpandedContainer>
  );
};

const FloatingButton = styled('button')`
  border-radius: 24px;
  background: #fff;
  box-shadow: 2px 8px 16px 0px rgba(3, 17, 38, 0.11),
    0px 0px 1px 0px rgba(0, 12, 32, 0.02);
  cursor: pointer;
  padding: 12px;

  &:hover {
    background-color: ${props => props.theme.palette.colors.grey[100]};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloatingDiv = styled('div')`
  position: absolute;
  top: 140px;
  right: 24px;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ExpandedContainer = styled(Box)<{ isOverlayActive: boolean }>`
  background: ${props => props.theme.palette.colors.white};
  border-radius: 12px;
  box-shadow: 2px 8px 16px 0px rgba(3, 17, 38, 0.11),
    0px 0px 1px 0px rgba(0, 12, 32, 0.02);
  width: ${props => (props.isOverlayActive ? '700px' : '480px')};
  height: calc(100vh - 120px);
  display: flex;
`;

const ContentContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: row;
`;

const TabContent = styled(Box)`
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  border-right: 1px solid ${props => props.theme.palette.colors.grey[200]};
`;

const Tabs = styled('ul')`
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 20px 0;
`;

const TabItem = styled('li')`
  padding: 0 8px;
`;

const Button = styled('button')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  padding: 4px 12px;
  border: none;
  background: none;
`;

export default FloatingSidebar;
