import { IntercomCustomFieldAndValue } from 'src/types/workflowBuilderAPITypes';

export function cleanCustomizationData(
  customFields: IntercomCustomFieldAndValue[],
): Record<string, string | number | boolean> {
  return customFields.reduce<Record<string, string | number | boolean>>(
    (acc, field) => {
      acc[field.name] = field.value;
      return acc;
    },
    {},
  );
}
