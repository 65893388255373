import { Box } from '@mui/material';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';

export const RemoveNumberModal = ({
  isModalVisible,
  onDeleteClicked,
  setIsModalVisible,
}: {
  isModalVisible: boolean;
  onDeleteClicked: () => void;
  setIsModalVisible: (isVisible: boolean) => void;
}) => {
  return (
    <Dialog
      footer={
        <>
          <Button onClick={() => setIsModalVisible(false)} variant='ghost'>
            Cancel
          </Button>
          <Button onClick={onDeleteClicked} variant='main'>
            Delete
          </Button>
        </>
      }
      onClose={() => setIsModalVisible(false)}
      open={isModalVisible}
      title='Delete phone number?'
    >
      <Box display='flex' flexDirection='column' width='300px'>
        <Typography variant='font14'>
          Are you sure you want to delete this phone number? This action can not
          be undone.
        </Typography>
      </Box>
    </Dialog>
  );
};
