import { TAB_PARAMS_NAME, TABS } from './constants';
import DashboardLayout from 'src/components/dashboard-layout/DashboardLayout';
import DashboardTabs from 'src/components/dashboard-tabs/DashboardTabs';

const TriageInsightsPage = () => {
  return (
    <DashboardLayout>
      <DashboardTabs
        name='triage-insights'
        tabHeaderSx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: '0px',
          zIndex: 99,
        }}
        tabParamsName={TAB_PARAMS_NAME}
        tabs={TABS}
      />
    </DashboardLayout>
  );
};

export default TriageInsightsPage;
