import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import icon from 'src/assets/images/empty-dashboards.svg';

const EmptyState = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      gap={4}
      py={5}
    >
      <img src={icon} />
      <Typography color={palette.colors.grey[500]} variant='font24'>
        Insufficient data available to display at the moment
      </Typography>
    </Box>
  );
};

export default EmptyState;
