import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { getWorkflowValueFromSearch } from '../autonomous-agent/helpers';
import { useEmailMode } from '../hooks';
import { EmailMode } from '../types';
import {
  EMAIL_RESPONSE,
  INTERACTIVE,
  JOURNEY_MAP,
} from './intent-email-builder/constants';
import { useIsFallbackEmailWorkflowAllowed } from './hooks';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import { isOnlyInteractiveEmail } from 'src/utils/solve/intentUtils';

export default function IntentEmailToggleBar() {
  const { search } = useLocation();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isInteractiveEmailEnabled = featureFlags.includes('interactive_email');
  const intentId = getWorkflowValueFromSearch(search, 'intentId');
  const isOnlyInteractive =
    !useIsFallbackEmailWorkflowAllowed() && isOnlyInteractiveEmail(intentId);

  const { emailMode, isLoading, setEmailMode } = useEmailMode();

  if (isOnlyInteractive || isLoading) {
    return null;
  }

  const showInteractive =
    isInteractiveEmailEnabled &&
    // Interactive email does not support the old 'Other questions' workflow
    intentId != CommonIntentWorkflowType.GENERAL_HANDOFF;

  return (
    <Box
      borderBottom={theme => '1px solid ' + theme.palette.colors.slate[100]}
      p={3}
    >
      <ToggleButtonGroup
        alignment={emailMode}
        items={
          [
            {
              label: JOURNEY_MAP,
              value: JOURNEY_MAP,
            },
            {
              label: EMAIL_RESPONSE,
              value: EMAIL_RESPONSE,
            },
            ...(showInteractive
              ? [{ label: INTERACTIVE, value: INTERACTIVE }]
              : []),
            ,
          ] as { label: string; value: EmailMode }[]
        }
        onChange={newAlignment => {
          if (!newAlignment) {
            return;
          }
          setEmailMode(newAlignment);
        }}
        toggleWidth='100%'
        width='100%'
      />
    </Box>
  );
}
