import { useMemo } from 'react';

import {
  useGetIntentsQueryWithProduct,
  useIsFeatureFlagEnabled,
} from 'src/hooks/hooks';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import { useGetIntentsForBrandQuery } from 'src/services/workflow-builder-metrics/workflowBuilderMetricsApi';
import { SolveWidgetProduct } from 'src/types/types';
import { IntentData } from 'src/types/workflowBuilderAPITypes';
import { CommonIntentWorkflowType } from 'src/utils/enums';

export const useGetIntents = ({
  isActiveUserCreatedWorkflows = false,
}: {
  isActiveUserCreatedWorkflows: boolean;
}) => {
  const { data } = useGetIntentsQueryWithProduct();

  const filteredIntents = useMemo(() => {
    if (data) {
      const { intents } = data;
      if (isActiveUserCreatedWorkflows) {
        return intents.filter(
          intent =>
            intent.active_workflow_types.length &&
            intent.intent_definition_id !== 'general-handoff',
        );
      }
      return intents;
    }
    return [];
  }, [data, isActiveUserCreatedWorkflows]);

  return filteredIntents;
};

export const useFilterOutCommonIntents = ({
  intents,
}: {
  intents: IntentData[];
}) => {
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery();

  const result = useMemo(() => {
    if (handoffConfigurationsResponse) {
      const handoffConfigurations =
        handoffConfigurationsResponse?.configurations;
      return intents.filter(intent => {
        return (
          intent.intent_definition_id !==
            CommonIntentWorkflowType.KNOWLEDGE_ARTICLE &&
          intent.intent_definition_id !== CommonIntentWorkflowType.FALLBACK &&
          intent.intent_definition_id !== CommonIntentWorkflowType.HANDOFF &&
          !handoffConfigurations.some(
            handoffConfig =>
              handoffConfig.intent_definition_id ===
              intent.intent_definition_id,
          )
        );
      });
    }
    return intents;
  }, [handoffConfigurationsResponse, intents]);

  return result;
};

export const useGetIntentsForBrand = ({
  brand_id,
  solve_widget_product,
}: {
  brand_id: string;
  solve_widget_product: SolveWidgetProduct;
}) => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const { data } = useGetIntentsForBrandQuery(
    {
      brand_id,
      solve_widget_product,
    },
    {
      skip: !isMultibrandEnabled,
    },
  );

  if (!data) {
    return [];
  }

  return data;
};
