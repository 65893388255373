import { useCallback, useMemo } from 'react';
import {
  createMRTColumnHelper,
  MRT_Row,
  MRT_RowData,
} from 'material-react-table';
import { useTheme } from '@mui/material';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../dashboard-pages/triage-model-detail-page/common/TextWithInfo';
import {
  createContainerSx,
  getColor,
  getValue,
  verifyHasTooltip,
} from './helpers';
import { TableColumnItem } from './types';
import Value from './Value';
import { NA } from 'src/constants/solve';

export const createTableColumns = <T extends MRT_RowData>(
  columns: TableColumnItem<T>[],
) => {
  const columnHelper = createMRTColumnHelper<T>();

  return columns.map(item => {
    return columnHelper.accessor(item.key as MRT_RowData[string], {
      Cell: ({ row }: { row: MRT_Row<T> }) => {
        const { palette } = useTheme();
        const value = useMemo(
          () =>
            getValue<T>({
              data: row.original[item.key],
              setValue: item.setValue,
              valueType: item.valueType,
            }) || NA,
          [row],
        );

        const fontVariant = item.fontVariant || 'font14';
        const hasTooltip = verifyHasTooltip(item.tooltip, item.rows);

        const handleClick = useCallback(
          (event: React.MouseEvent) => {
            if (!item.onClick) {
              return;
            }
            event.preventDefault();
            event.stopPropagation();
            item.onClick(row.original);
          },
          [row.original],
        );

        const color = useMemo(
          () =>
            getColor(
              value,
              {
                na: palette.colors.grey[600],
              },
              typeof item.color === 'string'
                ? item.color
                : item.color?.(row.original),
            ),
          [value, palette.colors.grey, row.original],
        );

        if (item.Component) {
          return (
            <item.Component
              data={value}
              options={item}
              row={row}
              rowData={row.original}
            />
          );
        }

        if (hasTooltip) {
          return (
            <Tooltip tooltipContent={value}>
              <Value
                color={color}
                fontVariant={fontVariant}
                handleClick={handleClick}
                sx={createContainerSx(item.rows)}
                value={value}
              />
            </Tooltip>
          );
        }

        return (
          <Value
            color={color}
            fontVariant={fontVariant}
            handleClick={handleClick}
            sx={createContainerSx(item.rows)}
            value={value}
          />
        );
      },
      header: item.title,
      Header: (
        <TextWithInfo
          textColor={['grey', 800]}
          title={item.title}
          tooltip={item.headerTooltip || ''}
          variant='font14Bold'
          wrap={item.headerWrapText}
        />
      ),
      size: item.size,
    });
  });
};
