import React from 'react';

import { useWidgetAggregateData } from '../intent-workflows-table/hooks/useWidgetAggregateData';
import AggregateMetrics, { AggregateMetric } from './AggregateMetrics';
import {
  formatNumber,
  getTooltipForMetric,
} from 'src/components/reusable-components/data-overview-card/helpers';
import { NA } from 'src/constants/solve';
import { formatTimeSpent } from 'src/pages/intent-conversation-analytics/ConversationDrawer/utils';
import { MetricAggregateResponse } from 'src/types/workflowBuilderAPITypes';

export default function VoiceAggregateMetricsContainer() {
  const { data: data, isLoading } = useWidgetAggregateData();

  const formatMetrics = React.useCallback(function formatMetrics(
    data: MetricAggregateResponse,
  ): AggregateMetric[] {
    return [
      {
        label: 'Call Volume',
        tooltip:
          'This metric counts the total number of calls received by the AI voice agent.',
        value: formatNumber(
          'user_queries',
          data.aggregate.user_queries.user_queries,
        ),
      },
      {
        label: 'Total call time',
        tooltip: 'Total call time',
        value: data.aggregate.voice
          ? formatTimeSpent(data.aggregate.voice.call_duration)
          : NA,
      },
      {
        label: 'Average call time',
        tooltip: 'Average call time',
        value: data.aggregate.voice
          ? formatTimeSpent(data.aggregate.voice.average_call_duration)
          : NA,
      },
      {
        label: 'Deflection',
        tooltip: 'Total Deflected calls divided by total Call Volume.',
        value: formatNumber(
          'deflection_rate',
          data.aggregate.deflection_rate.deflection_rate,
        ),
      },
      {
        label: 'Positive Sentiment',
        tooltip:
          'Percentage of the end users that feel positive about the call.',
        value: data.aggregate.voice
          ? formatNumber(
              'positive_sentiment_rate',
              data.aggregate.voice.positive_sentiment_rate,
            )
          : NA,
      },
      {
        label: 'Realized savings',
        tooltip: getTooltipForMetric(
          'cost_savings',
          data.aggregate.cost_savings.cost_per_ticket,
        ),
        value: formatNumber(
          'cost_savings',
          data.aggregate.cost_savings.cost_savings,
        ),
      },
    ];
  },
  []);

  const metrics = React.useMemo(() => {
    if (!data) {
      return null;
    }

    return formatMetrics(data);
  }, [data, formatMetrics]);

  return (
    <AggregateMetrics
      isDeflectionInsightsEnabled={false}
      metrics={metrics}
      numLoadingSkeletons={isLoading ? 6 : 0}
    />
  );
}
