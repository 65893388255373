import React, { useCallback, useMemo, useState } from 'react';
import { MRT_ExpandedState } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { SxProps, useTheme } from '@mui/material';

import {
  getDefaultMRTOptions,
  InfiniteTable,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { sortDirectionToInsightSortDirection } from '../helpers';
import { InsightSortDirection } from '../types';
import CallToActionCell from './CallToActionCell';
import { ColumnHeaderItemProps } from './ColumnHeaderItem';
import { INITIAL_SORTING, InsightTopicSortColumns } from './constants';
import { createColumnHeader, createRows, getActionsColumnSize } from './helper';
import { InsightTopicData } from './types';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { replaceIdInRoute } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

type TopicsTableProps = {
  costPerTicket: string;
  data: InsightTopicData[];
  emitTrackingEventCallback: ColumnHeaderItemProps['emitTrackingEventCallback'];
  filters: React.ReactNode;
  isLoading: boolean;
  isTopicGrouped: boolean;
  setSortColumn: (
    column: InsightTopicSortColumns,
    batchUpdater?: (() => URLSearchParams) | undefined,
  ) => URLSearchParams;
  setSortDirection: (
    state: InsightSortDirection,
    batchUpdater?: (() => URLSearchParams) | undefined,
  ) => URLSearchParams;
  sortColumn: InsightTopicSortColumns;
  sortDirection: InsightSortDirection;
  totalTopicsCount: number;
};

const TopicsTable = ({
  costPerTicket,
  data,
  emitTrackingEventCallback,
  filters,
  isLoading,
  isTopicGrouped,
  setSortColumn,
  setSortDirection,
  sortColumn,
  sortDirection,
  totalTopicsCount,
}: TopicsTableProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<MRT_ExpandedState>({});

  const currentSortState = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortDirection, sortColumn],
  );

  const navigate = useNavigate();

  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.SOLVE_INSIGHTS_TOPIC_DETAIL,
  );
  const linkWithSearchParent = useGenerateLinkWithSearch(
    Routes.SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT,
  );

  const defaultMrt = getDefaultMRTOptions({ theme });
  const muiExpandButtonSx = defaultMrt.muiExpandButtonProps as { sx: SxProps };

  const handleTopicClick = useCallback(
    (topic: { clusterId: string; isChild: boolean }) => {
      emitTrackingEventCallback('insight-table-row-clicked', {
        id: topic.clusterId,
        isParentTopic: !topic.isChild,
        scope: 'main',
        tab: 'topic',
        table: 'topic',
      });
      // If the row is a parent, go to parent detail page
      if (!topic.isChild) {
        navigate(replaceIdInRoute(linkWithSearchParent, topic.clusterId));
        return;
      }
      navigate(replaceIdInRoute(linkWithSearch, topic.clusterId));
    },
    [emitTrackingEventCallback, navigate, linkWithSearch, linkWithSearchParent],
  );

  const rows = useMemo(() => createRows(data), [data]);
  const columns = useMemo(
    () =>
      createColumnHeader({
        costPerTicket,
        data,
        emitTrackingEventCallback,
        isGrouped: isTopicGrouped,
        onClick: handleTopicClick,
        palette: theme.palette,
        sortDirection: sortDirection,
        totalTopicsCount,
      }),
    [
      totalTopicsCount,
      sortDirection,
      data,
      costPerTicket,
      emitTrackingEventCallback,
      theme.palette,
      handleTopicClick,
      isTopicGrouped,
    ],
  );

  const isExpanded =
    Boolean(Object.entries(expanded).length) || expanded === true;
  const enableRowActions = !isTopicGrouped || isExpanded;
  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={INITIAL_SORTING}
      isError={false}
      isLoadingFirstPage={isLoading}
      isLoadingNextPage={false}
      onLoadMore={() => undefined}
      onSortCallback={undefined}
      stickyHeaderHeight={60}
      tableOptions={{
        columns,
        data: rows,
        displayColumnDefOptions: {
          'mrt-row-actions': {
            grow: 1,
            header: 'Actions',
            Header: <Typography variant='font14'>Actions</Typography>,
            muiTableBodyCellProps: {
              align: 'right',
            },
            size: getActionsColumnSize(rows, isTopicGrouped),
          },
          'mrt-row-expand': {
            size: 60,
          },
        },
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: isTopicGrouped,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions,
        enableSorting: true,
        initialState: {
          columnPinning: {
            left: ['mrt-row-expand', 'cluster_name'],
            right: ['mrt-row-actions'],
          },
        },
        manualSorting: false,
        muiExpandButtonProps: rows => {
          if (rows.row.depth > 0) {
            return {
              sx: {
                display: 'none',
              },
            };
          }
          return {
            sx: muiExpandButtonSx.sx,
          };
        },
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => {
            const item = row.original;

            const isChild =
              row.depth > 0 ||
              (!item.insight_subtopics.length && !isTopicGrouped);

            handleTopicClick({
              clusterId: row.original.cluster_id,
              isChild: isChild,
            });
          },
          sx: {
            cursor: 'pointer',
          },
        }),
        onExpandedChange: setExpanded,
        onSortingChange: sortingUpdater => {
          const newSortVal =
            sortingUpdater instanceof Function
              ? sortingUpdater(currentSortState)
              : sortingUpdater;

          setSortColumn(newSortVal[0].id as InsightTopicSortColumns, () =>
            setSortDirection(
              sortDirectionToInsightSortDirection(
                newSortVal[0].desc === true ? 'desc' : 'asc',
              ),
            ),
          );
        },
        renderRowActions: ({ row }) => {
          if (row.depth === 0 && isTopicGrouped) {
            return null;
          }
          return <CallToActionCell topic={row.original as InsightTopicData} />;
        },
        renderTopToolbarCustomActions: () => filters,
        state: {
          expanded,
          sorting: currentSortState,
        },
      }}
    />
  );
};

export default TopicsTable;
