import { RequireExactlyOne } from 'type-fest';

import {
  AttachmentAnalysisUploadStepFields,
  GlobalContextVariable,
  HandoffConfiguration,
} from '../slices/canvas-workflow-builder/types/dataTypes';
import { DiscoverAutomationsByTypeResponse } from './DiscoverTypes';
import { SolveWidgetProduct } from './types';
import { DropdownOptionsType } from 'src/pages/solve-workflows/go-to-step/GoToStepTypes';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import {
  ConditionExpressions,
  DropoffBreakdown,
  RelevanceBreakdown,
} from 'src/services/apiInterfaces';
import { Action, ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ElasticSearchDataType,
  StepTypes,
  TranslationsTableMode,
} from 'src/utils/enums';

export type ConversationChannel =
  | 'email'
  | 'widget'
  | 'api'
  | 'slack'
  | 'voice';
export type TranslationChannel =
  | ConversationChannel
  | 'interactive_email'
  | 'slack';

export type AllConversationChannel =
  | ConversationChannel
  | 'sunshine'
  | 'preview';

export const channelToDisplayName: Record<ConversationChannel, string> = {
  api: 'API',
  email: 'Email',
  slack: 'Slack',
  voice: 'Voice',
  widget: 'Chat',
};

export type WorkflowBuilderHeaderChannel =
  | 'workflows'
  | 'chats'
  | 'conversations' // currently 'conversations' is for the email channel, 'chats' is for widget.
  | 'templates';

export interface ActionSettingsCustomization {
  action_type: string;
  configuration_fields:
    | FreshdeskTicketAndContactCreationCustomizationSettings
    | ZendeskHandoffCustomization
    | ZendeskTicketCreationCustomization
    | ZendeskMessagingHandoffActionSettings
    | IntercomHandoffSettings
    | SalesforceHandoffCustomization
    | SalesforceCaseCreationCustomization
    | SalesforceChatHandoffCustomization
    | ArticleSuggestionActionSettings
    | GranularArticleSuggestionActionV2Settings
    | CatchAllHandoffCustomization
    | JiraServiceManagementCreateRequestSettings
    | ZendeskChatHandoffCustomization
    | HyperlinkRedirectCustomizationSettings
    | KustomerHandoffCustomization
    | KustomerChatHandoffCustomization
    | KustomerConversationCreationCustomization
    | TriggerEventCustomizationSettings
    | FreshdeskTicketCreationCustomizationSettings
    | SnapEngageChatHandoffActionSettings
    | GorgiasChatHandoffActionSettings
    | SalesforceMessagingHandoffActionSettings
    | ContextVariableMappingActionSettings
    | ZendeskAttachmentUploadActionSettings
    | DynamicArticleSuggestionInitialSettings
    | ParseJwtCustomizationSettings
    | SetContextVariableCustomizationSettings
    | ForethoughtLiveChatHandoffActionSettings
    | DynamicCardCustomizationSettings
    | IntercomTicketCreationCustomizationSettings
    | IntercomLiveChatActionSettings;
}

export interface CatchAllHandoffCustomization {
  custom_message: null | string;
  show_link_preview?: boolean;
}

export interface ZendeskOrSalesforceContextVariables {
  email: string | null;
  name: string | null;
  question: string | null;
}

export interface ZendeskChatHandoffCustomization
  extends ZendeskOrSalesforceContextVariables {
  available_zd_chat_departments: Array<{
    id: string;
    name: string;
  }>;
  chat_tags?: string[];
  email: string;
  name: string;
  one_chat?: boolean;
  question: string;
  zd_chat_department: {
    id: string;
    name: string;
  } | null;
}

export interface SuncoTicketCustomizationConfig {
  custom_fields_and_values: ZendeskCustomFieldAndValue[];
  ticket_custom_fields: ZendeskTicketCustomField[];
}

export interface SuncoLiveChatCustomization
  extends ZendeskOrSalesforceContextVariables {
  available_zd_chat_departments: Array<{
    id: string;
    name: string;
  }>;
  chat_tags?: string[];
  email: string;
  jwt?: string | null;
  name: string;
  placeholder_message?: string;
  question: string;
  sunco_ticket_customization_config?: SuncoTicketCustomizationConfig;
  zd_chat_department: {
    id: string;
    name: string;
  } | null;
}

export interface ZendeskTicketCustomField {
  custom_field_options: Array<{ name: string; value: string }>;
  id: number;
  title: string;
  type: string;
}

export interface ZendeskTicketField {
  field_options: Array<{ name: string; value: string }>;
  id: string;
  title: string;
  type: string;
}

export interface ZendeskCustomFieldAndValue {
  id: number;
  value: string | number;
}

export interface TicketStaticFieldAndValue {
  id: string;
  value: string | number;
}

export interface ZendeskCommonCustomization {
  ticket_creation_settings: {
    ticket_creation_config: {
      allow_attachment_upload: boolean;
      available_statuses: {
        label: string;
        value: string;
      }[];
      available_ticket_brands: string[];
      custom_fields_and_values: ZendeskCustomFieldAndValue[];
      status: string | null;
      ticket_brand: null | string;
      ticket_custom_fields: ZendeskTicketCustomField[];
      ticket_tags: string[];
      ticket_title: null | string;
      update_flamethrower_conversation_deflection_status: null | boolean;
    };
  };
}

export interface ZendeskHandoffCustomization
  extends ZendeskCommonCustomization {
  agent_chat_handoff_settings: {
    available_zd_chat_departments: Array<{
      id: string;
      name: string;
    }>;
    chat_tags?: string[];
    zd_chat_department: {
      id: string;
      name: string;
    } | null;
  };
  include_agent_chat_handoff: boolean;
  include_ticket_creation_handoff: boolean;
  sunco_ticket_customization_config?: SuncoTicketCustomizationConfig;
  ticket_creation_settings: {
    create_ticket: boolean;
    custom_message: null | string;
    ticket_creation_config: {
      allow_attachment_upload: boolean;
      available_statuses: {
        label: string;
        value: string;
      }[];
      available_ticket_brands: string[];
      confirmation_message: string;
      custom_fields_and_values: ZendeskCustomFieldAndValue[];
      handoff_form_question_message: string;
      status: string | null;
      ticket_brand: null | string;
      ticket_custom_fields: ZendeskTicketCustomField[];
      ticket_tags: string[];
      ticket_title: null | string;
      update_flamethrower_conversation_deflection_status: null | boolean;
    };
  };
}

export interface ZendeskTicketCreationCustomization
  extends ZendeskCommonCustomization {
  email: string | null;
  name: string | null;
  question: string | null;
  ticket_id_output_variable: string | null;
}

export type ReadArticleActionOptions = 'new_tab' | 'expand_in_widget' | 'none';
export interface BaseArticleConfigurationFields {
  allow_generative_ai: boolean;
  knowledge_configuration_prompt: string | null;
  should_override_global_knowledge_configuration_prompt: boolean;
}
export interface GranularArticleSuggestionActionV2Settings
  extends Partial<BaseArticleConfigurationFields> {
  allow_generative_ai: boolean;
  article_sources: Array<ArticleSource>;
  available_article_sources: Array<ArticleSourceOption>;
  hand_off_when_not_found?: boolean;
  helpful_button_text?: string;
  is_last_element: boolean;
  not_helpful_button_text?: string;
  question: string;
  read_article_action: string;
  read_article_action_options: {
    label: string;
    value: ReadArticleActionOptions;
  }[];
  show_buttons?: boolean;
  top_n_articles: number;
}

export interface ExtraPrechatFormDetails {
  label: string;
  transcript_fields: string[];
  value: string;
}

export interface FieldAndValue {
  id: string;
  is_custom?: boolean;
  value: string | boolean;
}

export interface SalesforceCommonCustomization {
  ticket_creation_settings: {
    ticket_creation_config: {
      fields_and_values: Array<FieldAndValue>;
      ticket_fields: Array<{
        field_options: Array<{ name: string; value: string }>;
        id: string;
        is_custom?: boolean;
        title: string;
        type: string;
      }>;
      ticket_title: string;
      update_flamethrower_conversation_deflection_status: null | boolean;
    };
  };
}

export interface SalesforceHandoffCustomization
  extends SalesforceCommonCustomization {
  agent_chat_handoff_settings: {
    button_id: string;
    chat_transcript_text_area_fields: Array<{
      id: string;
      title: string;
    }> | null;
    deployment_id: string;
    extra_prechat_form_details: Array<ExtraPrechatFormDetails>;
    form_prompt: string;
    handoff_success_message: string;
    ignore_name_email: boolean;
    no_agents_available_message: string;
    store_transcript_to_chat_transcript_field_id: string | null;
    talk_to_agent_button_prompt: string;
  };
  include_agent_chat_handoff: boolean;
  include_ticket_creation_handoff: boolean;
  ticket_creation_settings: {
    create_ticket: boolean;
    custom_message: string;
    ticket_creation_config: {
      confirmation_message: string;
      fields_and_values: Array<{
        id: string;
        is_custom?: boolean;
        value: string;
      }>;
      handoff_form_question_message: string;
      ticket_fields: Array<{
        field_options: Array<{ name: string; value: string }>;
        id: string;
        is_custom?: boolean;
        title: string;
        type: string;
      }>;
      ticket_title: string;
      update_flamethrower_conversation_deflection_status: null | boolean;
    };
  };
}

export interface SalesforceChatHandoffCustomization {
  agent_chat_handoff_settings: {
    button_id: string;
    chat_transcript_text_area_fields: Array<{
      id: string;
      title: string;
    }> | null;
    deployment_id: string;
    extra_prechat_form_details: Array<ExtraPrechatFormDetails>;
    store_transcript_to_chat_transcript_field_id: string | null;
  };
  email: string | null;
  name: string | null;
  question: string | null;
}

export interface SalesforceCaseCreationCustomization
  extends SalesforceCommonCustomization {
  add_transcript_comment: boolean;
  create_email_message: boolean;
  email: string | null;
  name: string | null;
  question: string | null;
  ticket_id_output_variable: string | null;
}

export interface IntercomHandoffSettings {
  intercom_handoff_settings: {
    ask_for_visitor_email: boolean;
    ask_for_visitor_name: boolean;
    ask_for_visitor_question: boolean;
    failure_message: string;
    form_prompt: string;
    identity_verification_enabled: boolean;
    include_transcript: boolean;
  };
}

export interface HyperlinkRedirectCustomizationSettings {
  destination_link: string;
  redirect_option: string;
}

export interface TriggerEventCustomizationSettings {
  context_variables_to_update: string[];
  event_name: string;
}

export interface ParseJwtCustomizationSettings {
  context_variables_to_update: string[];
  encoded_context_variable: string;
  key: string;
}

export interface FreshdeskTicketCreationCustomizationSettings {
  ticket_creation_settings: {
    custom_fields?: { [key: string]: string | number | boolean } | null;
    form_prompt: string;
    group_id?: number;
    priority?: number;
    tags: string[];
    ticket_creation_success_message: string;
    transcript_field?: string;
    upload_files_attached: boolean;
  };
}

export interface FreshdeskTicketAndContactCreationCustomizationSettings {
  custom_fields?: { [key: string]: string | number | boolean } | null;
  description: string;
  email: string;
  group_id?: number;
  name: string;
  priority?: number;
  subject: string;
  tags: string[];
  ticket_creation_success_message: string;
  transcript_field?: string;
  upload_files_attached: boolean;
}

export interface SetContextVariableCustomizationSettings {
  mappings: {
    context_variable_id: string;
    value: ContextVariableValue;
  }[];
}

export interface DynamicCardCustomizationSettings {
  content_config?: { limit: number } | null;
  display_option: { limit: number; type: 'capped' | 'all' };
  dynamic_list_context_variable?: string | null;
  message?: string | null;
  sections: {
    components: { key: string; label?: string | null }[];
    type: 'image' | 'content' | 'title';
  }[];
  style: 'carousel' | 'stacked_cards';
}

export interface JiraServiceManagementCreateRequestSettings {
  // not customizable
  description_context_variable: string;
  email_context_variable: string;
  include_transcript: boolean;
  is_last_element: boolean;
  other_request_fields: { [key: string]: string | number | boolean | null };
  request_type_id: string;
  service_desk_id: string;
  summary_context_variable: string;
}

export interface ArticleSuggestionFeedbackSettings {
  button_prompt: string;
  helpful_button_text: string;
  not_helpful_button_text: string;
  show_buttons: boolean;
}

export interface ArticleSuggestionActionSettings
  extends Partial<BaseArticleConfigurationFields> {
  allow_generative_ai: boolean;
  article_sources: Array<ArticleSource>;
  available_article_sources: Array<ArticleSourceOption>;
  button_prompt: string;
  hand_off_when_not_found?: boolean;
  helpful_button_text?: string;
  input_variable?: string;
  is_last_element: boolean;
  not_helpful_button_text?: string;
  read_article_action: string;
  read_article_action_options: {
    label: string;
    value: ReadArticleActionOptions;
  }[];
  show_buttons?: boolean;
  top_n_articles: number;
}

export interface DynamicArticleSuggestionInitialSettings {
  hand_off_when_not_found: boolean;
  show_buttons: boolean;
  show_not_helpful_text: boolean;
}

export interface SnapEngageChatHandoffActionSettings {
  bot_alias_list: string[] | null;
  include_transcript: boolean;
  visitor_email_variable: string | null;
  visitor_name_variable: string | null;
  visitor_question_variable: string | null;
}

export interface GorgiasChatHandoffActionSettings {
  include_transcript: boolean;
  question_variable: string;
}

export interface LiveChatChatHandoffActionSettings {
  email_variable: string | null;
  include_transcript: boolean;
  name_variable: string | null;
  question_variable: string;
}

export interface IntercomTicketCreationCustomization {
  contact_id_variable: string | null;
  custom_fields: IntercomCustomFieldAndValue[] | null;
  description_variable: string | null;
  ticket_id_output_variable: string | null;
  ticket_type_id_variable: string | null;
  title_variable: string | null;
}

export interface IntercomCustomFieldAndValue {
  name: string;
  value: string | number | boolean;
}

export interface IntercomCustomFieldAndValuesOptions {
  id: string;
  name: string;
  type: string;
}

export interface IntercomTicketType {
  attributes: IntercomCustomFieldAndValuesOptions[];
  id: string;
  name: string;
  type: string;
}

export interface IntercomFieldsResponse {
  data: IntercomTicketType[];
}
export interface IntercomTicketCreationCustomizationSettings {
  contact_email: string;
  contact_id_variable: string | null;
  contact_name: string;
  context_variables: Record<string, string> | undefined;
  custom_fields: Record<string, string | number | boolean> | undefined;
  description_variable: string | null;
  is_last_element: boolean;
  ticket_id_output_variable: string | null;
  ticket_type_id_variable: string | null;
  title_variable: string | null;
  workflow_action_type: string;
}

interface FreshchatHelpdeskConfig {
  api_url: string | null;
  helpdesk: 'freshdesk';
}

export interface ForethoughtLiveChatHandoffActionSettings {
  freshchat_instances_options?: { label: string; value: string }[];
  helpdesk_config: FreshchatHelpdeskConfig | null;
  placeholder_message: string;
  user_id_variable: string | null;
}

export interface SalesforceMessagingHandoffActionSettings {
  clear_session_before_handoff: boolean;
  hidden_fields: {
    key: string;
    value: string;
  }[];
  include_transcript: boolean;
  question_variable: string;
}

export interface FredeshdeskGroup {
  chat_group_id: string;
  id: string;
  name: string;
  routing_logic: string;
  routing_type: string;
}

export interface FreshChatField {
  choices: Array<{
    id: string;
    value: string;
  }>;
  custom: boolean;
  default_value?: string;
  editable: boolean;
  id: string;
  label: string;
  name: string;
  position: number;
  required: boolean;
  type: string;
}
export interface FreshdeskFieldsResponse {
  fields: Array<FreshChatField>;
}

export interface FreshChatCustomField {
  id: string;
  options?: Array<{ name: string; value: string }>;
  title: string;
  type: string;
}

export interface FreshChatCustomFieldAndValue {
  name: string;
  value: string | number;
}

export interface FreshchatChatHandoffActionSettings {
  api_url: string | null;
  custom_fields_and_values: FreshChatCustomFieldAndValue[] | null;
  email_variable: string | null;
  first_name_variable: string | null;
  freshchat_instances_options?: { label: string; value: string }[];
  group: string | null;
  groups?: Array<FredeshdeskGroup>;
  include_transcript: boolean;
  last_name_variable: string | null;
  one_chat: boolean;
  question_variable: string;
  tags: string[] | null;
  transcript_field: string | null;
  user_id_variable: string | null;
}

export interface FreshChatTicketCustomField {
  custom_field_options: Array<{ id: string; value: string }>;
  name: string;
  title: string;
  type: string;
}

export interface ZendeskMessagingHandoffActionSettings {
  auth_token?: string;
  available_ticket_fields: ZendeskTicketCustomField[];
  custom_fields_and_values: Array<ZendeskCustomField>;
  prepend_transcript: boolean;
  question_variable: string;
  ticket_tags: string[];
}

export interface ZendeskCustomField {
  custom_field_id: number;
  custom_field_value: string | number | boolean | Array<string>;
}

export interface ContextVariableMap {
  input_value: string;
  output_value: string;
}

export interface ContextVariableMappingActionSettings {
  input_context_variable: string;
  output_context_variable: string;
  value_map: ContextVariableMap[];
}

export interface ZendeskAttachmentUploadActionSettings {
  required: boolean;
}

export interface ArticleSourceOption {
  available_article_fields: Array<ArticleField>;
  doc_type: string;
}

export interface ArticleField {
  available_field_values: Array<ArticleFieldValues>;
  field_name: string;
  field_type: ElasticSearchDataType | null;
}

export interface ArticleFieldValues {
  name: string;
  value: number | string | boolean;
}

export interface ArticleSource {
  doc_type: string | null;
  filter_queries: Array<ArticleSuggestionFilterQuery>;
}

export interface ArticleSuggestionFilterQuery {
  boolean_operator: string | null;
  field: string | null;
  operator: string;
  value: ArticleFieldValues | null;
}

export interface Intent {
  description?: string;
  intent_id: string;
  is_custom: boolean;
  isActive?: boolean;
  phrases: Phrase[];
  title: string;
  workflow_types_enabled?: Array<WorkflowTypes>;
}

// Data passed from component to Redux thunk to add a new Button Step
export interface ButtonStepData {
  isEntryStep?: boolean;
  options: ButtonOption[];
  parentStepId?: string;
  prompt: string;
}

// Data passed from component to Redux thunk to add a new Form Step
export interface FormData {
  formFields: FormField[];
  isEntryStep?: boolean;
  parentStepId?: string;
  prompt: string;
}

export interface CsatTriggerPointData {
  delay: number;
  isEntryStep?: boolean;
  negative_rating_routing_intent?: string;
  parentStepId?: string;
  should_use_delay: boolean;
}

// Data passed from component to Redux thunk to add a new Message Step
export interface MessageStepData {
  isEntryStep?: boolean;
  message: string;
  parentStepId?: string;
  showLinkPreview?: boolean;
}

export interface AggregateData {
  [key: string]: string | number | boolean | null;
}

export interface OrgIdResponse {
  org_id: number;
}

export interface ChannelsResponse {
  channels: Array<string>;
}

export interface MetricDataTypesResponse {
  data_types: {
    [key: string]: string;
  };
}

export interface SolveMetricsRequestQueryParams {
  /**
   * indicates fetching metrics for which channel
   */
  channel?: ConversationChannel;
  /**
   * unix timestamp as string
   */
  from?: string | null;
  /**
   * workflow tags to filter by
   */
  tags?: string[] | null;
  /**
   * unix timestamp as string
   */
  to?: string | null;
}

export interface HandoffUsagesParams {
  intentWorkflowId: string;
}

export interface MetricAggregateResponse {
  aggregate: {
    avg_csat?: number | null;
    cost_savings: {
      change: number;
      contact_support: boolean;
      cost_per_ticket: number;
      cost_savings: number;
    };
    deflection_rate: {
      change: number;
      deflection_rate: number;
    };
    dropoff_breakdown?: {
      dropoff_count: number;
      no_dropoff_count: number;
    };
    relevance_breakdown?: {
      irrelevant_count: number;
      relevant_count: number;
      somewhat_relevant_count: number;
    };
    resolutions: {
      change: number;
      resolutions: number;
    };
    user_queries: {
      change: number;
      user_queries: number;
    };
    voice?: {
      average_call_duration: number;
      call_duration: number;
      positive_sentiment_rate: number;
      positive_sentiments: number;
    };
  };
  last_updated_at: string | null;
}

export interface MetricBreakdownResponse {
  breakdown: Array<BreakdownData>;
}

export interface WorkflowBuilderLandingPageData {
  aggregate: {
    [key: string]: AggregateData | number | null;
  } & {
    avg_csat?: number | null;
  };
  breakdown: Array<BreakdownData>;
  channels: Array<string>;
  data_types: {
    [key: string]: string;
  };
  org_id: number;
}
export interface DecommissionWorkflowErrorReferencedData {
  id: string;
  intent_definition_id?: string;
  name?: string;
  product?: SolveWidgetProduct;
}
export interface DecommissionWorkflowResponse {
  detail?: {
    message: string;
    referenced_data: Array<DecommissionWorkflowErrorReferencedData>;
  };
  success: boolean;
}

interface VoiceConversationBreakdownData {
  average_call_duration?: number;
  call_duration?: number;
  positive_sentiment_rate?: number;
}

export type BreakdownData = {
  avg_csat?: number | null;
  deflected: number;
  deflection_rate: number;
  dropoff_breakdown?: DropoffBreakdown;
  has_surge: boolean;
  id: string;
  inquiries: number;
  intent_id: string;
  is_active: boolean;
  is_effective: boolean;
  name: string;
  needs_adjustment: boolean;
  relevance_breakdown?: RelevanceBreakdown;
  topics?: Array<DiscoverTopic>;
  workflow_tags?: string[];
} & VoiceConversationBreakdownData;

export interface WidgetConfigResponse {
  agent_chat_image?: string | null;
  csat_survey_config: {
    is_enabled: boolean;
  };
  display_proactive_chat?: boolean;
  display_proactive_chat_intents?: boolean;
  free_form_intent_detection_enabled: boolean | null;
  greeting_line: string | null;
  header_image?: string | null;
  help_button_image?: string | null;
  intents?: Array<SimpleIntentData> | null;
  manually_selected_top_intents: Array<string> | null;
  num_top_intents: number | null;
  proactive_chat_greeting?: string;
  quick_feedback_config?: {
    is_enabled: boolean;
  };
  tag_selection_enabled?: boolean;
  theme_color?: string | null;
  top_n_intents?: Array<SimpleIntentData>;
}

export interface SimpleIntentData {
  id: string;
  title: string;
}

export interface CreateWidgetConfigAssetBody {
  content_type: string;
  description?: string;
  file_extension: string;
}

export interface CreateAssetLinkResponse {
  asset_id: string;
  description: string;
  file_name: string;
  public_url: string;
  url: string;
}

export interface Phrase {
  is_modifiable?: boolean;
  phrase_id?: string;
  phrase_text: string;
}

export interface CreateCustomHandoffRequest {
  is_default_handoff: boolean;
  is_handoff: boolean;
}

export interface LanguagesResponse {
  languages: Array<Language>;
}

export interface LanguagesEnabledResponse {
  language_codes: Array<string>;
  modified_date: string | null;
}

export interface LanguagesEnabledRequest {
  channel: ConversationChannel;
  language_codes: Array<string>;
  modified_date: string | null;
}

export interface GetStepTranslationsForOrgRequest {
  language_code: string;
  mode: TranslationsTableMode;
}

export interface GetStepTranslationsForStepRequest {
  intent_workflow_id: string;
  language_code: string;
  step_id: string;
}

export interface GetConfigurationTranslationsForOrgRequest {
  language_code: string;
}

export interface GetStepTranslationsForOrgResponse {
  mode?: TranslationsTableMode;
  translations: StepTranslation[];
}

export interface GetStepTranslationsForStepResponse {
  translations: StepTranslation[];
}

export interface GetConfigurationTranslationsForOrgResponse {
  translations: ConfigurationTranslation[];
}

export interface GetRestoreTranslationResponse {
  index: number;
  translation: StepTranslation | ConfigurationTranslation;
}

export interface ConfigurationTranslation {
  brand_id: string | null;
  bucket_id: string;
  configuration_id: string;
  configuration_path?: string;
  display_name: string;
  intent_id?: string | null;
  is_override: boolean;
  language_code: string;
  modified_date: string;
  org_id: number;
  source_text: string;
  target_text: string;
  translation_request_id: string;
}

export interface StepTranslation {
  bucket_id: string;
  display_name: string;
  element_path: string;
  intent_workflow_id: string;
  intent_workflow_version: number;
  is_override: boolean;
  language_code: string;
  modified_date: string;
  org_id: number;
  source_text: string;
  step_id: string;
  target_text: string;
  translation_request_id: string;
  translation_type: string;
  workflow_is_draft: boolean;
}

export function isStepTranslation(
  object: StepTranslation | ConfigurationTranslation,
): object is StepTranslation {
  return 'step_id' in object;
}

export interface OverrideTranslationRequest {
  element_path: string;
  intent_workflow_id: string;
  modified_date: string;
  step_id: string;
  target_text: string;
}

export interface OverrideConfigurationTranslationRequest {
  brand_id: string | null;
  configuration_id: string;
  configuration_path?: string;
  intent_id?: string | null;
  modified_date: string;
  source_text?: string;
  target_text: string;
}

export interface Language {
  code: string;
  display_name: string;
  language_id: string;
}

export interface SuggestedIntent {
  description?: string | null;
  expected_coverage?: number | null;
  intent_id: string;
  intent_type: string;
  phrases?: Phrase[] | null;
  title: string;
}

export interface SuggestedIntentsResponse {
  suggested_intents: SuggestedIntent[];
}

export interface IntentData {
  active_workflow_types: WorkflowTypes[];
  api_is_default_handoff: boolean;
  api_is_handoff: boolean;
  api_workflow_id: string | null;
  api_workflow_is_autoflow: boolean;
  channels: ConversationChannel[];
  email_autopilot_enabled: boolean;
  inquiry_count_per_channel: {
    api?: number;
    email: number;
    slack?: number;
    voice?: number;
    widget: number;
  };
  intent_definition_id: string;
  intent_name: string;
  intent_workflow_id: string;
  interactive_email_workflow_id: string | null;
  interactive_email_workflow_is_autoflow: boolean;
  is_autoflow: boolean;
  is_default_handoff: boolean;
  is_handoff: boolean;
  slack_is_default_handoff: boolean;
  slack_is_handoff: boolean;
  slack_workflow_id: string | null;
  slack_workflow_is_autoflow: boolean;
  voice_workflow_id: string | null;
}

export interface IntentsResponse {
  intents: IntentData[];
}

export interface CanvasResponse {
  canvas: Canvas;
  canvas_action_id: string | null;
  handoff_configuration?: HandoffConfiguration;
  invalid_go_to_steps: string[];
  step_id: string;
  undefined_context_variables_in_step?: Record<string, string[]> | null;
  unsupported_steps: string[];
}

type ToolType =
  | 'demo'
  | 'api_call'
  | 'hand_off'
  | 'intent_routing'
  | 'template_api_call'
  | 'system_built_in';

export interface AddAutoflowToolRequest {
  tool_id: string;
  tool_type: ToolType;
}

export interface DeleteAutoflowToolRequest {
  tool_id: string;
}

export interface PatchAutoflowCVRequest {
  context_variable_id: string;
}

export interface PatchAutoflowVersionHistoryRequest {
  description: string;
  is_draft: boolean;
  version_name: string;
}

export interface AutoflowTool {
  configuration_fields: Partial<{
    available_article_sources: Array<ArticleSourceOption>;
  }>;
  tool_id: string;
  tool_name: string;
  tool_type: ToolType;
}

export interface AutoflowToolResponse {
  tools: AutoflowTool[];
}

export interface ToolDefinitionResponse {
  description: string;
  name: string;
  tool_fields?: Partial<{ article_sources: Array<ArticleSource> }>;
  tool_id: string;
  tool_type: ToolType;
}

export interface AutoflowVersionHistory {
  description: string;
  intent_workflow_id: string;
  is_draft: boolean;
  modified_date: string;
  updated_by: string;
  version: number;
  version_name: string;
}

export interface AutoflowIntentWorkflowResponse {
  context_variables: string[];
  intent_workflow_id: string;
  is_draft: boolean;
  policy_description: string;
  status: 'enabled' | 'disabled';
  tool_definitions: ToolDefinitionResponse[];
  unsaved_changes: boolean;
}

export interface TemplateAutoflowIntentWorkflow
  extends AutoflowIntentWorkflowResponse {
  instruction?: string;
  name: string;
  sample_user_query: string;
  template_intent_phrases: string[];
}

export interface WorkflowVersionsResponse {
  versions: WorkflowVersion[];
}

export interface WorkflowVersion {
  is_draft: boolean;
  modified_date: string | null;
  version: number;
}

export interface CanvasIntentsResponse {
  intents: Array<{
    intent_name: string;
    intent_workflow_id: string;
    is_active: boolean;
  }>;
}

export interface HandoffConfigurationsResponse {
  configurations: HandoffConfiguration[];
}

export interface IntentsResponse {
  intents: IntentData[];
}

export interface IntentWorkflowMetadata {
  modified_by: string;
  modified_date: number;
  unsaved_changes: boolean;
}

export interface IntentWorkflowMetadataResponse {
  autoflow: Record<string, IntentWorkflowMetadata>;
  classic: Record<string, IntentWorkflowMetadata>;
}

export interface Canvas {
  activated_date: string | null;
  api_workflow_id: string | null;
  canvas_action_id_to_action_component: Record<
    Action['action_id'],
    ActionStepList
  >;
  created_date: string | null;
  deactivated_date: string | null;
  entry_step_id: string | null;
  intent_id: string;
  intent_title: string;
  intent_workflow_id: string;
  is_active: boolean;
  is_draft: boolean;
  is_duplicated_pending_customization: boolean;
  modified_date: string | null;
  org_id: number;
  policy_description?: string | null;
  preview_api_token: string | null;
  slack_workflow_id: string | null;
  step_map: Record<string, Step>;
  unsaved_changes: boolean;
  version: number;
  voice_workflow_id: string | null;
  workflow_recommendation: WorkflowRecommendation | null;
  workflow_tags: Array<string>;
}

export interface WorkflowRecommendation {
  conversation_ids: string[];
  problematic_convo_str: string;
  recommendation: string;
  summary: string;
}

export interface Step {
  condition_name: string | null;
  step_fields:
    | TextMessageFields
    | ButtonsStepFields
    | FormStepFields
    | GoToIntentStepFields
    | ArticleStepFields
    | ConditionStepFields
    | ZendeskTicketCreationStepFields
    | ZendeskChatHandoffStepFields
    | SalesforceCaseCreationStepFields
    | SalesforceChatHandoffStepFields
    | ImageStepFields
    | EmbedStepFields
    | CsatTriggerPointStepFields
    | AttachmentAnalysisUploadStepFields;
  step_type: StepTypes;
  transitions: Transition[];
}

export type Nullable<T> = { [P in keyof T]?: T[P] | null };
export interface BaseTransition {
  condition_expression: ConditionExpressions;
  step_id: string;
  transition_id: string;
  uses_mapped_fields?: boolean;
}
export type Transition = Nullable<BaseTransition>;

export interface TextMessageFields {
  message: string;
  show_link_preview?: boolean;
}

export interface ArticleStepFields {
  doc_type: string;
  document_id: string;
  document_title: string;
  read_article_action: string;
  standardized_document_id: string;
}

export interface ImageStepFields {
  images: {
    description: string;
    url: string;
  }[];
}

export interface EmbedStepFields {
  embed_url: string;
}

export interface ButtonsStepFields {
  hide_free_form_query?: boolean;
  multi_select: boolean;
  options: ButtonOption[];
  output_variable: string;
  prompt: string;
}

export interface ConditionStepFields {
  condition_name: string;
}

export interface CsatTriggerPointStepFields {
  delay: number;
  negative_rating_routing_intent?: string;
  should_use_delay: boolean;
}

export interface ButtonOption {
  display_name: string;
  transition_id?: string | null;
  url_config?: UrlConfig;
  value: string;
}

export interface FormStepFields {
  form_fields: FormField[];
  is_skippable?: boolean;
  pre_populate_fields: boolean;
  prompt: string;
}

export interface FormField {
  dropdown_options?: DropdownOptionsType[];
  dynamic_list_option_mapping?: string;
  input_type: string;
  output_variable: string;
  placeholder: string;
  prepopulated_field?: string;
}

type CustomFieldValue = boolean | number | string | string[];

export type ContextVariableValue = boolean | number | string | string[];

type CustomFieldPair = [string, CustomFieldValue];

export interface ZendeskTicketCreationStepFields {
  additional_tags: string[];
  brand_name: string | null;
  custom_fields: CustomFieldPair[][];
  include_attachments: boolean;
  include_transcript: boolean;
  update_conversation_status: boolean;
  update_flamethrower_conversation_deflection_status: boolean;
  visitor_email_variable: string | null;
  visitor_name_variable: string | null;
  visitor_question_variable: string | null;
  visitor_subject_formattable_message: string | null;
  visitor_subject_variable: string | null;
}

export interface ZendeskChatHandoffStepFields {
  chat_tags: string[] | null;
  department: string | null;
  ignore_agent_availability: boolean;
  update_flamethrower_conversation_deflection_status: boolean;
  visitor_email_variable: string;
  visitor_name_variable: string;
  visitor_question_variable: string;
}

export interface SalesforceCaseCreationStepFields {
  custom_fields: {};
  standard_fields?: Record<string, string>;
  transcript_field: string;
  update_flamethrower_conversation_deflection_status: boolean;
  visitor_email_variable: string;
  visitor_name_variable: string;
  visitor_question_variable: string;
}

export interface SalesforceChatHandoffStepFields {
  extra_prechat_form_details: string[][][];
  salesforce_chat_config: string[][];
  update_flamethrower_conversation_deflection_status: boolean;
  visitor_email_variable: string;
  visitor_name_variable: string;
  visitor_question_variable: string;
}

export interface UrlConfig {
  base_url: string;
  query_parameters: Record<string, string>;
}

export type ContextVariablesStep = {
  defined_context_variables: string[];
  step_output_variables: string[];
};

export type ActionStepList = {
  action_id: Action['action_id'];
  config_fields?: Record<string, unknown>;
  entry_step_id: string;
  is_last_element: boolean;
  steps: string[];
  transition_branches?: Array<ActionTransitionBranch>;
};

export type ActionTransitionBranch = {
  display_name: string;
  parent_step_id: string;
};

export type GoToIntentStepFields = {
  intent_workflow_id: string;
};

export interface PreviewTokenReponse {
  preview_api_token: string;
}

export interface SolveApiKeyResponse {
  api_key: string;
}

export interface SlackInstallationResponse {
  has_active_installation: boolean;
}

export interface DeflectionConfig {
  knowledge_config: {
    doc_types: string[];
  };
}

interface EsDocumentOverview {
  doc_count: number | null;
  doc_type: string;
}

export interface SolveApiConfigResponse {
  config: DeflectionConfig | null;
  document_overview: EsDocumentOverview[];
}

export interface WidgetApiKeyResponse {
  api_key: string;
}

export type SolveEmbeddedDashboardResponse = {
  embed_url: string;
};

export interface WorkflowBuilderSettingsResponse {
  created_date: string;
  global_context_variables: GlobalContextVariable[];
  org_id: number;
}

export interface HandoffIntentWorkflowUsage {
  intent_definition_id: string;
  intent_title: string;
  intent_workflow_id: string;
}

export interface HandoffUsagesResponse {
  usages: HandoffIntentWorkflowUsage[];
}

export type RestartConversationStepFields = {
  restart_message: string;
  show_greeting_message: boolean;
  show_top_n_intents: boolean;
};

export interface ModelTrainingResponse {
  is_model_training: boolean;
}

export type Article = {
  document_id: string;
  link?: string;
  permission: string;
  preview: string;
  source_type: string;
  standardized_document_id: string;
  title: string;
};

interface MacroSearchResult extends Article {
  html: string;
}

export type ArticlesResponse = {
  articles: Article[];
  is_last_page: boolean;
  page: number;
  query: string;
  total_hits: number;
};

export interface MacroSearchResponse {
  is_last_page: boolean;
  macros: MacroSearchResult[];
  page: number;
  query: string;
  total_hits: number;
}

export interface WhitelistedDomainsResponse {
  allow_all_domains: boolean;
  whitelist: string[] | null;
}

export interface StepMetricsResponse {
  csat: {
    average_score: number;
  };
  deflection_rate: {
    count: number;
    total_count: number;
  };
  dropoff: {
    count: number;
    total_count: number;
  };
  step_is_modified: boolean;
  step_last_modified_at: string;
  traffic: {
    count: number;
    total_count: number;
  };
}

export interface StepMetricsOfABranch {
  button_display_name: string;
  button_index: number;
  button_value: string;
  csat: {
    average_score: number;
  };
  deflection_rate: {
    count: number;
    total_count: number;
  };
  traffic: {
    count: number;
    total_count: number;
  };
}

export interface BranchingStepMetricsResponse {
  buttons: StepMetricsOfABranch[];
  dropoff: {
    count: number;
    total_count: number;
  };
  step_is_modified: boolean;
  step_last_modified_at: string;
}

export interface ConversationMetrics {
  date_created: string;
  first_input_line: string;
  intent_names: Array<string>;
  is_deflected: boolean;
  is_need_review: boolean;
  workflow_tags: Array<string>;
}
export type KustomerHandoffCustomAttribute = {
  field: string;
  value: number | boolean | string;
};

export type KustomerResourceCustomAttribute = {
  display_name: string;
  id: string;
  options: string[];
  type: string;
};

export interface KustomerChatHandoffCustomization {
  agent_chat_handoff_settings: {
    assistant_id?: string | null;
    custom_attributes: KustomerHandoffCustomAttribute[];
    handoff_form_prompt: string;
    kustomer_custom_attributes: KustomerResourceCustomAttribute[];
    transcript_field?: string | null;
    upload_files_attached?: boolean | null;
    write_transcript_to_note: boolean;
  };
  email: string | null;
  jwt: string | null;
  name: string | null;
  one_chat: boolean;
  question: string | null;
}

export interface KustomerConversationCreationCustomization {
  conversation_creation_settings: {
    confirmation_message: string;
    custom_attributes: KustomerHandoffCustomAttribute[];
    handoff_form_question_message: string;
    kustomer_custom_attributes: KustomerResourceCustomAttribute[];
    subject: string | null;
    transcript_field?: string | null;
    upload_files_attached?: boolean | null;
    write_transcript_to_note: boolean;
  };
  email: string | null;
  name: string | null;
  question: string | null;
}

export interface KustomerAttributeField {
  field: string;
  type: string;
  value: number | boolean | string;
}

export interface KustomerHandoffCustomization
  extends KustomerChatHandoffCustomization,
    KustomerConversationCreationCustomization {
  include_agent_chat_handoff: boolean;
  include_conversation_creation_handoff: boolean;
}

export type LandingPageHelperAPIResponse =
  | OrgIdResponse
  | ChannelsResponse
  | MetricDataTypesResponse
  | MetricAggregateResponse
  | MetricBreakdownResponse
  | Array<DiscoverAutomationsByTypeResponse>;

export interface SolveEmailMetricsBase {
  /**
   * Number of email conversations where we sent a response.
   */
  attempted: number;
  /**
   * estimated cost per ticket.
   */
  cost_per_ticket: number;
  /**
   * cost per ticket * self_served
   */
  cost_savings: number;
  /**
   * Number of email conversations that were created.
   */
  inquiries: number;
  /**
   * when the table was last updated
   */
  last_updated_date: string;
  /**
   * Number of email conversations where we had an opportunity to send a response.
   */
  potential_attempts: number;
  /**
   * self_served / inquiries.
   */
  received_deflection_rate: number;
  /**
   * self_served / attempts.
   */
  responded_deflection_rate: number;
  /**
   * attempts / potential_attempts.
   */
  response_rate: number;
  /**
   * Number of email conversations that were self served.
   */
  self_served: number;
  /**
   * self_served / inquiries
   */
  self_served_rate: number;
}

export interface EmailConversationMetricBreakdownRow
  extends SolveEmailMetricsBase {
  /**
   * intent definition id - to match metrics to intents
   */
  intent_definition_id: string;
}

export interface EmailConversationMetricBreakdown {
  breakdown: EmailConversationMetricBreakdownRow[];
}

export interface OnboardingFlagsResponse {
  is_bot_activated: boolean;
  is_handoff_onboarding_passed: boolean;
  is_knowledge_onboarding_passed: boolean;
  is_onboarding_finished: boolean;
  is_widget_onboarding_passed: boolean;
}

export type UpdateOnboardingFlags =
  | 'is_theme_visited'
  | 'is_handoff_visited'
  | 'is_knowledge_visited';

export type UpdateOnboardingFlagsRequest = RequireExactlyOne<
  Record<UpdateOnboardingFlags, true>
>;

export type SolveEmailTrackingEventsType =
  | 'generated-response-accepted'
  | 'generated-response-rejected'
  | 'user-observed-generated-responses';

export type SolveEmailTrackingApplication = 'solve-email';

export type GenerateResponseActionType = 'rephrase' | 'extend' | 'shorten';

export interface GenerateResponseRequest {
  action: GenerateResponseActionType;
  text: string;
}

export interface GoToIntentUsage {
  intent_workflow_id: string;
  intent_workflow_ids_using_intent_workflow: string[];
}

export interface GoToIntentUsagesResponse {
  usages: GoToIntentUsage[];
}

export interface ContextVariablesResponse {
  context_variables: ContextVariable[];
  template_context_variables: ContextVariable[];
  usable_system_context_variables: ContextVariable[];
}

interface MentionFormat {
  prefix: string;
  suffix: string;
}

interface ProductMentionOption {
  image?: string;
  label: string;
  value: string; // Optional property
}

export interface ProductMentionField {
  mention_format: MentionFormat;
  mention_trigger: string;
  name: string;
  options: ProductMentionOption[];
}

export interface IntercomLiveChatActionSettings {
  conversation_attributes: Array<{
    name: string;
    value: string;
  }> | null;
  user_email: string | null;
  user_id: string | null;
  user_name: string | null;
}

export interface IntercomConversationAttribute {
  data_type: string;
  name: string;
  options?: Array<string>;
  value: string;
}

export interface IntercomLiveChatHandoffCustomization {
  conversation_attributes: Array<IntercomConversationAttribute>;
}
