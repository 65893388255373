import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { MissMatchContextVariableTypePayload } from 'src/types/ErrorEventTypes';

export const MissMatchContextVariableFix = ({
  payload,
}: {
  payload: MissMatchContextVariableTypePayload;
}) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font16'>
        To resolve this issue, ensure the parameter matches the type of its
        associated Context Variable:
      </Typography>
      <Typography variant='font16'>
        - The mismatched Context Variable is:{' '}
        <strong>{payload.failed_cv}</strong>
      </Typography>
      <Typography variant='font16'>
        - The mismatched Context Variable Value is:{' '}
        <strong>{payload.cv_value}</strong>
      </Typography>
      <Typography variant='font16'>
        - Context Variable ID: <strong>{payload.cv_id}</strong>
      </Typography>
      <Typography variant='font16'>Steps to fix:</Typography>
      <Typography variant='font16'>
        1. Check the type of the Context Variable in the configuration.
      </Typography>
      <Typography variant='font16'>
        2. Update the parameter to match the expected type of the Context
        Variable.
      </Typography>
      <Typography variant='font16'>
        3. If needed, modify the Context Variable type in the configuration to
        align with the parameter.
      </Typography>
    </Box>
  );
};
