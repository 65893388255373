import {
  useGetFeatureFlagsQuery,
  useGetPermissionsQuery,
} from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { Permission } from 'src/utils/enums';

export const useGetIsDiscoverEnabledInInsights = () => {
  const { data: permissions = {} } = useGetPermissionsQuery();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isTopicEnabled = featureFlags.includes('solve_insights_topic');
  const isDiscoverEnabled = orgConfigData?.discover_status === 'enabled';
  const isDiscoverPermissionEnabled = Boolean(permissions[Permission.DISCOVER]);
  return (
    true || (isDiscoverEnabled && isDiscoverPermissionEnabled && isTopicEnabled)
  );
};
