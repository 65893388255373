import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useExtensionEvent,
} from '@remirror/react';

import { LinkExtension, type ShortcutHandlerProps } from 'remirror/extensions';

function useLinkShortcut() {
  const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps>();
  const [isEditing, setIsEditing] = useState(false);

  useExtensionEvent(
    LinkExtension,
    'onShortcut',
    useCallback(
      props => {
        if (!isEditing) {
          setIsEditing(true);
        }

        return setLinkShortcut(props);
      },
      [isEditing],
    ),
  );

  return { isEditing, linkShortcut, setIsEditing } as const;
}

export function useFloatingLinkState({
  shouldValidateHrefLength,
}: {
  shouldValidateHrefLength?: boolean;
} = {}) {
  const chain = useChainedCommands();
  const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
  const { empty, to } = useCurrentSelection();

  const url = String(useAttrs().link()?.href ?? '');
  const existingTarget = useAttrs().link()?.target;
  const target = String(
    existingTarget === undefined ? '_blank' : existingTarget ?? '',
  );

  const [href, setHref] = useState(url);
  const [shouldOpenInNewTab, setShouldOpenInNewTab] = useState(
    target === '_blank',
  );

  const errorMessage =
    href.length > 150 && shouldValidateHrefLength
      ? 'Links cannot be more than 150 characters'
      : undefined;

  const onRemove = useCallback(() => {
    return chain.removeLink().focus().run();
  }, [chain]);

  const resetState = useCallback(() => {
    setIsEditing(false);
    setHref('');
    setShouldOpenInNewTab(true);
  }, [setIsEditing, setHref, setShouldOpenInNewTab]);

  useEffect(() => {
    if (isEditing) {
      setHref(url);
    }
  }, [url, isEditing]);

  useEffect(() => {
    setShouldOpenInNewTab(target === '_blank');
  }, [target]);

  const submitHref = useCallback(() => {
    const range = linkShortcut;

    if (href === '') {
      chain.removeLink();
    } else {
      chain.updateLink(
        {
          auto: false,
          href,
          target: shouldOpenInNewTab ? '_blank' : undefined,
        },
        range,
      );
    }

    chain.focus(range?.to ?? to).run();

    resetState();
  }, [resetState, linkShortcut, chain, href, to, shouldOpenInNewTab]);

  const cancelHref = useCallback(() => {
    resetState();
  }, [resetState]);

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink();
    }

    setIsEditing(true);
  }, [chain, empty, setIsEditing]);

  return useMemo(
    () =>
      ({
        cancelHref,
        clickEdit,
        errorMessage,
        href,
        isEditing,
        linkShortcut,
        onRemove,
        setHref,
        setShouldOpenInNewTab,
        shouldOpenInNewTab,
        submitHref,
      } as const),
    [
      href,
      shouldOpenInNewTab,
      linkShortcut,
      isEditing,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref,
      errorMessage,
    ],
  );
}
