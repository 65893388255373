import { LineGraphDataType } from 'src/services/insights/types';

export const generateChartData = ({
  data,
  enabledLegends,
}: {
  data: LineGraphDataType | undefined;
  enabledLegends: string[];
}) => {
  const values =
    data?.values.map(value => {
      return {
        ...value,
        visible: enabledLegends.includes(value.label),
      };
    }) || [];
  return {
    ...data,
    values,
    x_axis: data?.x_axis || [],
  };
};

export const getSkeletonHeight = (height: string | number) => {
  const extraHeight = 90;
  if (typeof height === 'string') {
    return Number(height.replace('px', '')) - extraHeight;
  }
  return height - extraHeight;
};
