import { SearchBar } from '@forethought-technologies/forethought-elements';
import Bubble from './Bubble';
import {
  BubbleGroup,
  Content,
  NarrowContentWrapper,
  Section,
  Subtitle,
  Title,
} from './styles';
import { avatarTranslations, bubbleAvatars } from './utils';
import useMediaQuery from 'src/hooks/useMediaQuery';

interface HeaderProps {
  clearSearch?: () => void;
  isCollectionsPage: boolean;
  onSearch?: (value: string) => void;
  searchQuery?: string;
}

export default function IntegrationsHeader({
  clearSearch,
  isCollectionsPage,
  onSearch,
  searchQuery = '',
}: HeaderProps) {
  const isXLDesktop = useMediaQuery('(min-width: 1440px)');

  return (
    <Section>
      {isXLDesktop && (
        <BubbleGroup>
          {bubbleAvatars.left.map(
            (src, idx) =>
              src && (
                <Bubble
                  idx={idx}
                  key={src}
                  src={src}
                  translate={avatarTranslations[idx]}
                />
              ),
          )}
        </BubbleGroup>
      )}
      <Content>
        <NarrowContentWrapper>
          <Title>Connect your tools to make the best out of Forethought</Title>
        </NarrowContentWrapper>
        <Subtitle>
          {isCollectionsPage
            ? 'Find the products your company uses, and integrate them to make your Forethought platform smarter and better for your entire company!'
            : 'Search and manage your existing integrations or find new ones to enhance your workflows!'}
        </Subtitle>
        {!isCollectionsPage && (
          <NarrowContentWrapper>
            <SearchBar
              onChange={e => onSearch && onSearch(e.target.value)}
              onClear={searchQuery ? clearSearch : undefined}
              placeholder='Search for products'
              value={searchQuery}
            />
          </NarrowContentWrapper>
        )}
      </Content>
      {isXLDesktop && (
        <BubbleGroup>
          {bubbleAvatars.right.map(
            (src, idx) =>
              src && (
                <Bubble
                  idx={idx}
                  key={src}
                  src={src}
                  translate={avatarTranslations[idx]}
                />
              ),
          )}
        </BubbleGroup>
      )}
    </Section>
  );
}
