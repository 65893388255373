import Box from '@mui/material/Box';

import {
  DateRangeFilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TicketFilter from './TicketFilter';
import { DateRange } from 'src/types/types';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface HeaderFiltersProps {
  filters: string[];
  onDateRangeChange: (dateRange: DateRange) => void;
  onFilterChange: (filters: string[]) => void;
  selectedDateRange: DateRange;
}

const HeaderFilters = ({
  filters,
  onDateRangeChange,
  onFilterChange,
  selectedDateRange,
}: HeaderFiltersProps) => {
  return (
    <Box columnGap={1} display='flex'>
      <Typography variant='font24'>Tickets</Typography>
      <DateRangeFilterButton
        initialValue={selectedDateRange}
        onChange={onDateRangeChange}
        options={datePickerRangeOptionsRevamp}
        value={selectedDateRange}
      />
      <TicketFilter onChange={onFilterChange} value={filters} />
    </Box>
  );
};

export default HeaderFilters;
