import { Box } from '@mui/material';

import { Badge } from '@forethought-technologies/forethought-elements';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';

export default function AutopilotBadge() {
  return (
    <Badge
      label={
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
          }}
        >
          <img alt='' height='14px' src={aaBadgeSrc} width='14px' />
          Autoflow
        </Box>
      }
      variant='macro'
    />
  );
}
