import moment from 'moment';

import { AutomationCallPreview } from '../slices/assist-automations/types';
import { buildQueryFromState } from './serializer/SERIALIZER';
import { sendAuthRequest, sendAuthRequestWithErrorHandling } from './api';
import {
  Automation,
  ChartDataDict,
  Connector,
  ConnectorDefinition,
  ConnectorJobListResponse,
  ConnectorTypes,
  Credentials,
  ESDocumentOverviewResponse,
  ExperimentResponse,
  Fields,
  FilterValueResponse,
  GetAllReportsDict,
  GetAnswersQuery,
  GetCurrentUserResponse,
  Helpdesk,
  IdPSettings,
  MacroTableDataDict,
  Organization,
  OrgConfigResponse,
  OrgDict,
  Report,
  ReportsResponseDict,
  SolveOverviewDataDict,
  SolveTableDict,
  SSOConfig,
  SSOSettings,
  TableDataDict,
  UpdateConnectorDisplaySettings,
  UpdateConnectorRequest,
  UpdateOrganizationResponse,
  User,
  UserRequestBody,
  WorkflowConfigDict,
} from './apiInterfaces';
import omit from 'lodash/fp/omit';
import { selectSelectedBreakdownValues } from 'src/reducers/breakdownsReducer/breakdownsReducer';
import { automationTemplate } from 'src/slices/assist-automations/mockData';
import type { RootState } from 'src/store/rootReducer';
import store from 'src/store/store';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

const USERS_API = `${API_URL}ssel/users`;
const ORG_API = `${API_URL}ssel/organizations`;

export const getAnswersDataAPI = (
  state: RootState,
): Promise<ChartDataDict & TableDataDict> => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const query = buildQueryFromState(state);
  const params: GetAnswersQuery = {
    query,
    time_zone: timeZone,
  };
  return sendAuthRequest(`${API_URL}analytics/query`, params)
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getFilterFieldsAPI = (curateFields = false): Promise<Fields> => {
  return sendAuthRequest(
    `${API_URL}analytics/filter-fields${
      curateFields ? '?curate_fields=True' : ''
    }`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

// TODO - remove this method and use the decoupled method below
export const getFilterValuesAPI = (
  state: RootState,
  searchParameter?: string,
): Promise<FilterValueResponse> => {
  const selectedFilterField = state.filters.selectedFilterField;

  const filterField = {
    field_category: selectedFilterField?.field_category?.toLowerCase(),
    field_id: selectedFilterField?.field_id,
    partial_field_value: searchParameter,
  };

  return sendAuthRequest(
    `${API_URL}analytics/filter-values`,
    filterField,
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getBreakdownFieldsAPI = (): Promise<Fields> => {
  return sendAuthRequest(`${API_URL}analytics/breakdown-fields`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getAllReportsAPI = (): Promise<GetAllReportsDict> => {
  return sendAuthRequest(`${API_URL}analytics/reports`, {}, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const bookmarkReportAPI = (
  reportId: number,
  bookmark: boolean,
): Promise<ReportsResponseDict> => {
  const data = {
    bookmark,
    report_id: reportId,
  };

  return sendAuthRequest(`${API_URL}analytics/bookmark-report`, data)
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteReportAPI = (
  reportId: number,
): Promise<ReportsResponseDict> => {
  const data = {
    report_id: reportId,
  };

  return sendAuthRequest(`${API_URL}analytics/reports`, data, 'DELETE')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getReportAPI = (reportId: number): Promise<Report> => {
  const data = {
    report_id: reportId,
  };

  return sendAuthRequest(`${API_URL}analytics/reports/${reportId}`, data, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const createReportAPI = (
  name: string,
  description: string,
  baseReport: string,
) => {
  const { timeRange, viewMode } = store.getState().pageSettings;
  const startTime = timeRange.from ? moment(timeRange.from).unix() : 0;
  const endTime = timeRange.to ? moment(timeRange.to).unix() : 9999999999;
  const base = baseReport === 'Tickets' ? 'TICKET' : 'AGENT';
  const query = {
    filters: [],
    query_type: base,
    time_range: {
      end_time: endTime,
      start_time: startTime,
    },
    time_window_unit: 'DAILY',
    visualization_type: viewMode.toUpperCase(),
  };

  const data = {
    description,
    name,
    query,
  };

  return sendAuthRequest(`${API_URL}analytics/reports`, data)
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const modifyExistingReportAPI = (
  report_id: number,
  name: string,
  description: string,
  shouldSaveAsNew: boolean,
) => {
  const state = store.getState();
  const { pageSettings } = state;
  const { chartYAxis } = pageSettings;
  const query = buildQueryFromState(state);

  const method = shouldSaveAsNew ? 'POST' : 'PUT';
  const selected_breakdown_values = selectSelectedBreakdownValues(state);

  const dataVal = {
    description,
    name,
    query,
    report_id,
    selected_breakdown_values,
    selected_y_axis: chartYAxis,
  };
  return sendAuthRequest(`${API_URL}analytics/reports`, dataVal, method)
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getSearchOrganizationsAPI = (): Promise<OrgDict> => {
  return sendAuthRequest(`${API_URL}superadmin/organizations`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getSolveDataAPI = (
  state: RootState,
): Promise<SolveOverviewDataDict & SolveTableDict> => {
  const { pageSettings, solveData } = state;
  const { solveViewMode, timeFrameFilter, timeRange } = pageSettings;
  const { selectedChannel } = solveData;
  const start_timestamp = timeRange.from ? moment(timeRange.from).unix() : 0;
  const end_timestamp = timeRange.to ? moment(timeRange.to).unix() : 9999999999;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const setVisualizationType = () => {
    if (solveViewMode === 'breakdowns') {
      return 'table';
    } else {
      return solveViewMode;
    }
  };

  const params = {
    query: {
      breakdown_type: timeFrameFilter,
      channel: selectedChannel,
      end_timestamp,
      start_timestamp,
      visualization_type: setVisualizationType(),
    },
    time_zone: timeZone,
  };

  return sendAuthRequest(`${API_URL}solve-analytics/query`, params)
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getMacrosAPI = (): Promise<MacroTableDataDict> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v0/macros`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateMacroAPI = () => {
  const state = store.getState();
  const { macroControlsData } = state;
  const { macroFieldtoUpdate, selectedMacro } = macroControlsData;
  // @ts-expect-error legacy code with untyped state
  const { last_modified_at, macro_id } = selectedMacro;

  const data = {
    last_modified_at,
    ...macroFieldtoUpdate,
  };

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v0/macros/${macro_id}`,
    data,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getWorkflowConfigAPI = (): Promise<WorkflowConfigDict> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v1/workflow-builder`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deleteWorkflowAPI = (
  workflowId: string,
): Promise<WorkflowConfigDict> => {
  const { solveWorkflows } = store.getState();
  const { workflowConfigData } = solveWorkflows;
  // @ts-expect-error legacy code with untyped state
  const { version } = workflowConfigData;

  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v1/workflow-builder/${version}/workflow/${workflowId}`,
    {},
    'DELETE',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getAgentHelpdesk: () => Promise<Helpdesk> = async () => {
  return sendAuthRequest(`${API_URL}agent-landing-page`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getAvailableConnectors = async (): Promise<
  ConnectorDefinition[]
> => {
  return sendAuthRequest(`${API_URL}ssel/connectors/available`, null, 'GET')
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getConnectorDocumentCount = (
  connectorId: string,
): Promise<ESDocumentOverviewResponse> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}/es-document-overview`,
    null,
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getConnector = (connectorId: string): Promise<Connector> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}`,
    null,
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getConnectorJobHistory = (
  connectorId: string,
): Promise<ConnectorJobListResponse> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}/jobs`,
    null,
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const runConnectorJob = (connectorId: string): Promise<void> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}/jobs`,
    null,
    'POST',
  ).then(res => normalizeResponseErrors(res));
};

export const updateConnector = (
  connectorId: string,
  updateConnectorRequest: UpdateConnectorRequest,
): Promise<Connector> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}`,
    updateConnectorRequest,
    'PUT',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const updateConnectorDisplayName = async (
  connectorId: string,
  updateConnectorRequest: UpdateConnectorDisplaySettings,
): Promise<Connector> => {
  const res = await sendAuthRequest(
    `${API_URL}ssel/connectors/${connectorId}/display-settings`,
    updateConnectorRequest,
    'PUT',
  );
  const res_2 = normalizeResponseErrors(res);
  return res_2.json();
};

export const getIsMultiConnectorsEnabled = async (): Promise<boolean> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/multi-connectors/enabled`,
    null,
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getCreatedConnectors = async (): Promise<Connector[]> => {
  return sendAuthRequest(`${API_URL}ssel/connectors/created`, null, 'GET')
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getConnectorById = async (
  connectorId: string,
): Promise<Required<Connector>> => {
  return sendAuthRequest(
    `${API_URL}ssel/assist/connector/${connectorId}`,
    null,
    'GET',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const generateOauthUrl = async (
  connector_definition_slug: string,
  oauth_credentials: ConnectorDefinition['oauth_credential_schema'],
  multiConnectorsEnabled: boolean,
): Promise<{ oauth_url: string }> => {
  const body = {
    connector_definition_slug,
    oauth_credentials,
    ...(multiConnectorsEnabled && { multi_connectors: true }),
  };

  return sendAuthRequest(`${API_URL}ssel/connectors/oauth`, body, 'POST')
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const generateOauthUrlForConnector = async (
  connector_id: string,
  connector_definition_slug: string,
  oauth_credentials: ConnectorDefinition['oauth_credential_schema'],
  multiConnectorsEnabled: boolean,
): Promise<{ oauth_url: string }> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connector_id}/oauth`,
    {
      connector_definition_slug: multiConnectorsEnabled
        ? connector_definition_slug
        : connector_id,
      oauth_credentials,
    },
    'POST',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const removeCreatedIntegration = async (
  connector_id: string,
): Promise<{ success: boolean }> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors/${connector_id}`,
    null,
    'DELETE',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const createConnector = async ({
  connectorDefinitionSlug,
  connectorFields = {},
  connectorTypes,
}: {
  connectorDefinitionSlug: string;
  connectorFields?: Credentials;
  connectorTypes: ConnectorTypes;
}): Promise<Connector> => {
  return sendAuthRequest(
    `${API_URL}ssel/connectors`,
    {
      connector_definition_slug: connectorDefinitionSlug,
      connector_fields: connectorFields,
      connector_types: connectorTypes,
      reindex_interval_sec: 0,
    },
    'POST',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const getCurrentUser = async (): Promise<GetCurrentUserResponse> => {
  return sendAuthRequest(`${USERS_API}/current-user`, null, 'GET')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateUser = async (
  userId: string,
  userRequestBody: UserRequestBody,
): Promise<User> => {
  // Cannot update email anyway, so do not send it
  const user = omit('email', userRequestBody);

  return sendAuthRequest(encodeURI(`${USERS_API}/${userId}`), { user }, 'PUT')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateOrganization = async (
  organization: Partial<Organization>,
): Promise<UpdateOrganizationResponse> => {
  return sendAuthRequest(
    `${API_URL}ssel/organizations/settings`,
    { organization },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getUsers = async (): Promise<User[]> => {
  return sendAuthRequest(USERS_API, null, 'GET')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const createUser = async (user: UserRequestBody): Promise<User> => {
  return await sendAuthRequest(USERS_API, { user }, 'POST')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const deleteUser = async (userId: string): Promise<void> => {
  await sendAuthRequest(
    encodeURI(`${USERS_API}/${userId}`),
    null,
    'DELETE',
  ).then(normalizeResponseErrors);
};

export const resendUserInvite = async (email: string): Promise<void> => {
  await sendAuthRequest(
    `${USERS_API}/resend-invitation`,
    { email },
    'POST',
  ).then(normalizeResponseErrors);
};

export const getAssistAutomations = (
  isPreFilledTemplateEnabled?: boolean,
): Promise<{
  automations: Array<Automation>;
}> => {
  return sendAuthRequest(`${API_URL}ssel/assist/automation`, null, 'GET')
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json())
    .then(resJson => {
      if (isPreFilledTemplateEnabled) {
        resJson.automations = [automationTemplate, ...resJson.automations];
      }

      return resJson;
    });
};

export const updateAssistAutomation = (
  automationId: string,
  automation: Partial<Automation>,
): Promise<Automation> => {
  return sendAuthRequest(
    `${API_URL}ssel/assist/automations/${automationId}`,
    automation,
    'PUT',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const createAssistAutomation = (
  automation: Partial<Automation>,
): Promise<Automation> => {
  return sendAuthRequest(
    `${API_URL}ssel/assist/automations`,
    automation,
    'POST',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const callPreviewAssistAutomation = ({
  automation,
  inputs,
}: {
  automation: AutomationCallPreview;
  inputs: Record<string, string | string[] | number>;
}): Promise<{ external_text_field?: string; internal_text_field?: string }> => {
  return sendAuthRequest(
    `${API_URL}ssel/assist/preview-automation`,
    {
      automation,
      inputs,
    },
    'POST',
  )
    .then(res => normalizeResponseErrors(res))
    .then(res => res.json());
};

export const deleteAssistAutomation = (automationId: string) => {
  return sendAuthRequest(
    `${API_URL}ssel/assist/automation/${automationId}`,
    {},
    'DELETE',
  ).then(res => normalizeResponseErrors(res));
};

export const getAppCuesStateAPI = (
  callback: (res: { enabled: boolean; org_id: number }) => void,
) => {
  sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/appcues_enabled`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json())
    .then(callback);
};

export const getOrgConfig = (): Promise<OrgConfigResponse> => {
  return sendAuthRequest(`${API_URL}org-config`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getSSOSettings = (): Promise<SSOSettings> => {
  return sendAuthRequest(`${ORG_API}/sso`, null, 'GET')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const createIdP = async (payload: IdPSettings): Promise<void> => {
  const res = await sendAuthRequest(`${ORG_API}/sso`, payload, 'POST');
  if ('error' in res) {
    throw new Error(res.error);
  }
};

export const deleteIdP = async (): Promise<void> => {
  await sendAuthRequest(`${ORG_API}/sso`, null, 'DELETE');
};

export const updateSSOConfig = async (payload: SSOConfig): Promise<void> => {
  await sendAuthRequest(`${ORG_API}/sso`, payload, 'PUT');
};

export const createSCIMKey = (): Promise<{
  scim_token: string;
}> => {
  return sendAuthRequest(`${ORG_API}/sso/scim`, {}, 'POST')
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const disableSCIM = async (): Promise<void> => {
  await sendAuthRequest(`${ORG_API}/sso/scim`, {}, 'DELETE');
};

export const getExperimentsTreatments =
  async (): Promise<ExperimentResponse> => {
    return await sendAuthRequestWithErrorHandling(
      `${API_URL}experiments`,
      null,
      'GET',
    );
  };
