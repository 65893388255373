import { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import StatusIcon from '../StatusIcon';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { ModelLabelMapping } from 'src/services/triage/types';

export interface ModelSelectProps {
  isTableFilter?: boolean;
  models: ModelLabelMapping[];
  modelsLoading: boolean;
  onModelChange: (selectedModel: string) => void;
  selectedModel: string | null;
}

const ModelSelect = ({
  isTableFilter,
  models,
  modelsLoading,
  onModelChange,
  selectedModel,
}: ModelSelectProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  useEffect(() => {
    if (!models.length) {
      return;
    }
    if (
      !selectedModel ||
      !models.find(item => item.model_name === selectedModel)
    ) {
      onModelChange(models[0].model_name);
    }
  }, [models, onModelChange, selectedModel]);

  if (modelsLoading) {
    return (
      <Box sx={{ '&>span': { transform: 'scale(1)' } }}>
        <Skeleton height='33px' width={100} />
      </Box>
    );
  }

  const handleChange = (event: SelectChangeEvent) => {
    const model = event.target.value;
    const eventType = isTableFilter
      ? 'triage-insights-table-model-select-changed'
      : 'triage-insights-header-model-select-changed';
    emitTrackingEventCallback(eventType, {
      model,
    });
    onModelChange(model);
  };

  const value = selectedModel || '';

  return (
    <Box sx={{ '.MuiSelect-select': { py: '6px !important' } }} width='200px'>
      <SelectDropdown
        id='model-select'
        isMenuSearchable
        menuTitle='Models'
        multiple={false}
        onChange={handleChange}
        options={models.map(model => ({
          label: model.model_name,
          optionStartAdornment: <StatusIcon isEnabled={model.is_active} />,
          value: model.model_name,
        }))}
        value={value}
      />
    </Box>
  );
};

export default ModelSelect;
