import { useEffect } from 'react';
import { SolveConfigContentContainer, ToolbarContainer } from '..';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import { SOLVE_VOICE_CONFIG_TABS } from '../constants';
import FallbackWidgetContent from '../fallback-tab-content/FallbackWidgetContent';
import { useGetVoiceTabIndex } from '../hooks/useGetTabIndex';
import KnowledgeWidgetContent from '../knowledge-tab-content/KnowledgeWidgetContent';
import StaticSolveWidget from '../static-solve-widget/StaticSolveWidget';
import { Channel } from '../types';
import { getSolveVoiceConfigTabs } from '../utils';
import VoiceMockContent from './VoiceMockContent';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import {
  getBoughtPhoneNumbers,
  selectIsValidationFailing,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

export default function VoiceTab({ channel }: { channel: Channel }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';

  const voiceTabIndex = useGetVoiceTabIndex();
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isFallbackWorkflowEnabled =
    useIsFeatureFlagEnabled('fallback_workflow');

  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const filteredTabs = getSolveVoiceConfigTabs({ isFallbackWorkflowEnabled });

  useEffect(() => {
    dispatch(getBoughtPhoneNumbers());
  }, [dispatch]);

  const handleTabOnClick = (index: number) => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    const tab = SOLVE_VOICE_CONFIG_TABS[index].id;
    navigate(
      {
        pathname: Routes.SOLVE_CONFIGURATION,
        search: `?channel=${channel}&tab=${tab}`,
      },
      { replace: true },
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
      tab: tab,
    });
  };

  const handleRenderApiConfigurationBasedOnTabType = () => {
    switch (query) {
      case 'voice':
        return <VoiceMockContent />;
      case 'integrate':
        return <VoiceMockContent />;
      case 'knowledge':
        return <KnowledgeWidgetContent />;
      case 'fallback':
        return <FallbackWidgetContent />;
      default:
        return <VoiceMockContent />;
    }
  };

  return (
    <>
      <SolveConfigContentContainer>
        <StaticSolveWidget type='minimized'>
          {handleRenderApiConfigurationBasedOnTabType()}
        </StaticSolveWidget>
      </SolveConfigContentContainer>
      <ToolbarContainer
        isFullScreen={false}
        isTabSelected={typeof voiceTabIndex === 'number'}
      >
        <VerticalTabBar
          setTabIndex={handleTabOnClick}
          tabBodyWidth={520}
          tabIndex={voiceTabIndex}
          tabs={filteredTabs}
        />
      </ToolbarContainer>
    </>
  );
}
