import { WarningAmberRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ERROR_MESSAGE } from './constants';

const ErrorState = () => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height='200px'
      justifyContent='center'
      rowGap={3}
    >
      <WarningAmberRounded
        sx={{ color: palette.colors.slate[300], fontSize: 48 }}
      />
      <Typography variant='font14'>{ERROR_MESSAGE}</Typography>
    </Box>
  );
};

export default ErrorState;
