import TicketStatusTableCell from './TicketStatusTableCell';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { TriageTicket } from 'src/services/triage/types';

export const COLUMNS: DashboardTableProps<TriageTicket>['columns'] = [
  {
    key: 'timestamp',
    size: 200,
    title: 'Prediction date',
    valueType: 'timestamp_ms',
  },
  {
    key: 'id',
    title: 'Ticket ID',
  },
  {
    key: 'title',
    size: 300,
    title: 'Ticket title',
  },
  {
    key: 'body',
    rows: 3,
    size: 300,
    title: 'Ticket body',
  },
  {
    Component: TicketStatusTableCell,
    key: 'status',
    title: 'Ticket status',
  },
  {
    key: 'model_name',
    size: 240,
    title: 'Model name',
  },
  {
    key: 'predicted_label',
    rows: 2,
    size: 300,
    title: 'Predicted label',
  },
  {
    key: 'current_label',
    rows: 2,
    size: 300,
    title: 'Ground truth label',
  },
  {
    key: 'is_prediction_accurate',
    size: 200,
    title: 'Correct prediction',
    valueType: 'boolean_yn',
  },
  {
    key: 'prediction_reasoning',
    size: 300,
    title: 'Prediction reasoning',
  },
  {
    key: 'reason_no_prediction',
    size: 300,
    title: 'Reason for no prediction',
  },
  {
    key: 'written_to_helpdesk',
    size: 200,
    title: 'Written to helpdesk',
    valueType: 'boolean_yn',
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'Ticket status',
    options: [
      {
        label: 'Closed',
        value: 'ticket_status_closed',
      },
      {
        label: 'In progress',
        value: 'ticket_status_in_progress',
      },
      {
        label: 'Open',
        value: 'ticket_status_open',
      },
      {
        label: 'Resolved',
        value: 'ticket_status_resolved',
      },
    ],
    value: 'ticket_status',
  },
  {
    label: 'Correct prediction',
    options: [
      {
        label: 'Yes',
        value: 'correct_prediction_yes',
      },
      {
        label: 'No',
        value: 'correct_prediction_no',
      },
    ],
    value: 'correct_prediction',
  },
  {
    label: 'Reason for no prediction',
    options: [
      {
        label: 'Below confidence threshold',
        value: 'reason_below_confidence_threshold',
      },
      {
        label: 'Empty input fields',
        value: 'reason_empty_input_fields',
      },
      {
        label: 'Model dependencies not met',
        value: 'reason_model_dependencies_not_met',
      },
    ],
    value: 'reason_for_incorrect_prediction',
  },
  {
    label: 'Written to helpdesk',
    options: [
      {
        label: 'Yes',
        value: 'written_to_helpdesk_yes',
      },
      {
        label: 'No',
        value: 'written_to_helpdesk_no',
      },
    ],
    value: 'written_to_helpdesk',
  },
];

export const SEARCH_OPTIONS = [
  {
    text: 'Ticket',
    value: 'keyword',
  },
  {
    text: 'Ticket ID',
    value: 'ticket_id',
  },
];

export const INITIAL_LEGENDS = [
  'Written predictions',
  'Correct predictions',
  'Accuracy',
];

export const FILTER_PREFIX = {
  correctPrediction: 'correct_prediction_',
  endFieldValues: 'end_field_values_',
  model: 'model_',
  predictionValues: 'prediction_values_',
  reasonForIncorrectPrediction: 'reason_',
  ticketStatus: 'ticket_status_',
  writtenToHelpdesk: 'written_to_helpdesk_',
};

export const CHAR_SIZE = 8;

export const MENU_OPTION_EXTRA_PADDING = 150;

export const MENU_OPTION_DEFAULT_WIDTH = 500;
