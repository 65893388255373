import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { formatPhoneNumber } from '../utils';
import { VoiceConfig } from './VoiceConfig';
import {
  getVoiceConfigurationByPhoneNumber,
  selectBoughtNumbers,
  selectSelectedPhoneNumber,
  setSelectedPhoneNumber,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

const VoiceTabContent = () => {
  const dispatch = useAppDispatch();
  const selectedNumber = useSelector(selectSelectedPhoneNumber);
  const boughtNumbers = useSelector(selectBoughtNumbers);

  const boughtNumberOptions = useMemo(() => {
    return boughtNumbers.map(number => ({
      label: formatPhoneNumber(number),
      value: number,
    }));
  }, [boughtNumbers]);

  useEffect(() => {
    // no number selected or selected number still exists in the bought numbers
    if (!selectedNumber || boughtNumbers.includes(selectedNumber)) {
      return;
    }

    // selected number is no longer in the bought numbers, unset it
    dispatch(setSelectedPhoneNumber(null));
  }, [boughtNumbers, selectedNumber, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <SelectDropdown
        id='phone-numbers'
        onChange={e => {
          dispatch(
            getVoiceConfigurationByPhoneNumber({
              phoneNumber: e.target.value as string,
            }),
          );
        }}
        options={boughtNumberOptions}
        placeholder='Select a phone number to edit configuration'
        value={selectedNumber || ''}
      />
      <Separator />
      <VoiceConfig />
    </Box>
  );
};

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.palette.colors.grey[100]};
`;

export default VoiceTabContent;
