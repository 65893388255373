import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { useGetCurrentDataSourcePredictedField } from '../../triage-config-detail-page/hooks/useGetCurrentDataSourcePredictedField';
import { useGetCurrentFieldPredictorOrigins } from '../../triage-config-detail-page/hooks/useGetCurrentFieldPredictorOrigins';
import { useGetDataSourcePredictedFields } from '../../triage-config-detail-page/hooks/useGetDataSourcePredictedFields';
import { useGetHelpdesk } from '../../triage-config-detail-page/hooks/useGetHelpdesk';
import HelpDesk from '../../triage-models-overview-page/HelpDesk';
import { isModelReadOnly } from '../helpers';
import { IntegrationChangeParams } from '../types';
import ConfigureInstructions from './ConfigureInstructions';
import DefaultLabelField from './DefaultLabelField';
import GroundTruth from './GroundTruth';
import HelpdeskIntegration from './HelpdeskIntegration';
import HelpdeskObjectOptionDropdown from './HelpdeskObjectOptionDropdown';
import Spinner from 'src/components/spinner';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { getFieldPredictorOutputField } from 'src/reducers/triageSettingsReducer/helpers';
import { selectIntegrationSelectedState } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { AccuracyCheckMethod } from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelByIdQuery,
  useGetModelVersionQuery,
} from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const IntegrationTab = ({
  isPatching,
  modelId,
  onChange,
  openLabelMapping,
}: {
  isPatching?: boolean;
  modelId?: string;
  onChange: (params: IntegrationChangeParams) => void;
  openLabelMapping: () => void;
}) => {
  const { data: currentTriageModel, isLoading: isModelLoading } =
    useGetModelByIdQuery({ modelId: modelId || '' });
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const helpdesk = useGetHelpdesk();
  const { data: selectedVersion, isLoading: isVersionLoading } =
    useGetModelVersionQuery(
      { modelId: modelId || '', versionId: version || '' },
      { skip: !version },
    );
  const triageModel = selectedVersion || currentTriageModel;
  const emitTrackingEventCallback = useSelfServeEvents({
    model: triageModel,
    modelId: modelId,
  });

  const integrationSelectedState = useSelector(selectIntegrationSelectedState);

  // Dropdown options
  const { origins, outputFields, preselectedOrigins } =
    useGetDataSourcePredictedFields();

  const [groundTruthValue, setGroundTruthValue] = useState<string | undefined>(
    triageModel?.model?.true_value[0]?.field_name,
  );

  const { predictedFieldValue, setPredictedFieldValue } =
    useGetCurrentDataSourcePredictedField();

  const {
    originFieldValue,
    selectedOrigins, // used on api body
    setOriginFieldValue,
  } = useGetCurrentFieldPredictorOrigins(
    triageModel?.model || null,
    origins,
    preselectedOrigins,
  );

  useEffect(() => {
    if (!triageModel?.model?.outputs?.length || !helpdesk?.name) {
      return;
    }

    const modelOutput =
      getFieldPredictorOutputField(helpdesk.name, triageModel.model) || {};

    const currentOutputFields = Object.keys(modelOutput);
    if (currentOutputFields.length === 0) {
      return;
    }

    const currentOutputField = currentOutputFields[0];
    setPredictedFieldValue(currentOutputField);
  }, [triageModel, helpdesk, setPredictedFieldValue]);

  useEffect(() => {
    if (triageModel) {
      const originInputValue =
        // @ts-expect-error legacy code with untyped state
        triageModel.model.model_inputs[0]?.schema.properties?.Origin?.enum ||
        [];
      setOriginFieldValue(originInputValue);
    }
  }, [triageModel, setOriginFieldValue]);

  const isHelpdeskDataAvailable = Boolean(helpdesk?.name);
  const isPageLoaded =
    !isModelLoading &&
    !isVersionLoading &&
    isHelpdeskDataAvailable &&
    triageModel;

  if (!helpdesk?.name) {
    return <Spinner />;
  }

  if (!isPageLoaded) {
    return null;
  }

  const handleGroundTruthChange = (event: SelectChangeEvent<string>) => {
    const newGroundTruthValue = event.target.value;
    setGroundTruthValue(newGroundTruthValue);

    onChange({
      groundTruthValue: newGroundTruthValue,
      integrationType: helpdesk?.name || '',
      predictedFieldValueName: predictedFieldValue,
    });
  };

  const handleChange = (changeValue: IntegrationChangeParams) => {
    if (changeValue.select) {
      setPredictedFieldValue(changeValue.select);

      setGroundTruthValue(changeValue.select);
    }

    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.UPDATE_CONFIGURATION);
    onChange({
      ...changeValue,
      groundTruthValue,
      origins: selectedOrigins,
    });
  };

  const handleOriginsChange = (originValues: string[]) => {
    setOriginFieldValue(originValues);
    const originsValue = origins.filter(item =>
      originValues.includes(item.value),
    );
    onChange({
      integrationType: 'salesforce',
      isOriginChange: true,
      origins: originsValue,
      select: predictedFieldValue,
    });
  };

  const handleAccuracyCheckChange = (event: SelectChangeEvent<string>) => {
    onChange({
      accuracyCheckValue: event.target.value as AccuracyCheckMethod,
      groundTruthValue,
      integrationType: helpdesk.name,
      origins: selectedOrigins,
      predictedFieldValueName: predictedFieldValue,
    });
  };

  const isReadOnly = isModelReadOnly(triageModel);

  const isLoading =
    integrationSelectedState === 'loading' || isVersionLoading || isPatching;

  const integrationComponents: Record<string, JSX.Element> = {
    freshdesk: (
      <HelpdeskIntegration
        integrationSelectedState={integrationSelectedState}
        integrationType='freshdesk'
        isDisabled={isReadOnly || isLoading}
        onChange={handleChange}
        options={outputFields}
        predictedFieldValue={predictedFieldValue}
      />
    ),
    jira: (
      <HelpdeskIntegration
        integrationSelectedState={integrationSelectedState}
        integrationType='jira'
        isDisabled={isReadOnly || isLoading}
        onChange={handleChange}
        options={outputFields}
        predictedFieldValue={predictedFieldValue}
      />
    ),
    kustomer: (
      <HelpdeskIntegration
        integrationSelectedState={integrationSelectedState}
        integrationType='kustomer'
        isDisabled={isReadOnly || isLoading}
        onChange={handleChange}
        options={outputFields}
        predictedFieldValue={predictedFieldValue}
      />
    ),
    salesforce: (
      <>
        <HelpdeskObjectOptionDropdown />
        <HelpdeskIntegration
          integrationSelectedState={integrationSelectedState}
          integrationType='salesforce'
          isDisabled={isReadOnly || isLoading}
          onChange={handleChange}
          options={outputFields}
          originFieldValue={originFieldValue}
          origins={origins}
          predictedFieldValue={predictedFieldValue}
          setOriginFieldValue={handleOriginsChange}
        />
      </>
    ),
    zendesk: (
      <>
        <HelpdeskObjectOptionDropdown />
        <HelpdeskIntegration
          integrationSelectedState={integrationSelectedState}
          integrationType='zendesk'
          isDisabled={isReadOnly || isLoading}
          onChange={handleChange}
          options={outputFields}
          predictedFieldValue={predictedFieldValue}
        />
      </>
    ),
  };

  return (
    <Box display='grid' gridTemplateColumns='1fr 1fr'>
      <Box
        display='flex'
        flexDirection='column'
        rowGap={4}
        sx={{
          '&>div': {
            opacity: isLoading ? '0.5' : undefined,
          },
          pointerEvents: isLoading ? 'none' : undefined,
        }}
        width='450px'
      >
        {isLoading && <LinearProgress />}
        <Typography variant='font16Bold'>Helpdesk Integration</Typography>
        <HelpDesk />
        {integrationComponents[helpdesk.name]}
        <Typography variant='font16Bold'>
          Prediction accuracy settings
        </Typography>
        <GroundTruth
          accuracyCheckValue={
            triageModel?.model?.model_output_formatter.accuracy_check_method ||
            'exact_match'
          }
          groundTruthValue={groundTruthValue}
          isDisabled={isModelReadOnly(triageModel)}
          onAccuracyCheckChange={handleAccuracyCheckChange}
          onGroundTruthChange={handleGroundTruthChange}
          options={outputFields}
          outputFieldValue={predictedFieldValue}
        />
        <Typography variant='font16Bold'>Default label (optional)</Typography>
        <DefaultLabelField
          helpdesk={helpdesk}
          isDisabled={isReadOnly}
          isLoading={isLoading}
          onChange={onChange}
          placeholder='select an existing label'
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        <ConfigureInstructions openLabelMapping={openLabelMapping} />
      </Box>
    </Box>
  );
};

export default IntegrationTab;
