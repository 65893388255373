import moment from 'moment';
import { ValidationError } from 'yup';

import { Order } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../discover-dashboard-page/types';
import { INSIGHTS_DATE_FORMAT } from './constants';
import { ChatTrendsPeriodicalFilter, InsightSortDirection } from './types';
import format from 'date-fns/format';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { uuidOrEmptySchema } from 'src/utils/validators';

export const stringifyDateRange = (range: DateRange) => {
  let dateRangeString = '';

  if (range?.from) {
    dateRangeString += format(range.from, INSIGHTS_DATE_FORMAT);
  }

  if (range?.to) {
    dateRangeString += ` - ${format(range.to, INSIGHTS_DATE_FORMAT)}`;
  }

  return dateRangeString;
};

export const dateRangeToTimestamp = (dateRange: DateRange) => {
  const now = Date.now();
  const startTime = dateRange.from
    ? dateRange.from.getTime() / 1000
    : now / 1000 - 30 * 24 * 60 * 60;
  const endTime = dateRange.to ? dateRange.to.getTime() / 1000 : now / 1000;
  return {
    end_timestamp: Math.floor(endTime),
    start_timestamp: Math.floor(startTime),
  };
};

export const insightSortDirectionToSortDirection = (
  direction: InsightSortDirection,
): Order => (direction === 'ASC' ? 'asc' : 'desc');

export const sortDirectionToInsightSortDirection = (
  direction: Order,
): InsightSortDirection => (direction === 'asc' ? 'ASC' : 'DESC');

export const numbersToStringWithCommas = ({
  canBeFloat,
  maximumFractionDigits,
  minimumFractionDigits,
  number,
  showDecimals = false,
  style = 'decimal',
}: {
  canBeFloat?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  number?: number;
  showDecimals?: boolean;
  style?: 'decimal' | 'percent';
}) => {
  const getMaxFractionDigits = () => {
    // Used for the custom formats
    if (maximumFractionDigits) {
      return maximumFractionDigits;
    }
    if (showDecimals) {
      return 3;
    }
    const showFixedPoints =
      canBeFloat && style === 'decimal' && !Number.isInteger(number);
    if (showFixedPoints) {
      return 2;
    }
    return 0;
  };
  return (
    number?.toLocaleString(undefined, {
      maximumFractionDigits: getMaxFractionDigits(),
      minimumFractionDigits: minimumFractionDigits,
      style,
    }) ?? NA
  );
};

export const percentageValueAsString = (value: number) => {
  // 0.25 => +25%, -0.25 => -25%
  return `${value >= 0 ? '+' : ''}${numbersToStringWithCommas({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    number: value,
    style: 'percent',
  })}`;
};

export const formatNumbersWithComma = (value: number) => {
  return value.toLocaleString('en-US');
};

export const numberToCurrency = ({
  includeDigits = false,
  number,
  shouldRound = false,
}: {
  includeDigits?: boolean;
  number?: number;
  shouldRound?: boolean;
}) => {
  if (includeDigits) {
    return (number || 0).toLocaleString('en-US', {
      currency: 'USD',
      style: 'currency',
    });
  }

  return (number || 0).toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: shouldRound ? 0 : undefined,
    minimumFractionDigits: 0,
    style: 'currency',
  });
};

export const getTooltipForMetric = (
  label: string,
  costPerTicket: number | null = null,
): string => {
  switch (label) {
    case 'realized_savings':
      if (costPerTicket) {
        return `Dollarized savings assuming a $${costPerTicket} cost per deflection.`;
      }
    case 'potential_savings':
      return 'Maximum potential savings you could have realized for this topic with Discover automation.';
    default:
      return '';
  }
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const periodToTimestamps = (
  period: ChatTrendsPeriodicalFilter,
  index: number,
) => {
  const periodMapper: {
    [Property in ChatTrendsPeriodicalFilter]: 'months' | 'quarters' | 'weeks';
  } = {
    monthly: 'months',
    quarterly: 'quarters',
    weekly: 'weeks',
  };
  const periodValue = periodMapper[period];
  const periodValueSingular = periodValue.substring(
    0,
    periodValue.length - 1,
  ) as 'month' | 'quarter' | 'week';
  const start = moment()
    .subtract(index, periodValue)
    .startOf(periodValueSingular)
    .toDate();
  const end = moment()
    .subtract(index, periodValue)
    .endOf(periodValueSingular)
    .toDate();
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 0);
  const result = dateRangeToTimestamp({ from: start, to: end });
  return [result.start_timestamp, result.end_timestamp];
};

export const periodToTimestampsByNumOfRange = ({
  numberOfRanges = 4,
  period,
}: {
  numberOfRanges?: number;
  period: ChatTrendsPeriodicalFilter;
}) => {
  /**
   * Get timestamps based on period
   * The timestamp will be be in this format [timestamp_1, timestamp_2, ... timestamp_8]
   * Where timestamp_1 and timestamp_2 would be the oldest range (start, end)
   * and continue to get the latest (oldest range -> recent range)
   */
  if (period === 'weekly') {
    const timestamps = [...Array(numberOfRanges)].map((_, i) => {
      // First day starts on Sunday, ends at Saturday
      return periodToTimestamps(period, i);
    });
    return timestamps.reverse().flat();
  }
  if (period === 'monthly') {
    const timestamps = [...Array(numberOfRanges)].map((_, i) => {
      return periodToTimestamps(period, i);
    });
    return timestamps.reverse().flat();
  }
  if (period === 'quarterly') {
    const timestamps = [...Array(numberOfRanges)].map((_, i) => {
      return periodToTimestamps(period, i);
    });
    return timestamps.reverse().flat();
  }
  return [];
};

export const isUiQueryError = ({
  searchQueryMode,
  uiQuery,
}: {
  searchQueryMode: string;
  uiQuery: string;
}) => {
  if (searchQueryMode !== 'conversation_id') {
    return '';
  }

  try {
    uuidOrEmptySchema.validateSync(uiQuery);

    return '';
  } catch (err) {
    return err instanceof ValidationError
      ? err.message
      : 'An unexpected error occurred';
  }
};
