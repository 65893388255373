import { FormProps } from '@rjsf/core';

import CreateIntegrationForm from '../integration-form/create-integration-form/CreateIntegrationForm';
import UpdateIntegrationForm from '../integration-form/update-integration-form/UpdateIntegrationForm';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';

interface IntegrationDrawerBodyProps {
  connectorDefinition: ConnectorDefinition;
  existingNames?: string[];
  formProps: FormProps<Credentials>;
  isCollectionsPage: boolean;
  multiConnectorsEnabled: boolean;
  selectedConnector?: Connector;
}

export default function IntegrationDrawerBody({
  connectorDefinition,
  existingNames,
  formProps,
  isCollectionsPage,
  multiConnectorsEnabled,
  selectedConnector,
}: IntegrationDrawerBodyProps) {
  if (isCollectionsPage) {
    return (
      <CreateIntegrationForm
        connectorDefinition={connectorDefinition}
        existingNames={existingNames}
        multiConnectorsEnabled={multiConnectorsEnabled}
        oauthConnector={selectedConnector}
        {...formProps}
      />
    );
  } else {
    if (selectedConnector) {
      return (
        <UpdateIntegrationForm
          existingNames={existingNames}
          selectedConnector={selectedConnector}
          {...formProps}
        />
      );
    }
  }
}
