import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import SankeyDiagram, {
  SankeyDiagramProps,
} from '../sankey-diagram/SankeyDiagram';
import EmptyState from './EmptyState';
import SankeyOverview from './SankeyOverview';
import { SankeyOverviewData } from './types';
import { DateRange } from 'src/types/types';

export interface SankeyDiagramSectionProps extends SankeyDiagramProps {
  dateRange: DateRange;
  height?: string;
  loading: boolean;
  overview: SankeyOverviewData[];
}

const SankeyDiagramSection = ({
  data,
  dateRange,
  height = '240px',
  loading,
  overview,
}: SankeyDiagramSectionProps) => {
  const [isLoading, setIsLoading] = useState(loading);
  const { palette } = useTheme();
  const emptyValue = data[0]?.weight === 0 && data[1]?.weight === 0;
  const noData = !isLoading && emptyValue;
  const hasData = Boolean(!isLoading && data.length && !emptyValue);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data && !loading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [data, loading]);

  return (
    <Box
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius={1}
      display='flex'
      flexDirection='column'
      p={3}
      rowGap={3}
    >
      {isLoading && <Skeleton height={height} />}
      {noData && <EmptyState />}
      {hasData && (
        <>
          <SankeyOverview data={overview} loading={isLoading} />
          <Box height={height}>
            <SankeyDiagram data={data} dateRange={dateRange} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SankeyDiagramSection;
