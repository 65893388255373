import React, { FC, useState } from 'react';
import { ClickAwayListener, Portal, Snackbar, styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  Alert,
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useDeleteIntentMutation } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { useDeactivateIntentWorkflowMutation } from 'src/services/workflow-builder-metrics';
import { DecommissionWorkflowErrorReferencedData } from 'src/types/workflowBuilderAPITypes';
import { DecommissionWorkflowResponse } from 'src/types/workflowBuilderAPITypes';
import { PRODUCT_TO_CHANNEL_MAP } from 'src/utils/constants';
import { Routes } from 'src/utils/enums';

const DecommissionedWorkflowErrorModal: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Portal>
      <DeleteDialog />
      <DeactivateDialog />
    </Portal>
  );
};

const DeleteDialog = () => {
  const [, { data: deleteData, fulfilledTimeStamp = 0 }] =
    useDeleteIntentMutation({
      fixedCacheKey: 'deleteIntent',
    });

  return (
    <Dialog
      actionText='delete'
      decommissionedWorkflowError={
        deleteData?.success === false ? deleteData : null
      }
      key={fulfilledTimeStamp}
    />
  );
};

const DeactivateDialog = () => {
  const [, { data: deactivateData, fulfilledTimeStamp = 0 }] =
    useDeactivateIntentWorkflowMutation({
      fixedCacheKey: 'deactivateIntentWorkflow',
    });

  return (
    <Dialog
      actionText='deactivate'
      decommissionedWorkflowError={
        deactivateData?.success === false ? deactivateData : null
      }
      key={fulfilledTimeStamp}
    />
  );
};

const Dialog = ({
  actionText,
  decommissionedWorkflowError,
}: {
  actionText: string;
  decommissionedWorkflowError: DecommissionWorkflowResponse | null;
}) => {
  const [isAcked, setIsAcked] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsAcked(true)}>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={Boolean(decommissionedWorkflowError) && !isAcked}
      >
        <Alert title={`Unable to ${actionText} intent`} variant='warning'>
          <Body>
            <Box marginRight='44px' width='452px'>
              <Typography
                color={theme.palette.colors.grey[600]}
                variant='font14'
              >
                The intent is currently used in other workflows. To {actionText}
                , you must first remove it from the following workflows:
              </Typography>
              <UnorderdList>
                {decommissionedWorkflowError?.detail?.referenced_data?.map(
                  (
                    data: DecommissionWorkflowErrorReferencedData,
                    idx: number,
                  ) => (
                    <ReferencedWorkflowItem
                      data={data}
                      idx={idx}
                      key={`intentName-${idx}`}
                    />
                  ),
                )}
              </UnorderdList>
            </Box>
            <GotItButton onClick={() => setIsAcked(true)} variant='ghost'>
              Got It
            </GotItButton>
          </Body>
        </Alert>
      </Snackbar>
    </ClickAwayListener>
  );
};

const ReferencedWorkflowItem = ({
  data,
  idx,
}: {
  data: DecommissionWorkflowErrorReferencedData;
  idx: number;
}) => {
  const { data: intentsData } = useGetIntentsQueryWithProduct();
  const { intents = [] } = intentsData ?? {};
  if (!data.intent_definition_id || !data.product) {
    throw new Error(
      `Missing intent_definition_id or product in data for intent_workflow_id=${data.id}`,
    );
  }
  const queryParams = new URLSearchParams({
    intentId: data.intent_definition_id,
    view: PRODUCT_TO_CHANNEL_MAP[data.product],
    workflowId: data.id,
  });

  return (
    <Typography
      color={theme.palette.colors.grey[600]}
      key={`intentName-${idx}`}
      variant='font14'
    >
      <li aria-label={`${data.name}`}>
        <Link
          href={`${Routes.WORKFLOW_BUILDER_EDIT}?${queryParams}`}
          rel='noopener noreferrer'
          target='_blank'
        >
          {data.name ??
            intents.find(intent => intent.intent_workflow_id === data.id)
              ?.intent_name}
        </Link>
      </li>
    </Typography>
  );
};

const Body = styled('div')`
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const UnorderdList = styled('ul')`
  margin: 16px 0 4px 22px;
  padding: 0;
  &:li {
    text-decoration: underline;
  }
`;

const GotItButton = styled(Button)`
  width: 78px !important;
`;

const Link = styled('a')`
  color: ${theme.palette.colors.grey[600]};
`;

export default DecommissionedWorkflowErrorModal;
