import { QueryExpressionInputType, QueryExpressionOperatorData } from './types';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';

const containsOneOfOperators = [
  {
    inputType: 'multi_string',
    label: 'contains one of',
    negate: false,
    value: 'contains_one_of',
  },
  {
    inputType: 'multi_string',
    label: 'does not contain one of',
    negate: true,
    value: 'contains_one_of',
  },
] as const;

export const stringOperators = [
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is',
    negate: false,
    value: '=',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is not',
    negate: true,
    value: '=',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'contains',
    negate: false,
    value: 'contains',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'does not contain',
    negate: true,
    value: 'contains',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is empty',
    negate: false,
    value: 'empty',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is not empty',
    negate: true,
    value: 'empty',
  },
  ...containsOneOfOperators,
];

export const numberOperators = [
  ...stringOperators.map(operator => ({
    ...operator,
    inputType: 'numeric_input' as QueryExpressionInputType,
  })),
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'greater than',
    negate: false,
    value: '>',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'less than',
    negate: false,
    value: '<',
  },
];

export const checkboxOperators = [
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is',
    negate: false,
    value: '=',
  },
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is empty',
    negate: false,
    value: 'empty',
  },
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is not empty',
    negate: true,
    value: 'empty',
  },
];

export const listTypeOperators = [
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is',
    negate: false,
    value: '=',
  },
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is not',
    negate: true,
    value: '=',
  },
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is empty',
    negate: false,
    value: 'empty',
  },
  {
    inputType: 'dropdown' as QueryExpressionInputType,
    label: 'is not empty',
    negate: true,
    value: 'empty',
  },
];

export const checkboxTypeValueOptions = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
];

export const booleanOperatorOptions = [
  { label: 'and', value: 'and' },
  { label: 'or', value: 'or' },
];

export const expressionData: {
  [expressionType in QueryExpressionTypes]: Array<QueryExpressionOperatorData>;
} = {
  CHECKBOX: checkboxOperators,
  DATE: stringOperators,
  EMAIL: stringOperators,
  LIST: listTypeOperators,
  LONG_TEXT: stringOperators,
  NUMBER: numberOperators,
  PHONE: stringOperators,
  SHORT_TEXT: stringOperators,
};
