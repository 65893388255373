import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  LoadingIndicator,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ApiArticleAnswerStep } from './ApiArticleAnswerStep';
import { ApiArticleStep } from './ApiArticleStep';
import { ApiButtonStep } from './ApiButtonStep';
import { ApiDynamicCardStep } from './ApiDynamicCardStep';
import { ApiFormStep } from './ApiFormStep';
import ApiHandoffStep from './ApiHandoffStep';
import { ApiHyperlinkRedirectStep } from './ApiHyperlinkRedirectStep';
import { ApiImageStep } from './ApiImageStep';
import { RequestStep } from './ApiRequestStep';
import { ApiTextStep } from './ApiTextStep';
import {
  ApiWidgetComponent,
  ButtonApiWidgetComponent,
  ComponentType,
  isForethoughtError,
  isHistoricalSolveApiRequest,
  isSolveApiResponse,
  SolveApiTransaction,
} from 'src/slices/workflow-preview/types';
import { selectApiPreviewState } from 'src/slices/workflow-preview/workflowPreviewSlice';

export const InteractiveApiView = () => {
  const { palette } = useTheme();
  const { history, isLoading } = useSelector(selectApiPreviewState);
  const endRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endRef.current?.scrollIntoView();
  }, [history, isLoading]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      fontSize='16px'
      gap={2}
      height='100%'
      overflow='auto'
      padding='24px 32px 0 32px'
    >
      {history.map((transaction, index) => {
        const prevTransaction: SolveApiTransaction | undefined =
          history[index - 1];
        const prevButtonStep =
          isSolveApiResponse(prevTransaction) &&
          prevTransaction.widget_components.find(
            step => step.type === ComponentType.BUTTON,
          );

        return (
          <ComponentsForTransaction
            isTheLatestStep={index === history.length - 1}
            key={index}
            // error showing up in vite but not in vscode
            // as is needed her for vite
            prevButtonStep={prevButtonStep as ButtonApiWidgetComponent}
            transaction={transaction}
          />
        );
      })}
      {isLoading && (
        <Box alignSelf='flex-start'>
          <LoadingIndicator color={palette.colors.black} size='large' />
        </Box>
      )}
      <div ref={endRef} />
    </Box>
  );
};

const ComponentsForTransaction = ({
  isTheLatestStep,
  prevButtonStep,
  transaction,
}: {
  isTheLatestStep: boolean;
  prevButtonStep: false | ButtonApiWidgetComponent | undefined;
  transaction: SolveApiTransaction;
}) => {
  const { palette } = useTheme();

  if (isForethoughtError(transaction)) {
    if (transaction.status >= 500) {
      return null;
    }

    return (
      <Box
        alignItems='center'
        bgcolor={'rgba(242, 244, 247, 0.50)'}
        border={`1px solid ${palette.colors.grey[100]}`}
        borderRadius='4px'
        display='flex'
        px={1.5}
        py={1}
      >
        <Typography color={palette.colors.grey[700]} variant='font12'>
          An error occurred. please check API request logs more information.
        </Typography>
      </Box>
    );
  }

  // request
  if (isHistoricalSolveApiRequest(transaction)) {
    if (prevButtonStep) {
      const selectedOption = prevButtonStep.buttons.find(
        button =>
          button.value === Object.values(transaction.context_variables)[0],
      );
      return <ApiTextStep message={selectedOption?.display_name ?? ''} />;
    }

    return <RequestStep request={transaction} />;
  }

  // response
  return (
    <>
      {transaction.widget_components.map((step, index) => (
        <Step isTheLatestStep={isTheLatestStep} key={index} step={step} />
      ))}
    </>
  );
};

const Step = ({
  isTheLatestStep,
  step,
}: {
  isTheLatestStep: boolean;
  step: ApiWidgetComponent;
}) => {
  switch (step.type) {
    case ComponentType.TEXT:
      return <ApiTextStep format={step.format} message={step.message} />;
    case ComponentType.HYPERLINK_REDIRECT:
      return <ApiHyperlinkRedirectStep step={step} />;
    case ComponentType.BUTTON:
      return <ApiButtonStep isTheLatestStep={isTheLatestStep} step={step} />;
    case ComponentType.FORM:
      return <ApiFormStep isTheLatestStep={isTheLatestStep} step={step} />;
    case ComponentType.IMAGE:
      return <ApiImageStep step={step} />;
    case ComponentType.ARTICLE_ANSWER:
      return <ApiArticleAnswerStep step={step} />;
    case ComponentType.ARTICLE:
      return <ApiArticleStep step={step} />;
    case ComponentType.DYNAMIC_CARD:
      return (
        <ApiDynamicCardStep isTheLatestStep={isTheLatestStep} step={step} />
      );
    default:
      return <ApiHandoffStep step={step} />;
  }
};
