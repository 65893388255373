import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../dashboard-pages/triage-model-detail-page/common/TextWithInfo';
import { toPercentageDecimal } from './helpers';
import { SankeyOverviewDataItem } from './types';

interface MetricItemsProps {
  items?: SankeyOverviewDataItem[];
}

const MetricItems = ({ items }: MetricItemsProps) => {
  const { palette } = useTheme();
  if (!items) {
    return null;
  }
  return (
    <Box columnGap={5} display='flex' mt={2} width='100%'>
      {items.map((item, index) => {
        if (item.hideIfZero && item.value === 0) {
          return null;
        }
        return (
          <Box
            display='flex'
            flexDirection='column'
            key={index}
            minWidth='calc(33% - 40px)'
            rowGap={0.5}
          >
            <TextWithInfo
              containerSx={{ span: { whiteSpace: 'pre-wrap' } }}
              title={item.title}
              tooltip={item.tooltip || ''}
              variant='font11'
            />
            <Typography variant='font18'>
              {(item.value || 0).toLocaleString()}
            </Typography>
            <Typography color={palette.colors.grey[600]} variant='font12'>
              {toPercentageDecimal(item.rate || 0)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default MetricItems;
