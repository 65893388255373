import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  ArticleCardTranscriptComponent,
  ArticleTranscriptComponent,
  RelatedArticlesTranscriptComponent,
} from '../ConversationTranscript/types';
import { quickFeedbackOneToTwoOptions } from 'src/pages/solve-config/constants';
import { QuickFeedbackStyleOption } from 'src/pages/solve-config/types';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

const ArticleLink = ({
  additionalFeedback,
  chosenStyleOptions,
  isUseful,
  link,
  title,
}: {
  additionalFeedback?: string;
  chosenStyleOptions: QuickFeedbackStyleOption;
  isUseful?: boolean;
  link: string;
  title: string;
}) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' flexWrap='wrap' gap='4px'>
      <Typography variant='font12'>
        <QuickFeedbackArticleLink href={link} rel='noreferrer' target='_blank'>
          {title}
        </QuickFeedbackArticleLink>
      </Typography>
      {isUseful && (
        <QuickFeedbackStyleImage
          src={
            chosenStyleOptions?.[`${isUseful ? 'positive' : 'negative'}Icons`]
              ?.active
          }
        />
      )}
      {additionalFeedback && (
        <Typography color={palette.colors.grey[600]} variant='font12'>
          <i>
            &quot;
            {additionalFeedback}
            &quot;
          </i>
        </Typography>
      )}
    </Box>
  );
};

export const ArticlesSectionFromTranscriptComponents = ({
  transcriptComponents,
}: {
  transcriptComponents?: ArticleTranscriptComponent[] | null;
}) => {
  const { palette } = useTheme();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { style } = widgetConfiguration.quick_feedback_config;
  const chosenStyleOptions =
    quickFeedbackOneToTwoOptions.find(option => option.value === style) ||
    quickFeedbackOneToTwoOptions[0];

  if (!transcriptComponents || transcriptComponents.length === 0) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap='4px'>
      <Divider />
      <Box>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          Articles:
        </Typography>
      </Box>
      {transcriptComponents.map(comp => {
        const quickFeedback = comp.quick_feedback;

        if (comp.message_type === 'related_articles') {
          return (comp as RelatedArticlesTranscriptComponent).articles.map(
            article => {
              return (
                <ArticleLink
                  additionalFeedback={quickFeedback?.additional_feedback}
                  chosenStyleOptions={chosenStyleOptions}
                  isUseful={quickFeedback?.is_useful}
                  key={article.document_id}
                  link={article.link}
                  title={article.title}
                />
              );
            },
          );
        }

        return (
          <ArticleLink
            additionalFeedback={quickFeedback?.additional_feedback}
            chosenStyleOptions={chosenStyleOptions}
            isUseful={quickFeedback?.is_useful}
            key={(comp as ArticleCardTranscriptComponent).title}
            link={(comp as ArticleCardTranscriptComponent).link || ''}
            title={(comp as ArticleCardTranscriptComponent).title}
          />
        );
      })}
    </Box>
  );
};

const QuickFeedbackStyleImage = styled('img')`
  height: 18px;
  width: 18px;
`;

const QuickFeedbackArticleLink = styled('a')`
  color: ${({ theme }) => theme.palette.colors.blue[600]};
`;

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;
