/* eslint-disable react/prop-types -- false warnings for column definitions */

import React, { useCallback, useMemo, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Badge,
  DateRangeFilterButton,
  InfiniteTable as InfiniteTable2,
  Order,
  SearchBar,
  Table,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmailAggregateMetricsContainer, {
  FilteredEmailAggregateMetricsContainer,
} from '../workflow-builder/aggregate-metrics/EmailAggregateMetricsContainer';
import { useSolveMetricsQueryParams } from '../workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { getTimeRangeFromQueryParams } from '../workflow-builder/intent-workflows-table/utils';
import AutopilotBadge from '../workflow-builder-edit/email-builder-page/intent-email-builder/components/autopilot/AutopilotBadge';
import { ArticleLinks } from './components/ArticleLinks';
import { BooleanCell } from './components/BooleanCell';
import { ConversationsTabLayout } from './components/ConversationsTabLayout';
import { DateCell } from './components/DateCell';
import { IntentFilter } from './components/IntentFilter';
import {
  ALL_INTENTS,
  ALLTIME_END,
  ALLTIME_START,
  SMALL_CELL_WIDTH,
  STICKY_HEADER_HEIGHT,
  TOTAL_STICKY_HEADERS_HEIGHT,
} from './constants';
import { EmailConversationDrawer } from './EmailConversationDrawer';
import HeaderSection from './HeaderSection';
import { InfiniteTable } from './InfiniteTable';
import IntentBadge from './IntentBadge';
import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';
import WithHelperText from 'src/components/with-helper-text/WithHelperText';
import { useGetIntentsQueryWithProduct, useStateParams } from 'src/hooks/hooks';
import { useValidatedState } from 'src/hooks/useValidatedState';
import { EmailConversationTableRow } from 'src/services/apiInterfaces';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetEmailConversationsQuery } from 'src/services/solve-conversations/emailConversationsApi';
import { useGetEmailAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
} from 'src/utils/discover/helpers';
import {
  constructTimeRangeQueryParamsRevamped,
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';
import { uuidOrEmptySchema } from 'src/utils/validators';

type Columns = React.ComponentProps<
  typeof Table<EmailConversationTableRow>
>['columns'];

export const EmailConversationsTab = ({
  scrollTop,
  scrollToTop,
}: {
  scrollTop: number;
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const qParams = useSolveMetricsQueryParams();
  const { palette } = useTheme();
  const [page, setPage] = useState(1);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const { data: featureFlagsResponse } = useGetFeatureFlagsQuery();
  const isNewTableComponentEnabled =
    featureFlagsResponse?.feature_flags?.includes('new_table_component');

  const selectedDateRange = useMemo(() => {
    return getTimeRangeFromQueryParams({
      from: qParams.from,
      to: qParams.to,
    });
  }, [qParams]);
  const timeFilter = dateRangeToTimeFilter(selectedDateRange);
  const startDate = qParams.from ?? ALLTIME_START;
  const endDate = qParams.to ?? ALLTIME_END;
  const { data: aggregateMetricsData } = useGetEmailAggregateMetricsQuery({
    from: startDate,
    to: endDate,
  });

  const [intentId, setIntentId] = useStateParams({
    deserialize: genericSerializeAndDeserialize,
    initialState: ALL_INTENTS,
    paramsName: 'intentId',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortDirection, setSortDirection] = useStateParams({
    deserialize: genericSerializeAndDeserialize,
    initialState: 'desc',
    paramsName: 'email_sort_direction',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortColumn, setSortColumn] = useStateParams({
    deserialize: genericSerializeAndDeserialize,
    initialState: 'created_date',
    paramsName: 'email_sort_column',
    serialize: genericSerializeAndDeserialize,
  });
  const filterByIntent = intentId !== ALL_INTENTS;
  const [conversationId, setConversationId, conversationIdValidationError] =
    useValidatedState({
      initialValue: '',
      validationSchema: uuidOrEmptySchema,
    });

  const { data, isLoading: intentsLoading } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};
  const searchByConversationId =
    conversationId && !conversationIdValidationError;
  const {
    data: conversationsData,
    isError: isConversationsDataError,
    isFetching: isConversationsDataFetching,
    isLoading: isConversationsDataLoading,
  } = useGetEmailConversationsQuery(
    searchByConversationId
      ? {
          conversationId,
          end: ALLTIME_END,
          intentId: null,
          page: 1,
          sortColumn,
          sortDirection,
          start: ALLTIME_START,
        }
      : {
          conversationId: null,
          end: endDate,
          intentId: filterByIntent ? intentId : null,
          page,
          sortColumn,
          sortDirection,
          start: startDate,
        },
  );

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationsData) {
      return;
    }
    return conversationsData.conversations.find(
      conversation => conversation.conversation_id === selectedConversationId,
    );
  }, [conversationsData, selectedConversationId]);

  const columns = useMemo(() => {
    const initialColumns: Columns = [
      {
        cellRenderer: data => {
          return <DateCell dateString={data.created_date} />;
        },
        id: 'created_date',
        label: 'Timestamp',
        width: SMALL_CELL_WIDTH,
      },
      {
        cellRenderer: data => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                maxWidth: 540,
              }}
            >
              <Box
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                <Typography variant='font14Bold'>
                  {data.source_title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                <Typography color={palette.colors.grey[500]} variant='font14'>
                  {data.source_body}
                </Typography>
              </Box>
            </Box>
          );
        },
        hideSortIcon: true,
        id: 'inquiry',
        label: 'Incoming email',
      },
      {
        cellRenderer: data => {
          return <BooleanCell value={Boolean(data.potential_attempt)} />;
        },
        id: 'potential_attempt',
        label: 'Eligible email',
        width: SMALL_CELL_WIDTH,
      },
    ];

    if (!filterByIntent) {
      initialColumns.push({
        cellRenderer: data => {
          if (!data.potential_attempt) {
            return null;
          }

          const flatArticles = data.dynamic_article_suggestion_events.flatMap(
            event => event.articles,
          );

          return (
            <Box display='flex' flexDirection='column' gap='4px'>
              <IntentBadge
                channel='email'
                intentDefinitionId={data.intent_definition_id}
                intentName={data.intent_title}
              />
              {!!flatArticles.length && (
                <ArticleLinks articles={flatArticles} />
              )}
            </Box>
          );
        },
        hideSortIcon: true,
        id: 'intent',
        label: 'Intent detected',
        width: SMALL_CELL_WIDTH,
      });
    }

    const secondaryColumns: Columns = [
      {
        cellRenderer: data => {
          return <BooleanCell value={Boolean(data.response_sent)} />;
        },
        id: 'response_sent',
        label: 'Response sent',
        width: SMALL_CELL_WIDTH,
      },
      {
        cellRenderer: data => {
          return <BooleanCell value={Boolean(data.deflected)} />;
        },
        id: 'useful_interaction',
        label: 'Deflected',
        width: SMALL_CELL_WIDTH,
      },
    ];
    initialColumns.push(...secondaryColumns);

    return initialColumns;
  }, [palette, filterByIntent]);

  const columnHelper = createMRTColumnHelper<EmailConversationTableRow>();

  const materialReactTableColumns = useMemo(() => {
    return [
      columnHelper.accessor('created_date', {
        Cell: ({ cell }) => {
          return <DateCell dateString={cell.getValue()} />;
        },
        grow: false,
        header: 'Timestamp',
        size: SMALL_CELL_WIDTH,
      }),
      columnHelper.accessor('source_body', {
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                maxWidth: 540,
              }}
            >
              <Box mb='4px'>
                {row.original.interactive_email_deflection_status ===
                'deflected' ? (
                  <Badge label='Interactive' variant='inverse' />
                ) : row.original.autopilot_used ? (
                  <AutopilotBadge />
                ) : null}
              </Box>
              <Box
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                <Typography variant='font14Bold'>
                  {row.original.source_title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                <Typography color={palette.colors.grey[500]} variant='font14'>
                  {cell.getValue()}
                </Typography>
              </Box>
            </Box>
          );
        },
        enableSorting: false,
        header: 'Incoming email',
      }),
      columnHelper.accessor('potential_attempt', {
        Cell: ({ cell }) => <BooleanCell value={Boolean(cell.getValue())} />,
        grow: false,
        header: 'Eligible email',
        size: SMALL_CELL_WIDTH,
      }),
      ...(filterByIntent
        ? []
        : [
            columnHelper.accessor('intent_title', {
              Cell: ({ cell, row }) => {
                if (!row.original.potential_attempt) {
                  return null;
                }

                const flatArticles =
                  row.original.dynamic_article_suggestion_events.flatMap(
                    event => event.articles,
                  );

                return (
                  <Box display='flex' flexDirection='column' gap='4px'>
                    <IntentBadge
                      channel='email'
                      intentDefinitionId={row.original.intent_definition_id}
                      intentName={cell.getValue()}
                    />
                    {!!flatArticles.length && (
                      <ArticleLinks articles={flatArticles} />
                    )}
                  </Box>
                );
              },
              enableSorting: false,
              header: 'Intent detected',
              size: SMALL_CELL_WIDTH,
            }),
          ]),
      columnHelper.accessor('response_sent', {
        Cell: ({ cell }) => <BooleanCell value={Boolean(cell.getValue())} />,
        grow: false,
        header: 'Response sent',
        size: SMALL_CELL_WIDTH,
      }),
      columnHelper.accessor('deflected', {
        Cell: ({ cell }) => <BooleanCell value={Boolean(cell.getValue())} />,
        grow: false,
        header: 'Deflected',
        size: SMALL_CELL_WIDTH,
      }),
    ];
  }, [columnHelper, filterByIntent, palette.colors.grey]);

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    // Reset page number
    setPage(1);
    // Update date range
    const existingQueryParams = new URLSearchParams(location.search);
    const newSearchParams = constructTimeRangeQueryParamsRevamped(
      dateRange,
      qParams.channel,
    );
    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(existingQueryParams),
      ...Object.fromEntries(newSearchParams),
    });
    navigate(
      {
        search: mergedSearchParams.toString(),
      },
      { replace: true },
    );
    scrollToTop(STICKY_HEADER_HEIGHT);
  };

  const { intent_name: intentName } =
    intents?.find(intent => intent.intent_definition_id === intentId) ?? {};
  const showLoadingSkeleton =
    isConversationsDataLoading || (page === 1 && isConversationsDataFetching);
  const lastUpdated = useMemo(() => {
    if (!aggregateMetricsData?.last_updated_date) {
      return null;
    }
    return moment(aggregateMetricsData.last_updated_date).format(
      'MMMM Do YYYY, h:mm a',
    );
  }, [aggregateMetricsData]);

  const handleLoadMore = useCallback(
    () => setPage(prevPage => prevPage + 1),
    [],
  );

  const handleSortCallback = useCallback(
    (property: keyof EmailConversationTableRow, order: Order) => {
      // For the `deflected` column, we have to use `useful_interaction`
      // property for sorting:
      const normalizedProperty =
        property === 'deflected' ? 'useful_interaction' : property;

      setPage(1);
      setSortDirection(order);
      setSortColumn(normalizedProperty as string);
      scrollToTop(STICKY_HEADER_HEIGHT);
    },
    [scrollToTop, setSortColumn, setSortDirection],
  );

  return (
    <>
      <ConversationsTabLayout
        aggregateMetrics={
          filterByIntent ? (
            <FilteredEmailAggregateMetricsContainer intentId={intentId} />
          ) : (
            <EmailAggregateMetricsContainer />
          )
        }
        header={
          <HeaderSection
            channel='email'
            dateLabel={timeFilter.label}
            intentDefinitionId={intentId}
            intentName={intentName}
            isLoading={!!(intentId && intentsLoading)}
            lastUpdated={lastUpdated}
            overviewText='All conversations'
          />
        }
        table={
          <>
            {isNewTableComponentEnabled ? (
              <InfiniteTable2
                hasNextPage={
                  typeof conversationsData?.metadata.next_page === 'number'
                }
                initialSorting={[
                  {
                    desc: true,
                    id: 'created_date',
                  },
                ]}
                isError={isConversationsDataError}
                isLoadingFirstPage={showLoadingSkeleton}
                isLoadingNextPage={isConversationsDataFetching}
                onLoadMore={handleLoadMore}
                onSortCallback={handleSortCallback}
                stickyHeaderHeight={TOTAL_STICKY_HEADERS_HEIGHT}
                tableOptions={{
                  columns: materialReactTableColumns,
                  data: conversationsData?.conversations || [],
                  enableColumnActions: false,
                  enableColumnFilters: false,
                  enableExpanding: false,
                  enableFullScreenToggle: false,
                  enableGlobalFilter: false,
                  enableRowActions: false,
                  muiTableBodyRowProps: ({ row }) => ({
                    onClick: () => {
                      setSelectedConversationId(row.original.conversation_id);
                    },
                    sx: {
                      cursor: 'pointer',
                    },
                  }),
                  renderTopToolbarCustomActions: () => (
                    <Box display='flex' gap='8px' paddingTop='4px'>
                      <Box width='360px'>
                        <WithHelperText
                          helperText={conversationIdValidationError}
                        >
                          <SearchBar
                            aria-label='Conversation ID filter'
                            fullWidth
                            onChange={({ target }) =>
                              setConversationId(target.value)
                            }
                            onClear={() => setConversationId('')}
                            placeholder='Search by Conversation ID'
                            size='small'
                            value={conversationId}
                          />
                        </WithHelperText>
                      </Box>
                      <DateRangeFilterButton
                        initialValue={last30DaysTimeRange}
                        onChange={dateRange =>
                          handleDateRangeOnChange(dateRange)
                        }
                        options={datePickerRangeOptionsRevamp}
                        value={selectedDateRange}
                      />
                      <IntentFilter
                        intents={intents}
                        onChange={value => {
                          setIntentId(value);
                          setPage(1);
                          scrollToTop(STICKY_HEADER_HEIGHT);
                        }}
                        selectedIntentId={intentId}
                      />
                    </Box>
                  ),
                }}
              />
            ) : (
              <InfiniteTable
                hasNextPage={
                  typeof conversationsData?.metadata.next_page === 'number'
                }
                isLoadingFirstPage={showLoadingSkeleton}
                isLoadingNextPage={isConversationsDataFetching}
                onLoadMore={handleLoadMore}
                scrollTop={scrollTop}
                scrollToTop={scrollToTop}
                stickyHeaderHeight={STICKY_HEADER_HEIGHT}
                tableProps={{
                  columns,
                  defaultOrder: 'desc',
                  defaultOrderBy: 'created_date',
                  onClickRow: row => {
                    setSelectedConversationId(row.conversation_id);
                  },
                  onSortCallback: handleSortCallback,
                  rows: conversationsData?.conversations ?? [],
                }}
              />
            )}
          </>
        }
      />
      <EmailConversationDrawer
        onClose={() => setSelectedConversationId(null)}
        selectedConversation={selectedConversation}
      />
    </>
  );
};
