import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  IconButton,
  LoadingIndicator,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { formatPhoneNumber } from '../utils';
import {
  buyPhoneNumber,
  getAvailablePhoneNumbers,
} from 'src/services/solve-config/solveConfigApi';
import {
  addBoughtPhoneNumber,
  selectBoughtNumbers,
} from 'src/slices/solve-config/solveConfigSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export const AddNumberModal = ({
  isAddNumberModalVisible,
  setIsAddNumberModalVisible,
}: {
  isAddNumberModalVisible: boolean;
  setIsAddNumberModalVisible: (isVisible: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const [availableNumbers, setAvailableNumbers] = useState<string[]>([]);
  const [isLoadingNumbers, setIsLoadingNumbers] = useState(false);
  const [buyingNumberIndex, setBuyingNumberIndex] = useState(-1);
  const boughtNumbers = useSelector(selectBoughtNumbers);

  useEffect(() => {
    if (isAddNumberModalVisible) {
      setIsLoadingNumbers(true);
      getAvailablePhoneNumbers().then(res => {
        setAvailableNumbers(res.phone_numbers);
        setIsLoadingNumbers(false);
      });
    }
  }, [isAddNumberModalVisible]);

  const handleAddPhoneNumber = async ({
    index,
    number,
  }: {
    index: number;
    number: string;
  }) => {
    if (boughtNumbers.length === 5) {
      dispatch(
        setGlobalToastOptions({
          subtitle:
            'To add more integration phone numbers, please delete some existing numbers first.',
          title: 'Exceeded limit of 5 phone numbers',
          variant: 'warning',
        }),
      );
      return;
    }

    setBuyingNumberIndex(index);

    try {
      const resp = await buyPhoneNumber({
        phoneNumber: number,
      });
      if (!resp.success || !resp.phone_number) {
        dispatch(
          setGlobalToastOptions({
            subtitle: 'Phone number was already in use',
            title: 'Failed to add the phone number',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(addBoughtPhoneNumber(resp.phone_number));
      }
      setBuyingNumberIndex(-1);
    } catch (error) {
      dispatch(
        setGlobalToastOptions({
          title:
            "We're sorry, but the purchase of the phone number was not completed. Please try again later",
          variant: 'danger',
        }),
      );
    }
  };

  return (
    <Dialog
      footer={
        <>
          <Button
            onClick={() => setIsAddNumberModalVisible(false)}
            variant='ghost'
          >
            Cancel
          </Button>
        </>
      }
      onClose={() => setIsAddNumberModalVisible(false)}
      open={isAddNumberModalVisible}
      title='Add phone number'
    >
      <Box
        display='flex'
        flexDirection='column'
        height='350px'
        overflow='auto'
        width='500px'
      >
        <Box
          alignItems='center'
          bgcolor={palette.colors.slate[100]}
          borderBottom={`1px solid ${palette.colors.slate[200]}`}
          display='grid'
          gridTemplateColumns='1fr 60px'
          padding='16px'
        >
          <Typography color={palette.colors.grey[800]} variant='font14'>
            Phone number
          </Typography>
          <Typography color={palette.colors.grey[800]} variant='font14'>
            Add
          </Typography>
        </Box>
        {isLoadingNumbers ? (
          <Box mt='30%'>
            <LoadingIndicator color={palette.colors.purple[500]} size='large' />
          </Box>
        ) : (
          availableNumbers.map((number, index) => {
            const isAddDisabled =
              buyingNumberIndex !== -1 || boughtNumbers.includes(number);
            const buttonIconColor = isAddDisabled
              ? palette.colors.grey[500]
              : palette.colors.purple[500];

            return (
              <Box
                alignItems='center'
                borderBottom={`1px solid ${palette.colors.slate[200]}`}
                display='grid'
                gridTemplateColumns='1fr 60px'
                key={number}
                padding='16px'
                sx={{
                  '&:hover': {
                    bgcolor: palette.colors.blue[100],
                  },
                }}
              >
                <Typography variant='font14'>
                  {formatPhoneNumber(number)}
                </Typography>
                <Tooltip placement='top' tooltipContent='Add this phone number'>
                  <IconButton
                    aria-label='Add this phone number'
                    disabled={isAddDisabled}
                    onClick={() => {
                      handleAddPhoneNumber({ index, number });
                    }}
                    variant='ghost'
                  >
                    {buyingNumberIndex === index ? (
                      <LoadingIndicator color={buttonIconColor} />
                    ) : (
                      <IconPlus color={buttonIconColor} size={20} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            );
          })
        )}
      </Box>
    </Dialog>
  );
};
