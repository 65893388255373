import { useMemo } from 'react';
import React from 'react';
import Markdown, { type MarkdownToJSX } from 'markdown-to-jsx';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { AgathaContent, AgathaMessageContainer } from './StyledComponents';
import { MessageBubblePosition, TextTranscriptComponent } from './types';
import { safeLinkifyHtml } from './utils';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const TextStep = ({
  messageBubblePosition,
  transcriptComponent,
}: {
  messageBubblePosition: MessageBubblePosition;
  transcriptComponent: TextTranscriptComponent;
}) => {
  const theme = useTheme();
  const { theme_color: themeColor } = useSelector(selectWidgetConfiguration);
  const fontColor = useSelector(selectLegibleTextColor);

  const markdownOptions: MarkdownToJSX.Options = useMemo(
    () => ({
      overrides: {
        a: {
          props: {
            rel: 'noreferrer',
            target: '_blank',
          },
        },
      },
      wrapper: React.Fragment,
    }),
    [],
  );

  if (!transcriptComponent.message) {
    return null;
  }

  const component_type = transcriptComponent.component_type;
  if (component_type === 'output') {
    return (
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <AgathaMessageContainer
          backgroundColor={theme.palette.colors.slate[100]}
          messageBubblePosition={messageBubblePosition}
        >
          <Typography variant='font16'>
            {transcriptComponent.should_render_as_markdown ? (
              <AgathaContent
                backgroundColor={theme.palette.colors.slate[100]}
                dir='auto'
                tabIndex={0}
                themeColor={themeColor}
              >
                <Markdown options={markdownOptions}>
                  {transcriptComponent.message}
                </Markdown>
              </AgathaContent>
            ) : (
              <AgathaContent
                backgroundColor={theme.palette.colors.slate[100]}
                dangerouslySetInnerHTML={{
                  __html: safeLinkifyHtml(transcriptComponent.message),
                }}
                dir='auto'
                tabIndex={0}
                themeColor={themeColor}
              />
            )}
          </Typography>
        </AgathaMessageContainer>
      </Box>
    );
  }

  return (
    <AgathaMessageContainer backgroundColor={themeColor}>
      <Typography color={fontColor} variant='font16'>
        {transcriptComponent.message}
      </Typography>
    </AgathaMessageContainer>
  );
};
