import { useState } from 'react';

import { cartesiaTtsBytes } from 'src/services/solve-config/solveConfigApi';

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
};

export const useCartesiaAudio = () => {
  const [isBuffering, setIsBuffering] = useState(false);

  const handlePlay = async ({
    onFinishPlaying,
    text,
    voiceId,
  }: {
    onFinishPlaying: () => void;
    text?: string;
    voiceId: string;
  }) => {
    setIsBuffering(true);

    const response = await cartesiaTtsBytes({
      transcript:
        text || "Hi I'm your customer support. How can I help you today?",
      voice_id: voiceId,
    });

    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const audio = new Audio(blobUrl);
    audio.addEventListener('ended', function () {
      onFinishPlaying();
    });

    setIsBuffering(false);
    audio.play();
  };

  return { handlePlay, isBuffering };
};
