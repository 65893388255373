import Box from '@mui/material/Box';

import { PreviewChatBot } from './PreviewChatBot';

export const VoicePreviewSection = () => {
  return (
    <Box
      alignItems='center'
      alignSelf='center'
      display='flex'
      flex={1}
      flexDirection='column'
      height='100%'
      justifyContent='center'
    >
      <PreviewChatBot />
    </Box>
  );
};
