import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { ArticleCardTranscriptComponent } from './types';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

export const ArticleCardStep = ({
  transcriptComponent,
}: {
  transcriptComponent: ArticleCardTranscriptComponent;
}) => {
  const { theme_color: themeColor } = useSelector(selectWidgetConfiguration);

  return (
    <ArticleContainer themeColor={themeColor}>
      <ArticleTitle>{transcriptComponent.title}</ArticleTitle>
      <AnswerContentBody
        dangerouslySetInnerHTML={{ __html: transcriptComponent.preview }}
      />
      {transcriptComponent.link && (
        <ReadArticleLink
          href={transcriptComponent.link}
          rel='noopener'
          target='_blank'
          themeColor={themeColor}
        >
          Read Article
        </ReadArticleLink>
      )}
    </ArticleContainer>
  );
};

const ArticleContainer = styled('article')<{ themeColor: string }>`
  word-break: break-word;
  background-color: ${props => props.theme.palette.colors.white};
  line-height: 1.2rem;
  border: 1px solid ${props => props.theme.palette.colors.grey[200]};
  border-radius: 5px;
  padding: 7%;
  border-radius: 20px;
  &:focus {
    outline: none;
  }
  margin: 0 16px 0 0;
  width: 80%;
`;

const ArticleTitle = styled('h1')`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  color: ${props => props.theme.palette.colors.black};
`;

const AnswerContentBody = styled('div')`
  margin-bottom: 10px;
  /* border: 1px solid ${props => props.theme.palette.colors.black}; */
  max-height: 200px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  &::after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 70%
    );
  }

  & h2 {
    font-size: 16px;
  }

  & h3,
  h4,
  h5 {
    font-size: 15px;
  }

  & pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }

  & .article_header {
    display: none;
  }

  & a {
    color: var(--message-link-color);
    word-break: break-word;
  }

  & a:hover {
    color: ${props => props.theme.palette.colors.purple[600]};
    text-decoration: none;
  }

  & img {
    max-width: 100% !important;
    height: auto !important;
    width: initial !important;
  }

  & dl,
  dd,
  dt {
    margin: 0;
  }

  & iframe,
  video,
  dl,
  dd,
  dt {
    width: 100%;
    height: auto;
  }

  & table {
    width: 100% !important;
    table-layout: auto;
  }
  & td {
    padding: 3px;
    word-break: break-word;
    height: auto;
  }

  & ft-highlight {
    background: #fbf2de;
    color: #404b5a;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #edbd59;
    text-decoration-style: solid;
    text-underline-position: under;
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 1px solid var(--message-link-color);
  }
`;

export const ReadArticleLink = styled('a')<{ themeColor: string }>`
  color: ${props => props.themeColor};
  font-size: 16px;
  display: block;
  text-align: initial;
  padding: 0 14px 20px 0;
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 0;
  margin-top: 20px;
  background-color: transparent;
  border: none;
`;
