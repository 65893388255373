import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';

import GlobalDiscover from '../global-discover';
import Navbar from './navbar/navbarV2';
import { usePermittedRoutes } from './routes/usePermittedRoutes';
import { isRouteValid } from './helpers';
import Layout from './layout';
import { AnalyticsRedirect, NavigateAndMergeQueryParams } from './redirects';
import {
  DISCOVER_ROUTE_PREFIX,
  Routes,
  SOLVE_INSIGHT_ROUTE_PREFIX,
} from 'src/utils/enums';

const defaultNavbar = <Navbar />;

interface AppRoutesProps {
  permissionKeys: string[];
  permissionsLoaded: boolean;
}

const GlobalComponentByPath = ({ path }: { path: `${Routes}` }) => {
  if (
    path.startsWith(DISCOVER_ROUTE_PREFIX) ||
    path.startsWith(SOLVE_INSIGHT_ROUTE_PREFIX)
  ) {
    return <GlobalDiscover />;
  }

  return null;
};

const AppRoutes: React.FC<React.PropsWithChildren<AppRoutesProps>> = ({
  permissionKeys,
  permissionsLoaded,
}) => {
  const permittedRoutes = usePermittedRoutes({
    permissionKeys,
  });
  const permissionSet = new Set(permissionKeys);
  const routes = permittedRoutes
    .map(route => {
      if (!route) {
        return null;
      }
      const { path, showNavBar = true, showSideBar = true } = route;

      return (
        <Route
          element={
            <Layout
              globalComponent={<GlobalComponentByPath path={path} />}
              main={<route.component />}
              navbar={showNavBar ? defaultNavbar : null}
              permissions={permissionSet}
              showMainApp
              showSideBar={showSideBar}
            />
          }
          key={path}
          path={path}
        />
      );
    })
    .filter(Boolean);

  const path = window.location.pathname;
  const validPaths = Object.values(Routes).filter(item =>
    permittedRoutes.find(route => route?.path === item),
  );
  const isInitialPermittedRoutes = permittedRoutes.length <= 1;
  const validRoute = isRouteValid(path, validPaths);
  const navigateToHome =
    !validRoute && !isInitialPermittedRoutes && permissionsLoaded;

  return (
    <>
      {navigateToHome && <Navigate replace to={Routes.HOMEPAGE} />}
      <ReactRoutes>
        <Route
          element={<AnalyticsRedirect />}
          path='/analytics-beta/:dashboardId'
        />
        <Route
          element={
            <NavigateAndMergeQueryParams
              newParams={{ channel: 'email' }}
              to={Routes.SOLVE_CONFIGURATION}
            />
          }
          path={Routes.SOLVE_EMAIL_CONFIGURATION}
        />
        <Route
          element={
            <NavigateAndMergeQueryParams
              newParams={{ channel: 'widget' }}
              to={Routes.SOLVE_CONFIGURATION}
            />
          }
          path={Routes.WIDGET_CONFIGURATION}
        />
        {routes}
      </ReactRoutes>
    </>
  );
};

export default AppRoutes;
