import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import {
  Button,
  Dialog,
  elevations,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { IntentEmailConfigurationResponse } from '../../types';
import { useEmailBuilderTabs } from '../hooks';
import { AutopilotPolicyView } from './components/autopilot/AutopilotPolicyView';
import ResponseTemplateList from './components/ResponseTemplateList';
import { INTENT_EMAIL_BUILDER_CONFIG } from './constants';
import { useUpdateStateAfterDeletingEmailConfiguration } from './hooks';
import {
  formatEmailBuilderComponent,
  formatRequestComponentFields,
  formatRequestComponentType,
} from './utils';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import EmailBuilder from 'src/components/email-builder';
import Spinner from 'src/components/spinner';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { useDeleteEmailConfigurationEventSubscription } from 'src/hooks/PusherEventHooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import {
  addIntentEmailConfigurationComponent,
  deleteIntentEmailConfiguration,
  deleteIntentEmailConfigurationComponent,
  getEmailConfigurationsPerIntent,
  reorderIntentEmailConfigurationComponent,
  selectEmailBuilderState,
  toggleAutopilot,
  updateIntentEmailConfigurationComponent,
} from 'src/slices/email-builder/emailBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';

interface SolveEmailBuilderProps {
  intentEmailConfiguration: IntentEmailConfigurationResponse | null;
  intentId: string;
}

export default function SolveEmailBuilder({
  intentEmailConfiguration,
  intentId,
}: SolveEmailBuilderProps) {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const { intentEmailConfigurations, isLoading: isLoadingEmailBuilderState } =
    useSelector(selectEmailBuilderState);
  const { isLoading: isHelpdeskLoading } = useGetHelpdeskQuery();
  const tabs = useEmailBuilderTabs();
  const isEmailAutopilotEnabled =
    useIsFeatureFlagEnabled('solve_email_autopilot') &&
    intentId !== CommonIntentWorkflowType.FALLBACK;

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateStateAfterDeletingEmailConfiguration =
    useUpdateStateAfterDeletingEmailConfiguration();

  async function deleteConfiguration() {
    if (!intentEmailConfiguration) {
      return;
    }
    try {
      const response = await dispatch(
        deleteIntentEmailConfiguration({
          configurationId: intentEmailConfiguration.configuration_id,
          lastModifiedDate: intentEmailConfiguration.last_modified_date,
          version: intentEmailConfiguration.version,
        }),
      ).unwrap();

      updateStateAfterDeletingEmailConfiguration(response.configuration_id);
    } catch {}
  }

  useDeleteEmailConfigurationEventSubscription({
    onEvent: useCallback(
      (configurationId, intentDefinitionId) => {
        try {
          if (intentId === intentDefinitionId) {
            dispatch(
              getEmailConfigurationsPerIntent({
                intentDefinitionId: intentId,
              }),
            );
          }
          updateStateAfterDeletingEmailConfiguration(configurationId);
        } catch {}
      },
      [dispatch, intentId, updateStateAfterDeletingEmailConfiguration],
    ),
    orgCode: getUserOrgCode(),
  });

  useEffect(() => {
    if (intentId) {
      dispatch(
        getEmailConfigurationsPerIntent({
          intentDefinitionId: intentId,
        }),
      );
    }
  }, [dispatch, intentId]);

  const isLoading = isLoadingEmailBuilderState || isHelpdeskLoading;

  const emailConfigurationComponents = useMemo(() => {
    if (!intentEmailConfiguration) {
      return [];
    }
    const components =
      isEmailAutopilotEnabled && intentEmailConfiguration.autopilot_enabled
        ? intentEmailConfiguration.autopilot_components
        : intentEmailConfiguration.components;

    return components.map(formatEmailBuilderComponent);
  }, [intentEmailConfiguration, isEmailAutopilotEnabled]);

  return (
    <Box display='flex' height='100%'>
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.colors.white,
          boxShadow: elevations.z1,
          flex: '0 0 250px',
          overflow: 'hidden',
        })}
      >
        <ResponseTemplateList
          activeEmailConfiguration={
            !isLoading && intentEmailConfiguration
              ? intentEmailConfiguration
              : null
          }
          onDeleteConfiguration={() => setIsDeleteDialogVisible(true)}
        />
      </Box>
      <Box flex='1' overflow='auto'>
        {isLoading || !intentEmailConfiguration ? (
          <Spinner />
        ) : (
          <EmailBuilder
            contentOverCanvas={
              isEmailAutopilotEnabled && (
                <Box
                  boxSizing='border-box'
                  display='flex'
                  justifyContent='center'
                  paddingTop='24px'
                  width='100%'
                >
                  <Box
                    bgcolor={theme => theme.palette.colors.white}
                    marginBottom='-20px'
                    marginLeft='-8px'
                    maxWidth='700px'
                    padding='20px 16px'
                    width='calc(100% - 108px)'
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        backgroundColor: palette.colors.brand.greyBackground,
                        borderRadius: '12px',
                        display: 'flex',
                        padding: '16px',
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          backgroundColor: palette.colors.white,
                          borderRadius: '50%',
                          display: 'flex',
                          height: '46px',
                          justifyContent: 'center',
                          'svg stop': {
                            stopColor:
                              intentEmailConfiguration.autopilot_enabled
                                ? undefined
                                : palette.colors.grey[600],
                          },
                          width: '46px',
                        }}
                      >
                        <SVG src={aaBadgeSrc} />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                        }}
                      >
                        <Typography variant='font16Bold'>
                          Email Autoflow
                        </Typography>
                        <Typography
                          color={palette.colors.grey[700]}
                          variant='font12'
                        >
                          Streamline back-and-forth responses with an Autoflow
                          policy
                        </Typography>
                      </Box>
                      <Toggle
                        checked={intentEmailConfiguration.autopilot_enabled}
                        onChange={() => {
                          dispatch(
                            toggleAutopilot({
                              configurationId:
                                intentEmailConfiguration.configuration_id,
                              isAutopilotEnabled:
                                !intentEmailConfiguration.autopilot_enabled,
                              lastModifiedDate:
                                intentEmailConfiguration.last_modified_date,
                              version: intentEmailConfiguration.version,
                            }),
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            }
            emailBuilderConfiguration={INTENT_EMAIL_BUILDER_CONFIG}
            emailBuilderTabConfiguration={tabs}
            emailConfigurationComponents={emailConfigurationComponents}
            onAddComponent={({ component, position }) => {
              const requestComponentFields = formatRequestComponentFields(
                component,
                contextVariables,
              );
              const requestComponentType = formatRequestComponentType(
                component.component_type,
              );

              dispatch(
                addIntentEmailConfigurationComponent({
                  // eslint-disable-next-line
                  // @ts-ignore TODO: need to figure out generic types or type guard here
                  component: {
                    component_fields: requestComponentFields,
                    component_type: requestComponentType,
                  },
                  configurationId: intentEmailConfiguration.configuration_id,
                  lastModifiedDate: intentEmailConfiguration.last_modified_date,
                  position,
                  version: intentEmailConfiguration.version,
                }),
              );
            }}
            onDeleteComponent={componentId => {
              dispatch(
                deleteIntentEmailConfigurationComponent({
                  componentId,
                  configurationId: intentEmailConfiguration.configuration_id,
                  lastModifiedDate: intentEmailConfiguration.last_modified_date,
                  version: intentEmailConfiguration.version,
                }),
              );
            }}
            onReorderComponent={({ componentId, position }) => {
              dispatch(
                reorderIntentEmailConfigurationComponent({
                  componentId,
                  configurationId: intentEmailConfiguration.configuration_id,
                  lastModifiedDate: intentEmailConfiguration.last_modified_date,
                  position,
                  version: intentEmailConfiguration.version,
                }),
              );
            }}
            onUpdateComponent={({ component }) => {
              const requestComponentFields = formatRequestComponentFields(
                component,
                contextVariables,
              );

              dispatch(
                updateIntentEmailConfigurationComponent({
                  component: {
                    // eslint-disable-next-line
                    // @ts-ignore TODO: need to figure out generic types or type guard here
                    component_fields: requestComponentFields,
                    component_id: component.component_id,
                  },
                  configurationId: intentEmailConfiguration.configuration_id,
                  lastModifiedDate: intentEmailConfiguration.last_modified_date,
                  version: intentEmailConfiguration.version,
                }),
              );
            }}
          />
        )}
      </Box>
      <Dialog
        footer={
          <>
            <Button
              onClick={() => setIsDeleteDialogVisible(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              aria-label='Delete confirm'
              onClick={() => {
                deleteConfiguration();
                setIsDeleteDialogVisible(false);
              }}
              variant='secondary'
            >
              Delete
            </Button>
          </>
        }
        hideCloseButton
        onClose={() => setIsDeleteDialogVisible(false)}
        open={isDeleteDialogVisible}
        title='Delete?'
      >
        <Typography variant='font14'>
          Are you sure you want to delete{' '}
          <Typography variant='font14Bold'>
            {intentEmailConfigurations.find(
              config =>
                config.configuration_id ===
                intentEmailConfiguration?.configuration_id,
            )?.name || ''}
          </Typography>
          ?
        </Typography>
      </Dialog>
      <AutopilotPolicyView
        onClose={() => {
          setSearchParams(params => {
            params.delete('autopilot');
            return params;
          });
        }}
        open={searchParams.get('autopilot') === 'true'}
      />
    </Box>
  );
}

const SVG = styled(ReactSVG)`
  width: 24px;
  height: 24px;

  > div {
    width: 100%;
    height: 100%;
  }
`;
