import { ActionBuilderActionTypes, APITemplateTypes } from './enums';
import { type TriageSelfServeTrackingEventType } from 'src/components/app/types';
import { BuilderView } from 'src/pages/workflow-builder-edit/types';
import {
  ShopifySelectedActionTemplate,
  SnowflakeSelectedActionTemplate,
} from 'src/types/actionBuilderApiTypes';
import { SolveWidgetProduct } from 'src/types/types';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';

/** `ViewNavigator` options when current view is Solve */
export const solveViews = ['overview', 'breakdowns'];

/** `ViewNavigator` options when current view is Solve Macro Controls */
export const SolveMacroControlAdminViews = [
  'overview',
  'breakdowns',
  'Macro Controls',
];

export const shopifyTemplateInitialValue: ShopifySelectedActionTemplate = {
  action_description: 'Retrieve order status using customer email and ID',
  action_fields: {
    customer_email: '',
    customer_id: '',
    password: '',
    url: '',
    username: '',
  },
  action_name: 'Shopify Order Status',
  action_type: ActionBuilderActionTypes.SHOPIFY_ORDER_STATUS_TEMPLATE,
};

export const snowflakeTemplateInitialValue: SnowflakeSelectedActionTemplate = {
  action_description: 'Retrieve data using a custom query',
  action_fields: {
    account: '',
    data_table_name: '',
    db_name: '',
    output_fields: {
      kv_list: [{ key: '', value: '' }],
    },
    password: '',
    query_condition: '',
    username: '',
  },
  action_name: 'Snowflake Data Query',
  action_type: ActionBuilderActionTypes.SNOWFLAKE_DATA_QUERY_TEMPLATE,
};

export const hubspotContactCreationTemplateInitialValue = {
  action_description: 'Create a HubSpot contact',
  action_name: 'HubSpot Contact Creation',
  action_type: APITemplateTypes.HUBSPOT_CREATE_CONTACT_TEMPLATE,
  icon: 'https://d3tnn7lar6ozas.cloudfront.net/e3797092-5de1-49f4-9e5b-5613cc1036ff.png',
};

export type ActionBuilderTemplate = {
  description: string;
  icon: string;
  title: string;
  type:
    | 'shopify_order_status_template'
    | 'snowflake_data_query_template'
    | APITemplateTypes;
};

/* Article Suggestion Action Case */

export const articleWasHelpfulCase = 'Yes';

export const articleWasNotHelpfulCase = 'No';

export const helpdesks = [
  'freshdesk',
  'kustomer',
  'salesforce',
  'zendesk',
  'servicenow',
  'odo',
  'jira',
  'intercom',
  'other',
] as const;

export const customFieldHelpdesks = [
  'zendesk',
  'kustomer',
  'jira',
  'freshdesk',
];

// Unsaved changes modal
export const unsavedChangesTitle = 'You have unsaved changes';
export const unsavedChangesPrompt = 'Discard unsaved changes';
export const ASSIST_AUTOMATIONS_TEXT = {
  actionDescription:
    'Select an available connector and pick an action to perform',
  actionTitle: 'Step 2 - Setup action',
  assignVariablesText: 'Assign value to variable',
  assignVariablesTooltipContent:
    'Give inputs or outputs a placeholder value to use later in crafting the text response. An example could be saving the order ID to the “$order_id” placeholder.',
  inputArrayTypeTooltip:
    'It is optional to enter more than one value for this input. If entering multiple values, please separate them with commas (e.g. “input a,value b”).',
  inputDescriptionPlaceholder:
    "Describe what inputs you'd like the agent to input",
  inputFieldCardDescription:
    'Use the below to create a form for agents to input any needed info',
  inputFieldCardTitle: 'Step 3 - Set inputs',
  outputCardDescription:
    'Select the API outputs that can be used to create a pre-formatted reply or internal information for the agent',
  outputCardTitle: 'Step 4 - Save output',
  overviewDescription:
    'Your title and description should define how others in your team discover this Automation',
  overviewTitle: 'Step 1 - Give automation a title and description',
  previewCardDescription:
    'Automation description, what best describes your action ',
  previewCardTitle: 'Automation Name',
  previewDescription:
    'Running a test is live and may update or change data in system',
  previewTitle: 'Run Test',
  textFieldsTitle: 'Step 5 - Format output',
};

export const ASSIST_AUTOMATIONS_APPCUES_TARGET = {
  formatOutoutSection: 'FormatOutoutSection',
  overviewSection: 'OverviewSection',
  runTest: 'RunTest',
  saveOutputSection: 'SaveOutputSection',
  setInputSection: 'SetInputSection',
  setupActionSection: 'SetupActionSection',
};

export const minimumPhrasesRequired = 2;

export const TRIAGE_LLM_TRACKING_EVENTS: Record<
  string,
  TriageSelfServeTrackingEventType
> = {
  CONFIGURATION_TAB_CLICKED: 'triage-ss-configuration-tab-clicked',
  CONFIGURATION_VERSIONS_TAB_CLICKED:
    'triage-ss-configuration-versions-tab-clicked',
  CREATE_LABEL: 'triage-ss-create-label',
  CREATE_MODEL_CLICKED: 'triage-ss-create-model-clicked',
  CREATE_MODEL_SUCCESS: 'triage-ss-create-model-success',
  DELETE_LABEL: 'triage-ss-delete-label',
  DELETE_MODEL_CLICKED: 'triage-ss-delete-model-clicked',
  DELETE_MODEL_SUCCESS: 'triage-ss-delete-model-success',
  DELETE_VERSION_CLICKED: 'triage-ss-delete-version-clicked',
  DELETE_VERSION_SUCCESS: 'triage-ss-delete-version-success',
  DUPLICATE_MODEL_CLICKED: 'triage-ss-duplicate-model-clicked',
  DUPLICATE_MODEL_SUCCESS: 'triage-ss-duplicate-model-success',
  DUPLICATE_VERSION_CLICKED: 'triage-ss-duplicate-version-clicked',
  DUPLICATE_VERSION_SUCCESS: 'triage-ss-duplicate-version-success',
  LABELS_TAB_CLICKED: 'triage-ss-labels-tab-clicked',
  LABELS_VERSIONS_TAB_CLICKED: 'triage-ss-labels-versions-tab-clicked',
  PUBLISH_MODEL_SUCCESS: 'triage-ss-publish-model-success',
  PUBLISH_VERSION_CLICKED: 'triage-ss-publish-version-clicked',
  PUBLISH_VERSION_SUCCESS: 'triage-ss-publish-version-success',
  TEST_MODEL_OPENED: 'triage-ss-test-model-opened',
  TEST_USED: 'triage-ss-test-used',
  TEST_VERSION_OPENED: 'triage-ss-test-version-opened',
  TICKETS_TAB_CLICKED: 'triage-ss-tickets-tab-clicked',
  UNPUBLISH_MODEL_SUCCESS: 'triage-ss-unpublish-model-success',
  UPDATE_CONFIGURATION: 'triage-ss-update-configuration',
  UPDATE_LABEL: 'triage-ss-update-label',
  VIEW_LABEL_DRAWER: 'triage-ss-view-label-drawer',
  VIEW_OTHER_VERSION_CLICKED: 'triage-ss-view-other-version-clicked',
  VIEW_VALUE_MAPPING_DRAWER: 'triage-ss-view-value-mapping-drawer',
  VIEW_VERSIONS_CLICKED: 'triage-ss-view-versions-clicked',
};

type TriageModelTab = 'Labels' | 'Configuration' | 'Tickets';

export const TRIAGE_MODEL_TABS: Array<TriageModelTab> = [
  'Labels',
  'Configuration',
  'Tickets',
];

export const TAB_TO_EVENT_MAP: Record<
  TriageModelTab,
  TriageSelfServeTrackingEventType
> = {
  Configuration: TRIAGE_LLM_TRACKING_EVENTS.CONFIGURATION_TAB_CLICKED,
  Labels: TRIAGE_LLM_TRACKING_EVENTS.LABELS_TAB_CLICKED,
  Tickets: TRIAGE_LLM_TRACKING_EVENTS.TICKETS_TAB_CLICKED,
};

export const CHANNEL_TO_PRODUCT_MAP: Record<
  ConversationChannel | BuilderView,
  SolveWidgetProduct
> = {
  api: 'api',
  email: 'interactive_email',
  slack: 'slack',
  voice: 'voice',
  widget: 'workflow_builder',
};
export const PRODUCT_TO_CHANNEL_MAP: Record<
  SolveWidgetProduct,
  ConversationChannel | BuilderView
> = {
  api: 'api',
  interactive_email: 'email',
  slack: 'slack',
  voice: 'voice',
  workflow_builder: 'widget',
};

export const lightPurpleGradient =
  'linear-gradient(270deg, rgba(189, 0, 255, 0.05) 0%, rgba(56, 102, 245, 0.05) 100%)';
