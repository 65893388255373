import { Box, styled, useTheme } from '@mui/material';
import {
  useRTVIClientTransportState,
  VoiceVisualizer,
} from '@pipecat-ai/client-react';

import { Typography } from '@forethought-technologies/forethought-elements';

export function StatusDisplay() {
  const theme = useTheme();
  const transportState = useRTVIClientTransportState();

  const handleMessageToDisplay = () => {
    switch (transportState) {
      case 'disconnected':
      case 'disconnecting':
        return 'Click icon to connect to an AI agent';
      case 'initializing':
      case 'authenticating':
      case 'connecting':
        return 'Establishing connection...';
      case 'connected':
        return 'Waiting for an AI agent to pick up the call...';
      case 'ready':
        return 'In the call with an AI agent';
      case 'error':
        return 'Something went wrong. Please try again later.';
    }
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      flex={1}
      flexDirection='column'
      gap='16px'
      justifyContent='center'
    >
      <WaterDisplay>
        <VoiceVisualizer
          backgroundColor='transparent'
          barColor='#5c0979'
          barGap={3}
          barMaxHeight={80}
          barWidth={5}
          participantType='local'
        />
      </WaterDisplay>
      <Typography color={theme.palette.colors.purple[100]} variant='font14'>
        {handleMessageToDisplay()}
      </Typography>
    </Box>
  );
}

const WaterDisplay = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: #bd6aff;
  border-radius: 50%;
  box-shadow: inset 5px 10px 15px rgba(0, 0, 0, 0.5),
    5px 10px 15px rgba(0, 0, 0, 0.3), 10px 10px 25px rgba(0, 0, 0, 0.05);

  animation: action 6s ease-in-out infinite alternate;

  canvas {
    transform: scaleX(2);
  }

  @keyframes action {
    0% {
      transform: scale(1);
      border-radius: 41% 59% 53% 47% / 53% 49% 51% 47%;
    }
    50% {
      transform: scale(1.1);
      border-radius: 48% 52% 49% 51% / 49% 49% 51% 51%;
    }

    100% {
      transform: scale(1);
      border-radius: 44% 56% 44% 56% / 56% 41% 59% 44%;
    }
  }
`;
