import ArticleList from './ArticleList';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface TableArticleListProps {
  rowData?: KnowledgeGapArticleRecommendation;
}

const TableArticleList = ({ rowData }: TableArticleListProps) => {
  return <ArticleList articles={rowData?.relevant_articles} />;
};

export default TableArticleList;
