import { Box, styled, useTheme } from '@mui/material';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useHelpdeskTicketFields from '../../hooks/useHelpdeskTicketFields';
import {
  generateWordingByOperator,
  generateWordingForAutomationAction,
} from './utils';
import { AutomationAction } from 'src/pages/workflow-builder-edit/types';
import { ConditionExpressions } from 'src/services/apiInterfaces';

export function FormattedAutomation({
  automationAction,
  queryExpression,
  runOtherAutomations = false,
  size = 'medium',
}: {
  automationAction: AutomationAction;
  queryExpression: ConditionExpressions;
  runOtherAutomations?: boolean;
  size?: 'small' | 'medium';
}) {
  const { palette } = useTheme();
  const fontVariant = size === 'small' ? 'font14' : 'font16';
  const boldFontVariant = size === 'small' ? 'font14Bold' : 'font16Bold';

  const { customFields, isLoading, mappedFields, staticFields } =
    useHelpdeskTicketFields();

  const ticketFields = [...staticFields, ...customFields];
  const expressions =
    queryExpression.expression_type === 'filter'
      ? [queryExpression]
      : queryExpression.expressions;

  const displayListOfValues = (values: ConditionExpressions['values']) => {
    return (
      <Box>
        Values:
        <Ul>
          {values?.map(value => (
            <li key={value.toString()}>{value}</li>
          ))}
        </Ul>
      </Box>
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box
      alignItems='center'
      display='flex'
      flexGrow={1}
      flexWrap='wrap'
      gap='3px'
    >
      <Typography variant={fontVariant}>If</Typography>

      {expressions?.map((expression, index) => {
        const expressionValues = expression.values || [];
        const shouldDisplayTooltip =
          expression.operator === 'contains_one_of' &&
          expressionValues.length > 1;
        const expressionsLength = expressions.length;
        const shouldAddWhiteSpace = index === expressionsLength - 1;
        const shouldAddBooleanOperator = index === expressionsLength - 2;
        const endOfExpression = shouldAddWhiteSpace ? (
          ' '
        ) : shouldAddBooleanOperator ? (
          <Typography color={palette.colors.black} variant={boldFontVariant}>
            {` ${queryExpression.operator?.toUpperCase()} `}
          </Typography>
        ) : (
          ', '
        );

        return (
          <Typography
            color={palette.colors.purple[500]}
            key={index}
            variant={boldFontVariant}
          >
            <Tooltip
              tooltipContent={
                shouldDisplayTooltip
                  ? displayListOfValues(expressionValues)
                  : ''
              }
            >
              <span>
                {generateWordingByOperator(expression, ticketFields, [
                  ...mappedFields,
                ])}
              </span>
            </Tooltip>
            {endOfExpression}
          </Typography>
        );
      })}

      <Typography variant={fontVariant}>
        <Box>{generateWordingForAutomationAction(automationAction)}</Box>
      </Typography>
      {runOtherAutomations && (
        <>
          <Typography color={palette.colors.black} variant={fontVariant}>
            and
          </Typography>
          <Typography variant={fontVariant}>
            <Box>{'run other automations'}</Box>
          </Typography>
        </>
      )}
    </Box>
  );
}

const Ul = styled('ul')`
  padding: 0 0 0 20px;
  margin: 5px 0 0 0;
`;
