import { useCallback, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Chip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import keyBy from 'lodash/fp/keyBy';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { TicketUpdateStepFields } from 'src/pages/workflow-builder-edit/types';
import { useGetTicketCustomFieldsQuery } from 'src/services/email-automation/emailAutomationApi';
import { regexReplaceContextVariables } from 'src/utils/cleanStr';

export function ZendeskTicketUpdateFields({
  automatedValue,
  stepFields,
}: {
  automatedValue: string;
  stepFields?: TicketUpdateStepFields;
}) {
  const { data: customFieldsResponse } = useGetTicketCustomFieldsQuery();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const { palette } = useTheme();

  const formatCV = useCallback(
    (text: string) => {
      const contextVariableMap = new Map<string, string>();
      for (const ctxVar of contextVariables) {
        contextVariableMap.set(
          ctxVar.context_variable_id,
          ctxVar.context_variable_name,
        );
      }

      return regexReplaceContextVariables({
        contextVariableMap,
        replaceValueFn: ({ name }) => {
          return `$${name}`;
        },
        text,
        undefinedContextVariableIds: [],
      });
    },
    [contextVariables],
  );

  const ticketFields = useMemo(() => {
    if (
      !customFieldsResponse ||
      !stepFields?.static_fields_and_values?.length
    ) {
      return null;
    }
    const ticketFieldsById = keyBy('id', customFieldsResponse.static_fields);
    return stepFields.static_fields_and_values.map(({ id, value }) => {
      const ticketFieldDefinition = ticketFieldsById[id];
      if (
        ticketFieldDefinition &&
        ticketFieldDefinition.field_options?.length
      ) {
        const option = ticketFieldDefinition.field_options.find(
          option => option.value === value,
        );
        if (option) {
          return ticketFieldDefinition.title + ': ' + option.label;
        }
      }
      return ticketFieldDefinition.title + ': ' + formatCV(value.toString());
    });
  }, [customFieldsResponse, stepFields, formatCV]);

  const customFields = useMemo(() => {
    if (
      !customFieldsResponse ||
      !stepFields?.custom_fields_and_values?.length
    ) {
      return null;
    }
    const customFieldsById = keyBy('id', customFieldsResponse.custom_fields);
    return stepFields.custom_fields_and_values.map(({ id, value }) => {
      const ticketFieldDefinition = customFieldsById[id];
      const title = ticketFieldDefinition?.title;
      if (
        ticketFieldDefinition &&
        ticketFieldDefinition.field_options?.length
      ) {
        const option = ticketFieldDefinition.field_options.find(
          option => option.value === value,
        );
        if (option) {
          return title + ': ' + option.label;
        }
      }
      return title + ': ' + formatCV(value.toString());
    });
  }, [customFieldsResponse, stepFields, formatCV]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='12px'
      maxWidth='100%'
      padding='0 8px 8px'
    >
      <Box
        alignItems='flex-start'
        display='flex'
        flexDirection='column'
        gap='4px'
      >
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          Ticket Tags:
        </Typography>
        <Box display='flex' flexWrap='wrap' gap='4px' maxWidth='100%'>
          <Chip
            fontSize={11}
            label={automatedValue}
            size='small'
            variant='filled'
          />
          {stepFields?.ticket_tags.map(tag => (
            <Chip
              key={tag}
              label={formatCV(tag)}
              size='small'
              sx={{
                maxWidth: '100%',
              }}
              variant='filled'
            />
          ))}
        </Box>
      </Box>
      {ticketFields && (
        <Box
          alignItems='flex-start'
          display='flex'
          flexDirection='column'
          gap='4px'
        >
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Ticket Fields:
          </Typography>
          <Box display='flex' flexWrap='wrap' gap='4px' maxWidth='100%'>
            {ticketFields.map(field => (
              <Chip
                key={field}
                label={field}
                size='small'
                sx={{
                  maxWidth: '100%',
                }}
                variant='filled'
              />
            ))}
          </Box>
        </Box>
      )}
      {customFields && (
        <Box
          alignItems='flex-start'
          display='flex'
          flexDirection='column'
          gap='4px'
        >
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Custom Ticket Fields:
          </Typography>
          <Box display='flex' flexWrap='wrap' gap='4px' maxWidth='100%'>
            {customFields.map(field => (
              <Chip
                key={field}
                label={field}
                size='small'
                sx={{
                  maxWidth: '100%',
                }}
                variant='filled'
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
