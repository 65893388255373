import { EditSearchHelpCenterArticlesForm } from './EditSearchHelpCenterArticlesForm';
import {
  useAddAutoflowToolMutation,
  useUpdateAutoflowToolConfigurationFieldsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  ArticleSource,
  AutoflowTool,
  ToolDefinitionResponse,
} from 'src/types/workflowBuilderAPITypes';

interface EditSearchHelpCenterArticlesProps {
  intentWorkflowId: string;
  onClose: () => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditSearchHelpCenterArticles = ({
  intentWorkflowId,
  onClose,
  tool,
}: EditSearchHelpCenterArticlesProps) => {
  const [mutate, { isLoading: isUpdatingFields }] =
    useUpdateAutoflowToolConfigurationFieldsMutation();
  const [addAutoflowTool, { isLoading: isAddingTool }] =
    useAddAutoflowToolMutation();

  const onSave = async (articleSources: ArticleSource[]) => {
    try {
      if (!tool?.toolDefinition) {
        const toolData = {
          body: {
            tool_id: tool?.tool_id ?? '',
            tool_type: tool?.tool_type ?? 'system_built_in',
          },
          intentWorkflowId,
        };
        await addAutoflowTool(toolData).unwrap();
      }
      await mutate({
        body: {
          configuration_fields: { article_sources: articleSources },
          tool_id: tool?.tool_id || '',
        },
        intentWorkflowId,
      }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <EditSearchHelpCenterArticlesForm
      initialArticleSources={tool?.toolDefinition?.tool_fields?.article_sources}
      isLoading={isAddingTool || isUpdatingFields}
      onClose={onClose}
      onSave={onSave}
      tool={tool}
    />
  );
};
