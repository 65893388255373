import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconChartBarOff } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { EMPTY_STATE_TEXT } from './constants';

const EmptyState = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      py={4}
      rowGap={3}
      sx={{ whiteSpace: 'pre' }}
    >
      <IconChartBarOff color={palette.colors.slate[300]} size={48} />
      <Typography color={palette.colors.black} variant='font14'>
        {EMPTY_STATE_TEXT}
      </Typography>
    </Box>
  );
};

export default EmptyState;
