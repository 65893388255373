import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import useTabs from './hooks/useTabs';
import SolveInsightsArticles from './articles';
import SolveInsightsChats from './chats';
import SolveInsightsOverview from './overview';
import SolveInsightsTopics from './topics';
import SolveInsightsWorkflows from './workflows';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

const SolveInsightsMain = () => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // state params
  const [searchParams, setSearchParams] = useSearchParams();
  const serializedTab = searchParams.get('tab') ?? '0';
  const activeTab = Number(serializedTab);

  const { availableTabs } = useTabs();

  useEffect(() => {
    emitTrackingEventCallback('insight-initial-page', {
      scope: 'main',
      tab: availableTabs[activeTab],
    });
  }, [emitTrackingEventCallback, activeTab, availableTabs]);

  const tabValue = useMemo(
    () => availableTabs[activeTab],
    [availableTabs, activeTab],
  );

  const handlePageRender = useCallback(() => {
    if (typeof tabValue === 'string') {
      if (tabValue === 'Overview') {
        return <SolveInsightsOverview />;
      }
      if (tabValue === 'Topics') {
        return <SolveInsightsTopics />;
      }
      if (tabValue === 'Workflows') {
        return <SolveInsightsWorkflows />;
      }
      if (tabValue === 'Articles') {
        return <SolveInsightsArticles />;
      }
      if (tabValue === 'Chats') {
        return <SolveInsightsChats />;
      }
    }
    return <SolveInsightsArticles />;
  }, [tabValue]);

  return (
    <>
      <Box
        padding='0px 40px'
        sx={{
          bgcolor: palette.colors.white,
        }}
      >
        <Tabs
          a11yProps={index => ({
            'aria-controls': `tabpanel-${index}`,
            id: `main-tab-${index}`,
          })}
          aria-label='tabs to pick data table below'
          onChange={(_, tab) => {
            emitTrackingEventCallback('insight-tab-change', {
              scope: 'main',
              tab: tabValue,
            });
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('tab', tab.toString());
            setSearchParams(newSearchParams);
          }}
          tabs={availableTabs}
          typographyVariant='font18'
          value={activeTab}
        />
      </Box>

      {handlePageRender()}
    </>
  );
};

export default SolveInsightsMain;
