import React, { useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { Box, styled, SystemStyleObject } from '@mui/system';

import { EmailBuilderComponent } from '../types';
import { DROPZONE_PADDING } from './EmailBuilderCanvas';
import VeriticalMenu from './VerticalMenu';

interface DroppableEmailBuilderComponentProps {
  component: EmailBuilderComponent;
  componentIndex: number;
  deleteDisabledMessage?: string;
  hoverPosition: number | null;
  isComponentActive: boolean;
  isDragActive: boolean;
  isEditorActive: boolean;
  isTranslationIconVisible: boolean;
  onClick: () => void;
  onClickDelete: () => void;
  onTranslationsClick: () => void;
  renderComponent: (params: {
    component: EmailBuilderComponent;
  }) => React.ReactNode;
  reorderComponentsDuringDrag: (dragIndex: number, hoverIndex: number) => void;
  setHoverPosition: (index: number) => void;
}

export default function DroppableEmailBuilderComponent({
  component,
  componentIndex,
  deleteDisabledMessage,
  hoverPosition,
  isComponentActive,
  isDragActive,
  isEditorActive,
  isTranslationIconVisible,
  onClick,
  onClickDelete,
  onTranslationsClick,
  renderComponent,
  reorderComponentsDuringDrag,
  setHoverPosition,
}: DroppableEmailBuilderComponentProps) {
  const { component_id: componentId } = component;
  const boundingBoxRef = useRef<DOMRect | null>(null);

  const [, drop] = useDrop<
    { componentIndex?: number },
    { hoverPosition: number | null; type: string },
    void
  >(
    () => ({
      accept: [
        'feedback',
        'static_article',
        'image',
        'text_message',
        'EXISTING_COMPONENT',
        'button',
        'dynamic_article',
        'macro',
        'interactive_email_button',
        'html',
      ],
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (_, monitor) => {
        return {
          componentId,
          componentIndex,
          hoverPosition,
          type: monitor.getItemType() as string,
        };
      },
      hover: (item, monitor) => {
        const draggableItemY = (monitor.getClientOffset() as XYCoord).y;
        const boundingBox = boundingBoxRef.current;

        if (!draggableItemY || !boundingBox) {
          return;
        }

        const isNewItem = monitor.getItemType() !== 'EXISTING_COMPONENT';
        const dragIndex = item.componentIndex ?? null;
        const hoverIndex = componentIndex;

        // Is dragging over itself
        if (!isNewItem && dragIndex === hoverIndex) {
          return;
        }

        // Determine insert position above or below this component based on hover position
        const { bottom, top } = boundingBox;
        const dropzoneY = bottom - (bottom - top) / 2;
        const atTop = dropzoneY > draggableItemY;

        const hoverPosition = componentIndex + (atTop ? 0 : 1);

        if (isNewItem) {
          return setHoverPosition(hoverPosition);
        }

        // This shouldn't happen because if this is not a new item it will have componentIndex
        if (dragIndex === null) {
          return;
        }
        // Is dragging in bottom half of item above
        if (dragIndex < hoverIndex && atTop) {
          return;
        }
        // Is dragging in top half of item below
        if (dragIndex > hoverIndex && !atTop) {
          return;
        }

        reorderComponentsDuringDrag(dragIndex, hoverIndex);
        item.componentIndex = hoverIndex;
      },
    }),
    [componentIndex, hoverPosition],
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      item: {
        componentIndex,
      },
      type: 'EXISTING_COMPONENT',
    }),
    [componentIndex],
  );

  return (
    <div
      ref={element => {
        preview(drop(element));
        if (element) {
          boundingBoxRef.current = element.getBoundingClientRect();
        }
      }}
    >
      <Box
        sx={{
          display: 'block',
          opacity: isDragging ? 0 : 1,
          // the drop zone is wider than the component.
          // this is so that the dropzone will register drop events when the cursor is slightly outside in either direction
          padding: `0 ${DROPZONE_PADDING}px`,
          transform: `translateX(-${DROPZONE_PADDING}px)`,
          width: `calc(100% + ${DROPZONE_PADDING * 2}px)`,
        }}
      >
        <Box
          onClick={onClick}
          sx={theme => {
            let conditionalStyles: SystemStyleObject = {};

            if (isComponentActive) {
              conditionalStyles = {
                borderColor: theme.palette.colors.purple[500],
              };
            } else if (!isDragActive && !isEditorActive) {
              conditionalStyles = {
                '&:hover': {
                  borderColor: theme.palette.colors.grey[400],
                },
                cursor: 'pointer',
              };
            }

            return {
              backgroundColor: theme.palette.colors.white,
              border: '2px solid transparent',
              position: 'relative',
              ...conditionalStyles,
            };
          }}
        >
          {renderComponent({ component })}
          {isComponentActive && (
            <EditMenuSlot>
              <VeriticalMenu
                deleteDisabledMessage={deleteDisabledMessage}
                isTranslationIconVisible={isTranslationIconVisible}
                onClickDelete={onClickDelete}
                onTranslationsClick={() => {
                  onTranslationsClick();
                }}
                ref={drag}
              />
            </EditMenuSlot>
          )}
        </Box>
      </Box>
    </div>
  );
}

export const EditMenuSlot = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(calc(100% + 6px));
  z-index: 2;
`;
