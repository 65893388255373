import EditableSelectField from './EditableSelectField';
import { HelpdeskOption } from 'src/reducers/triageSettingsReducer/types';
import { useGetHelpdeskOptionsQuery } from 'src/services/triage/triageApi';

const HelpdeskObjectOptionDropdown = () => {
  const { data: helpdeskOptionsResponse } = useGetHelpdeskOptionsQuery();
  const options = helpdeskOptionsResponse?.helpdesk_options.map(
    (option: HelpdeskOption) => ({
      label: option.option_label,
      value: option.option_value,
    }),
  );
  if (!options?.length) {
    return null;
  }
  return (
    <EditableSelectField
      allowNew={false}
      label='Object'
      onChange={() => undefined}
      onToggleCallback={() => undefined}
      options={options}
      value={options[0].value}
    />
  );
};

export default HelpdeskObjectOptionDropdown;
