import React from 'react';
import { SxProps, Theme } from '@mui/material';
import {
  CommandButtonGroup,
  FloatingToolbar as RemirrorFloatingToolbar,
  ToggleBoldButton,
  ToggleBulletListButton,
  ToggleItalicButton,
  ToggleOrderedListButton,
  ToggleUnderlineButton,
  useActive,
  useEditorFocus,
  useSelectedText,
  VerticalDivider,
} from '@remirror/react';
import {
  IconBold,
  IconItalic,
  IconList,
  IconListNumbers,
  IconUnderline,
} from '@tabler/icons-react';

import CustomThemeProvider from '../theme';
import ContextVariableTool from './ContextVariableTool';
import { EmojiButton } from './EmojiButton';
import { useFloatingLinkState } from './hooks';
import LinkTools, { LinkToolForm } from './LinkTools';
import { LinkPreviewConfig, MentionComponentProps } from './types';

const buttonStyles: SxProps<Theme> = theme => ({
  '&:hover:not(:disabled,.Mui-selected)': {
    backgroundColor: theme.palette.colors.grey[100],
  },
  '&:not(:disabled,.Mui-selected)': {
    color: theme.palette.common.black,
  },
  border: 'none !important',
  borderRadius: '6px',
  margin: '0 5px 0 0',
  padding: '6px',
});

interface FloatingToolbarProps {
  additionalToolbarButtons: React.ReactNode[];
  contextVariables: MentionComponentProps['contextVariables'] | null;
  disabled: boolean;
  linkPreviewConfig?: LinkPreviewConfig;
  shouldShowWhenFocused?: boolean;
  shouldValidateHrefLength?: boolean;
  withEmojiButton: boolean;
}

const FloatingToolbar = ({
  additionalToolbarButtons,
  contextVariables,
  disabled,
  linkPreviewConfig,
  shouldShowWhenFocused = false,
  shouldValidateHrefLength = false,
  withEmojiButton,
}: FloatingToolbarProps) => {
  const { isEditing, ...rest } = useFloatingLinkState({
    shouldValidateHrefLength,
  });
  const [isFocused] = useEditorFocus();
  const active = useActive();
  const activeLink = active.link();
  const selectedText = useSelectedText();

  return (
    <CustomThemeProvider>
      {isEditing ? null : (
        <RemirrorFloatingToolbar
          positioner={
            isFocused && shouldShowWhenFocused ? 'always' : 'selection'
          }
          sx={theme => ({
            backgroundColor: 'white',
            border: '1px solid rgba(0, 0, 0, 0.10)',
            borderRadius: '6px',
            boxShadow: theme.shadows[1],
            p: 0.5,
            zIndex: 1202, // 1px above the sidebar
          })}
        >
          <CommandButtonGroup>
            <ToggleBoldButton
              disabled={disabled}
              icon={<IconBold size='18px' />}
              sx={buttonStyles}
            />
            <ToggleItalicButton
              disabled={disabled}
              icon={<IconItalic size='18px' />}
              sx={buttonStyles}
            />
            <ToggleUnderlineButton
              disabled={disabled}
              icon={<IconUnderline size='18px' />}
              sx={buttonStyles}
            />
          </CommandButtonGroup>
          <VerticalDivider />
          <CommandButtonGroup>
            <ToggleBulletListButton
              disabled={disabled}
              icon={<IconList size='18px' />}
              sx={buttonStyles}
            />
            <ToggleOrderedListButton
              disabled={disabled}
              icon={<IconListNumbers size='18px' />}
              sx={buttonStyles}
            />
          </CommandButtonGroup>

          {contextVariables && contextVariables.length > 0 && (
            <>
              <VerticalDivider />
              <CommandButtonGroup>
                <ContextVariableTool
                  contextVariables={contextVariables}
                  disabled={disabled}
                />
              </CommandButtonGroup>
            </>
          )}
          {withEmojiButton && (
            <>
              <VerticalDivider />
              <CommandButtonGroup>
                <EmojiButton disabled={disabled} />
              </CommandButtonGroup>
            </>
          )}
          {(activeLink || selectedText) && (
            <>
              <VerticalDivider />
              <CommandButtonGroup>
                <LinkTools
                  buttonStyles={buttonStyles}
                  disabled={disabled}
                  isEditing={isEditing}
                  {...rest}
                />
              </CommandButtonGroup>
            </>
          )}
          {additionalToolbarButtons.length > 0 && (
            <>
              <VerticalDivider />
              <CommandButtonGroup>
                {...additionalToolbarButtons}
              </CommandButtonGroup>
            </>
          )}
        </RemirrorFloatingToolbar>
      )}
      <LinkToolForm
        enabled={isEditing}
        linkPreviewConfig={linkPreviewConfig}
        {...rest}
      />
    </CustomThemeProvider>
  );
};

export default FloatingToolbar;
