import { useMemo } from 'react';

import _orderBy from 'lodash/fp/orderBy';
import {
  filterCommonIntentWorkflowsToTop,
  IntentTableDataFilter,
} from 'src/pages/workflow-builder/intent-workflows-table/helper';
import {
  ConversationChannel,
  IntentsResponse,
} from 'src/types/workflowBuilderAPITypes';

export const useGetIntentsForFilter = ({
  channel,
  intentsData,
}: {
  channel: ConversationChannel;
  intentsData?: IntentsResponse;
}) => {
  return useMemo(() => {
    if (!intentsData?.intents) {
      return [];
    }

    const filter = new IntentTableDataFilter(intentsData.intents);
    const filteredTable = filter
      .setIsOtherQuestionsActive(channel)
      .setDefaultHandoffWorkflowActive();

    const withDefaultSorting = _orderBy(
      [
        item => item.active_workflow_types.length > 0,
        item => item.inquiry_count_per_channel[channel],
      ],
      ['desc', 'desc'],
      filteredTable.getTableData(),
    );

    // always show knowledge retrieval and handoff workflows on top
    const filtered = filterCommonIntentWorkflowsToTop(withDefaultSorting);

    return filtered.map(intent => ({
      label: intent.intent_name,
      value: `workflow_id.${intent.intent_workflow_id}`,
    }));
  }, [channel, intentsData]);
};
