import tinycolor from 'tinycolor2';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  AvailableVoice,
  BannerImageConfig,
  HandoffConfiguration,
  KnowledgeConfiguration,
  SolveConfigState,
  SolveKnowledgeSource,
  ToneOfVoiceConfig,
  ValidationFailure,
  VoiceConfiguration,
  WidgetConfiguration,
} from './types';
import omit from 'lodash/fp/omit';
import { validateKnowledgeConfigurationPrompt } from 'src/components/knowledge-configuration-prompt-text-field/helpers';
import * as API from 'src/services/solve-config/solveConfigApi';
import { ALLOW_LIST_EMPTY } from 'src/slices/solve-config/constants';
import type { RootState } from 'src/store/rootReducer';

const initialState: SolveConfigState = {
  brandId: '',
  handoff: {
    data: {
      handoff_message: '',
      is_modified: false,
    },
    error: null,
    loading: false,
  },
  isInitialized: false,
  isLoadingInitialConfig: true,
  isPublishing: false,
  isSolveLiteEnabled: false,
  knowledge: {
    data: {
      available_knowledge_sources: [],
      is_modified: false,
      selected_knowledge_source: undefined,
    },
    error: null,
    loading: false,
  },
  liveVersionExists: false,
  liveVersionIsActive: false,
  uiLoadingState: {
    isGenericFieldsLoading: false,
    isHighFrequencyFieldsLoading: false,
    isSwitchingConfig: false,
  },
  validationState: {
    isGreetingWarningModalVisible: false,
    isHandoffWarningModalVisible: false,
    isKnowledgePromptWarningModalVisible: false,
    isPromptWarningModalVisible: false,
    validationFailure: null,
  },
  voice: {
    available_voices: [],
    bought_phone_numbers: [],
    data: {
      agent_voice_id: '',
      default_language: '',
      greeting_message: '',
      is_draft: false,
      is_modified: false,
      org_id: -1,
      phone_number: '',
      use_only_default_language: false,
    },
    error: null,
    loading: false,
    selectedPhoneNumber: null,
  },
  widget: {
    data: {
      agent_chat_image: null,
      banner_image_config: {
        alt_text: null,
        image: null,
        is_enabled: false,
        link: null,
      },
      csat_config: {
        is_enabled: true,
        negative_rating_routing_intent: undefined,
        no_resolve_option: '',
        one_to_five_labels: [],
        options_for_resolution_confirmation: [],
        question_for_additional_feedback: '',
        question_for_high_ratings: '',
        question_for_low_ratings: '',
        question_for_resolution_confirmation: '',
        rating_question: '',
        reasons_for_high_ratings: [],
        reasons_for_low_ratings: [],
        scale: 'one_to_two',
        should_request_additional_feedback: false,
        should_request_feedback_for_high_ratings: false,
        should_request_feedback_for_low_ratings: false,
        should_request_resolution_confirmation: false,
        should_show_submit_confirmation_message: false,
        should_use_rating_based_submit_message: false,
        show_on_header: false,
        show_when_agent_chat_ends: false,
        show_when_widget_minimizes: false,
        style: 'star',
        submit_confirmation_message: '',
        submit_message_for_high_ratings: '',
        submit_message_for_low_ratings: '',
        yes_resolve_option: '',
      },
      display_proactive_chat: false,
      display_proactive_chat_intents: false,
      free_form_intent_detection_enabled: false,
      greeting_line: '',
      header_image: null,
      help_button_image: null,
      is_active: false,
      is_draft: true,
      is_modified: false,
      knowledge_configuration_prompt: '',
      manually_selected_intent_button_configuration: {},
      manually_selected_top_intents: [],
      num_top_intents: 3,
      org_id: -1,
      privacy_consent_config: {
        background_color: '',
        call_to_action_label: '',
        is_banner_dismissible: false,
        placement: 'top',
        privacy_policy: '',
        prompt_header: '',
        show_when_conversation_starts: false,
        style: 'call_to_action',
      },
      proactive_chat_greeting: null,
      quick_feedback_config: {
        feedback_prompt: '',
        hover_label_bad: '',
        hover_label_good: '',
        is_enabled: false,
        negative_routing_intent_workflow_id: '',
        question_for_high_ratings: '',
        question_for_low_ratings: '',
        should_request_feedback_for_high_ratings: false,
        should_request_feedback_for_low_ratings: false,
        style: '',
      },
      tab_name: '',
      tag_selection_enabled: false,
      theme_color: '',
      tone_of_voice_config: {
        custom_prompt: null,
        enabled: false,
        predefined_tones: [],
        should_use_predefined_tones: false,
      },
      top_n_intents: null,
      use_help_button_image_as_icon: false,
      whitelisted_domains: [],
    },
    error: null,
    loading: false,
  },
};

interface MultibrandParams {
  brandId?: string;
  isMultibrandEnabled?: boolean;
}

export const getInitialSolveConfiguration = createAsyncThunk(
  'solveConfig/getInitialSolveConfiguration',
  async ({ brandId, isMultibrandEnabled }: MultibrandParams) => {
    const initialSolveLiteConfigResponse =
      await API.getInitialSolveConfiguration();
    if (initialSolveLiteConfigResponse.acknowledged) {
      if (isMultibrandEnabled && brandId) {
        return API.getMultibrandWidgetConfiguration({ brandId });
      }
      return API.getWidgetConfiguration();
    }
  },
);

export const getWidgetConfiguration = createAsyncThunk(
  'solveConfig/getWidgetConfiguration',
  () => API.getWidgetConfiguration(),
);

export const getKnowledgeConfiguration = createAsyncThunk(
  'solveConfig/getKnowledgeConfiguration',
  () => API.getKnowledgeConfiguration(),
);

export const updateWidgetConfiguration = createAsyncThunk(
  'solveConfig/updateWidgetConfiguration',
  ({
    brandId,
    isMultibrandEnabled,
    widgetConfiguration,
  }: { widgetConfiguration: WidgetConfiguration } & MultibrandParams) => {
    if (isMultibrandEnabled && brandId) {
      return API.updateMultibrandWidgetConfiguration(widgetConfiguration, {
        brandId,
      });
    }
    return API.updateWidgetConfiguration(widgetConfiguration);
  },
);

export const updateKnowledgeConfiguration = createAsyncThunk(
  'solveConfig/updateKnowledgeConfiguration',
  (params: SolveKnowledgeSource) => {
    return API.updateKnowledgeConfiguration(params);
  },
);

export const updateHandoffConfiguration = createAsyncThunk(
  'solveConfig/updateHandoffConfiguration',
  (updatedConfig: HandoffConfiguration) => {
    return API.updateHandoffConfiguration(updatedConfig);
  },
);

export const activateWidget = createAsyncThunk(
  'solveConfig/activateWidget',
  ({ brandId, isMultibrandEnabled }: MultibrandParams) => {
    if (isMultibrandEnabled && brandId) {
      return API.activateMultibrandWidget({ brandId });
    }
    return API.activateWidget();
  },
);

export const deactivateWidget = createAsyncThunk(
  'solveConfig/deactivateWidget',
  ({ brandId, isMultibrandEnabled }: MultibrandParams) => {
    if (isMultibrandEnabled && brandId) {
      return API.deactivateMultibrandWidget({ brandId });
    }
    return API.deactivateWidget();
  },
);

export const publishConfiguration = createAsyncThunk(
  'solveConfig/publishConfiguration',
  ({ brandId, isMultibrandEnabled }: MultibrandParams) => {
    if (isMultibrandEnabled && brandId) {
      return API.publishMultibrandWidgetConfiguration({ brandId });
    }

    return API.publishWidgetConfiguration();
  },
);

export const publishVoiceConfiguration = createAsyncThunk(
  'solveConfig/publishVoiceConfiguration',
  ({ phoneNumber }: { phoneNumber: string }) => {
    return API.publishVoiceConfiguration({ phoneNumber });
  },
);

export const updateVoiceConfiguration = createAsyncThunk(
  'solveConfig/updateVoiceConfiguration',
  ({
    updatedConfigurations,
  }: {
    updatedConfigurations: VoiceConfiguration;
  }) => {
    return API.updateVoiceConfiguration({ updatedConfigurations });
  },
);

export const getVoiceConfigurationByPhoneNumber = createAsyncThunk(
  'solveConfig/getVoiceConfigurationByPhoneNumber',
  ({ phoneNumber }: { phoneNumber: string }) => {
    return API.getVoiceConfigurationByPhoneNumber({ phoneNumber });
  },
);

export const getBoughtPhoneNumbers = createAsyncThunk(
  'solveConfig/getBoughtPhoneNumbers',
  () => {
    return API.getBoughtPhoneNumbers();
  },
);

const solveConfigSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(
      getInitialSolveConfiguration.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // since we used Promise.all in the action, payload is an array and the order matters
          const getWidgetConfigResponse = payload;
          // update the data
          state.isInitialized = true;
          state.liveVersionExists = getWidgetConfigResponse.live_version_exists;
          state.liveVersionIsActive =
            getWidgetConfigResponse.live_version_is_active;
          state.widget.data =
            getWidgetConfigResponse.solve_widget_configuration;

          if (
            validateKnowledgeConfigurationPrompt(
              getWidgetConfigResponse.solve_widget_configuration
                .knowledge_configuration_prompt,
            )
          ) {
            // when reloading, invalid values may exist in draft version
            state.validationState.validationFailure =
              ValidationFailure.KNOWLEDGE_PROMPT_TOO_LONG;
            return;
          }
          state.isLoadingInitialConfig = false;
          // clear validation states
          state.validationState.validationFailure = null;
        }
      },
    );
    builder.addCase(getInitialSolveConfiguration.pending, state => {
      state.isLoadingInitialConfig = true;
    });
    builder.addCase(getWidgetConfiguration.fulfilled, (state, { payload }) => {
      state.liveVersionExists = payload.live_version_exists;
      state.widget.data = payload.solve_widget_configuration;
      state.widget.error = null;
    });
    builder.addCase(
      getKnowledgeConfiguration.fulfilled,
      (state, { payload }) => {
        state.knowledge.data = payload;
      },
    );
    builder.addCase(updateWidgetConfiguration.fulfilled, (state, action) => {
      // Redux state should be the source of truth for RTE/high frequency fields:
      const payloadWithoutRteFields = omit(
        [
          'greeting_line',
          'tab_name',
          'theme_color',
          'proactive_chat_greeting',
          'csat_config',
          'privacy_consent_config',
        ],
        action.payload,
      );

      Object.assign(state.widget.data, payloadWithoutRteFields);

      // check if we should update prompt's validation state
      if (
        !state.widget.data.display_proactive_chat &&
        state.validationState.validationFailure ===
          ValidationFailure.EMPTY_PROMPT
      ) {
        state.validationState.validationFailure = null;
      } else if (state.widget.data.proactive_chat_greeting === '') {
        state.validationState.validationFailure =
          ValidationFailure.EMPTY_PROMPT;
      }

      state.widget.error = null;
      state.widget.loading = false;
    });
    builder.addCase(updateWidgetConfiguration.pending, state => {
      state.widget.loading = true;
    });
    builder.addCase(updateWidgetConfiguration.rejected, state => {
      state.widget.loading = false;
    });

    // Voice configuration
    builder.addCase(getVoiceConfigurationByPhoneNumber.pending, state => {
      state.uiLoadingState.isSwitchingConfig = true;
    });
    builder.addCase(
      getVoiceConfigurationByPhoneNumber.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // move the selected voice to the top in the available voices
          const availableVoices = payload.available_voices;
          const selectedVoiceId =
            payload.solve_voice_configuration.agent_voice_id;
          const sortedVoices = availableVoices.sort((voice1, voice2) =>
            voice1.voice_id === selectedVoiceId
              ? -1
              : voice2.voice_id === selectedVoiceId
              ? 1
              : 0,
          );

          // set the values in redux
          state.voice.data = payload.solve_voice_configuration;
          state.voice.available_voices = sortedVoices;
          state.voice.selectedPhoneNumber =
            payload.solve_voice_configuration.phone_number || null;

          state.liveVersionExists = payload.live_version_exists;
          state.voice.error = null;
          state.uiLoadingState.isSwitchingConfig = false;
        }
      },
    );
    builder.addCase(getVoiceConfigurationByPhoneNumber.rejected, state => {
      state.uiLoadingState.isSwitchingConfig = true;
    });
    builder.addCase(updateVoiceConfiguration.fulfilled, state => {
      // redux should be the source of truth for the states updated
      state.voice.data.is_modified = true;

      state.voice.error = null;
      state.voice.loading = false;
    });
    builder.addCase(updateVoiceConfiguration.pending, state => {
      state.voice.loading = true;
    });
    builder.addCase(updateVoiceConfiguration.rejected, state => {
      state.voice.loading = false;
    });

    builder.addCase(getBoughtPhoneNumbers.fulfilled, (state, action) => {
      state.voice.bought_phone_numbers = action.payload.phone_numbers;
    });

    // update handoff config
    builder.addCase(updateHandoffConfiguration.pending, state => {
      state.handoff.loading = true;
    });
    builder.addCase(updateHandoffConfiguration.fulfilled, (state, action) => {
      // Redux state should be the source of truth for RTE fields:
      const payloadWithoutRteFields = omit(['handoff_message'], action.payload);

      Object.assign(state.handoff.data, payloadWithoutRteFields);

      state.handoff.error = null;
      state.handoff.loading = false;
    });
    builder.addCase(updateHandoffConfiguration.rejected, state => {
      state.handoff.loading = false;
    });
    builder.addCase(activateWidget.fulfilled, state => {
      state.liveVersionIsActive = true;
      state.widget.error = null;
    });
    builder.addCase(deactivateWidget.fulfilled, state => {
      state.liveVersionIsActive = false;
      state.widget.error = null;
    });
    builder.addCase(publishConfiguration.fulfilled, state => {
      state.liveVersionExists = true;
      state.widget.data.is_modified = false;
      state.handoff.data.is_modified = false;
      state.knowledge.data.is_modified = false;
      state.isPublishing = false;
    });
    builder.addCase(publishConfiguration.pending, state => {
      state.isPublishing = true;
    });
    builder.addCase(publishConfiguration.rejected, state => {
      state.isPublishing = false;
    });
    builder.addCase(publishVoiceConfiguration.fulfilled, state => {
      state.liveVersionExists = true;
      state.voice.data.is_modified = false;
      state.isPublishing = false;
    });
    builder.addCase(publishVoiceConfiguration.pending, state => {
      state.isPublishing = true;
    });
    builder.addCase(publishVoiceConfiguration.rejected, state => {
      state.isPublishing = false;
    });
    // update knowledge config
    builder.addCase(updateKnowledgeConfiguration.rejected, state => {
      state.knowledge.loading = false;
    });
    builder.addCase(updateKnowledgeConfiguration.pending, state => {
      state.knowledge.loading = true;
    });
    builder.addCase(
      updateKnowledgeConfiguration.fulfilled,
      (state, { payload }) => {
        state.knowledge.data = payload;
        state.knowledge.error = null;
        state.knowledge.loading = false;
      },
    );
  },
  initialState,
  name: 'solveConfig',
  reducers: {
    addBoughtPhoneNumber(
      state: SolveConfigState,
      action: PayloadAction<string>,
    ) {
      state.voice.bought_phone_numbers = [
        ...state.voice.bought_phone_numbers,
        action.payload,
      ];
    },
    hideWarningModal(state: SolveConfigState) {
      const validationFailure = state.validationState.validationFailure;
      switch (validationFailure) {
        case ValidationFailure.EMPTY_GREETING:
          state.validationState.isGreetingWarningModalVisible = false;
          break;
        case ValidationFailure.EMPTY_HANDOFF:
          state.validationState.isHandoffWarningModalVisible = false;
          break;
        case ValidationFailure.EMPTY_PROMPT:
          state.validationState.isPromptWarningModalVisible = false;
          break;
        case ValidationFailure.KNOWLEDGE_PROMPT_TOO_LONG:
          state.validationState.isKnowledgePromptWarningModalVisible = false;
          break;
      }
    },
    removeBoughtPhoneNumber(
      state: SolveConfigState,
      action: PayloadAction<string>,
    ) {
      state.voice.bought_phone_numbers =
        state.voice.bought_phone_numbers.filter(
          number => number !== action.payload,
        );
    },
    setBannerImageConfig(
      state: SolveConfigState,
      action: PayloadAction<BannerImageConfig>,
    ) {
      state.widget.data.banner_image_config = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
    },
    setBrandId(state: SolveConfigState, action: PayloadAction<string>) {
      state.brandId = action.payload;
    },
    setCsatConfigByKey<
      T extends keyof SolveConfigState['widget']['data']['csat_config'],
    >(
      state: SolveConfigState,
      action: PayloadAction<{
        isGenericField?: boolean;
        key: T;
        value: SolveConfigState['widget']['data']['csat_config'][T];
      }>,
    ) {
      const { isGenericField, key, value } = action.payload;
      // if we're changing scale, should also update the style to the default style under
      // that scale
      if (key === 'scale' && value !== state.widget.data.csat_config.scale) {
        state.widget.data.csat_config.style =
          value === 'one_to_five' ? 'star' : 'thumbs';
      }

      state.widget.data.csat_config[key] = value;

      // determine what kind of field is being loading
      if (isGenericField !== undefined) {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isGenericFieldsLoading: true,
        };
      } else {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isHighFrequencyFieldsLoading: true,
        };
      }
    },
    setExcludePrivateArticles(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      if (!state.knowledge.data.selected_knowledge_source) {
        return;
      }

      state.knowledge.data.selected_knowledge_source.exclude_private_articles =
        action.payload;

      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setFreeFormIntentDetectionEnabled(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      state.widget.data.free_form_intent_detection_enabled = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setGreetingLine(state: SolveConfigState, action: PayloadAction<string>) {
      state.widget.data.greeting_line = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
      // if we fail validation, set the flag
      if (action.payload === '') {
        state.validationState.validationFailure =
          ValidationFailure.EMPTY_GREETING;
      } else {
        state.validationState.validationFailure = null;
      }
    },
    setHandoffMessage(state: SolveConfigState, action: PayloadAction<string>) {
      state.handoff.data.handoff_message = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
      // if we fail validation, set the flag
      if (action.payload === '') {
        state.validationState.validationFailure =
          ValidationFailure.EMPTY_HANDOFF;
      } else {
        state.validationState.validationFailure = null;
      }
    },
    setIntentOptions(state: SolveConfigState, action: PayloadAction<string>) {
      if (action.payload === 'no_display') {
        state.widget.data.manually_selected_top_intents = [];
        state.widget.data.num_top_intents = 0;
      }
      if (action.payload === 'auto') {
        state.widget.data.manually_selected_top_intents = [];
        state.widget.data.num_top_intents = 3;
      }
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setIsGenericFieldsLoading(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: action.payload,
      };
    },
    setIsHighFrequencyFieldsLoading(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: action.payload,
      };
    },
    setKnowledgeConfigurationPrompt(
      state: SolveConfigState,
      action: PayloadAction<string>,
    ) {
      state.widget.data.knowledge_configuration_prompt = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };

      if (validateKnowledgeConfigurationPrompt(action.payload)) {
        state.validationState.validationFailure =
          ValidationFailure.KNOWLEDGE_PROMPT_TOO_LONG;
      } else {
        state.validationState.validationFailure = null;
      }
    },
    setLiveVersionIsActive(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      state.liveVersionIsActive = action.payload;
    },
    setManuallySelectedIntentButtonConfiguration(
      state: SolveConfigState,
      action: PayloadAction<
        WidgetConfiguration['manually_selected_intent_button_configuration']
      >,
    ) {
      state.widget.data.manually_selected_intent_button_configuration =
        action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setManuallySelectedTopIntents(
      state: SolveConfigState,
      action: PayloadAction<string[]>,
    ) {
      state.widget.data.manually_selected_top_intents = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setNumTopIntents(state: SolveConfigState, action: PayloadAction<number>) {
      state.widget.data.num_top_intents = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setPrivacyConsentConfigurationByKey<
      T extends keyof SolveConfigState['widget']['data']['privacy_consent_config'],
    >(
      state: SolveConfigState,
      action: PayloadAction<{
        isGenericField?: boolean;
        key: T;
        value: SolveConfigState['widget']['data']['privacy_consent_config'][T];
      }>,
    ) {
      const { isGenericField, key, value } = action.payload;
      state.widget.data.privacy_consent_config[key] = value;

      // determine what kind of field is being loading
      if (isGenericField !== undefined) {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isGenericFieldsLoading: true,
        };
      } else {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isHighFrequencyFieldsLoading: true,
        };
      }
    },
    setProactiveChatGreeting(
      state: SolveConfigState,
      action: PayloadAction<string>,
    ) {
      state.widget.data.proactive_chat_greeting = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
      // if we fail validation, set the flag
      if (action.payload === '' && state.widget.data.display_proactive_chat) {
        state.validationState.validationFailure =
          ValidationFailure.EMPTY_PROMPT;
      } else {
        state.validationState.validationFailure = null;
      }
    },
    setQuickFeedbackConfigByKey<
      T extends keyof SolveConfigState['widget']['data']['quick_feedback_config'],
    >(
      state: SolveConfigState,
      action: PayloadAction<{
        isGenericField?: boolean;
        key: T;
        value: SolveConfigState['widget']['data']['quick_feedback_config'][T];
      }>,
    ) {
      const { isGenericField, key, value } = action.payload;

      state.widget.data.quick_feedback_config[key] = value;
      // determine what kind of field is being loading
      if (isGenericField !== undefined) {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isGenericFieldsLoading: true,
        };
      } else {
        state.uiLoadingState = {
          ...state.uiLoadingState,
          isHighFrequencyFieldsLoading: true,
        };
      }
    },
    setSelectedPhoneNumber(
      state: SolveConfigState,
      action: PayloadAction<string | null>,
    ) {
      state.voice.selectedPhoneNumber = action.payload;
    },
    setStartUploadingImage(state: SolveConfigState) {
      state.widget.loading = true;
      setIsGenericFieldsLoading(true);
    },
    setTabName(state: SolveConfigState, action: PayloadAction<string>) {
      state.widget.data.tab_name = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
    },
    setTagSelectionEnabled(
      state: SolveConfigState,
      action: PayloadAction<boolean>,
    ) {
      state.widget.data.tag_selection_enabled = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setThemeColor(state: SolveConfigState, action: PayloadAction<string>) {
      state.widget.data.theme_color = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
    },
    setToneOfVoiceConfig(
      state: SolveConfigState,
      action: PayloadAction<ToneOfVoiceConfig>,
    ) {
      state.widget.data.tone_of_voice_config = action.payload;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isHighFrequencyFieldsLoading: true,
      };
    },
    setVoiceDataByKey<T extends keyof SolveConfigState['voice']['data']>(
      state: SolveConfigState,
      action: PayloadAction<{
        key: T;
        value: SolveConfigState['voice']['data'][T];
      }>,
    ) {
      const { key, value } = action.payload;
      state.voice.data[key] = value;

      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    setWidgetDataByKey<T extends keyof SolveConfigState['widget']['data']>(
      state: SolveConfigState,
      action: PayloadAction<{
        key: T;
        value: SolveConfigState['widget']['data'][T];
      }>,
    ) {
      const { key, value } = action.payload;
      state.widget.data[key] = value;
      state.uiLoadingState = {
        ...state.uiLoadingState,
        isGenericFieldsLoading: true,
      };
    },
    showWarningModal(state: SolveConfigState) {
      const validationFailure = state.validationState.validationFailure;
      switch (validationFailure) {
        case ValidationFailure.EMPTY_GREETING:
          state.validationState.isGreetingWarningModalVisible = true;
          break;
        case ValidationFailure.EMPTY_HANDOFF:
          state.validationState.isHandoffWarningModalVisible = true;
          break;
        case ValidationFailure.EMPTY_PROMPT:
          state.validationState.isPromptWarningModalVisible = true;
          break;
        case ValidationFailure.KNOWLEDGE_PROMPT_TOO_LONG:
          state.validationState.isKnowledgePromptWarningModalVisible = true;
          break;
      }
    },
    swapManuallySelectedTopIntents(
      state: SolveConfigState,
      action: PayloadAction<{ dragIndex: number; hoverIndex: number }>,
    ) {
      const { dragIndex, hoverIndex } = action.payload;

      // Swap elements by index:
      [
        state.widget.data.manually_selected_top_intents[dragIndex],
        state.widget.data.manually_selected_top_intents[hoverIndex],
      ] = [
        state.widget.data.manually_selected_top_intents[hoverIndex],
        state.widget.data.manually_selected_top_intents[dragIndex],
      ];
    },
  },
});

export const selectIsSolveConfigInitialized = (state: RootState): boolean =>
  state.solveConfig.isInitialized;

export const selectWidgetConfiguration = (
  state: RootState,
): WidgetConfiguration => state.solveConfig.widget.data;

export const selectVoiceConfiguration = (
  state: RootState,
): VoiceConfiguration => state.solveConfig.voice.data;

export const selectBoughtNumbers = (state: RootState): string[] =>
  state.solveConfig.voice.bought_phone_numbers;

export const selectSelectedPhoneNumber = (state: RootState): string | null =>
  state.solveConfig.voice.selectedPhoneNumber;

export const selectAgentVoiceId = (state: RootState): string =>
  state.solveConfig.voice.data.agent_voice_id;

export const selectAvailableVoices = (state: RootState): AvailableVoice[] =>
  state.solveConfig.voice.available_voices;

export const selectIsSwitchingConfig = (state: RootState): boolean =>
  state.solveConfig.uiLoadingState.isSwitchingConfig;

export const selectHandoffConfiguration = (
  state: RootState,
): HandoffConfiguration => state.solveConfig.handoff.data;

export const selectKnowledgeConfiguration = (
  state: RootState,
): KnowledgeConfiguration => state.solveConfig.knowledge.data;

export const selectUiLoadingState = (
  state: RootState,
): {
  isGenericFieldsLoading: boolean;
  isHighFrequencyFieldsLoading: boolean;
} => state.solveConfig.uiLoadingState;

export const selectIsSaving = (state: RootState): boolean => {
  return (
    state.solveConfig.uiLoadingState.isGenericFieldsLoading ||
    state.solveConfig.uiLoadingState.isHighFrequencyFieldsLoading ||
    state.solveConfig.handoff.loading ||
    state.solveConfig.knowledge.loading ||
    state.solveConfig.widget.loading ||
    state.solveConfig.voice.loading ||
    state.solveConfig.isPublishing
  );
};

const selectIsConfigurationModified = (state: RootState): boolean => {
  return (
    state.solveConfig.handoff.data.is_modified ||
    state.solveConfig.knowledge.data.is_modified ||
    state.solveConfig.widget.data.is_modified
  );
};

export const selectIsActive = (state: RootState): boolean => {
  return state.solveConfig.liveVersionIsActive;
};

export const selectLiveVersionExists = (state: RootState): boolean => {
  return state.solveConfig.liveVersionExists;
};

const buildValidationErrors = (
  widgetConfig: WidgetConfiguration,
  isMultibrandEnabled: boolean,
) => {
  if (isMultibrandEnabled) {
    return [];
  }
  const validationErrors: string[] = [];

  // All
  if (!widgetConfig.whitelisted_domains.length) {
    validationErrors.push(ALLOW_LIST_EMPTY);
  }

  return validationErrors;
};

export const selectValidationErrors = createSelector(
  (state: RootState) => state.solveConfig.widget.data,
  // Extract the second argument to pass it on
  (state: RootState, isMultibrandEnabled: boolean) => isMultibrandEnabled,
  buildValidationErrors,
);

export const selectCanPublish = createSelector(
  (state: RootState, isMultibrandEnabled: boolean) =>
    selectValidationErrors(state, isMultibrandEnabled).length === 0,
  noValidationErrors => noValidationErrors,
);

export const selectCanUpdatePublish = createSelector(
  (state: RootState) => selectIsConfigurationModified(state),
  (state: RootState) => selectLiveVersionExists(state),
  (state: RootState, isMultibrandEnabled: boolean) =>
    selectCanPublish(state, isMultibrandEnabled),
  (isConfigurationModified, liveVersionExists, canPublish) =>
    isConfigurationModified && liveVersionExists && canPublish,
);

export const selectIsVoiceConfigModified = (state: RootState): boolean => {
  return state.solveConfig.voice.data.is_modified;
};

// these two color selectors should be the same as the ones in solve-ui,
// such that the style of static widget aligns with that of solve widget
const selectIsWhiteColorLegible = (state: RootState): boolean =>
  tinycolor.readability(
    state.solveConfig.widget.data.theme_color,
    theme.palette.colors.white,
  ) >= 4.5;

export const selectLegibleTextColor = (state: RootState): string =>
  selectIsWhiteColorLegible(state)
    ? theme.palette.colors.white
    : theme.palette.text.primary;

export const selectLegibleButtonOutlineColor = (state: RootState): string =>
  tinycolor(
    selectIsWhiteColorLegible(state)
      ? state.solveConfig.widget.data.theme_color
      : theme.palette.colors.grey[800],
  )
    .setAlpha(0.3)
    .toRgbString();

export const selectLegibleElementColor = (state: RootState): string =>
  selectIsWhiteColorLegible(state)
    ? state.solveConfig.widget.data.theme_color
    : theme.palette.colors.grey[800];

export const selectIsValidationFailing = (state: RootState): boolean =>
  state.solveConfig.validationState.validationFailure !== null;

export const selectWarningModalsVisibility = (state: RootState): boolean[] => {
  const validationState = state.solveConfig.validationState;
  return [
    validationState.isGreetingWarningModalVisible,
    validationState.isPromptWarningModalVisible,
    validationState.isHandoffWarningModalVisible,
    validationState.isKnowledgePromptWarningModalVisible,
  ];
};

export const selectBrandId = (state: RootState): string =>
  state.solveConfig.brandId;

export const selectIsLoadingInitialConfig = (state: RootState): boolean =>
  state.solveConfig.isLoadingInitialConfig;

export default solveConfigSlice.reducer;
export const {
  addBoughtPhoneNumber,
  hideWarningModal,
  removeBoughtPhoneNumber,
  setBannerImageConfig,
  setBrandId,
  setCsatConfigByKey,
  setExcludePrivateArticles,
  setFreeFormIntentDetectionEnabled,
  setGreetingLine,
  setHandoffMessage,
  setIntentOptions,
  setIsGenericFieldsLoading,
  setIsHighFrequencyFieldsLoading,
  setKnowledgeConfigurationPrompt,
  setLiveVersionIsActive,
  setManuallySelectedTopIntents,
  setNumTopIntents,
  setPrivacyConsentConfigurationByKey,
  setProactiveChatGreeting,
  setQuickFeedbackConfigByKey,
  setSelectedPhoneNumber,
  setStartUploadingImage,
  setTabName,
  setTagSelectionEnabled,
  setThemeColor,
  setToneOfVoiceConfig,
  setVoiceDataByKey,
  setWidgetDataByKey,
  showWarningModal,
  swapManuallySelectedTopIntents,
} = solveConfigSlice.actions;
