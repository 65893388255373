import { sendAuthRequest } from '../api';
import { dashboardApi } from '../dashboard-api';
import {
  AcknowledgedResponse,
  BuyPhoneNumberResponse,
  GetPhoneNumbersResponse,
  HandoffConfiguration,
  KnowledgeConfiguration,
  SolveKnowledgeSource,
  VoiceConfiguration,
  VoiceConfigurationResponse,
  WidgetConfiguration,
  WidgetConfigurationResponse,
  WidgetConfigurationsResponse,
} from 'src/slices/solve-config/types';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

const BASE_URL = `${API_URL}dashboard-controls/solve/config`;

export const getInitialSolveConfiguration =
  (): Promise<AcknowledgedResponse> => {
    return sendAuthRequest(`${BASE_URL}/initialize`, {}, 'PUT')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

/**
 * widget configuration endpoints
 */

export const getWidgetConfiguration =
  (): Promise<WidgetConfigurationResponse> => {
    return sendAuthRequest(`${BASE_URL}/widget`, null, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getMultibrandWidgetConfiguration = ({
  brandId,
}: {
  brandId: string;
}): Promise<WidgetConfigurationResponse> => {
  return sendAuthRequest(`${BASE_URL}/brand/${brandId}/widget`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getKnowledgeConfiguration =
  (): Promise<KnowledgeConfiguration> => {
    return sendAuthRequest(`${BASE_URL}/knowledge`, null, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const updateWidgetConfiguration = (
  updatedConfigurations: WidgetConfiguration,
): Promise<WidgetConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/widget`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateMultibrandWidgetConfiguration = (
  updatedConfigurations: WidgetConfiguration,
  {
    brandId,
  }: {
    brandId: string;
  },
): Promise<WidgetConfiguration> => {
  return sendAuthRequest(
    `${BASE_URL}/brand/${brandId}/widget`,
    updatedConfigurations,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getHandoffConfiguration = (): Promise<HandoffConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/handoff`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateHandoffConfiguration = (
  updatedConfigurations: HandoffConfiguration,
): Promise<HandoffConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/handoff`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const activateWidget = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/activate`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const activateMultibrandWidget = ({
  brandId,
}: {
  brandId: string;
}): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/brand/${brandId}/widget/activate`,
    null,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deactivateWidget = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/deactivate`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deactivateMultibrandWidget = ({
  brandId,
}: {
  brandId: string;
}): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/brand/${brandId}/widget/deactivate`,
    null,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishConfiguration = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/publish`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishWidgetConfiguration = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/publish`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishMultibrandWidgetConfiguration = ({
  brandId,
}: {
  brandId: string;
}): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/brand/${brandId}/widget/publish`,
    null,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishVoiceConfiguration = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/voice/publish/${phoneNumber}`,
    null,
    'PUT',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateKnowledgeConfiguration = (
  updatedConfigurations: SolveKnowledgeSource,
): Promise<KnowledgeConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/knowledge`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

/**
 * Voice configuration endpoints
 */
export const updateVoiceConfiguration = ({
  updatedConfigurations,
}: {
  updatedConfigurations: VoiceConfiguration;
}): Promise<VoiceConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/voice`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getAvailablePhoneNumbers =
  (): Promise<GetPhoneNumbersResponse> => {
    return sendAuthRequest(
      `${BASE_URL}/voice/available-phone-numbers`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getBoughtPhoneNumbers = (): Promise<GetPhoneNumbersResponse> => {
  return sendAuthRequest(`${BASE_URL}/voice/bought-phone-numbers`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const buyPhoneNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<BuyPhoneNumberResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/voice/buy-phone-number`,
    {
      phone_number: phoneNumber,
    },
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const releasePhoneNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/voice/release-phone-number/${phoneNumber}`,
    null,
    'DELETE',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getVoiceConfigurationByPhoneNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<VoiceConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/voice/phone-number/${phoneNumber}`,
    null,
    'GET',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const cartesiaTtsBytes = ({
  transcript,
  voice_id,
}: {
  transcript: string;
  voice_id: string;
}): Promise<Response> => {
  return (
    sendAuthRequest(
      `${BASE_URL}/voice/cartesia/tts/bytes`,
      { transcript, voice_id },
      'POST',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      // tts returns audio in bytes, just return as is
      .then((res: Response) => res)
  );
};

/**
 * RTK query for brands
 */
const solveConfigApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    duplicateWidgetConfigurationForBrand: builder.mutation<
      WidgetConfiguration,
      { brandId: string }
    >({
      invalidatesTags: ['Brands'],
      query: ({ brandId }) => ({
        method: 'POST',
        url: `${BASE_URL}/brand/${brandId}/widget/duplicate`,
      }),
    }),
    getPublishedWidgetConfigurations: builder.query<
      WidgetConfigurationsResponse,
      void
    >({
      query: () => ({
        method: 'GET',
        url: `${BASE_URL}/widget/published`,
      }),
    }),
    toneOfVoiceTest: builder.query<{ response: string }, { input: string }>({
      query: body => ({
        body,
        method: 'POST',
        url: `${BASE_URL}/widget/tone-of-voice-test`,
      }),
    }),
  }),
});

export const {
  useDuplicateWidgetConfigurationForBrandMutation,
  useGetPublishedWidgetConfigurationsQuery,
  useToneOfVoiceTestQuery,
} = solveConfigApi;
