import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  LoadingIndicator,
  SearchBar,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitVoiceConfigForNonRTE } from '../../hooks/useDebouncedSubmitConfig';
import { AgentVoice } from './AgentVoice';
import {
  selectAvailableVoices,
  selectIsSwitchingConfig,
  selectSelectedPhoneNumber,
  selectVoiceConfiguration,
  setVoiceDataByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { VoiceConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

export const VoiceConfig = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const selectedNumber = useSelector(selectSelectedPhoneNumber);
  const isSwitchingConfig = useSelector(selectIsSwitchingConfig);
  const voiceConfiguration = useSelector(selectVoiceConfiguration);
  const availableVoices = useSelector(selectAvailableVoices);
  const [voiceSearchText, setVoiceSearchText] = useState('');

  const debouncedSubmitConfig = useDebouncedSubmitVoiceConfigForNonRTE();

  const filteredVoices = useMemo(() => {
    return availableVoices.filter(voice =>
      voice.name.toLowerCase().includes(voiceSearchText.trim().toLowerCase()),
    );
  }, [availableVoices, voiceSearchText]);

  const updateAgentVoiceId = (voiceId: string) => {
    dispatch(
      setVoiceDataByKey({
        key: 'agent_voice_id',
        value: voiceId,
      }),
    );

    const updatedConfig: VoiceConfiguration = {
      ...voiceConfiguration,
      agent_voice_id: voiceId,
    };
    debouncedSubmitConfig(updatedConfig, 'agent_voice_id', voiceId);
  };

  const {
    default_language: defaultLanguage,
    greeting_message: greetingMessage,
  } = voiceConfiguration;

  if (isSwitchingConfig) {
    return (
      <Box mt='30%'>
        <LoadingIndicator color={palette.colors.purple[500]} size='large' />
      </Box>
    );
  }

  if (!selectedNumber) {
    return (
      <Box mt='30%' textAlign='center'>
        <Typography color={palette.colors.slate[200]} variant='font46'>
          Select a phone number to configure
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography variant='font16Bold'>Greeting</Typography>
      <TextField
        aria-label='Update greeting message'
        multiline
        onChange={e => {
          dispatch(
            setVoiceDataByKey({
              key: 'greeting_message',
              value: e.target.value,
            }),
          );

          const updatedConfig: VoiceConfiguration = {
            ...voiceConfiguration,
            greeting_message: e.target.value,
          };
          debouncedSubmitConfig(
            updatedConfig,
            'greeting_message',
            e.target.value,
          );
        }}
        placeholder='Greeting message'
        rows={3}
        value={greetingMessage}
      />
      <Typography variant='font16Bold'>Choose your AI voice</Typography>
      <Typography variant='font14'>
        Turn on the speakers and test the voice.
      </Typography>
      <SearchBar
        aria-label='Voice search bar'
        onChange={e => setVoiceSearchText(e.target.value)}
        placeholder='Search'
        value={voiceSearchText}
      />
      <AgentVoiceSection>
        {filteredVoices.map(voice => (
          <AgentVoice
            key={voice.voice_id}
            updateAgentVoiceId={updateAgentVoiceId}
            voice={voice}
          />
        ))}
      </AgentVoiceSection>

      <Typography variant='font16Bold'>Select default language</Typography>
      <Typography variant='font14'>
        The default language is set for the greeting message. If the &apos;Only
        use the selected language&apos; checkbox is unchecked, the bot can
        intelligently detect the user&apos;s language and switch to it for the
        rest of the conversation once the user starts talking.
      </Typography>
      <SelectDropdown
        id='select default language'
        onChange={e => {
          dispatch(
            setVoiceDataByKey({
              key: 'default_language',
              value: e.target.value,
            }),
          );

          const updatedConfig: VoiceConfiguration = {
            ...voiceConfiguration,
            default_language: e.target.value,
          };
          debouncedSubmitConfig(
            updatedConfig,
            'default_language',
            e.target.value,
          );
        }}
        options={[{ label: 'English', value: 'en' }]}
        placeholder='Select default language'
        value={defaultLanguage}
      />
    </Box>
  );
};

const AgentVoiceSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
