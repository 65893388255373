import Box from '@mui/material/Box';
import {
  IconAdjustmentsHorizontal,
  IconArrowsDownUp,
  IconBookmark,
  IconCalendar,
  IconChartBar,
  IconClock,
  IconDiscountCheck,
  IconEyeOff,
} from '@tabler/icons-react';

import { theme } from '@forethought-technologies/forethought-elements';

interface FilterButtonIconProps {
  dropdownType:
    | 'time-range'
    | 'time-period'
    | 'metric'
    | 'ticket'
    | 'histogram'
    | 'bookmark'
    | 'recommended'
    | 'show-all'
    | 'filter';
}

const FilterButtonIcon = ({ dropdownType }: FilterButtonIconProps) => {
  const getDropdownComponent = () => {
    switch (dropdownType) {
      case 'metric':
        return IconChartBar;
      case 'time-range':
        return IconCalendar;
      case 'time-period':
        return IconClock;
      case 'ticket':
        return IconArrowsDownUp;
      case 'bookmark':
        return IconBookmark;
      case 'recommended':
        return IconDiscountCheck;
      case 'show-all':
        return IconEyeOff;
      case 'filter':
        return IconAdjustmentsHorizontal;
      default:
        throw new Error(
          `Discover select start adornment of type ${dropdownType} not supported`,
        );
    }
  };
  const IconComponent = getDropdownComponent();
  return (
    <Box display='flex'>
      <IconComponent
        color={theme.palette.colors.grey[800]}
        height={20}
        width={20}
      />
    </Box>
  );
};

export default FilterButtonIcon;
