import { useCallback, useState } from 'react';
import { MRT_Row, MRT_RowData } from 'material-react-table';

import { COLUMNS, SEARCH_OPTIONS } from './constants';
import { createApiFilters } from './helpers';
import TicketFilter from './TicketFilter';
import DashboardTable from 'src/components/dashboard-table';
import { createTimeStampObject } from 'src/services/triage/helpers';
import { useGetTriageInsightTableQuery } from 'src/services/triage/triageApi';
import { TriageTicket } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

interface TableSectionProps {
  apiFilters: ReturnType<typeof createApiFilters>;
  filters: string[];
  onDateRangeChange: (dateRange: DateRange) => void;
  onFilterChange: (filters: string[]) => void;
  onSearch: (value: string) => void;
  onSearchTypeChange: (value: string) => void;
  onTableRowClick: (row: MRT_Row<TriageTicket & MRT_RowData>) => void;
  searchText: string;
  searchType: string;
  selectedDateRange: DateRange;
}

const TableSection = ({
  apiFilters,
  filters,
  onDateRangeChange,
  onFilterChange,
  onSearch,
  onSearchTypeChange,
  onTableRowClick,
  searchText,
  searchType,
  selectedDateRange,
}: TableSectionProps) => {
  const [page, setPage] = useState(1);
  const { data, isFetching, isLoading } = useGetTriageInsightTableQuery({
    ...apiFilters,
    page,
  });

  const handleLoadMore = useCallback(() => {
    if (!data?.next_page || isFetching) {
      return;
    }
    setPage(data.next_page);
  }, [data?.next_page, isFetching]);

  const handleSearchTypeChange = (value: string) => {
    onSearchTypeChange(value);
    setPage(1);
    onSearch('');
  };

  const handleSearch = (value: string) => {
    setPage(1);
    onSearch(value);
  };

  const handleFilterChange = (value: string[]) => {
    setPage(1);
    onFilterChange(value);
  };

  return (
    <DashboardTable<TriageTicket>
      columns={COLUMNS}
      data={data?.tickets || []}
      dateRangeFilter={{
        initialValue: selectedDateRange,
        onChange: onDateRangeChange,
        value: selectedDateRange,
      }}
      downloadExportType='triage_insight_tickets_table'
      downloadRequestData={{
        ...apiFilters,
        ...createTimeStampObject(apiFilters.start, apiFilters.end),
      }}
      FilterComponent={
        <TicketFilter
          isTableFilter
          onChange={handleFilterChange}
          value={filters}
        />
      }
      hasNextPage={Boolean(data?.next_page)}
      isFetching={isFetching}
      isLoading={isLoading}
      onClick={onTableRowClick}
      onLoadMore={handleLoadMore}
      onSearch={handleSearch}
      onSearchTypeChange={handleSearchTypeChange}
      searchOptions={SEARCH_OPTIONS}
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={249}
      tableOptions={{
        enableSorting: false,
      }}
    />
  );
};

export default TableSection;
