import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { TriageInsightsLabel } from 'src/services/triage/types';

export const MODEL_TABLE_COLUMNS: DashboardTableProps<TriageInsightsLabel>['columns'] =
  [
    {
      key: 'name',
      size: 300,
      title: 'Label',
    },
    {
      key: 'description',
      rows: 2,
      size: 400,
      title: 'Description',
    },
    {
      key: 'pending_count',
      size: 140,
      title: 'Pending',
      valueType: 'number',
    },
    {
      key: 'finalized_count',
      size: 200,
      title: 'Finalized',
      valueType: 'number',
    },
    {
      key: 'correct_count',
      size: 200,
      title: 'Correct predictions',
      valueType: 'number',
    },
    {
      headerTooltip:
        'Accuracy = (number of correct label predictions) / (total number of finalized labels).',
      key: 'accuracy',
      size: 200,
      title: 'Accuracy',
      valueType: 'percentage',
    },
    {
      headerTooltip:
        'Recall measures how effectively Triage identifies tickets that should have a particular label. Specifically, it’s the proportion of tickets that actually require this label and are correctly predicted with that label. In other words, out of all tickets that truly needed this label, recall shows how many Triage successfully assigned it to.',
      key: 'recall',
      title: 'Recall',
      valueType: 'percentage',
    },
  ];

export const INITIAL_LEGENDS = [
  'Predicted tickets',
  'Correct predictions',
  'Accuracy',
];
