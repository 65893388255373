import { useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconExternalLink, IconTag } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from '../constants';
import { TextDivider } from '../ConversationDrawer';
import ConversationTranscript from '../ConversationTranscript';
import {
  RevampConversationTranscript,
  TranscriptContainer,
} from '../ConversationTranscript/RevampConversationTranscript';
import {
  ArticleTranscriptComponent,
  isArticleCardComponent,
  isRelatedArticlesTranscriptComponent,
} from '../ConversationTranscript/types';
import { formatCSAT, formatTimestamp } from '../helpers';
import useGetCSATColor from '../hooks/useGetCSATColor';
import useIsCSATEnabled from '../hooks/useIsCSATEnabled';
import useIsDeflectionInsightsEnabled from '../hooks/useIsDeflectionInsightsEnabled';
import { UpdateIntentDialog } from '../UpdateIntentDialog';
import { ArticlesSection } from './ArticlesSection';
import { ChatEventsSection } from './ChatEventsSection';
import { ContextVariables } from './ContextVariables';
import { DeflectionInsightsData } from './DeflectionInsightData';
import { InlineData } from './InlineData';
import { formatTimeSpent } from './utils';
import { Conversation } from 'src/services/apiInterfaces';

// revamped transcript requires archive components that are added after this date, hence
// only show revamped transcript if the start datetime of the conversation is greater then this date
const REVAMPED_TRANSCRIPT_START_DATETIME = new Date('2025-01-09T00:00:00.000Z');

export const RevampDrawerBody = ({
  selectedConversation,
}: {
  selectedConversation: Conversation;
}) => {
  const {
    additional_feedback: csatAdditionalFeedback,
    channel: conversationChannel,
    context_variables,
    conversation_id,
    csat_feedback,
    csat_resolve,
    csat_score,
    deflected,
    last_executed_intent_user_query,
    quick_feedback_responses,
    start_datetime,
    tagged_zendesk_ticket_source_id,
    tagged_zendesk_ticket_source_link,
    time_spent_in_widget,
    transcript,
    transcript_components,
    user_interactions,
    user_queries,
    workflow_tag,
  } = selectedConversation;

  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  const isCSATEnabled = useIsCSATEnabled();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  const csatFeedback = csat_feedback?.join(', ');

  const getCsatResolve = () => {
    if (typeof csat_resolve == 'boolean') {
      return csat_resolve ? 'Yes' : 'No';
    }
    return 'n/a';
  };

  const articleTranscriptComponents: ArticleTranscriptComponent[] =
    useMemo(() => {
      const result: ArticleTranscriptComponent[] = [];

      transcript_components?.forEach(comp => {
        if (
          isArticleCardComponent(comp) ||
          isRelatedArticlesTranscriptComponent(comp)
        ) {
          result.push(comp);
        }
      });

      return result;
    }, [transcript_components]);

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Chat Details</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='30px' py='24px'>
        {/* Info section */}
        <Box display='flex' flexDirection='column' gap='12px'>
          <InlineData label='Chat ID' value={conversation_id} />
          <InlineData label='Deflected' value={deflected ? 'True' : 'False'} />
        </Box>
        {/* Live chat id section */}
        {tagged_zendesk_ticket_source_id &&
          tagged_zendesk_ticket_source_link && (
            <Box display='flex' flexDirection='column' gap='2px'>
              <Typography variant='font11'>
                Live Chat ID (ticket created to speak with a live agent)
              </Typography>
              <Typography
                color={palette.colors.grey[600]}
                variant='font12Medium'
              >
                <a
                  href={tagged_zendesk_ticket_source_link}
                  rel='noreferrer'
                  target='_blank'
                >
                  <Box alignItems='center' display='flex' gap='2px'>
                    {tagged_zendesk_ticket_source_id}
                    <IconExternalLink size={14} />
                  </Box>
                </a>
              </Typography>
            </Box>
          )}
        {/* Chat section */}
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Chat</Typography>
          <Card>
            <CardSection>
              <InlineData
                label='Time created'
                value={formatTimestamp(start_datetime + 'Z')}
              />
              <InlineData
                label='Time spent'
                value={formatTimeSpent(time_spent_in_widget)}
              />
              <InlineData label='Channel' value={conversationChannel} />
              {workflow_tag && (
                <InlineData
                  label='Workflow Tag'
                  value={
                    <Box
                      sx={{
                        alignItems: 'center',
                        bgcolor: palette.colors.slate[100],
                        borderRadius: '4px',
                        color: palette.colors.grey[800],
                        display: 'flex',
                        px: 1,
                        py: 0.5,
                      }}
                    >
                      <IconTag color={palette.colors.blue[500]} size={16} />
                      <Typography
                        color={palette.colors.black}
                        noWrap
                        variant='font12'
                      >
                        {workflow_tag}
                      </Typography>
                    </Box>
                  }
                />
              )}
              <InlineData
                label='Deflected'
                value={deflected ? 'True' : 'False'}
              />
              <InlineData
                label='# of user interactions'
                value={user_interactions}
              />
              {isCSATEnabled && (
                <InlineData
                  color={getCSATColor(csat_score)}
                  label='CSAT'
                  value={formatCSAT(csat_score)}
                />
              )}
            </CardSection>

            <Divider />
            {context_variables && (
              <ContextVariables contextVariables={context_variables} />
            )}

            <Divider />
            <CardSection>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                User queries
              </Typography>
              {user_queries ? (
                <Box display='flex' flexDirection='column'>
                  {user_queries.map((query, index) => (
                    <i key={index}>
                      <Typography
                        color={palette.colors.blue[600]}
                        variant='font14'
                      >
                        {query}
                      </Typography>
                    </i>
                  ))}
                </Box>
              ) : (
                <i>
                  <Typography color={palette.colors.blue[600]} variant='font14'>
                    &quot;{last_executed_intent_user_query}&quot;
                  </Typography>
                </i>
              )}
            </CardSection>

            {transcript_components && (
              <>
                <Divider />
                {
                  <ChatEventsSection
                    transcriptComponents={transcript_components}
                  />
                }
              </>
            )}

            {isCSATEnabled && (
              <>
                <Divider />
                <CardSection>
                  <InlineData
                    color={getCSATColor(csat_score)}
                    label='CSAT'
                    value={formatCSAT(csat_score)}
                  />
                  <InlineData
                    label='Reasons for rating'
                    value={
                      <i>
                        <Typography
                          color={
                            !!csatFeedback
                              ? palette.colors.blue[600]
                              : palette.colors.grey[600]
                          }
                          variant='font12'
                        >
                          {csatFeedback || NA}
                        </Typography>
                      </i>
                    }
                  />
                  <InlineData
                    label='Additional Feedback'
                    value={
                      <i>
                        <Typography
                          color={
                            !!csatAdditionalFeedback
                              ? palette.colors.blue[600]
                              : palette.colors.grey[600]
                          }
                          variant='font12'
                        >
                          {csatAdditionalFeedback || NA}
                        </Typography>
                      </i>
                    }
                  />
                  <InlineData
                    label='Resolution Confirmation'
                    value={getCsatResolve()}
                  />
                </CardSection>
              </>
            )}
            {quick_feedback_responses &&
              quick_feedback_responses.length > 0 && (
                <CardSection>
                  <ArticlesSection
                    quickFeedbackResponses={quick_feedback_responses}
                    transcriptComponents={articleTranscriptComponents}
                  />
                </CardSection>
              )}

            {isDeflectionInsightsEnabled && (
              <CardSection>
                <DeflectionInsightsData
                  selectedConversation={selectedConversation}
                />
              </CardSection>
            )}

            {
              <CardSection gap='16px'>
                <TextDivider
                  size='small'
                  text='Reassign to new or existing intent'
                />
                <UpdateIntentDialog
                  userQuery={
                    user_queries && user_queries.length > 0
                      ? user_queries[0]
                      : last_executed_intent_user_query || ''
                  }
                />
              </CardSection>
            }
          </Card>
        </Box>
        {transcript_components &&
        new Date(start_datetime) > REVAMPED_TRANSCRIPT_START_DATETIME ? (
          <RevampConversationTranscript
            transcriptComponents={transcript_components}
          />
        ) : (
          <Box display='flex' flexDirection='column' gap='10px'>
            <Typography variant='font11'>Transcript</Typography>
            <TranscriptContainer>
              <ConversationTranscript
                transcript={transcript}
                variant='drawer'
              />
            </TranscriptContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
`;

const CardSection = styled(Box)<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${props => props.gap && `gap: ${props.gap}`}
`;
