import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CVItem } from '../../../../autonomous-agent/action-drawer/CVItem';
import { ToolItem } from '../../../../autonomous-agent/action-drawer/ToolItem';
import { GenericToolList } from '../../../../autonomous-agent/components/GenericToolList';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { EditSearchHelpCenterArticlesForm } from 'src/pages/workflow-builder-edit/autonomous-agent/edit-action-drawer/EditSearchHelpCenterArticlesForm';
import {
  useAddToolDefinitionMutation,
  useGetAvailableToolsQuery,
  useUpdateToolConfigurationFieldsMutation,
} from 'src/services/email-autopilot/emailAutopilotApi';
import {
  useContextVariableToggle,
  useToolDefinitionToggle,
} from 'src/services/email-autopilot/hooks';
import { AutopilotWorkflowModel } from 'src/services/email-autopilot/types';
import { isToolSelected } from 'src/services/email-autopilot/utils';
import { setGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

const useGetCVS = (
  contextVariablesInUse: string[],
): (ContextVariable & { isActive: boolean })[] => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeDynamicListContextVariables: false,
    shouldIncludeSystemContextVariables: false,
    shouldIncludeWorkflowTagContextVariable: false,
  });

  const cvs = useMemo(() => {
    // filter out conversation language
    const filteredWorkflowCvs = contextVariables.filter(
      cv => cv.context_variable_name !== 'Conversation Language',
    );
    return filteredWorkflowCvs.map(workflowCv => ({
      ...workflowCv,
      isActive: Boolean(
        contextVariablesInUse?.find(
          cvInUse => cvInUse === workflowCv.context_variable_id,
        ),
      ),
    }));
  }, [contextVariables, contextVariablesInUse]);

  return cvs;
};

interface AutopilotActionDrawerProps {
  autopilotWorkflow: AutopilotWorkflowModel;
  contextVariablesInUse: string[];
  editToolId: string;
  setEditToolId: (toolId: string) => void;
}

export const AutopilotActionDrawer = ({
  autopilotWorkflow,
  contextVariablesInUse,
  editToolId,
  setEditToolId,
}: AutopilotActionDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: tools = [] } = useGetAvailableToolsQuery();
  const contextVariables = useGetCVS(contextVariablesInUse);
  const [searchActionsText, setSearchActionsText] = useState('');
  const [searchCvsText, setSearchCvsText] = useState('');

  const toggleTool = useToolDefinitionToggle(autopilotWorkflow);
  const toggleCv = useContextVariableToggle(autopilotWorkflow);
  const [addToolDefinition, { isLoading: isAddingToolDefinition }] =
    useAddToolDefinitionMutation();
  const [
    updateToolConfigurationFields,
    { isLoading: isUpdatingToolConfigurationFields },
  ] = useUpdateToolConfigurationFieldsMutation();

  const filteredTools = useMemo(() => {
    const toolsWithIsActive = tools.map(tool => ({
      ...tool,
      isActive: isToolSelected(autopilotWorkflow, tool.tool_id),
    }));

    return toolsWithIsActive
      .filter(tool =>
        tool.tool_name.toLowerCase().includes(searchActionsText.toLowerCase()),
      )
      .sort((a, b) => {
        // Sort active tools to the top
        if (a.isActive && !b.isActive) {
          return -1;
        }
        if (!a.isActive && b.isActive) {
          return 1;
        }
        return 0;
      });
  }, [tools, searchActionsText, autopilotWorkflow]);

  // Filter context variables based on search
  const filteredCvs = contextVariables
    .filter(cv =>
      cv.context_variable_name
        .toLowerCase()
        .includes(searchCvsText.toLowerCase()),
    )
    .sort((a, b) => {
      // Sort active CVs to the top
      if (a.isActive && !b.isActive) {
        return -1;
      }
      if (!a.isActive && b.isActive) {
        return 1;
      }
      return 0;
    });

  const handleToolClick = (tool: AutoflowTool & { isActive: boolean }) => {
    if (tool.tool_type === 'system_built_in' && !tool.isActive) {
      setEditToolId(tool.tool_id);
      return;
    }

    toggleTool(tool.tool_id, tool.tool_type, tool.isActive);
  };

  const handleCvClick = (cv: ContextVariable & { isActive: boolean }) => {
    toggleCv(cv.context_variable_id, cv.isActive);
  };

  if (editToolId) {
    const editTool = tools.find(tool => tool.tool_id === editToolId);
    const existingTool = autopilotWorkflow.tool_definitions.find(
      tool => tool.tool_id === editToolId,
    );
    return (
      <EditSearchHelpCenterArticlesForm
        initialArticleSources={existingTool?.tool_fields?.article_sources}
        isLoading={isAddingToolDefinition || isUpdatingToolConfigurationFields}
        onClose={() => setEditToolId('')}
        onSave={async articleSources => {
          if (!existingTool) {
            await addToolDefinition({
              autopilotWorkflow,
              request: {
                tool_id: editToolId,
                tool_type: 'system_built_in',
              },
            }).unwrap();
          }
          await updateToolConfigurationFields({
            autopilotWorkflowId: autopilotWorkflow.autopilot_workflow_id,
            configurationFields: { article_sources: articleSources },
            toolId: editToolId,
          }).unwrap();
          setEditToolId('');
        }}
        tool={editTool}
      />
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <GenericToolList
        actionButton={
          <Button
            fullWidth
            onClick={() => {
              navigate(Routes.ACTION_BUILDER_EDIT_V2);
            }}
            startIcon={<IconPlus />}
            variant='secondary'
          >
            <Typography variant='font14Bold'>Create new Action</Typography>
          </Button>
        }
        description='Select actions to apply'
        idKey='tool_id'
        items={filteredTools}
        onSearchChange={setSearchActionsText}
        renderItem={tool => (
          <ToolItem
            key={tool.tool_id}
            onClickTool={handleToolClick}
            setEditToolId={setEditToolId}
            tool={tool}
          />
        )}
        searchValue={searchActionsText}
        title='Actions'
      />

      <GenericToolList
        actionButton={
          <Button
            fullWidth
            onClick={() => {
              dispatch(
                setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
              );
            }}
            startIcon={<IconPlus />}
            variant='secondary'
          >
            <Typography variant='font14Bold'>Create new CV</Typography>
          </Button>
        }
        description='Select context variables to apply'
        idKey='context_variable_id'
        items={filteredCvs}
        onSearchChange={setSearchCvsText}
        renderItem={cv => (
          <CVItem
            cv={cv}
            key={cv.context_variable_id}
            onClick={() => handleCvClick(cv)}
          />
        )}
        searchValue={searchCvsText}
        title='Context Variables'
      />
    </Box>
  );
};

export default AutopilotActionDrawer;
