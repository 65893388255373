import { useCallback, useMemo } from 'react';

import { InsightsTab } from '../types';
import { useGetIsDiscoverEnabledInInsights } from './useGetIsDiscoverEnabledInInsights';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export default function useTabs() {
  // Topic is powered by discover product or WIP or FF (Demo for customer)
  const isDiscoverEnabled = useGetIsDiscoverEnabledInInsights();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();

  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isWorkflowEnabled = featureFlags.includes('solve_insights_workflow');
  const isOverviewEnabled = featureFlags.includes('solve_insights_overview');
  const isChatInsightsEnabled = featureFlags.includes('chat_insights');

  const availableTabs = useMemo(() => {
    // Tab needs to be in the array in certain order
    const tabs = [];
    if (isOverviewEnabled) {
      tabs.push('Overview');
    }
    tabs.push('Articles');
    if (isChatInsightsEnabled) {
      tabs.push('Chats');
    }
    if (isWorkflowEnabled) {
      tabs.push('Workflows');
    }
    if (isDiscoverEnabled) {
      tabs.push('Topics');
    }
    return tabs;
  }, [
    isOverviewEnabled,
    isDiscoverEnabled,
    isChatInsightsEnabled,
    isWorkflowEnabled,
  ]);

  const tabTitleToIndex = useCallback(
    (tabTitle: string) =>
      availableTabs.findIndex(availableTab => availableTab === tabTitle),
    [availableTabs],
  );

  const tabIdToIndexMap = useMemo(
    () =>
      ({
        article: tabTitleToIndex('Articles'),
        chat: tabTitleToIndex('Chats'),
        overview: tabTitleToIndex('Overview'),
        topic: tabTitleToIndex('Topics'),
        workflow: tabTitleToIndex('Workflows'),
      } as const satisfies Record<InsightsTab, number>),
    [tabTitleToIndex],
  );

  return {
    availableTabs,
    tabIdToIndexMap,
  } as const;
}
