import { useMemo } from 'react';
import { MRT_Row, MRT_RowData, MRT_TableOptions } from 'material-react-table';
import Box, { BoxProps } from '@mui/material/Box';

import { InfiniteTable } from '@forethought-technologies/forethought-elements';
import TableDownloadButton from '../dashboard-pages/common/table-download-button';
import { createTableColumns } from './createTableColumns';
import { createInitialState } from './helpers';
import TableFilters, { DateRangeFilter } from './TableFilters';
import { TableColumnItem } from './types';

export type DashboardTableProps<T> = {
  actionColumnSize?: number;
  actionColumnTitle?: string;
  columns: TableColumnItem<T>[];
  data: T[];
  dateRangeFilter?: DateRangeFilter;
  downloadExportType?: string;
  downloadRequestData?: Record<string, MRT_RowData[string]>;
  FilterComponent?: React.ReactNode;
  hasNextPage?: boolean;
  headerControlContainerStyles?: BoxProps['sx'];
  HeaderControlOptions?: React.ReactNode;
  initialSorting?: { desc: boolean; id: string }[];
  isError?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  manualSorting?: boolean;
  onClick?: (row: MRT_Row<T & MRT_RowData>) => void;
  onLoadMore?: () => void;
  onSearch?: (text: string) => void;
  onSearchTypeChange?: (value: string) => void;
  RowAction?: React.ComponentType<{
    index?: number;
    row: MRT_Row<T & MRT_RowData>;
  }>;
  searchDisabled?: boolean;
  searchFields?: string[];
  searchOptions?: { text: string; value: string }[];
  searchPlaceholderText?: string;
  searchText?: string;
  searchType?: string;
  stickyHeaderHeight?: number;
  tableOptions?: Omit<MRT_TableOptions<T & MRT_RowData>, 'columns' | 'data'>;
  topToolbarCustomActionsStyles?: BoxProps['sx'];
};

const DashboardTable = <T extends MRT_RowData>({
  actionColumnSize = 120,
  actionColumnTitle,
  columns,
  data,
  dateRangeFilter,
  downloadExportType,
  downloadRequestData,
  FilterComponent,
  hasNextPage = false,
  headerControlContainerStyles,
  HeaderControlOptions,
  initialSorting = [{ desc: true, id: 'id' }],
  isError = false,
  isFetching = false,
  isLoading = false,
  manualSorting = false,
  onClick,
  onLoadMore,
  onSearch,
  onSearchTypeChange,
  RowAction,
  searchDisabled,
  searchFields,
  searchOptions,
  searchPlaceholderText,
  searchText,
  searchType,
  stickyHeaderHeight = 69,
  tableOptions,
  topToolbarCustomActionsStyles,
}: DashboardTableProps<T>) => {
  const tableColumns = useMemo(() => createTableColumns<T>(columns), [columns]);
  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    if (searchFields?.length) {
      return data.filter(item => {
        const text = searchText.toLowerCase();
        return searchFields.find(field => {
          const value = String(item[field]).toLowerCase();
          return value.includes(text);
        });
      });
    }
    return data;
  }, [data, searchText, searchFields]);
  const hasDownload = Boolean(downloadExportType);
  const initialState = createInitialState<T>(tableOptions?.initialState, {
    RowAction,
  });
  const enableRowActions = Boolean(RowAction);

  return (
    <InfiniteTable
      hasNextPage={hasNextPage}
      initialSorting={initialSorting}
      isError={isError}
      isLoadingFirstPage={isLoading}
      isLoadingNextPage={isFetching}
      onLoadMore={onLoadMore}
      stickyHeaderHeight={stickyHeaderHeight}
      tableOptions={{
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: actionColumnTitle,
            muiTableBodyCellProps: {
              align: 'center',
            },
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: actionColumnSize,
          },
        },
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions,
        manualSorting,
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => {
            onClick?.(row);
          },
          sx: {
            cursor: Boolean(onClick) ? 'pointer' : undefined,
          },
        }),
        ...tableOptions,
        columns: tableColumns,
        data: rows,
        initialState,
        renderRowActions: ({ row, staticRowIndex }) => {
          if (!RowAction) {
            return null;
          }
          return <RowAction index={staticRowIndex} row={row} />;
        },
        renderTopToolbarCustomActions: () => (
          <Box
            alignItems='center'
            display='flex'
            height='40px'
            justifyContent='space-between'
            sx={topToolbarCustomActionsStyles}
            width='100%'
          >
            <TableFilters
              dateRangeFilter={dateRangeFilter}
              FilterComponent={FilterComponent}
              isLoading={isLoading || isFetching}
              onSearch={onSearch}
              onSearchTypeChange={onSearchTypeChange}
              placeholder={searchPlaceholderText}
              searchDisabled={searchDisabled}
              searchOptions={searchOptions}
              searchText={searchText}
              searchType={searchType}
            />
            <Box
              alignItems='center'
              columnGap={1}
              display='flex'
              sx={headerControlContainerStyles}
            >
              {hasDownload && (
                <TableDownloadButton
                  data_export_type={downloadExportType || ''}
                  disabled={isError || !rows.length}
                  requestData={downloadRequestData || {}}
                />
              )}
              {HeaderControlOptions}
            </Box>
          </Box>
        ),
      }}
    />
  );
};

export default DashboardTable;
