import React from 'react';
import { Box } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import StepIcon from '../StepIcon';
import {
  DraggableFlowItem,
  DraggableFlowItemProps,
} from 'src/components/forethought-flow';

interface DraggableListItemProps<T>
  extends Pick<
    DraggableFlowItemProps<T>,
    'componentMetadata' | 'componentType'
  > {
  badges?: React.ReactNode;
  children?: React.ReactNode;
  contentRight?: React.ReactNode;
  description?: React.ReactNode;
  onTitleClick?: () => void;
  title: React.ReactNode;
}

export default function DraggableListItem<T>({
  badges,
  children,
  componentMetadata,
  componentType,
  contentRight,
  description,
  onTitleClick,
  title,
}: DraggableListItemProps<T>) {
  const titleComponent = onTitleClick ? (
    <Box
      component='span'
      onClick={() => onTitleClick()}
      sx={{
        '&:hover': {
          textDecoration: 'underline',
        },
        cursor: 'pointer',
      }}
    >
      <Typography variant='font14'>{title}</Typography>
    </Box>
  ) : (
    <Typography variant='font14'>{title}</Typography>
  );

  if (badges) {
    return (
      <DraggableFlowItem
        componentMetadata={componentMetadata}
        componentType={componentType}
      >
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          marginBottom='8px'
        >
          <Box alignItems='center' display='flex' gap='12px'>
            <StepIcon nodeType={componentType} />
          </Box>
          <Box>{badges}</Box>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>{titleComponent}</Box>
          <Box display='flex'>{contentRight}</Box>
        </Box>
      </DraggableFlowItem>
    );
  }

  return (
    <DraggableFlowItem
      componentMetadata={componentMetadata}
      componentType={componentType}
    >
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Box alignItems='center' display='flex' gap='12px'>
          <StepIcon nodeType={componentType} />
          {titleComponent}
        </Box>
        <Box display='flex'>{contentRight}</Box>
      </Box>
      {description && <Box ml={5.5}>{description}</Box>}
      {children && <Box ml={5.5}>{children}</Box>}
    </DraggableFlowItem>
  );
}
