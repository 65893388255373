import { useNavigate } from 'react-router-dom';

import { Button } from '@forethought-technologies/forethought-elements';
import { getTriageModelsPageUrl } from '../../triage-models-overview-page/helpers';
import { INSIGHT_MODEL_PARAMS_NAME, TAB_PARAMS_NAME } from '../constants';
import { MODEL_TABLE_COLUMNS } from './constants';
import ModelSelect, { ModelSelectProps } from './ModelSelect';
import DashboardTable from 'src/components/dashboard-table';
import { useStateParams } from 'src/hooks/hooks';
import {
  TriageInsightsLabel,
  TriageInsightsModelOverviewResponse,
} from 'src/services/triage/types';
import { DateRange } from 'src/types/types';
import { Routes } from 'src/utils/enums';

interface TableSectionProps extends ModelSelectProps {
  dataFilter: {
    end: number;
    model_name: string;
    start: number;
  };
  isFetching: boolean;
  isLoading: boolean;
  labels: TriageInsightsModelOverviewResponse['labels'];
  onDateRangeChange: (dateRange: DateRange) => void;
  selectedDateRange: DateRange;
  selectedModelName: string;
}

const TableSection = ({
  dataFilter,
  isFetching,
  isLoading,
  labels,
  models,
  modelsLoading,
  onDateRangeChange,
  onModelChange,
  selectedDateRange,
  selectedModel,
  selectedModelName,
}: TableSectionProps) => {
  const [searchText, setSearchText] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: 'insight-model-search-text',
    serialize: value => value,
  });
  const navigate = useNavigate();

  const handleViewModel = (event: React.MouseEvent, tagId: string | null) => {
    event.stopPropagation();
    const model = models.find(item => item.model_name === selectedModel);
    if (model) {
      const targetUrl = `${getTriageModelsPageUrl(
        model.model_id || '',
      )}?navigation=triage-insights&target-label=${tagId || ''}`;
      navigate(targetUrl);
    }
  };

  const handleRowClick = () => {
    const targetUrl = `${Routes.TRIAGE_INSIGHTS}?${INSIGHT_MODEL_PARAMS_NAME}=${selectedModelName}&${TAB_PARAMS_NAME}=1`;
    navigate(targetUrl);
  };

  return (
    <DashboardTable<TriageInsightsLabel>
      actionColumnSize={160}
      columns={MODEL_TABLE_COLUMNS}
      data={labels || []}
      dateRangeFilter={{
        initialValue: selectedDateRange,
        onChange: onDateRangeChange,
        value: selectedDateRange,
      }}
      downloadExportType='triage_insight_labels_table'
      downloadRequestData={dataFilter}
      FilterComponent={
        <ModelSelect
          isTableFilter
          models={models}
          modelsLoading={modelsLoading}
          onModelChange={onModelChange}
          selectedModel={selectedModel}
        />
      }
      initialSorting={[{ desc: true, id: 'name' }]}
      isFetching={isFetching}
      isLoading={isLoading}
      onClick={handleRowClick}
      onSearch={setSearchText}
      RowAction={({ row }) => {
        return (
          <Button
            onClick={event => handleViewModel(event, row.original.tag_id)}
            variant='ghost'
          >
            View model
          </Button>
        );
      }}
      searchFields={['name', 'description']}
      searchText={searchText}
      stickyHeaderHeight={249}
    />
  );
};

export default TableSection;
