/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useEffect, useMemo, useState } from 'react';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';
import {
  IconFilterPlus,
  IconInfoCircle,
  IconPlus,
  IconTag,
} from '@tabler/icons-react';

import {
  Button,
  Colors,
  EllipsisWithTooltip,
  getDefaultMRTOptions,
  MultiSelectFilter,
  SearchBar,
  Table,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DeleteWorkflowDialog } from '../DeleteWorkflowDialog';
import { WorkflowDropdownMenu } from '../DropdownMenu';
import IntentWorkflowToggle from '../intent-workflow-toggle';
import { useGetIntentFilterQuery } from './hooks/useGetIntentFilterQuery';
import { useIntents } from './hooks/useIntents';
import { useSolveMetricsQueryParams } from './hooks/useSolveMetricsQueryParams';
import { BadgesForWorkflow } from './BadgesForWorkflow';
import {
  INTENT_TABLE_HEADER_PROPERTIES,
  WORKFLOW_BUILDER_QUERY_PARAM_KEY,
} from './constants';
import { CreateIntentDropdownMenu } from './CreateIntentDropdownMenu';
import {
  filterCommonIntentWorkflowsToTop,
  getIsDefaultByChannel,
  getWorkflowIdByChannel,
  getWorkflowType,
  IntentTableDataFilter,
} from './helper';
import { IntentDataWithTopics, IntentWorkflowsTableProps } from './types';
import _orderBy from 'lodash/fp/orderBy';
import uniq from 'lodash/fp/uniq';
import { duplicateIntentWorkflow } from 'src/actions/workflow-builder/workflowBuilderActions';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import dynamicArticleSuggestionIcon from 'src/assets/images/dynamic-article-suggestion-revamped.svg';
import HandoffIcon from 'src/assets/images/handoff.svg';
import SearchNotFoundIcon from 'src/assets/images/table-search-not-found.svg';
import ActivateWorkflowModal from 'src/components/activate-workflow-modal/ActivateWorkflowModal';
import {
  useFlamethrowerTrackingEventAction,
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useIsWorkflowUsedByWidgetConfiguration,
} from 'src/hooks/hooks';
import { useGetHasOtherQuestions } from 'src/hooks/solve';
import { TOTAL_STICKY_HEADERS_HEIGHT } from 'src/pages/intent-conversation-analytics/constants';
import { viewToWorkflowTypes } from 'src/pages/workflow-builder-edit/constants';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import { getIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { getWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import {
  openGlobalIntentDrawer,
  setGlobalHandoffDrawerOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  IntentData,
  TranslationChannel,
} from 'src/types/workflowBuilderAPITypes';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import {
  CommonIntentWorkflowType,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  Routes,
} from 'src/utils/enums';
import {
  handleRemoveOnQueryParam,
  handleUpdateOnQueryParam,
} from 'src/utils/reactRouterUtils';
import {
  getWorkflowActivationCopy,
  isIntentActiveForChannel,
  isIntentDuplicable,
  isIntentEditable,
  isOnlyInteractiveEmail,
} from 'src/utils/solve/intentUtils';

/**
 * Load intents and display additional data columns for selected channel
 */
const IntentWorkflowsTable = ({
  channelColumns = [],
  channelMaterialReactTableColumns = [],
  dateFilter,
  getRowByWorkflowId,
  onEditButtonClick,
  onNewCustomIntentButtonClick,
  setWorkflowTagFilterValue,
  showMinPhrasesDialog,
  tableFilterCallback,
  workflowTagFilterValue = [],
}: IntentWorkflowsTableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const theme = useTheme();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER,
  );

  const {
    channel,
    from: startDate,
    to: endDate,
  } = useSolveMetricsQueryParams();

  const filters = useMemo(() => {
    const filterString = params.get('filters') || '';
    return filterString.split(',').filter(Boolean);
  }, [params]);

  const handleFilterChange = (newFilters: string[]) => {
    const filterString = newFilters.join(',');
    handleUpdateOnQueryParam(navigate, {
      property: 'filters',
      value: filterString,
    });
  };

  const order = params.get('order');
  const orderBy = params.get('orderBy');
  const isEmailChannel = channel === 'email';

  const { intents, loading } = useIntents();
  const { refetch: refetchIntents } = useGetIntentsQueryWithProduct();
  const { refetch: refetchHandoffConfigurations } =
    useGetHandoffConfigurationsQuery();
  const isWorkflowUsedByWidgetConfig = useIsWorkflowUsedByWidgetConfiguration();

  const workflowTags = useGetWorkflowTags();

  const userCanDeleteAction = useSelector(
    selectUserCan('delete_custom_intent'),
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  // Hooks
  const { setUiQuery, uiQuery } = useGetIntentFilterQuery();

  const [toggledIntentId, setToggledIntentId] = useState('');
  const [selectedWorkflowId, setSelectedWorkflowId] = useState('');

  const isAutoflowEnabled = featureFlags.includes('autonomous_agent_enabled');
  const isNewTableComponentEnabled = featureFlags.includes(
    'new_table_component',
  );
  const isFallbackWorkflowEnabled = featureFlags.includes('fallback_workflow');

  const rows = useMemo(() => {
    if (!intents) {
      return [];
    }

    const filteredByChannel = intents.filter(intent =>
      intent.channels.includes(channel),
    );
    const filter = new IntentTableDataFilter(filteredByChannel);
    const filteredTable = filter
      .setIsOtherQuestionsActive(channel)
      .setDefaultHandoffWorkflowActive(channel)
      .filterByKeyword(uiQuery);

    const tableData = tableFilterCallback
      ? tableFilterCallback(filteredTable)
      : filteredTable.getTableData();

    const tableDataWithDefaultSorting = !orderBy
      ? _orderBy(
          [item => item.inquiry_count_per_channel[channel]],
          ['desc', 'desc'],
          tableData,
        )
      : tableData;

    // always show knowledge retrieval and handoff workflows on top
    return filterCommonIntentWorkflowsToTop(tableDataWithDefaultSorting);
  }, [channel, intents, orderBy, uiQuery, tableFilterCallback]);

  const uniqueTopics = useMemo(
    () =>
      uniq(
        rows
          .flatMap(row => row.topics)
          .map(topic => ({
            label: deriveTopicNameFromTopic(topic),
            value: topic.topic_id,
          })),
      ),
    [rows],
  );

  const filterOptions = useMemo(
    () => [
      {
        label: 'Activate',
        options: [
          {
            label: 'On',
            value: 'activate.on',
          },
          {
            label: 'Off',
            value: 'activate.off',
          },
        ],
        value: 'activate',
      },
      {
        label: 'Types',
        options: [
          {
            label: 'AutoFlow',
            value: 'types.autoflow',
          },
          {
            label: 'Classic',
            value: 'types.classic',
          },
          {
            label: 'Handoff',
            value: 'types.handoff',
          },
          {
            label: 'Fallback',
            value: 'types.fallback',
          },
          {
            label: 'Knowledge Retrieval',
            value: 'types.knowledge_retrieval',
          },
        ],
        value: 'types',
      },
      ...(uniqueTopics.length > 0
        ? [
            {
              label: 'Topics',
              options: uniqueTopics,
              value: 'topics',
            },
          ]
        : []),
    ],
    [uniqueTopics],
  );

  const filteredRows = useMemo(
    () =>
      rows.filter(row => {
        const type = getWorkflowType(row, channel, isFallbackWorkflowEnabled);

        const isActive = isIntentActiveForChannel(
          row.active_workflow_types,
          channel,
        );

        const hasActivateFilters = filters.some(filter =>
          filter.startsWith('activate.'),
        );

        const hasTypeFilters = filters.some(filter =>
          filter.startsWith('types.'),
        );

        const hasTopicFilters =
          filters.filter(
            filter =>
              !filter.startsWith('activate.') && !filter.startsWith('types.'),
          ).length > 0;

        const isActivateFilterMatch =
          (filters.includes('activate.on') && isActive) ||
          (filters.includes('activate.off') && !isActive);

        const isTypeFilterMatch =
          (filters.includes('types.autoflow') && type === 'AutoFlow') ||
          (filters.includes('types.classic') && type === 'Classic') ||
          (filters.includes('types.handoff') && type === 'Handoff') ||
          (filters.includes('types.fallback') && type === 'Fallback') ||
          (filters.includes('types.knowledge_retrieval') &&
            type === 'Knowledge Retrieval');

        const isTopicFilterMatch = row.topics.some(({ topic_id }) =>
          filters.includes(topic_id),
        );

        return (
          (!hasActivateFilters || isActivateFilterMatch) &&
          (!hasTypeFilters || isTypeFilterMatch) &&
          (!hasTopicFilters || isTopicFilterMatch)
        );
      }),
    [channel, filters, isFallbackWorkflowEnabled, rows],
  );

  // handlers
  const onWorkflowClicked = ({
    apiWorkflowId,
    intentId,
    interactiveEmailWorkflowId,
    isActive,
    isAutoflow,
    isAutoflowEnabled,
    isHandoff,
    isKnowledge,
    slackWorkflowId,
    workflowId,
  }: {
    apiWorkflowId: string;
    apiWorkflowIsAutoflow: boolean;
    intentId: string;
    interactiveEmailWorkflowId: string | null;
    isActive: boolean;
    isAutoflow: boolean;
    isAutoflowEnabled: boolean;
    isHandoff: boolean;
    isKnowledge: boolean;
    slackWorkflowId: string;
    workflowId: string;
  }) => {
    const buildSearch = () => {
      const willUseInteractiveEmailWorkflowId =
        interactiveEmailWorkflowId &&
        channel === 'email' &&
        isOnlyInteractiveEmail(intentId);

      let workflowIdToUse = workflowId;
      if (willUseInteractiveEmailWorkflowId) {
        workflowIdToUse = interactiveEmailWorkflowId;
      } else if (apiWorkflowId && channel === 'api') {
        workflowIdToUse = apiWorkflowId;
      } else if (slackWorkflowId && channel === 'slack') {
        workflowIdToUse = slackWorkflowId;
      }

      let search = `workflowId=${workflowIdToUse}&intentId=${intentId}&start=${startDate}&end=${endDate}&view=${channel}`;

      /**
       * Show autoflow
       * 1. isAutoflow: true
       * 2. !isActive meaning not activated
       *    - If published but not active (isAutoflow value is truth)
       *    - If not published (autoflow/classic having draft - show autoflow) - business logic
       * 3. autonomous_agent_enabled feature flag: true
       * Note - Email ignores the mode key from URL
       */
      const isGeneralHandoff = intentId === 'general-handoff';
      if (willUseInteractiveEmailWorkflowId) {
        search = search + '&emailMode=Interactive';
      }

      const isAutoflowInCurrentChannel = isAutoflow;

      if (
        (isAutoflowInCurrentChannel ||
          (!isActive &&
            !isHandoff &&
            !isEmailChannel &&
            !isKnowledge &&
            !isGeneralHandoff)) &&
        isAutoflowEnabled
      ) {
        search = search + '&mode=autoflow';
      } else {
        search = search + '&mode=classic';
      }

      return search;
    };

    dispatchTrackingAction(
      FlamethrowerTrackingEventTypes.WORKFLOW_EDITING_BEGAN,
      { worfklow_id: workflowId },
    );

    // Save queryParam to storage to use on back button
    window.localStorage.setItem(
      WORKFLOW_BUILDER_QUERY_PARAM_KEY,
      location.search,
    );

    navigate({
      pathname: Routes.WORKFLOW_BUILDER_EDIT,
      search: `?${buildSearch()}`,
    });
  };

  const duplicateWorkflow = async (workflowId: string, isHandoff: boolean) => {
    // duplicate the workflow
    try {
      const data = await dispatch(
        duplicateIntentWorkflow({
          workflowId,
        }),
      ).unwrap();
      // Manually make a call to refetch intent list because getIntent is not yet part of RTK Query
      refetchIntents();
      refetchHandoffConfigurations();
      const newWorkflowId = data.canvas.intent_workflow_id;
      await dispatch(getIntent(data.canvas.intent_id as string));
      navigate({
        pathname: Routes.WORKFLOW_BUILDER_EDIT,
        search: `workflowId=${newWorkflowId}&intentId=${data.canvas.intent_id}&view=${channel}`,
      });
      if (!isHandoff) {
        dispatch(openGlobalIntentDrawer({ type: channel }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onPreviewIntentClicked = (intentId: string) => {
    const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
      channel: channel,
    });
    navigate({
      pathname: previewPath,
      search: `?intentId=${intentId}${
        channel === 'widget' && '&mode=landing-page'
      }`,
    });
  };

  const onViewInsightClick = (intentId: string) => {
    const start = parseInt(startDate ?? '') * 1000;
    const end = parseInt(endDate ?? '') * 1000;
    const search = `?tableTab=1&articles_sort_column=times_surfaced&date=${start}_${end}&article_sort_direction=DESC`;
    const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
      ':workflowId',
      intentId,
    );

    navigate({
      pathname: pathName,
      search: search,
    });
  };

  const onEditHandoffClicked = async (
    workflowId: string,
    intentId: string,
    handoffName: string,
    isDefaultHandoff: boolean,
  ) => {
    dispatch(
      setGlobalHandoffDrawerOptions({
        handoffToEdit: {
          handoffName: handoffName,
          intentId: intentId,
          intentWorkflowId: workflowId,
          isDefaultHandoff: isDefaultHandoff,
        },
        isCreateHandoffDrawerOpen: true,
        isEditing: true,
      }),
    );
  };

  const columnHelper = createMRTColumnHelper<IntentDataWithTopics>();

  const materialReactTableColumns = useMemo(() => {
    return [
      columnHelper.accessor('intent_name', {
        Cell: ({ row }) => {
          const {
            active_workflow_types: activeWorkflowTypes,
            email_autopilot_enabled: emailAutopilotEnabled,
            intent_definition_id: intentDefinitionId,
            intent_name: intentName,
          } = row.original;
          const isGeneralHandoff =
            intentDefinitionId === CommonIntentWorkflowType.GENERAL_HANDOFF;
          const isKnowledge =
            intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
          const isFallback =
            intentDefinitionId === CommonIntentWorkflowType.FALLBACK;
          const isInteractive =
            isEmailChannel &&
            activeWorkflowTypes.includes('interactive-email-live');

          return (
            <IntentNameCell
              data-appcues-target={
                isGeneralHandoff
                  ? 'WorkflowBuilderTableOtherQuestionsIntent'
                  : undefined
              }
            >
              <Box
                aria-label={`${intentName} workflow cell`}
                display='flex'
                gap='8px'
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  alignSelf='center'
                  // Needed for ellipsis:
                  maxWidth={isInteractive ? '230px' : '320px'}
                >
                  <EllipsisWithTooltip>
                    <Typography
                      variant={
                        isKnowledge || isFallback ? 'font14Bold' : 'font14'
                      }
                    >
                      <Highlighter
                        autoEscape
                        highlightStyle={{
                          backgroundColor: theme.palette.colors.blue[200],
                        }}
                        searchWords={uiQuery.split(' ')}
                        textToHighlight={intentName}
                      />
                    </Typography>
                  </EllipsisWithTooltip>
                </Box>
                <BadgesForWorkflow
                  isAutopilot={emailAutopilotEnabled}
                  isDefaultHandoff={false} // false is intentional
                  isHandoff={false} // false is intentional
                  isInteractive={isInteractive}
                />
              </Box>
            </IntentNameCell>
          );
        },
        header: 'Workflow',
        size: 336,
      }),
      ...channelMaterialReactTableColumns,
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intentData = row.original;

          return (
            <IntentWorkflowToggle
              channel={channel}
              disabled={
                intentData.is_handoff ||
                isWorkflowUsedByWidgetConfig(intentData.intent_workflow_id)
              }
              intentData={intentData}
              key={intentData.intent_definition_id} // prevent toggle transition on sort
              setSelectedWorkflowId={setSelectedWorkflowId}
              showMinPhrasesDialog={showMinPhrasesDialog}
              tooltipText={
                isWorkflowUsedByWidgetConfig(intentData.intent_workflow_id)
                  ? "Can't deactivate this workflow because it is used in Quick Feedback configuration"
                  : ''
              }
            />
          );
        },
        enableSorting: false,
        header: 'Activate',
        Header: ({ column }) => (
          <HeaderCell>
            {column.columnDef.header}
            <Tooltip
              tooltipContent={
                'By activating a workflow, the workflow will be published to your customers'
              }
            >
              <IconInfoCircle color='currentColor' height={16} width={16} />
            </Tooltip>
          </HeaderCell>
        ),
        id: 'is_active',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        size: 110,
      }),
    ];
  }, [
    channel,
    channelMaterialReactTableColumns,
    columnHelper,
    isEmailChannel,
    showMinPhrasesDialog,
    theme.palette.colors.blue,
    uiQuery,
    isWorkflowUsedByWidgetConfig,
  ]);

  const defaultMRTOptions = getDefaultMRTOptions<IntentDataWithTopics>({
    theme,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>(
    orderBy && order
      ? [
          {
            desc: order === 'desc',
            id: orderBy,
          },
        ]
      : [],
  );

  useEffect(() => {
    dispatch(getWidgetConfiguration());
  }, [dispatch]);

  useEffect(() => {
    const { desc, id: property } = sorting[0] || { desc: '', id: '' };
    const order = property ? (desc ? 'desc' : 'asc') : '';

    // Clean up
    handleRemoveOnQueryParam(navigate, INTENT_TABLE_HEADER_PROPERTIES);
    // Update
    handleUpdateOnQueryParam(navigate, {
      property: 'orderBy',
      value: property,
    });
    handleUpdateOnQueryParam(navigate, {
      property: 'order',
      value: order,
    });
  }, [navigate, sorting]);

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    columns: materialReactTableColumns,
    data: filteredRows,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        // TODO: ask Design Team if we want to use the default `Actions` text.
        // Note that the default `Actions` text is misaligned vertically.
        header: '',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
        size: 50,
      },
    },
    enableColumnActions: false,
    enableExpanding: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableRowVirtualization: true,
    initialState: {
      columnPinning: {
        left: ['intent_name', 'is_active'],
        right: ['mrt-row-actions'],
      },
    },
    muiTableBodyCellProps: ({ column, row }) => {
      const { intent_definition_id: intentDefinitionId } = row.original;
      const isKnowledge =
        intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
      const isFallback =
        intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

      function getBackgroundColor() {
        if (row.getIsSelected()) {
          return theme.palette.colors.purple[100];
        }

        if (column.getIsPinned()) {
          return theme.palette.colors.blue[50];
        }

        if (isKnowledge || isFallback) {
          return '#DBBCFB12';
        }

        return 'inherit';
      }

      return {
        sx: {
          '::before, ::after': {
            backgroundColor: 'initial !important',
          },
          backgroundColor: getBackgroundColor(),
          fontWeight: row.getCanExpand() ? 'bold' : 'normal',
          opacity: 'initial',
        },
      };
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        onWorkflowClicked({
          apiWorkflowId: row.original.api_workflow_id || '',
          apiWorkflowIsAutoflow: row.original.api_workflow_is_autoflow,
          intentId: row.original.intent_definition_id,
          interactiveEmailWorkflowId:
            row.original.interactive_email_workflow_id,
          isActive: row.original.active_workflow_types.length > 0,
          isAutoflow: row.original.is_autoflow || channel === 'voice',
          isAutoflowEnabled,
          isHandoff: row.original.is_handoff,
          isKnowledge:
            row.original.intent_definition_id ===
            CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
          slackWorkflowId: row.original.slack_workflow_id || '',
          workflowId: row.original.intent_workflow_id,
        });
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: `calc(100vh - ${TOTAL_STICKY_HEADERS_HEIGHT}px)` },
    },
    onSortingChange: setSorting,
    renderRowActions: ({ row }) => {
      const {
        api_workflow_id: apiWorkflowId,
        intent_definition_id: intentDefinitionId,
        intent_name: intentName,
        intent_workflow_id: intentWorkflowId,
        is_default_handoff: isDefaultHandoff,
        is_handoff: isHandoff,
      } = row.original;

      const WorkflowIdToUse =
        (channel === 'api' ? apiWorkflowId : intentWorkflowId) || '';

      const inquiries = getRowByWorkflowId?.(row.original)?.inquiries || 0;

      return (
        <WorkflowDropdownMenu
          intentId={intentDefinitionId}
          intentName={intentName}
          isHandoff={isHandoff}
          onDeleteClicked={() => {
            setToggledIntentId(intentDefinitionId);
          }}
          onDuplicateClicked={() => {
            duplicateWorkflow(WorkflowIdToUse, isHandoff);
          }}
          onEditClicked={() => {
            isHandoff
              ? onEditHandoffClicked(
                  getWorkflowIdByChannel(channel, row.original) ?? '',
                  intentDefinitionId,
                  intentName,
                  getIsDefaultByChannel(channel, row.original),
                )
              : onEditButtonClick(intentDefinitionId);
          }}
          onPreviewClicked={() => onPreviewIntentClicked(intentDefinitionId)}
          onViewInsightClick={() => onViewInsightClick(intentWorkflowId)}
          shouldEnableDelete={
            userCanDeleteAction &&
            intentDefinitionId !== CommonIntentWorkflowType.KNOWLEDGE_ARTICLE &&
            intentDefinitionId !== CommonIntentWorkflowType.FALLBACK &&
            !isDefaultHandoff &&
            !isWorkflowUsedByWidgetConfig(intentWorkflowId)
          }
          shouldEnableViewInsight={Boolean(inquiries)}
          shouldShowChats={channel === 'widget'}
          shouldShowDuplicate={
            ['widget', 'email'].includes(channel) &&
            isIntentDuplicable(intentDefinitionId)
          }
          shouldShowEdit={isIntentEditable(intentDefinitionId)}
          shouldShowPreview={
            !isHandoff && (channel === 'widget' || channel === 'api')
          }
        />
      );
    },
    renderTopToolbarCustomActions: () => (
      <TitleContainer>
        <Box display='flex' flex={1} gap={1}>
          <Box width='250px'>
            <SearchBar
              aria-label='Intent search bar filter'
              fullWidth
              onChange={({ target }) => setUiQuery(target.value)}
              onClear={() => setUiQuery('')}
              placeholder='Search intents'
              size='small'
              value={uiQuery}
            />
          </Box>
          {dateFilter}
          {setWorkflowTagFilterValue && channel === 'widget' && (
            <MultiSelectFilter
              icon={<IconTag color={theme.palette.grey[700]} size={20} />}
              isMenuSearchable
              maxHeight={400}
              menuTitle='Tags'
              onChange={setWorkflowTagFilterValue}
              options={workflowTags.map(tag => ({ label: tag, value: tag }))}
              placeholder='All workflow tags'
              value={workflowTagFilterValue}
            />
          )}
          {channel === 'widget' && (
            <MultiSelectFilter
              icon={
                <IconFilterPlus color={theme.palette.grey[700]} size={20} />
              }
              isMenuSearchable
              maxHeight={400}
              menuTitle='Filter by'
              onChange={handleFilterChange}
              options={filterOptions}
              placeholder='Filter by'
              value={filters}
            />
          )}
        </Box>
        <TableHeaderButtons
          onNewCustomIntentButtonClick={onNewCustomIntentButtonClick}
        />
      </TitleContainer>
    ),
    rowVirtualizerOptions: { overscan: 10 },
    state: {
      isLoading: loading,
      sorting,
    },
  });

  return (
    <TableContainer>
      <DeleteWorkflowDialog
        intentIdToDelete={toggledIntentId}
        isOpen={Boolean(toggledIntentId)}
        onClose={() => setToggledIntentId('')}
      />
      <TableWrapper>
        {isNewTableComponentEnabled ? (
          <MaterialReactTable table={table} />
        ) : (
          <Table
            columns={[
              {
                cellRenderer: data => {
                  const {
                    intent_definition_id: intentDefinitionId,
                    intent_name: intentName,
                    is_autoflow: isAutoflow,
                    is_default_handoff: isDefaultHandoff,
                    is_handoff: isHandoff,
                  } = data;
                  const isGeneralHandoff =
                    intentDefinitionId ===
                    CommonIntentWorkflowType.GENERAL_HANDOFF;
                  const isKnowledge =
                    intentDefinitionId ===
                    CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;

                  const getIcon = () => {
                    if (isHandoff) {
                      return <img src={HandoffIcon} />;
                    }

                    if (isKnowledge) {
                      return <img src={dynamicArticleSuggestionIcon} />;
                    }

                    if (isAutoflow && !isEmailChannel) {
                      return (
                        <Tooltip tooltipContent='AutoFlows'>
                          <img
                            src={aaBadgeSrc}
                            style={{ height: 20, width: 20 }}
                          />
                        </Tooltip>
                      );
                    }

                    return null;
                  };

                  return (
                    <IntentNameCell
                      data-appcues-target={
                        isGeneralHandoff
                          ? 'WorkflowBuilderTableOtherQuestionsIntent'
                          : undefined
                      }
                    >
                      <Box
                        aria-label={`${intentName} workflow cell`}
                        display='flex'
                        gap='8px'
                        sx={{ cursor: 'pointer' }}
                      >
                        {getIcon()}
                        <Highlighter
                          autoEscape
                          highlightStyle={{
                            backgroundColor: theme.palette.colors.blue[200],
                          }}
                          searchWords={uiQuery.split(' ')}
                          textToHighlight={intentName}
                        />
                      </Box>
                      <BadgesForWorkflow
                        isDefaultHandoff={isDefaultHandoff ?? false}
                        isHandoff={isHandoff}
                      />
                    </IntentNameCell>
                  );
                },
                comparatorFunction: (a, b) =>
                  a.intent_name.localeCompare(b.intent_name),
                id: 'intent_name',
                label: 'Intent',
                width: '50%',
              },
              ...channelColumns,
              {
                align: 'center',
                cellRenderer: intentData => {
                  return (
                    <IntentWorkflowToggle
                      channel={channel}
                      disabled={
                        intentData.is_handoff ||
                        isWorkflowUsedByWidgetConfig(
                          intentData.intent_workflow_id,
                        )
                      }
                      intentData={intentData}
                      key={intentData.intent_definition_id} // prevent toggle transition on sort
                      setSelectedWorkflowId={setSelectedWorkflowId}
                      showMinPhrasesDialog={showMinPhrasesDialog}
                      tooltipText={
                        isWorkflowUsedByWidgetConfig(
                          intentData.intent_workflow_id,
                        )
                          ? "Can't deactivate this workflow because it is used in Quick Feedback configuration"
                          : ''
                      }
                    />
                  );
                },
                hideSortIcon: true,
                id: 'is_active',
                label: (
                  <HeaderCell>
                    Activate
                    <Tooltip
                      tooltipContent={
                        'By activating a workflow, the workflow will be published to your customers'
                      }
                    >
                      <IconInfoCircle
                        color='currentColor'
                        height={16}
                        width={16}
                      />
                    </Tooltip>
                  </HeaderCell>
                ),
              },
              {
                align: 'center',
                cellRenderer: data => {
                  const {
                    intent_definition_id: intentDefinitionId,
                    intent_name: intentName,
                    intent_workflow_id: intentWorkflowId,
                    is_default_handoff: isDefaultHandoff,
                    is_handoff: isHandoff,
                  } = data;
                  let inquiries = 0;
                  if (getRowByWorkflowId) {
                    const rowData = getRowByWorkflowId(data);
                    inquiries = rowData?.inquiries || 0;
                  }
                  return (
                    <WorkflowDropdownMenu
                      intentId={intentDefinitionId}
                      intentName={intentName}
                      isHandoff={isHandoff}
                      onDeleteClicked={() => {
                        setToggledIntentId(intentDefinitionId);
                      }}
                      onDuplicateClicked={() => {
                        duplicateWorkflow(intentWorkflowId, isHandoff);
                      }}
                      onEditClicked={() => {
                        isHandoff
                          ? onEditHandoffClicked(
                              intentWorkflowId,
                              intentDefinitionId,
                              intentName,
                              isDefaultHandoff ?? false,
                            )
                          : onEditButtonClick(intentDefinitionId);
                      }}
                      onPreviewClicked={() =>
                        onPreviewIntentClicked(intentDefinitionId)
                      }
                      onViewInsightClick={() =>
                        onViewInsightClick(intentWorkflowId)
                      }
                      shouldEnableDelete={
                        userCanDeleteAction &&
                        intentDefinitionId !==
                          CommonIntentWorkflowType.KNOWLEDGE_ARTICLE &&
                        intentDefinitionId !==
                          CommonIntentWorkflowType.FALLBACK &&
                        !isDefaultHandoff
                      }
                      shouldEnableViewInsight={Boolean(inquiries)}
                      shouldShowChats={channel === 'widget'}
                      shouldShowDuplicate={
                        channel === 'widget' &&
                        isIntentDuplicable(intentDefinitionId)
                      }
                      shouldShowEdit={isIntentEditable(intentDefinitionId)}
                      shouldShowPreview={!isHandoff && channel === 'widget'}
                    />
                  );
                },
                hideSortIcon: true,
                id: 'menu',
                label: '',
              },
            ]}
            defaultOrder={(orderBy as 'desc' | 'asc') ?? undefined}
            defaultOrderBy={order ? (order as keyof IntentData) : undefined}
            emptyStateProps={{
              children: (
                <Typography
                  color={theme.palette.colors.grey[500]}
                  variant='font14'
                >
                  No results match your search. Try to adjust your search term.
                </Typography>
              ),
              icon: <img src={SearchNotFoundIcon} />,
              title: 'No results found',
            }}
            isLoading={loading}
            onClickRow={row => {
              onWorkflowClicked({
                apiWorkflowId: row.api_workflow_id || '',
                apiWorkflowIsAutoflow: row.api_workflow_is_autoflow,
                intentId: row.intent_definition_id,
                interactiveEmailWorkflowId: row.interactive_email_workflow_id,
                isActive: row.active_workflow_types.length > 0,
                isAutoflow: row.is_autoflow || channel === 'voice',
                isAutoflowEnabled,
                isHandoff: row.is_handoff,
                isKnowledge:
                  row.intent_definition_id ===
                  CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
                slackWorkflowId: row.slack_workflow_id || '',
                workflowId: row.intent_workflow_id,
              });
            }}
            onSortCallback={(property, order) => {
              // Clean up
              handleRemoveOnQueryParam(
                navigate,
                INTENT_TABLE_HEADER_PROPERTIES,
              );
              // Update
              handleUpdateOnQueryParam(navigate, {
                property: 'orderBy',
                value: order,
              });
              handleUpdateOnQueryParam(navigate, {
                property: 'order',
                value: property,
              });
            }}
            overscanCount={10}
            rows={rows}
            shouldDisablePagination
          />
        )}
      </TableWrapper>
      <TableActivateWorkflowModal
        channel={channel}
        intents={intents}
        selectedWorkflowId={selectedWorkflowId}
        setSelectedWorkflowId={setSelectedWorkflowId}
      />
    </TableContainer>
  );
};

const TableActivateWorkflowModal = ({
  channel,
  intents,
  selectedWorkflowId,
  setSelectedWorkflowId,
}: {
  channel: TranslationChannel;
  intents: IntentDataWithTopics[];
  selectedWorkflowId: string;
  setSelectedWorkflowId: (workflowId: string) => void;
}) => {
  const hasOtherQuestions = useGetHasOtherQuestions();
  const intentData = useMemo(() => {
    if (!selectedWorkflowId) {
      return undefined;
    }

    return intents.find(
      intent => intent.intent_workflow_id === selectedWorkflowId,
    );
  }, [intents, selectedWorkflowId]);

  const intentName = intentData?.intent_name || '';

  const isKnowledgeArticle =
    intentData?.intent_definition_id ===
    CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const workflowTypes = viewToWorkflowTypes[channel];
  const isActive = isIntentActiveForChannel(
    intentData?.active_workflow_types ?? [],
    channel,
  );

  const { confirmationMessage } = getWorkflowActivationCopy({
    channel,
    hasOtherQuestions,
    intentName,
    isActive,
    isKnowledgeArticle,
  });

  return (
    <ActivateWorkflowModal
      intentWorkflowId={
        getWorkflowIdByChannel(channel, intentData ?? {}) ?? selectedWorkflowId
      }
      isActive={isActive}
      isDialogVisible={Boolean(selectedWorkflowId)}
      message={confirmationMessage}
      onClose={() => setSelectedWorkflowId('')}
      workflowTypes={workflowTypes}
    />
  );
};

const TableHeaderButtons = ({
  onNewCustomIntentButtonClick,
}: {
  onNewCustomIntentButtonClick: () => void;
}) => {
  const userCanAddCustomIntent = useSelector(
    selectUserCan('add_custom_intent'),
  );
  const userCanPreview = useSelector(selectUserCan('preview_workflows'));
  const { channel } = useSolveMetricsQueryParams();
  const navigate = useNavigate();

  return (
    <Box display='flex' gap={1}>
      {userCanPreview && (
        <>
          {['api', 'voice', 'widget'].includes(channel) && (
            <Button
              onClick={() => {
                const previewPath = generatePath(
                  Routes.WORKFLOW_BUILDER_PREVIEW,
                  {
                    channel,
                  },
                );
                navigate({
                  pathname: previewPath,
                  search:
                    channel === 'widget'
                      ? `?mode=landing-page&product=${
                          CHANNEL_TO_PRODUCT_MAP[channel || 'widget']
                        }`
                      : undefined,
                });
              }}
              variant='secondary'
            >
              Preview{' '}
              {
                { api: 'API', voice: 'Voice', widget: 'Live' }[
                  channel as string
                ]
              }
            </Button>
          )}
        </>
      )}
      {userCanAddCustomIntent && (
        <>
          {channel === 'email' ? (
            <Button
              onClick={onNewCustomIntentButtonClick}
              startIcon={<IconPlus size={16} />}
              variant={'main'}
            >
              New Intent
            </Button>
          ) : (
            <CreateIntentDropdownMenu
              onNewCustomIntentButtonClick={onNewCustomIntentButtonClick}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default IntentWorkflowsTable;

const TableContainer = styled('div')`
  background-color: ${Colors.ui.backgrounds.default};
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TableWrapper = styled('div')`
  input[type='checkbox'] {
    display: inline-block;
  }

  flex: 1;
`;

const IntentNameCell = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 300px;
`;

const HeaderCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  width: 100%;
`;
