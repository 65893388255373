import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClickAwayListener, StandardTextFieldProps } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import { IconPlus } from '@tabler/icons-react';

import {
  SearchBar,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ContextVariableIndentedMenuItems } from '../context-variable-indented-menu-items/ContextVariableIndentedMenuItems';
import { ContextVariableMenuForm } from '../context-variable-select-dropdown/ContextVariableSelectDropdown';
import { formatContextVariablesForInput } from './formatContextVariablesForInput';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useCreateContextVariableMutation } from 'src/services/action-builder/actionBuilderApi';
import {
  ContextVariable,
  DynamicListOption,
} from 'src/types/actionBuilderApiTypes';
import { extractCvIdFromCurlyBraces } from 'src/utils/cleanStr';
import {
  flattenDynamicContextVariables,
  splitDynamicContextVariableId,
} from 'src/utils/solve/dynamicContextVariableUtils';

export const CONTEXT_VARIABLE_AUTOCOMPLETE_ID = 'context-variable-autocomplete';

type Range = { start: number; stop: number };

const contextVariablesToSorted = <
  T extends (ContextVariable | DynamicListOption)[],
>(
  nonSortedContextVariables: T,
): T => {
  for (const cv of nonSortedContextVariables) {
    if (
      'configuration_fields' in cv &&
      cv.configuration_fields?.dynamic_list_config?.context_variables
    ) {
      cv.configuration_fields.dynamic_list_config.context_variables =
        contextVariablesToSorted(
          cv.configuration_fields?.dynamic_list_config?.context_variables,
        );
    }

    if ('options' in cv && cv.options?.length) {
      cv.options = contextVariablesToSorted(cv.options);
    }
  }

  return nonSortedContextVariables.sort(
    (a, b) => b.context_variable_name.length - a.context_variable_name.length,
  );
};

const formatInputForContextVariables = (
  contextVariables: ContextVariable[] | DynamicListOption[],
  value: string,
  prevCvName?: string,
) => {
  let newMessage = value;

  contextVariables.forEach(cv => {
    const cvName = prevCvName
      ? prevCvName + '.' + cv.context_variable_name
      : `$${cv.context_variable_name}`;

    if (
      'configuration_fields' in cv &&
      cv.configuration_fields?.dynamic_list_config?.context_variables
    ) {
      newMessage = formatInputForContextVariables(
        cv.configuration_fields?.dynamic_list_config?.context_variables,
        newMessage,
        cvName,
      );
      return;
    }

    if ('options' in cv && cv.options?.length) {
      newMessage = formatInputForContextVariables(
        cv.options,
        newMessage,
        cvName,
      );
      return;
    }

    newMessage = newMessage.replaceAll(cvName, `{{${cv.context_variable_id}}}`);
  });

  return newMessage;
};

interface ContextVariableAutocompleteProps
  extends Omit<StandardTextFieldProps, 'error' | 'onChange'> {
  disabled?: boolean;
  disablePortal?: boolean;
  error?: boolean | string;
  filterFn?: (cv: ContextVariable) => boolean;
  label?: string | React.ReactElement;
  multiline?: boolean;
  onChange: (newValue: string) => void;
  placeholder?: string;
  rows?: number | string;
  setModalStatus?: (status: 'open' | 'closed') => void;
  shouldIncludeSystemContextVariables?: boolean;
  shouldIncludeTemplateContextVariables?: boolean;
  undefinedContextVariables?: string[];
  value: string;
}

const useContextVariableAutocomplete = ({
  filterFn,
  onChange: propOnChange,
  setModalStatus,
  value = '',
  ...props
}: ContextVariableAutocompleteProps) => {
  const { contextVariables: nonSortedContextVariables } =
    useGetContextVariables({
      filterFn: filterFn,
      shouldIncludeSystemContextVariables:
        props.shouldIncludeSystemContextVariables,
      shouldIncludeTemplateContextVariables:
        props.shouldIncludeTemplateContextVariables,
    });

  const inputRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const caretRef = useRef<number>(0);

  const contextVariables = useMemo(() => {
    // CV names need to be sorted from largest to smallest otherwise the text
    // editor will always replace the first matching cv name
    // example: cvnames = ["first name", "first name2"]
    // $first name2 matches "first name" causing confusing UX
    const sorted = contextVariablesToSorted(
      structuredClone(nonSortedContextVariables),
    );

    return sorted;
  }, [nonSortedContextVariables]);

  const inputValue = useMemo(
    () => formatContextVariablesForInput(contextVariables, value),
    [contextVariables, value],
  );

  useEffect(() => {
    inputRef?.current?.setSelectionRange(caretRef.current, caretRef.current);
  }, [inputValue]);

  const contextVariableRanges = useMemo(() => {
    let accumulator = 0;

    const arr = [...value.matchAll(new RegExp('{{.*?}}', 'gi'))].map(match => {
      const matchedContextVariableId = match[0];
      const cvIndex = match.index ?? 0;
      const [contextVariableId, suffix = ''] = splitDynamicContextVariableId(
        extractCvIdFromCurlyBraces(matchedContextVariableId),
      );

      const matchedCv = contextVariables.find(
        contextVariable =>
          contextVariable.context_variable_id === contextVariableId,
      )?.context_variable_name;
      const fullMatchedCv = suffix ? matchedCv + '.' + suffix : matchedCv;

      // length of name +1 for dollar sign
      const contextVariableNameLength = (fullMatchedCv?.length ?? 0) + 1;

      const range: Range = {
        start: cvIndex - accumulator,
        stop: cvIndex + contextVariableNameLength - accumulator,
      };

      accumulator +=
        matchedContextVariableId.length - contextVariableNameLength;

      return range;
    });

    return arr;
  }, [contextVariables, value]);

  const onInsert = useCallback(
    (fullCVID: string, newContextVariables?: ContextVariable[]) => {
      const [contextVariableId, suffix = ''] =
        splitDynamicContextVariableId(fullCVID);

      let tempValue = inputValue;
      let caretPosition = inputRef?.current?.selectionStart ?? 0;
      const cvInRange = contextVariableRanges.find(
        range => caretPosition > range.start && caretPosition < range.stop,
      );

      if (cvInRange) {
        tempValue =
          tempValue.substring(0, cvInRange.start) +
          tempValue.substring(cvInRange.stop);
        caretPosition = cvInRange.start;
      }

      let foundContextVariableName = (
        newContextVariables ?? contextVariables
      ).find(
        cv => cv.context_variable_id === contextVariableId,
      )?.context_variable_name;

      if (!foundContextVariableName) {
        return;
      }
      if (suffix) {
        foundContextVariableName += '.' + suffix;
      }

      const cvPrefix =
        tempValue.substring(caretPosition, caretPosition - 1) === '$'
          ? ''
          : '$';

      tempValue =
        tempValue.substring(0, caretPosition) +
        cvPrefix +
        foundContextVariableName +
        tempValue.substring(caretPosition);

      propOnChange(
        formatInputForContextVariables(
          newContextVariables ?? contextVariables,
          tempValue,
        ),
      );
      caretRef.current = caretPosition + foundContextVariableName.length + 1;
      setModalStatus && setModalStatus('closed');
    },
    [
      inputValue,
      contextVariableRanges,
      contextVariables,
      propOnChange,
      setModalStatus,
    ],
  );
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const inputValue = e.target.value;
      caretRef.current = e.target.selectionStart ?? 0;

      propOnChange(
        formatInputForContextVariables(contextVariables, inputValue),
      );
    },
    [contextVariables, propOnChange],
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      const caretPosition = inputRef.current?.selectionStart ?? 0;
      if (e.key === 'Backspace') {
        const newCaretPosition = caretPosition - 1;
        const cvInRange = contextVariableRanges.find(
          range =>
            newCaretPosition >= range.start && newCaretPosition < range.stop,
        );

        if (cvInRange) {
          const tempValue =
            inputValue.substring(0, cvInRange.start) +
            inputValue.substring(cvInRange.stop);

          propOnChange(
            formatInputForContextVariables(contextVariables, tempValue),
          );

          e.preventDefault();
          caretRef.current = cvInRange.start;
        }
      }
      setModalStatus && setModalStatus(e.key === '$' ? 'open' : 'closed');
    },
    [
      contextVariableRanges,
      contextVariables,
      inputValue,
      propOnChange,
      setModalStatus,
    ],
  );

  return {
    ...props,
    filterFn,
    inputRef,
    onChange,
    onInsert,
    onKeyDown,
    searchRef,
    value: inputValue,
  };
};

const ContextVariableAutocomplete = (
  props: ContextVariableAutocompleteProps,
) => {
  const { palette, zIndex } = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [isCreateMode, setIsCreateMode] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const setModalStatus = useCallback((status: 'open' | 'closed') => {
    setAnchorEl(status === 'open' ? containerRef.current : null);
  }, []);

  const {
    disablePortal,
    filterFn,
    inputRef,
    onInsert,
    searchRef,
    shouldIncludeSystemContextVariables = false,
    shouldIncludeTemplateContextVariables = false,
    undefinedContextVariables = [],
    value = '',
    ...rest
  } = useContextVariableAutocomplete({ ...props, setModalStatus });

  const { contextVariables } = useGetContextVariables({
    filterFn,
    shouldIncludeSystemContextVariables,
    shouldIncludeTemplateContextVariables,
  });

  const [createContextVariable] = useCreateContextVariableMutation();

  const filteredContextVariables = useMemo(() => {
    const flattened = flattenDynamicContextVariables(contextVariables);
    const filtered = flattened.filter(({ fullLabel, label }) =>
      (fullLabel || label)
        .toLowerCase()
        .includes(searchText.toLowerCase().trim()),
    );
    const undefinedCvSet = new Set(undefinedContextVariables);
    return filtered.map(cv => ({
      ...cv,
      isDefined: !undefinedCvSet.has(cv.label),
    }));
  }, [contextVariables, searchText, undefinedContextVariables]);

  const isOpen = Boolean(anchorEl);

  const handleClickAway = useCallback((event: MouseEvent | TouchEvent) => {
    if (!(event.target instanceof HTMLBodyElement)) {
      /**
       * Explicitly checks if the target is not the body element.
       * Addresses dropdown issues from incorrect click-away detection
       * and CSS positioning conflicts caused by the disablePortal setting.
       */
      setAnchorEl(null);
      setSearchText('');
    }
  }, []);

  if (!rest.disabled && shouldIncludeTemplateContextVariables) {
    throw new Error('Cannot use template CV in live input');
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div onFocus={e => setAnchorEl(e.currentTarget)} ref={containerRef}>
        <TextField
          aria-label={rest['aria-label'] || 'Context Variable'}
          autoComplete='off'
          inputRef={inputRef}
          value={value}
          {...rest}
        />

        {anchorEl && (
          <Popper
            anchorEl={anchorEl}
            disablePortal={disablePortal}
            id={CONTEXT_VARIABLE_AUTOCOMPLETE_ID}
            open={isOpen}
            sx={{
              width: anchorEl.clientWidth,
              zIndex: zIndex.modal + 1,
            }}
          >
            <Paper
              sx={theme => ({
                border: `1px solid ${theme.palette.colors.grey[300]}`,
                maxHeight: '400px',
                overflow: 'auto',
                width: '100%',
              })}
            >
              <Box padding={'12px 12px 0 12px'}>
                <Typography color={palette.text.secondary} variant='font14Bold'>
                  Type ‘$’ to reveal this dropdown again
                </Typography>
              </Box>
              <Box p={1.5}>
                <SearchBar
                  inputRef={searchRef}
                  onChange={({ target }) => setSearchText(target.value)}
                  onClear={() => setSearchText('')}
                  placeholder='Search'
                  size='small'
                  value={searchText}
                />
              </Box>
              {isCreateMode ? (
                <ContextVariableMenuForm
                  onClose={() => {
                    setIsCreateMode(false);
                    inputRef.current?.focus();
                  }}
                  onSave={async (cvName, cvType) => {
                    const res = await createContextVariable({
                      context_variable_name: cvName,
                      context_variable_type: cvType,
                    });

                    if (!('data' in res)) {
                      console.error('Cv update failed');
                      return;
                    }

                    onInsert(res.data.context_variable_id, [
                      ...contextVariables,
                      res.data,
                    ]);
                    inputRef.current?.focus();
                  }}
                />
              ) : (
                <AddNewButton
                  id='add-new-button'
                  onClick={() => setIsCreateMode(true)}
                >
                  <IconPlus size='18px' />
                  <Typography variant='font14Bold'>
                    Create new Context Variable
                  </Typography>
                </AddNewButton>
              )}
              <Box
                onMouseDown={e => {
                  e.preventDefault();
                  inputRef.current?.focus();
                }}
              >
                <ContextVariableIndentedMenuItems
                  onClick={contextVariable => onInsert(contextVariable.id)}
                  options={filteredContextVariables}
                />
              </Box>
            </Paper>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  );
};

const AddNewButton = styled('button')`
  width: 100%;
  height: 48px;
  border: none;
  background-color: ${({ theme }) => theme.palette.colors.purple[100]};
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.body1.fontFamily};

  :hover {
    background-color: ${({ theme }) => theme.palette.colors.purple[200]};
  }

  @media (min-width: 600px) {
    height: 40px;
  }
`;

const MemoizedContextVariableAutocomplete = React.memo(
  ContextVariableAutocomplete,
);

export default MemoizedContextVariableAutocomplete;
