import { Link } from 'react-router-dom';
import { Box, Tooltip, useTheme } from '@mui/material';

import {
  Badge,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TEMPLATE_INTENT_ID } from '../../constants';
import VariableBox from '../../workflow-builder/revamped/VariableBox';
import { ActivenessIndicator } from './ActivenessIndicator';
import {
  getIconForTool,
  useGetActionFieldsForTool,
  useGetHrefForTool,
} from './helpers';
import { ToolCard } from './StyledComponents';
import { TemplateBadge } from 'src/components/template-badge';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';

interface ToolItemProps {
  badgeText?: string;
  isDisabled?: boolean;
  onClickTool: (tool: AutoflowTool & { isActive: boolean }) => void;
  setEditToolId?: (toolId: string) => void;
  tool: AutoflowTool & { isActive: boolean };
}

export const ToolItem = ({
  badgeText,
  isDisabled: externalIsDisabled,
  onClickTool,
  setEditToolId,
  tool,
}: ToolItemProps) => {
  const theme = useTheme();
  const isActive = tool.isActive;
  const href = useGetHrefForTool(tool);
  const {
    description = '',
    dynamicListOutputVariables = [],
    inputVariables = [],
    isDisabled: internalIsDisabled,
    outputVariables = [],
  } = useGetActionFieldsForTool(tool);

  const isDisabled = externalIsDisabled || internalIsDisabled;
  const label = <Typography variant='font14'>{tool.tool_name}</Typography>;

  const componentBody = (
    <ToolCard
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={() => !isDisabled && onClickTool(tool)}
    >
      <Box
        alignItems={
          !description && !inputVariables.length && !outputVariables.length
            ? 'center'
            : 'flex-start'
        }
        display='flex'
        gap='8px'
        sx={{
          a: {
            '&:hover': { textDecoration: 'underline' },
            color: theme.palette.common.black,
            textDecoration: 'none',
          },
        }}
      >
        {getIconForTool(tool, theme)}
        <Box
          display='flex'
          flexDirection='column'
          onClick={e => {
            if (
              tool.tool_type === 'system_built_in' &&
              tool.tool_id !== 'forward_phone_call'
            ) {
              e.stopPropagation();
              setEditToolId?.(tool.tool_id);
            }
          }}
          sx={[
            tool.tool_type === 'system_built_in' &&
              tool.tool_id !== 'forward_phone_call' && {
                '&:hover': { textDecoration: 'underline' },
              },
          ]}
        >
          <Typography variant='font14Bold'>
            {href ? (
              <Link
                onClick={e => e.stopPropagation()}
                target='_blank'
                to={href}
              >
                {label}
              </Link>
            ) : (
              label
            )}
          </Typography>
          {Boolean(description) && (
            <Box>
              <Typography
                color={theme.palette.colors.grey[600]}
                variant='font12'
              >
                {description}
              </Typography>
            </Box>
          )}
          {(inputVariables.length > 0 ||
            outputVariables.length > 0 ||
            dynamicListOutputVariables.length > 0) && (
            <Box>
              <VariableBox contextVariables={inputVariables} label='Input:' />
              <VariableBox contextVariables={outputVariables} label='Output:' />
              <VariableBox
                contextVariables={dynamicListOutputVariables}
                label='Dynamic list output:'
              />
            </Box>
          )}
        </Box>
        <Box display='flex' flex={1} gap={1} justifyContent='flex-end'>
          {(tool.tool_type === 'template_api_call' ||
            tool.tool_id.startsWith(TEMPLATE_INTENT_ID)) && <TemplateBadge />}
          <ActivenessIndicator isActive={isActive} />
        </Box>
      </Box>
      {badgeText && (
        <Box ml={5}>
          <Badge label={badgeText} variant='macro' />
        </Box>
      )}
    </ToolCard>
  );

  return isDisabled ? (
    <Tooltip
      arrow
      placement='top'
      title='This action contains context variables of an unacceptable type and cannot be used in Autoflows'
    >
      {componentBody}
    </Tooltip>
  ) : (
    componentBody
  );
};
