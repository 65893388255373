import { useMemo } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { DEFAULT_CHART_STYLE, SANKEY_DIAGRAM_COLORS } from './constants';
import { createData, createNodes, renderSankeyTooltip } from './helpers';
import { SankeyDiagramData, SankeyPoint } from './types';
import Sankey from 'highcharts/modules/sankey';
import merge from 'lodash/fp/merge';
import { DateRange } from 'src/types/types';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

Sankey(Highcharts);

export interface SankeyDiagramProps {
  chartStyle?: { [key: string]: string };
  data: SankeyDiagramData[];
  dateRange: DateRange;
}

const SankeyDiagram = ({ chartStyle, data, dateRange }: SankeyDiagramProps) => {
  const { palette, typography } = useTheme();
  const dateLabel = dateRangeToTimeFilter(dateRange);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      plotOptions: {
        sankey: {
          events: {
            afterRender: function (
              this: Highcharts.Series & {
                nodes: Highcharts.SankeyNodeObject[];
              },
            ) {
              const highlightCurrent = (point: Highcharts.Point): void => {
                this.points.forEach(p => {
                  if (p !== point && p.graphic) {
                    p.graphic.animate(
                      {
                        opacity: 0.3,
                      },
                      { duration: 200 },
                    );
                  }
                });
                this.nodes.forEach(node => {
                  if (
                    point.options.from === node.id ||
                    point.options.to === node.id
                  ) {
                    return;
                  }
                  if (node.graphic && node.graphic.getBBox().height) {
                    node.graphic.animate(
                      {
                        opacity: 0.3,
                      },
                      { duration: 200 },
                    );
                  }
                });
              };

              const resetHighlight = (): void => {
                this.points.forEach(p => {
                  if (p.graphic) {
                    p.graphic.attr({
                      opacity: 1,
                    });
                  }
                });
                this.nodes.forEach(node => {
                  if (node.graphic && node.graphic.getBBox().height) {
                    node.graphic.attr({
                      opacity: 1,
                    });
                  }
                });
              };

              if (!this.points.length) {
                return;
              }

              this.points.forEach(point => {
                if (point.graphic) {
                  point.graphic.on('mouseover', function () {
                    highlightCurrent(point);
                  });
                  point.graphic.on('mouseout', function () {
                    resetHighlight();
                  });
                }
              });
            },
          },
        },
      },
      series: [
        {
          colors: SANKEY_DIAGRAM_COLORS,
          data: createData(data),
          dataLabels: {
            enabled: false,
          },
          keys: ['from', 'to', 'weight', 'color'],
          nodeAlignment: 'top',
          nodes: createNodes(data),
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
          type: 'sankey',
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        borderWidth: 0,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          const point = this.point as unknown as SankeyPoint & {
            to: string;
            toNode: SankeyPoint;
          };
          const pointData = point.from ? point.toNode : point;
          return renderSankeyTooltip({
            dateRange: dateLabel.label,
            palette,
            point: pointData,
          });
        },
        headerFormat: null,
        hideDelay: 0,
        outside: true,
        padding: 0,
        shadow: false,
        shared: false,
        split: false,
        style: {
          fontFamily: typography.fontFamily,
          fontSize: '14px',
        },
        useHTML: true,
      },
    }),
    [data, typography, palette, dateLabel.label],
  );

  return (
    <HighchartsReact
      containerProps={{ style: merge(chartStyle, DEFAULT_CHART_STYLE) }}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default SankeyDiagram;
