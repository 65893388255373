import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IntegrationSection = styled('div')`
  padding: 42px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const ConnectionSection = styled(Box)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
