import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, styled } from '@mui/material';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AgathaMessageContainer } from './StyledComponents';
import { QuickFeedbackTranscriptComponent } from './types';
import { quickFeedbackOneToTwoOptions } from 'src/pages/solve-config/constants';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const QuickFeedbackStep = ({
  transcriptComponent,
}: {
  transcriptComponent: QuickFeedbackTranscriptComponent;
}) => {
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { theme_color: themeColor } = widgetConfiguration;
  const {
    hover_label_bad: hoverLabelBad,
    hover_label_good: hoverLabelGood,
    style,
  } = widgetConfiguration.quick_feedback_config;
  const fontColor = useSelector(selectLegibleTextColor);

  const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
    option => option.value === style,
  );

  if (!chosenStyleOptions) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box display='flex' gap='6px' marginLeft='8px'>
        <Box
          aria-label={hoverLabelGood}
          sx={{ cursor: 'pointer' }}
          width='24px'
        >
          <Tooltip placement='bottom' tooltipContent={hoverLabelGood}>
            <Box>
              <SVGIcon
                src={
                  (transcriptComponent.is_useful
                    ? chosenStyleOptions.positiveIcons?.active
                    : chosenStyleOptions.positiveIcons?.default) || ''
                }
              />
            </Box>
          </Tooltip>
        </Box>
        <Box aria-label={hoverLabelBad} sx={{ cursor: 'pointer' }} width='24px'>
          <Tooltip placement='bottom' tooltipContent={hoverLabelBad}>
            <Box>
              <SVGIcon
                src={
                  (transcriptComponent.is_useful
                    ? chosenStyleOptions.negativeIcons?.default
                    : chosenStyleOptions.negativeIcons?.active) || ''
                }
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {transcriptComponent.additional_feedback && (
        <AgathaMessageContainer
          alignSelf='flex-end'
          backgroundColor={themeColor}
        >
          <Typography color={fontColor} variant='font16'>
            Additional feedback: <br />
            {transcriptComponent.additional_feedback}
          </Typography>
        </AgathaMessageContainer>
      )}
    </Box>
  );
};

const SVGIcon = styled(ReactSVG)`
  div {
    svg {
      height: 24px;
      width: 24px;

      path {
        :hover {
          stroke: ${props => props.theme.palette.colors.black};
          stroke-width: 2px;
          stroke-linejoin: round;
        }
      }
    }
  }

  :hover {
    div {
      svg {
        path {
          stroke: ${props => props.theme.palette.colors.black};
          stroke-width: 2px;
          stroke-linejoin: round;
        }
      }
    }
  }
`;
