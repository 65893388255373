import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ArticleTranscriptComponent } from '../ConversationTranscript/types';
import { ArticlesSectionFromTranscriptComponents } from './ArticlesSectionFromTranscriptComponent';
import { quickFeedbackOneToTwoOptions } from 'src/pages/solve-config/constants';
import { QuickFeedbackRecord } from 'src/services/apiInterfaces';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api/dashboardApi';

export const ArticlesSection = ({
  quickFeedbackResponses,
  transcriptComponents,
}: {
  quickFeedbackResponses?: Array<QuickFeedbackRecord> | null;
  transcriptComponents?: ArticleTranscriptComponent[] | null;
}) => {
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');

  // if transcript component is defined, show the revamped articles section
  if (transcriptComponents) {
    return (
      <ArticlesSectionFromTranscriptComponents
        transcriptComponents={transcriptComponents}
      />
    );
  }

  if (
    !isQuickFeedbackEnabled ||
    !quickFeedbackResponses ||
    quickFeedbackResponses.length === 0
  ) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap='4px'>
      <Divider />
      <Box>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          Articles:
        </Typography>
      </Box>
      {quickFeedbackResponses.map((quickFeedbackRecord, index) => {
        const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
          option => option.value === quickFeedbackRecord.style,
        );

        return (
          <Box alignItems='center' display='flex' gap='4px' key={index}>
            <Typography variant='font12'>
              <QuickFeedbackArticleLink
                href={quickFeedbackRecord.link}
                rel='noreferrer'
                target='_blank'
              >
                {quickFeedbackRecord.title}
              </QuickFeedbackArticleLink>
            </Typography>
            {quickFeedbackRecord.is_useful !== null && (
              <QuickFeedbackStyleImage
                src={
                  chosenStyleOptions?.[
                    `${
                      quickFeedbackRecord.is_useful ? 'positive' : 'negative'
                    }Icons`
                  ]?.active
                }
              />
            )}
            {quickFeedbackRecord.additional_feedback && (
              <Typography color={palette.colors.grey[600]} variant='font12'>
                <i>
                  &quot;
                  {quickFeedbackRecord.additional_feedback}
                  &quot;
                </i>
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const QuickFeedbackStyleImage = styled('img')`
  height: 18px;
  width: 18px;
`;

const QuickFeedbackArticleLink = styled('a')`
  color: ${({ theme }) => theme.palette.colors.blue[600]};
`;

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;
