import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import PromptTabContent from './prompt-tab-content/PromptTabContent';
import ThemeTabContent from './theme-tab-content/ThemeTabContent';
import { URLParamTabs } from 'src/components/url-param-tabs/URLParamTabs';

export const ThemeTabContainer = () => {
  const [params] = useSearchParams();
  const subTab = params.get('sub-tab');
  return (
    <>
      <Box mb={3}>
        <URLParamTabs
          ariaLabel='solve channel tabs'
          paramName='sub-tab'
          tabs={['Theme', 'Prompt']}
          typographyVariant='font24'
        />
      </Box>
      {subTab === 'prompt' ? <PromptTabContent /> : <ThemeTabContent />}
    </>
  );
};
