import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog as ElementsDialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getWorkflowValueFromSearch } from 'src/pages/workflow-builder-edit/autonomous-agent/helpers';
import { viewToWorkflowTypes } from 'src/pages/workflow-builder-edit/constants';
import { useGetBuilderQueryParams } from 'src/pages/workflow-builder-edit/hooks';
import WorkflowInvalidModal from 'src/pages/workflow-builder-edit/WorkflowInvalidModal';
import {
  selectCanvasWorkflowBuilder,
  selectInvalidGoToSteps,
  selectIsBranchValid,
  selectUndefinedContextVariables,
  selectUnsupportedSteps,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { dashboardApi } from 'src/services/dashboard-api';
import { activateIntentWorkflowAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import {
  useChangeWorkflowPublishStatusMutation,
  useUpdateWorkflowMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  getIntent,
  loadDraftWorkflow,
  saveWorkflow,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { WorkflowBuilderDialogType } from 'src/slices/ui/types';
import {
  selectGlobalWorkflowBuilderOptions,
  setGlobalToastOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const getTextByType = (dialogType: WorkflowBuilderDialogType | null) => {
  if (!dialogType) {
    return {};
  }

  const typeText =
    dialogType === 'switch-classic-to-aa' ||
    dialogType === 'publish-aa' ||
    dialogType === 'update-publish-aa'
      ? 'Customer Journey'
      : 'Classic';

  if (
    dialogType === 'switch-aa-to-classic' ||
    dialogType === 'switch-classic-to-aa'
  ) {
    return {
      bodyText: `You previously published this workflow using a different mode. Now,
          you are about to make this ${typeText} available to the public, replacing and publishing it for customer
          engagement.\n\nAre you sure you want to replace and publish?`,
      buttonText: 'Replace and publish',
      titleText: 'Replace and publish',
      toastText: `This Workflow (${typeText}) has been published successfully`,
    };
  }

  if (
    dialogType === 'update-publish-aa' ||
    dialogType === 'update-publish-classic'
  ) {
    return {
      bodyText: `You are about to update the published Workflow (${typeText}), and all changes will be made public.`,
      buttonText: 'Publish changes now',
      titleText: 'Publish changes?',
      toastText: `This Workflow (${typeText}) has been updated successfully`,
    };
  }

  return {
    bodyText: `Publish Workflow? You are about to make this Workflow (${typeText}) available to the public, publishing and exposing it for customer engagement.`,
    buttonText: 'Publish now',
    titleText: 'Publish Workflow?',
    toastText: `This Workflow (${typeText}) has been published successfully`,
  };
};

const Dialog = () => {
  const { search } = useLocation();
  const { dialogType } = useSelector(selectGlobalWorkflowBuilderOptions);
  const [isLoadingWorkflowData, setIsLoadingWorkflowData] = useState(false);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const { view } = useGetBuilderQueryParams();

  const dispatch = useAppDispatch();
  const onClose = () =>
    dispatch(setGlobalWorkflowBuilderOptions({ dialogType: null }));

  const [changeWorkflowPublishStatus, { isLoading }] =
    useChangeWorkflowPublishStatusMutation();

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const intentWorkflowId = getWorkflowValueFromSearch(search, 'workflowId');
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const [isAllBranchesFilled] = useSelector(
    selectIsBranchValid(canvasData.entryStepId),
  );
  const undefinedCvs: string[] = useSelector(selectUndefinedContextVariables);
  const unsupportedSteps = useSelector(selectUnsupportedSteps);
  const invalidGoToSteps = useSelector(selectInvalidGoToSteps);
  const isInvalid =
    !isAllBranchesFilled ||
    undefinedCvs.length > 0 ||
    unsupportedSteps.length > 0 ||
    invalidGoToSteps.length > 0;

  const isDialogOpen = typeof dialogType === 'string';

  const activateWorkflow = async () => {
    const channel = view === 'email' ? 'interactive_email' : view;
    await activateIntentWorkflowAPI(
      intentWorkflowId,
      viewToWorkflowTypes[channel],
    );
    await dispatch(loadDraftWorkflow(intentWorkflowId));
    await dispatch(dashboardApi.util.invalidateTags(['Intents']));
    dispatch(getIntent(canvasData.intent_id as string));
  };

  const saveClassicWorkflow = async () => {
    if (isInvalid) {
      return;
    }

    if (!!canvasData.intent_workflow_id) {
      await dispatch(
        saveWorkflow({
          intentWorkflowId: canvasData.intent_workflow_id,
        }),
      ).unwrap();
      // throw the error if not successful
      await dispatch(loadDraftWorkflow(canvasData.intent_workflow_id));
    }
  };

  const { bodyText, buttonText, titleText, toastText } =
    getTextByType(dialogType);

  const onClickCTA = async () => {
    setIsLoadingWorkflowData(true);

    const onComplete = () => {
      setIsLoadingWorkflowData(false);

      dispatch(
        setGlobalWorkflowBuilderOptions({
          dialogType: null,
        }),
      );
    };

    const onAction = async () => {
      switch (dialogType) {
        case 'publish-aa':
          return [
            (async () => {
              await updateWorkflow(intentWorkflowId);
              await changeWorkflowPublishStatus({
                intentWorkflowId,
                status: 'enabled',
              });
              await activateWorkflow();
            })(),
          ];
        case 'switch-aa-to-classic':
          return [
            changeWorkflowPublishStatus({
              intentWorkflowId,
              status: 'disabled',
            }),
            saveClassicWorkflow(),
          ];
        case 'switch-classic-to-aa':
          return [
            (async () => {
              await updateWorkflow(intentWorkflowId);
              await changeWorkflowPublishStatus({
                intentWorkflowId,
                status: 'enabled',
              });
            })(),
            ,
          ];
        case 'update-publish-aa':
          return [updateWorkflow(intentWorkflowId)];
        case 'publish-classic':
          await saveClassicWorkflow();

          return [
            activateWorkflow(),
            changeWorkflowPublishStatus({
              intentWorkflowId,
              status: 'disabled',
            }),
          ];
        case 'update-publish-classic':
          return [saveClassicWorkflow()];
      }

      return [];
    };

    if (
      (dialogType === 'update-publish-classic' ||
        dialogType === 'publish-classic') &&
      isInvalid
    ) {
      setShowIncompleteModal(true);
      onComplete();
      return;
    }

    try {
      const responses = await onAction();
      await Promise.all(responses);
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: toastText ?? '',
          variant: 'main',
        }),
      );
    } catch (error) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: 'Workflow publish failed',
          variant: 'danger',
        }),
      );
    }

    onComplete();
  };

  return (
    <>
      <ElementsDialog
        footer={
          <>
            <Button onClick={onClose} variant='ghost'>
              Cancel
            </Button>
            <Button
              isLoading={isLoading || isLoadingWorkflowData}
              onClick={onClickCTA}
              variant='secondary'
            >
              {buttonText ?? ''}
            </Button>
          </>
        }
        hideCloseButton
        onClose={onClose}
        open={isDialogOpen}
        title={titleText ?? ''}
        variant='sm'
      >
        <Box maxWidth='300px' whiteSpace='pre-line'>
          <Typography variant='font14'>{bodyText}</Typography>
        </Box>
      </ElementsDialog>
      {showIncompleteModal && (
        <WorkflowInvalidModal onClose={() => setShowIncompleteModal(false)} />
      )}
    </>
  );
};

export default Dialog;
