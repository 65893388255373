import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectHandoffCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { getHandoffCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { IntercomLiveChatHandoffCustomization } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

export const useGetAvailableConversationAttributes = () => {
  const dispatch = useDispatch();
  const conversationAttributes = useSelector(
    selectHandoffCustomization,
  ) as IntercomLiveChatHandoffCustomization;

  useEffect(() => {
    dispatch(
      getHandoffCustomization(ActionBuilderActionTypes.INTERCOM_LIVE_CHAT),
    );
  }, [dispatch]);

  return filterConversationAttributes(
    conversationAttributes.conversation_attributes,
  );
};

const filterConversationAttributes = (
  attributes: IntercomLiveChatHandoffCustomization['conversation_attributes'],
) => {
  return (
    attributes?.filter(
      attribute =>
        attribute.data_type === 'string' || attribute.data_type === 'list',
    ) || []
  );
};
