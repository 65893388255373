import {
  checkboxOperators,
  listTypeOperators,
  stringOperators,
} from '../expression-builder/constants';
import {
  QueryExpressionInputType,
  QueryExpressionOperatorData,
} from '../expression-builder/types';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';

export const INITIAL_EXPRESSION = {
  booleanOperator: 'and',
  expression: {
    expression_type: 'filter',
    field: '',
    negate: false,
    operator: '=',
    values: [],
  },
};

export const dateOperators = [
  {
    inputType: 'date_range_input' as QueryExpressionInputType,
    label: 'is between',
    negate: false,
    value: 'between',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is empty',
    negate: false,
    value: 'empty',
  },
  {
    inputType: 'text_input' as QueryExpressionInputType,
    label: 'is not empty',
    negate: true,
    value: 'empty',
  },
];

export const cvNumberOperators = [
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'is',
    negate: false,
    value: '=',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'is not',
    negate: true,
    value: '=',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'greater than',
    negate: false,
    value: '>',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'less than',
    negate: false,
    value: '<',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'is empty',
    negate: false,
    value: 'empty',
  },
  {
    inputType: 'numeric_input' as QueryExpressionInputType,
    label: 'is not empty',
    negate: true,
    value: 'empty',
  },
];

export const EXPRESSION_TYPE_TO_OPERATOR: {
  [expressionType in QueryExpressionTypes]: Array<QueryExpressionOperatorData>;
} = {
  CHECKBOX: checkboxOperators,
  DATE: dateOperators,
  EMAIL: stringOperators,
  LIST: listTypeOperators,
  LONG_TEXT: stringOperators,
  NUMBER: cvNumberOperators,
  PHONE: stringOperators,
  SHORT_TEXT: stringOperators,
};
