import { Badge } from '@forethought-technologies/forethought-elements';
import AutopilotBadge from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/components/autopilot/AutopilotBadge';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export const BadgesForWorkflow = ({
  isAutopilot,
  isDefaultHandoff,
  isHandoff,
  isInteractive,
}: {
  isAutopilot?: boolean;
  isDefaultHandoff: boolean;
  isHandoff: boolean;
  isInteractive?: boolean;
}) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isFallbackWorkflowEnabled = featureFlags.includes('fallback_workflow');

  return (
    <>
      {isHandoff && <Badge label='Handoff' variant='outlined' />}{' '}
      {isDefaultHandoff && !isFallbackWorkflowEnabled && (
        <Badge label='Default' variant='macro' />
      )}
      {isInteractive && <Badge label='Interactive' variant='inverse' />}
      {isAutopilot && <AutopilotBadge />}
    </>
  );
};
