import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { GotoWorkflowTranscriptComponent } from './types';

export const GotoWorkflow = ({
  transcriptComponent,
}: {
  transcriptComponent: GotoWorkflowTranscriptComponent;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(242, 244, 247, 0.5)',
        border: `1px solid ${theme.palette.colors.grey[100]}`,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 12px',
        width: '100%',
      }}
    >
      <Typography color={theme.palette.colors.grey[700]} variant='font14Medium'>
        Triggered workflow:{' '}
        {transcriptComponent.intent_title
          ? transcriptComponent.intent_title
          : transcriptComponent.intent_workflow_id}
      </Typography>
    </Box>
  );
};
