import { Box, styled } from '@mui/material';

import { MessageBubblePosition } from './types';

export const AgentIcon = ({ icon }: { icon: string | undefined }) => {
  if (!icon) {
    // return the default avatar here
    return null;
  }

  return (
    <Box
      sx={{
        display: 'block',
        height: '32px',
        marginLeft: '-8px',
        position: 'relative',
        right: '-8px',
        width: '32px',
        zIndex: 1,
      }}
    >
      <Box
        alt=''
        component='img'
        src={icon}
        sx={{
          borderRadius: '50%',
          height: '32px',
          width: '32px',
        }}
      />
    </Box>
  );
};

export const AgathaContent = styled('div')<{
  backgroundColor: string;
  themeColor: string;
}>`
  border: 1px solid transparent;
  line-height: 22px;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 1px solid ${props => props.themeColor};
  }

  // Prevent excessive margin.
  // The first child is a visually hidden paragraph needed for accessibility,
  // so we need to target the second child, which is actual content:
  > :nth-child(2) {
    margin-top: 0;
  }

  // Prevent excessive margin:
  > :last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    padding-left: 10px;
  }

  a {
    overflow-wrap: break-word;
    color: #0064fa;
  }

  a:visited {
    color: ${props => props.theme.palette.colors.purple[600]};
  }

  a:hover {
    color: ${props => props.theme.palette.colors.purple[600]};
    text-decoration: none;
  }

  a:active {
    color: ${props => props.theme.palette.colors.purple[700]};
  }

  & img {
    width: initial !important;
    height: initial !important;
    max-width: 100% !important;
  }

  & > p:empty {
    height: 20px;
  }
`;

const messageBubblePositionToRadiusMap: Record<MessageBubblePosition, string> =
  {
    'first-in-a-batch': '25px 25px 25px 8px',
    'last-in-a-batch': '8px 25px 25px 25px',
    lone: '25px',
    middle: '8px 25px 25px 8px',
  } as const;

export const AgathaMessageContainer = styled('div')<{
  alignSelf?: string;
  backgroundColor: string;
  marginBottom?: string;
  maxWidth?: string;
  messageBubblePosition?: MessageBubblePosition;
  minHeight?: string;
}>`
  white-space: normal;
  position: relative;
  padding: 14px 16px;
  background-color: ${props => props.backgroundColor};
  width: fit-content;
  max-width: ${props => props.maxWidth && props.maxWidth};
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'none')};
  min-height: ${props => (props.minHeight ? props.minHeight : '22px')};
  word-break: break-word;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};

  border-radius: ${props =>
    props.messageBubblePosition
      ? messageBubblePositionToRadiusMap[props.messageBubblePosition]
      : '25px'};
`;
