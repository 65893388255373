import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import useOnClickOutside from 'use-onclickoutside';
import { Box, styled } from '@mui/material';

import { ACTIVE_COMPONENT_ID } from '../constants';
import {
  ComponentFields,
  ComponentMetadata,
  EmailBuilderComponent,
  EmailBuilderConfiguration,
} from '../types';
import DroppableEmailBuilderComponent from './DroppableEmailBuilderComponent';
import DroppableLine from './DroppableLine';
import EmailCanvasEmptyState from './EmailCanvasEmptyState';
import { ComponentType } from 'src/pages/workflow-builder-edit/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export const DROPZONE_PADDING = 50;

const TRANSLATABLE_STEP_TYPES = ['feedback', 'text_message', 'button', 'macro'];

interface EmailBuilderCanvasProps {
  activeEditComponent: EmailBuilderComponent | null;
  emailBuilderConfiguration: EmailBuilderConfiguration;
  emailConfigurationComponents: EmailBuilderComponent[];
  onAddNewComponent: (params: {
    componentMetadata: ComponentMetadata;
    componentType: ComponentType;
    position: number;
  }) => void;
  onClickDelete: (componentId: string) => void;
  onDropReorder: (args: { componentId: string; position: number }) => void;
  onTranslationsClick: (componentId: string) => void;
  removeTemporaryComponent: () => void;
  reorderComponentsDuringDrag: (dragIndex: number, hoverIndex: number) => void;
  setActiveComponentFields: (componentFields: ComponentFields) => void;
  setActiveEditComponent: (component: EmailBuilderComponent | null) => void;
  tempEmailConfigurationComponents: EmailBuilderComponent[];
}

export default function EmailBuilderCanvas({
  activeEditComponent,
  emailBuilderConfiguration,
  emailConfigurationComponents,
  onAddNewComponent,
  onClickDelete,
  onDropReorder,
  onTranslationsClick,
  removeTemporaryComponent,
  reorderComponentsDuringDrag,
  setActiveComponentFields,
  setActiveEditComponent,
  tempEmailConfigurationComponents,
}: EmailBuilderCanvasProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isEmailTranslationsEnabled = featureFlags.includes(
    'solve_email_translation_enabled',
  );
  const [hoverPosition, setHoverPosition] = useState<number | null>(null);
  const isEmpty = tempEmailConfigurationComponents.length === 0;
  const canvasRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(canvasRef, () => {
    const cancelConfirmNecessary =
      activeEditComponent &&
      !emailBuilderConfiguration[activeEditComponent.component_type].autosave;
    // for 'autosave' type components, remove edit state when clicking outside canvas
    // for other components, this is handled in EditActiveConfigurationComponentOverlay
    if (!cancelConfirmNecessary) {
      removeTemporaryComponent();
    }
  });

  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: [
        'feedback',
        'static_article',
        'text_message',
        'EXISTING_COMPONENT',
        'button',
        'dynamic_article',
        'image',
        'macro',
        'interactive_email_button',
        'html',
      ],
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop(
        {
          componentMetadata,
          componentType,
        }: {
          componentMetadata: ComponentMetadata;
          componentType: ComponentType;
        },
        monitor,
      ) {
        const dropResult = monitor.getDropResult<{
          componentId: string;
          componentIndex: number;
          hoverPosition: number | null;
          type: string;
        }>();

        if (!dropResult) {
          return;
        }

        const { componentId, componentIndex, hoverPosition, type } = dropResult;
        const isExistingComponent = type === 'EXISTING_COMPONENT';
        if (isExistingComponent) {
          const currentIndex = emailConfigurationComponents.findIndex(
            component => component.component_id === componentId,
          );
          const isTemporary =
            tempEmailConfigurationComponents[currentIndex].temporary;

          if (componentIndex === currentIndex || isTemporary) {
            return;
          }

          return onDropReorder({
            componentId,
            position: componentIndex,
          });
        }

        if (hoverPosition === null) {
          return;
        }

        onAddNewComponent({
          componentMetadata,
          componentType,
          position: hoverPosition,
        });
      },
    }),
    [hoverPosition, onAddNewComponent],
  );

  useEffect(() => {
    if (!isOver) {
      setHoverPosition(null);
    }
  }, [isOver]);

  return (
    <Container data-testid='email-builder-canvas'>
      <ComponentListContainer
        ref={ref => {
          dropRef(ref);
          canvasRef.current = ref;
        }}
      >
        <EmailStyleOverrides>
          <DroppableLine visible={hoverPosition === 0} />
          {isEmpty ? (
            <EmailCanvasEmptyState />
          ) : (
            tempEmailConfigurationComponents.map((component, index) => {
              const {
                component_id,
                component_type,
                temporary = false,
              } = component;
              const { ActiveCanvasComponent, IdleCanvasComponent } =
                emailBuilderConfiguration[component_type];
              const isComponentActive =
                activeEditComponent?.component_id === component_id;

              return (
                <React.Fragment key={component_id}>
                  <Box
                    data-testid={`email-builder-canvas-component-${index}`}
                    id={isComponentActive ? ACTIVE_COMPONENT_ID : undefined}
                    position={isComponentActive ? 'relative' : 'static'}
                    zIndex={isComponentActive ? 1 : 0}
                  >
                    <DroppableEmailBuilderComponent
                      component={component}
                      componentIndex={index}
                      deleteDisabledMessage={
                        emailBuilderConfiguration[component_type]
                          .deleteDisabledMessage
                      }
                      hoverPosition={hoverPosition}
                      isComponentActive={isComponentActive}
                      isDragActive={canDrop}
                      isEditorActive={!!activeEditComponent}
                      isTranslationIconVisible={
                        isEmailTranslationsEnabled &&
                        !temporary &&
                        TRANSLATABLE_STEP_TYPES.includes(
                          component.component_type,
                        )
                      }
                      onClick={() => {
                        if (!isComponentActive) {
                          setActiveEditComponent(component);
                        }
                      }}
                      onClickDelete={() => onClickDelete(component_id)}
                      onTranslationsClick={() => {
                        onTranslationsClick(component_id);
                      }}
                      renderComponent={({ component }) => {
                        if (ActiveCanvasComponent && isComponentActive) {
                          return (
                            <ActiveCanvasComponent
                              component={activeEditComponent}
                              setActiveComponentFields={
                                setActiveComponentFields
                              }
                            />
                          );
                        }

                        return <IdleCanvasComponent component={component} />;
                      }}
                      reorderComponentsDuringDrag={reorderComponentsDuringDrag}
                      setHoverPosition={setHoverPosition}
                    />
                    <DroppableLine visible={hoverPosition === index + 1} />
                  </Box>
                </React.Fragment>
              );
            })
          )}
        </EmailStyleOverrides>
      </ComponentListContainer>
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  padding: 20px ${DROPZONE_PADDING}px;
  overflow-y: scroll;
`;

const ComponentListContainer = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  margin: auto;
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

export const EmailStyleOverrides = styled('div')`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5;
    font-weight: 400;
  }
`;
