import TableArticleList from './TableArticleList';
import TableRelatedTopics from './TableRelatedTopics';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

export const TABLE_COLUMNS: DashboardTableProps<KnowledgeGapArticleRecommendation>['columns'] =
  [
    {
      key: 'generated_title',
      rows: 2,
      size: 300,
      title: 'Title',
    },
    {
      key: 'api_data_filter',
      size: 200,
      title: 'Brand',
    },
    {
      key: 'generated_body',
      rows: 3,
      size: 300,
      title: 'Body',
      tooltip: false,
    },
    {
      Component: TableRelatedTopics,
      key: 'topic_name',
      size: 200,
      title: 'Related topic',
    },
    {
      Component: TableArticleList,
      key: 'relevant_articles',
      size: 300,
      title: 'Related articles',
    },
    {
      key: 'num_of_tickets_in_article',
      size: 220,
      title: 'Ticket coverage/year',
      valueType: 'number',
    },
  ];
