import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  ArticleCardTranscriptComponent,
  ExecutionTranscriptComponent,
  GotoWorkflowTranscriptComponent,
  IntentTriggeredTranscriptComponent,
  RelatedArticlesTranscriptComponent,
  TranscriptComponent,
} from '../ConversationTranscript/types';
import IntentBadge from '../IntentBadge';

const mapTranscriptComponentToChatEvent = (
  transcriptComponent: TranscriptComponent,
) => {
  switch (transcriptComponent.message_type) {
    case 'intent_trigger':
      return (
        <IntentPredictionEventLine
          transcriptComponent={
            transcriptComponent as IntentTriggeredTranscriptComponent
          }
        />
      );
    case 'csat_submission':
      return <GenericEventLine message='CSAT submitted' />;
    case 'related_articles':
      return (
        <ArticleLineFromRelatedArticles
          transcriptComponent={
            transcriptComponent as RelatedArticlesTranscriptComponent
          }
        />
      );
    case 'article_card':
      return (
        <ArticleLineFromArticleCard
          transcriptComponent={
            transcriptComponent as ArticleCardTranscriptComponent
          }
        />
      );
    case 'execution':
      return (
        <ExecutionEventLine
          transcriptComponent={
            transcriptComponent as ExecutionTranscriptComponent
          }
        />
      );
    case 'goto_workflow':
      return (
        <GotoWorkflowEventLine
          transcriptComponent={
            transcriptComponent as GotoWorkflowTranscriptComponent
          }
        />
      );
    default:
      return null;
  }
};

export const ChatEventsSection = ({
  transcriptComponents,
}: {
  transcriptComponents: TranscriptComponent[];
}) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap='8px' padding='16px'>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Events
      </Typography>
      <Box display='flex'>
        <VerticalLine />
        <Box display='flex' flexDirection='column' gap='4px'>
          {transcriptComponents.map(transcriptComponent =>
            mapTranscriptComponentToChatEvent(transcriptComponent),
          )}
        </Box>
      </Box>
    </Box>
  );
};

const IntentPredictionEventLine = ({
  transcriptComponent,
}: {
  transcriptComponent: IntentTriggeredTranscriptComponent;
}) => {
  return (
    <LineWrapper>
      <Circle />
      <IntentBadge
        intentDefinitionId={transcriptComponent.intent_id}
        intentName={transcriptComponent.intent_title}
      />
    </LineWrapper>
  );
};

const GotoWorkflowEventLine = ({
  transcriptComponent,
}: {
  transcriptComponent: GotoWorkflowTranscriptComponent;
}) => {
  return (
    <LineWrapper>
      <Circle />
      <IntentBadge
        intentDefinitionId={transcriptComponent.intent_id}
        intentName={transcriptComponent.intent_title}
      />
    </LineWrapper>
  );
};

const ArticleLineFromRelatedArticles = ({
  transcriptComponent,
}: {
  transcriptComponent: RelatedArticlesTranscriptComponent;
}) => {
  return (
    <Box display='flex' flexDirection='column'>
      {transcriptComponent.articles.map(article => {
        return (
          <Box display='flex' gap='4px' key={article.document_id} ml='40px'>
            <Typography variant='font12'>
              <QuickFeedbackArticleLink
                href={article.link}
                rel='noreferrer'
                target='_blank'
              >
                {article.title}
              </QuickFeedbackArticleLink>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const ArticleLineFromArticleCard = ({
  transcriptComponent,
}: {
  transcriptComponent: ArticleCardTranscriptComponent;
}) => {
  return (
    <Box display='flex' gap='4px' ml='40px'>
      <Typography variant='font12'>
        <QuickFeedbackArticleLink
          href={transcriptComponent.link}
          rel='noreferrer'
          target='_blank'
        >
          {transcriptComponent.title}
        </QuickFeedbackArticleLink>
      </Typography>
    </Box>
  );
};

const ExecutionEventLine = ({
  transcriptComponent,
}: {
  transcriptComponent: ExecutionTranscriptComponent;
}) => {
  const isHandoff = /^Start.*handoff$/.test(transcriptComponent.message);

  if (!isHandoff) {
    return null;
  }

  return (
    <Box display='flex' gap='4px' ml='40px'>
      <Typography variant='font12'>{transcriptComponent.message}</Typography>
    </Box>
  );
};

const GenericEventLine = ({ message }: { message: string }) => {
  return (
    <Box ml='40px'>
      <Typography variant='font12'>{message}</Typography>
    </Box>
  );
};

const Circle = styled('div')`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: black;
`;
const LineWrapper = styled('div')`
  margin-left: -5px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const VerticalLine = styled('div')`
  width: 2px;
  background-color: black;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const QuickFeedbackArticleLink = styled('a')`
  color: ${({ theme }) => theme.palette.colors.blue[600]};
`;
