import { useGetBuilderQueryParams } from '../hooks';
import {
  ARTICLES_TAB,
  INTENT_EMAIL_BUILDER_TAB_CONFIG,
  MACROS_TAB,
} from './intent-email-builder/constants';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import { CommonIntentWorkflowType } from 'src/utils/enums';

export const useIsFallbackEmailWorkflowAllowed = () => {
  const isAutopilotEnabled = useIsFeatureFlagEnabled('solve_email_autopilot');
  const { intentId } = useGetBuilderQueryParams();
  return isAutopilotEnabled && intentId === CommonIntentWorkflowType.FALLBACK;
};

export const useEmailBuilderTabs = () => {
  const { data: helpdeskData } = useGetHelpdeskQuery();
  const { intentId } = useGetBuilderQueryParams();
  const tabs = INTENT_EMAIL_BUILDER_TAB_CONFIG.slice();

  if (intentId !== CommonIntentWorkflowType.FALLBACK) {
    // Don't allow articles tab for fallback email workflows
    // they can cause emails to not be sent (e.g. if a translation is not available)
    // and we always want to send a response for the fallback
    tabs.push(ARTICLES_TAB);
  }

  if (!helpdeskData) {
    return tabs;
  }

  if (helpdeskData.helpdesk === 'zendesk') {
    tabs.push(MACROS_TAB);
  }

  return tabs;
};
