import { ReactNode } from 'react';
import { Box, styled } from '@mui/material';

interface MinimizedWidgetProps {
  children?: ReactNode;
}

const MinimizedWidget = ({ children }: MinimizedWidgetProps) => {
  return (
    <Container>
      <FakeBrowserHeader />
      <ContentContainer>
        {/* Content */}
        <Box height='100%'>{children}</Box>
        {/* TODO - create bubble that is customizable */}
      </ContentContainer>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  border-left: none;
  border-radius: 0px 10px 10px 0px;
  margin: 62px 0 55px 0px;
  flex: 1;
  max-width: 588px;
  width: 80%;
`;

const FakeBrowserHeader = styled('div')`
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  height: 30px;
  margin: 11px 100px 13px 0px;
`;

const ContentContainer = styled('div')`
  height: 100%;
  background-color: ${props => props.theme.palette.colors.slate[100]};
  border-radius: 0px 0px 10px 0px;
  border-top: 1px solid ${props => props.theme.palette.colors.purple[500]};
`;

export default MinimizedWidget;
