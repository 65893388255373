import { AllConversationChannel } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

export const MISSING_CONTEXT_VARIABLE = 'Missing Context Variable';

export const CONTEXT_VARIABLE_TYPE_MISMATCH = 'Context Variable type mismatch';

export const NO_VALID_CONDITION = 'No Valid Condition';

export const ZENDESK_REQUESTER_EMAIL_SUSPENDED =
  'Zendesk: requester email is suspended';

export const ZENDESK_REQUESTER_EMAIL_INVALID =
  'Zendesk: requester email is of invalid format';

export const ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS =
  'Zendesk: requester email is a support address and cannot be used';

export const ZENDESK_VALUE_INVALID_FORMAT =
  'Zendesk: provided value is of invalid format';

export const getErrorDescriptionByMessage = (errorMessage: string) => {
  switch (errorMessage) {
    case MISSING_CONTEXT_VARIABLE:
      return 'This error occurs when a workflow in the widget encounters a step that contains an undefined Context Variable (CV).';
    case CONTEXT_VARIABLE_TYPE_MISMATCH:
      return 'This error occurs when a parameter does not match the type of its associated Context Variable. Ensure that the parameter type aligns with the variable type in the configuration.';
    case NO_VALID_CONDITION:
      return 'This error occurs when executing a condition step and no valid branch is found. For example, all conditions failed in the condition step.';
    case ZENDESK_REQUESTER_EMAIL_INVALID:
      return 'This error happens when a request is sent to the Zendesk API with an improperly formatted requester email (e.g., ‘test@aa’).';
    case ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS:
      return 'This error occurs when a request is sent to the Zendesk API using a requester’s email that is already used as a support address.';
    case ZENDESK_REQUESTER_EMAIL_SUSPENDED:
      return 'This error occurs when a request is sent to the Zendesk API using a suspended requester email.';
    case ZENDESK_VALUE_INVALID_FORMAT:
      return 'This error happens when sending a request to the Zendesk API with a value that does not match the expected field type (e.g., passing a text for a number field).';
    default:
      return 'N/A';
  }
};

export const getChatUrl = ({
  channel,
  chatsTabIndex,
  conversationId,
}: {
  channel: AllConversationChannel | null;
  chatsTabIndex: number;
  conversationId: string | null;
}) => {
  if (!conversationId || !channel) {
    return null;
  }

  if ((channel === 'widget' || channel === 'sunshine') && chatsTabIndex >= 0) {
    return `${Routes.SOLVE_INSIGHTS_MAIN}?tab=${chatsTabIndex}&chats_search=${conversationId}`;
  }

  return `${Routes.WORKFLOW_BUILDER}?header=chats&conversationId=${conversationId}&channel=${channel}`;
};
