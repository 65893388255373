import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ConnectorTypes,
  Credentials,
  UpdateConnectorRequest,
} from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';

export const createConnector = createAsyncThunk(
  'connectors/create',
  async ({
    connectorDefinitionSlug,
    connectorFields,
    connectorTypes,
  }: {
    connectorDefinitionSlug: string;
    connectorFields: Credentials;
    connectorTypes: ConnectorTypes;
  }) => {
    return await API.createConnector({
      connectorDefinitionSlug,
      connectorFields,
      connectorTypes,
    });
  },
);

export const deleteConnector = createAsyncThunk(
  'connectors/delete',
  async ({ connectorId }: { connectorId: string }) => {
    await API.removeCreatedIntegration(connectorId);
    return await API.getAvailableConnectors();
  },
);

export const toggleConnectorIsActive = createAsyncThunk(
  'connectors/toggleIsActive',
  async ({
    connectorId,
    connectorTypes,
    isActive,
  }: {
    connectorId: string;
    connectorTypes: ConnectorTypes;
    isActive: boolean;
  }) => {
    return await API.updateConnector(connectorId, {
      connector_types: connectorTypes,
      is_active: isActive,
    });
  },
);

export const updateConnector = createAsyncThunk(
  'connectors/update',
  async ({
    connectorId,
    updateConnectorRequest,
  }: {
    connectorId: string;
    updateConnectorRequest: UpdateConnectorRequest;
  }) => {
    return await API.updateConnector(connectorId, updateConnectorRequest);
  },
);

export const updateConnectorDisplayName = createAsyncThunk(
  'connectors/updateDisplayName',
  async ({
    connectorId,
    displayName,
  }: {
    connectorId: string;
    displayName: string;
  }) => {
    return await API.updateConnectorDisplayName(connectorId, {
      display_name: displayName,
    });
  },
);
