import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetPredictions } from '../hooks/useGetPredictions';
import PredictionReasoning from './PredictionReasoning';
import TriagePredictionDemoWidget from './TriagePredictionDemoWidget';
import round from 'lodash/round';
import Spinner from 'src/components/spinner';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { selectFocusedTriageModel } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useTestSelfServeModelMutation } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface TriageConfigDetailPredictionsProps {
  selfServeModel?: VersionedTriageModel;
}

const TriageConfigDetailPredictions = ({
  selfServeModel,
}: TriageConfigDetailPredictionsProps) => {
  const {
    labels,
    model,
    model_id,
    model_name,
    phrases,
    version_id,
    version_name,
  } = selfServeModel || {};
  const emitTrackingEventCallback = useSelfServeEvents({
    modelId: model_id,
    modelName: model_name,
    versionId: version_id,
    versionName: version_name,
  });
  const { name = '' } = useParams<'name'>();
  const classicModel = useSelector(selectFocusedTriageModel);
  const { getPredictions, isError, predictions } = useGetPredictions({
    modelName: name,
  });

  const [testSelfServeModel, selfServeResults] =
    useTestSelfServeModelMutation();

  const getSelfServePrediction = (text: string) => {
    if (!model || !labels || !phrases || !version_id || !model_id) {
      return;
    }
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.TEST_MODEL, {
      test_text: text,
    });
    testSelfServeModel({
      body: {
        labels,
        model,
        phrases,
        test_text: text,
      },
      modelId: model_id,
      versionId: version_id,
    });
  };

  const onTestModel = (text: string) => {
    if (selfServeModel) {
      getSelfServePrediction(text);
    } else {
      getPredictions(text);
    }
  };
  const isPremium =
    classicModel?.field_predictor_data?.enablement_control_info
      .model_paid_plan === 'premium';
  const shownPredictions = selfServeModel
    ? selfServeResults.data?.best_prediction
      ? [selfServeResults.data?.best_prediction]
      : null
    : predictions;
  const showNoPrediction =
    selfServeResults.isSuccess && !selfServeResults.data?.best_prediction;
  const isLoadingNewResults = selfServeResults.isLoading;

  return (
    <>
      <TriagePredictionDemoWidget
        getPredictions={onTestModel}
        isSelfServe={Boolean(selfServeModel)}
        modelName={name}
      />
      {isError && (
        <>
          <Box sx={{ mb: 1 }} />
          <Typography
            color={theme.palette.error.main}
            component='p'
            variant='font14Bold'
          >
            An error occurred, please try again
          </Typography>
        </>
      )}
      {isLoadingNewResults && <Spinner />}
      {!isLoadingNewResults && shownPredictions && (
        <PredictionListContainer>
          <PredictionReasoning prediction={shownPredictions} />
          <PredictionListLabels>
            <Typography
              color={theme.palette.colors.grey[800]}
              variant='font14Bold'
            >
              Label
            </Typography>
            <span>
              <Typography
                color={theme.palette.colors.grey[800]}
                variant='font14Bold'
              >
                Confidence
              </Typography>
              <Box sx={{ mr: 1.25 }} />
              <Tooltip
                tooltipContent={`Confidence indicates how likely the output is accurate. ${
                  isPremium
                    ? ''
                    : 'This model selects the output with the highest confidence.'
                } ${
                  Boolean(selfServeModel)
                    ? '\nBy default, label will only be written to your helpdesk if confidence level is at least 50%.'
                    : ''
                }`}
              >
                <InfoOutlinedIcon
                  fontSize='small'
                  htmlColor={theme.palette.common.black}
                />
              </Tooltip>
            </span>
          </PredictionListLabels>
          <PredictionList>
            {!isLoadingNewResults &&
              !showNoPrediction &&
              shownPredictions.map(({ confidence, label }, index) => (
                <PredictionResultItem key={label}>
                  <span>{label}</span>
                  <Typography
                    color={
                      index === 0
                        ? theme.palette.colors.green[500]
                        : theme.palette.colors.grey[500]
                    }
                    variant='font14Bold'
                  >
                    {round(confidence * 100, 1)}%
                  </Typography>
                </PredictionResultItem>
              ))}
            {showNoPrediction && (
              <PredictionResultItem>
                <span>No prediction</span>
              </PredictionResultItem>
            )}
          </PredictionList>
        </PredictionListContainer>
      )}
    </>
  );
};

const PredictionListContainer = styled('div')`
  margin-top: 32px;
  font-size: 14px;
`;

const PredictionList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const PredictionResultItem = styled('li')`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  padding: 16px 0;
  word-break: break-all;
  span {
    max-width: 75%;
  }
`;

const PredictionListLabels = styled('div')`
  color: ${theme.palette.text.secondary};
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  font-weight: 600;
  span {
    display: flex;
  }
`;

export default TriageConfigDetailPredictions;
