import TriageInsightsModelsTab from './models-tab';
import TriageInsightsTicketsTab from './tickets-tab';
import { DashboardTab } from 'src/components/dashboard-tabs/DashboardTabs';

export const TABS: DashboardTab[] = [
  {
    Component: <TriageInsightsModelsTab />,
    label: 'Models',
    trackingEvent: {
      extra: {
        tab: 'models',
      },
      name: 'triage-clicked-on-insights-models-tab',
    },
  },
  {
    Component: <TriageInsightsTicketsTab />,
    label: 'Tickets',
    trackingEvent: {
      extra: {
        tab: 'tickets',
      },
      name: 'triage-clicked-on-insights-tickets-tab',
    },
  },
];

export const TOOLTIPS = {
  correct:
    "Tickets where Triage's predicted label was accurate, meaning the agent did not override it, and the ticket is now closed or resolved.",
  correct_labels:
    'Total number of correct, finalized labels. If agents did not override a label and the corresponding ticket is closed or resolved, that label’s Triage prediction is considered accurate.',
  eligible: `Tickets that bypass the filters configured on the Help Desk, preventing Triage from assigning labels. Common filters includes the following:

i) Tickets coming from a non-supported channel, such as voice

ii) Tickets from a specific customer that are to be excluded from triage

iii) Internally created tickets that do not need a prediction`,
  eligible_tickets_tab:
    'This metric represents the number of customer support tickets Triage is authorized to view based on the model’s active date, your chosen time window, and the Triage filters.',
  finalized: 'Predicted tickets which are close or resolved.',
  finalized_labels:
    'The total number of labels finalized and recorded in your helpdesk. If a single ticket is assigned multiple labels (for example, by different models), each label is counted separately.',
  non_predicted:
    'Reasons for non-prediction (e.g. low confidence) can be found in the Tickets tab above.',
  pending:
    'Ticket with predictions but haven’t marked as closed or resolved yet.',
  predicted:
    'Tickets that Triage successfully predicted and labeled. This number may exceed the metrics below if Triage cannot write to the Help Desk due to misconfigurations or access issues.',
};

export const TAB_PARAMS_NAME = 'triage-insights-tab';

export const Y_AXIS_OPTIONS = [
  { title: { text: 'Ticket count' } },
  {
    labels: {
      formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
        return (Number(this.value) * 100).toFixed(0);
      },
    },
    max: 1,
    min: 0,
    title: { text: 'Rate' },
  },
];

export const INSIGHT_MODEL_PARAMS_NAME = 'triage-insights-model';
