import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../common/TextWithInfo';
import { NA } from 'src/constants/solve';

export interface DataItem {
  key: string;
  tooltip: string | null;
  Value?: React.ReactNode;
  value: string;
}

interface OverviewItemProps {
  isLoading?: boolean;
  item: DataItem;
}

const OverviewItem = ({ isLoading, item }: OverviewItemProps) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Box alignItems='center' columnGap={1} display='flex'>
        <TextWithInfo
          textColor={['grey', 800]}
          title={item.key}
          tooltip={item.tooltip || ''}
          variant='font14Bold'
        />
      </Box>
      {isLoading && !item.Value ? (
        <Skeleton />
      ) : (
        !item.Value && (
          <>
            {item.value === null || item.value === NA ? (
              <Typography color={palette.colors.grey[600]} variant='font24'>
                {NA}
              </Typography>
            ) : null}
            {item.value !== null && item.value !== NA ? (
              <Typography variant='font24'>{item.value}</Typography>
            ) : null}
          </>
        )
      )}
      {Boolean(item.Value) ? item.Value : null}
    </Box>
  );
};

export default OverviewItem;
