import { FC } from 'react';
import { styled } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  IntercomCustomFieldAndValue,
  IntercomCustomFieldAndValuesOptions,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCustomFieldsProps {
  customFieldsAndValues?: IntercomCustomFieldAndValue[];
  setCustomFieldsAndValues: (newVal: IntercomCustomFieldAndValue[]) => void;
  ticketCustomFields?: IntercomCustomFieldAndValuesOptions[];
  title?: string;
}

const TicketCustomFields: FC<TicketCustomFieldsProps> = ({
  customFieldsAndValues,
  setCustomFieldsAndValues,
  ticketCustomFields,
  title = 'Ticket Custom Fields',
}) => {
  const customFieldArr = ticketCustomFields || [];
  const idCustomFieldMap = keyBy('name', customFieldArr);
  const idValueArr = customFieldsAndValues || [{ name: '', value: '' }];

  return (
    <>
      <Label>{title}</Label>
      {idValueArr.map(({ name, value }, idx) => (
        <div key={`custom-field-row-${idx}`}>
          <Row>
            <SelectWrapper>
              <SelectDropdown
                aria-label='Select a Field...'
                id={`custom-field-row-${idx}`}
                isMenuSearchable
                menuMaxHeight={180}
                onChange={e => {
                  if (e?.target.value) {
                    const updatedFieldValues = idValueArr
                      .slice(0, idx)
                      .concat({ name: e.target.value, value: '' })
                      .concat(idValueArr.slice(idx + 1));

                    setCustomFieldsAndValues(updatedFieldValues);
                  }
                }}
                options={customFieldArr.map(
                  (customField: { name: string }) => ({
                    label: customField.name,
                    value: customField.name,
                  }),
                )}
                value={name}
              />
            </SelectWrapper>
            <Spacer width='8px' />
            {get([name, 'type'], idCustomFieldMap) === 'string' && (
              <InputWrapper>
                <ContextVariableAutocomplete
                  aria-label='Field Value'
                  onChange={fieldValue => {
                    const updatedFieldValues = idValueArr
                      .slice(0, idx)
                      .concat({
                        name: idValueArr[idx].name,
                        value: fieldValue,
                      })
                      .concat(idValueArr.slice(idx + 1));
                    setCustomFieldsAndValues(updatedFieldValues);
                  }}
                  placeholder='Field Value'
                  shouldIncludeSystemContextVariables
                  value={value.toString()}
                />
              </InputWrapper>
            )}
            <ButtonWrapper>
              <CloseButton
                onClick={() => {
                  setCustomFieldsAndValues(
                    idValueArr.slice(0, idx).concat(idValueArr.slice(idx + 1)),
                  );
                }}
                role='button'
                src={xIcon}
              />
            </ButtonWrapper>
          </Row>
          <Spacer height='8px' />
        </div>
      ))}
      <LinkOut
        isDisabled={idValueArr.length === customFieldArr.length}
        onClick={() => {
          if (idValueArr.length !== customFieldArr.length) {
            setCustomFieldsAndValues(
              idValueArr.concat({
                name: ticketCustomFields?.at(0)?.name || '',
                value: '',
              }),
            );
          }
        }}
      >
        + Add field
      </LinkOut>
    </>
  );
};

export default TicketCustomFields;

const SelectWrapper = styled('div')`
  flex: 1 1 50%;
`;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
