import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import {
  SelectDropdown,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Spacer } from '../ActionStep';
import { cleanCustomizationData } from '../handoff-configuration/intercom/helpers';
import TicketCustomFields from '../handoff-configuration/intercom/TicketCustomFields';
import isEqual from 'lodash/fp/isEqual';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetIntercomFieldsQuery } from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  IntercomCustomFieldAndValue,
  IntercomTicketCreationCustomizationSettings,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface IntercomTicketCreationCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const IntercomTicketCreationCustomizationPanel: React.FC<
  React.PropsWithChildren<IntercomTicketCreationCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const intercomTicketCreationCustomizationSettings = canvasState
    ?.actionSettings
    ?.configuration_fields as IntercomTicketCreationCustomizationSettings;

  const [customizationData, setCustomizationData] =
    useState<IntercomTicketCreationCustomizationSettings>(
      intercomTicketCreationCustomizationSettings,
    );
  const addingAction = useSelector(selectAddingAction);

  const [customFields, setCustomFields] = useState<
    IntercomCustomFieldAndValue[]
  >([]);

  useEffect(() => {
    if (intercomTicketCreationCustomizationSettings) {
      setCustomizationData({
        ...intercomTicketCreationCustomizationSettings,
        custom_fields:
          intercomTicketCreationCustomizationSettings.custom_fields || {},
        ticket_type_id_variable:
          intercomTicketCreationCustomizationSettings.ticket_type_id_variable ||
          '',
      });

      const customFieldsObject =
        intercomTicketCreationCustomizationSettings.custom_fields || {};
      const arrayOfCustomFields: IntercomCustomFieldAndValue[] = Object.keys(
        customFieldsObject,
      ).map(key => ({
        name: key,
        value: customFieldsObject[key],
      }));
      setCustomFields(arrayOfCustomFields);
    } else {
      const initialCustomizationData = {
        contact_email: '',
        contact_id_variable: null,
        contact_name: '',
        context_variables: undefined,
        custom_fields: undefined,
        description_variable: null,
        is_last_element: false,
        ticket_id_output_variable: null,
        ticket_type_id_variable: null,
        title_variable: null,
        workflow_action_type: ActionBuilderActionTypes.INTERCOM_TICKET_CREATION,
      };
      setCustomizationData(initialCustomizationData);
    }
  }, [intercomTicketCreationCustomizationSettings]);

  const { data: intercomFieldsData, isLoading: isLoadingFields } =
    useGetIntercomFieldsQuery();

  const isPristine = useMemo(
    () =>
      isEqual(customizationData, intercomTicketCreationCustomizationSettings),
    [customizationData, intercomTicketCreationCustomizationSettings],
  );

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  const ticketOptions = useMemo(
    () =>
      intercomFieldsData?.data.map(ticket => ({
        label: ticket.name,
        value: ticket.id,
      })) || [],
    [intercomFieldsData],
  );

  const selectedTicketFields = useMemo(() => {
    if (
      !customizationData?.ticket_type_id_variable ||
      !intercomFieldsData?.data
    ) {
      return [];
    }

    const selectedTicket = intercomFieldsData.data.find(
      ticket => ticket.id === customizationData.ticket_type_id_variable,
    );

    return (
      selectedTicket?.attributes
        .filter(
          ({ name }) =>
            name !== '_default_title_' && name !== '_default_description_',
        )
        .map(({ id, name, type }) => ({
          id,
          name,
          type,
        })) || []
    );
  }, [customizationData, intercomFieldsData]);

  if (
    isLoadingFields ||
    !customizationData ||
    canvasState.isLoadingCustomization
  ) {
    return <LoadingSkeleton />;
  }

  const updateCustomizationData = (
    field: keyof typeof customizationData,
    value: string,
  ) =>
    setCustomizationData(prev => ({
      ...prev,
      [field]: value,
    }));

  const save = () => {
    const actionType = ActionBuilderActionTypes.INTERCOM_TICKET_CREATION;

    const cleanedData: IntercomTicketCreationCustomizationSettings = {
      ...customizationData,
      custom_fields: cleanCustomizationData(customFields),
    };

    if (addingAction) {
      dispatch(
        addAction({
          ...addingAction,
          data: {
            ...addingAction.data,
            configuration_fields: cleanedData,
          },
        }),
      );
    } else {
      dispatchActionEditedTrackingEvent({ actionType });
      dispatch(
        updateActionSettings({
          action_type: actionType,
          configuration_fields: cleanedData,
        }),
      );
    }

    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    onDismiss();
  };

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <ContentWrapper>
        <Spacer height='20px' />
        <Section>
          <Typography variant='font20Medium'>
            Intercom Ticket Creation
          </Typography>
          <Spacer height='20px' />
          <ContextVariableSelectDropdown
            isClearable
            label='User Name'
            onChange={value => updateCustomizationData('contact_name', value)}
            value={customizationData.contact_name || ''}
          />
          <Spacer height='20px' />
          <ContextVariableSelectDropdown
            isClearable
            label='User Email'
            onChange={value => updateCustomizationData('contact_email', value)}
            value={customizationData.contact_email || ''}
          />
        </Section>
        <Spacer height='20px' />
        <Section>
          <ContextVariableAutocomplete
            label='Title'
            onChange={value => updateCustomizationData('title_variable', value)}
            placeholder='Enter ticket title'
            value={customizationData.title_variable || ''}
          />
          <Spacer height='20px' />
          <ContextVariableAutocomplete
            label='Description'
            multiline
            onChange={value =>
              updateCustomizationData('description_variable', value)
            }
            placeholder='Enter ticket description'
            value={customizationData.description_variable || ''}
          />
        </Section>
        <Spacer height='28px' />
        <Section>
          <ContextVariableSelectDropdown
            isClearable
            label='Ticket ID Output Context Variable'
            onChange={value =>
              updateCustomizationData('ticket_id_output_variable', value)
            }
            value={customizationData.ticket_id_output_variable || ''}
          />
        </Section>
        <Spacer height='28px' />
        <Section>
          <SelectDropdown
            id='ticket-type-input'
            label='Ticket Type'
            onChange={e =>
              updateCustomizationData('ticket_type_id_variable', e.target.value)
            }
            options={ticketOptions}
            placeholder='Select ticket type'
            value={customizationData.ticket_type_id_variable || ''}
          />
          <Spacer height='28px' />
          {customizationData.ticket_type_id_variable && (
            <TicketCustomFields
              customFieldsAndValues={customFields || []}
              setCustomFieldsAndValues={newVal => setCustomFields(newVal)}
              ticketCustomFields={selectedTicketFields}
            />
          )}
        </Section>
      </ContentWrapper>
      <Spacer height='28px' />
      <SaveContainer hasTopBorder>
        <SecondaryButton onClick={onDiscard}>Cancel</SecondaryButton>
        <StyledButton
          disabled={
            hasWorkflowConflict ||
            !customizationData.title_variable ||
            !customizationData.description_variable ||
            !customizationData.ticket_type_id_variable ||
            !customizationData.contact_name ||
            !customizationData.contact_email ||
            customFields.some(field => !field.name || !field.value)
          }
          onClick={save}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </Box>
  );
};

export default IntercomTicketCreationCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
