import { addPercentageValue, numToPercentage, numToString } from './helpers';
import {
  AddLabelFormField,
  OverviewMapValue,
  TriageModelLabelOverview,
  TriageModelLabelOverviewKeys,
} from './types';

export const overviewPlaceholder: TriageModelLabelOverview = {
  actual_count: null,
  avg_accuracy: null,
  avg_coverage: null,
  correct_count: null,
  eligible_count: null,
  finalized_count: null,
  pending_count: null,
  predicted_count: null,
  prediction_count: null,
  qualified_count: null,
  recall: null,
  total_count: null,
};

export const LAST_14_DAYS_INDEX = 2;

export const MIN_COL_SIZE = 300;

export const AVG_CHAR_SIZE = 7.5;

export const labelInfoMap: Record<
  Exclude<
    TriageModelLabelOverviewKeys,
    'avg_coverage' | 'predicted_count' | 'recall'
  >,
  OverviewMapValue
> = {
  avg_accuracy: {
    formatValue: numToPercentage,
    key: 'Accuracy',
    tooltip:
      "This indicates the accuracy of Triage's label predictions. The calculation formula is Correct Predictions/Finalized tickets x 100",
  },
  correct_count: {
    formatValue: numToString,
    key: 'Correct labels',
    tooltip:
      'Total number of correct, finalized labels. If agents did not override a label and the corresponding ticket is closed or resolved, that label’s Triage prediction is considered accurate.',
  },
  eligible_count: {
    formatValue: numToString,
    key: 'Eligible tickets',
    tooltip: `Tickets that bypass the filters configured on the Help Desk, preventing Triage from assigning labels. Common filters includes the following:

i) Tickets coming from a non-supported channel, such as voice

ii) Tickets from a specific customer that are to be excluded from triage

iii) Internally created tickets that do not need a prediction`,
  },
  finalized_count: {
    formatValue: numToString,
    key: 'Finalized tickets',
    tooltip: 'Predicted tickets which are close or resolved.',
  },
  pending_count: {
    formatValue: numToString,
    key: 'Pending',
    tooltip:
      'Ticket with predictions but haven’t marked as closed or resolved yet.',
  },
  prediction_count: {
    formatValue: addPercentageValue,
    key: 'Predicted tickets',
    tooltip: 'Tickets that Triage successfully predicted and labeled.',
  },
  total_count: {
    formatValue: numToString,
    key: 'Total tickets',
    tooltip: 'Total number of tickets that Forethought has access to.',
  },
};

export const CANT_PUBLISH_ERROR =
  'Publishing the model requires at least two labels, an output field, and accurate label mapping.';

export const CANT_TEST_ERROR = 'Testing the model requires at least two labels';

export const REQUIRED_TRAINING_PHRASES = 2;
export const REQUIRED_LABELS = 2;

export const ADD_LABEL_FIELDS: AddLabelFormField[] = [
  {
    description:
      'The label will appear as the predicted field value in the ticket when the model makes this prediction.',
    key: 'name',
    label: 'Label',
    placeholder: 'E.g. Login Issues',
  },
  {
    description:
      'Provide a concise summary of how this label should be used and the types of inquiries that should be classified under this label.',
    info: 'Keep the description under 1000 characters',
    key: 'description',
    label: 'Description',
    maxLength: 1000,
    multiline: true,
    placeholder:
      'E.g. This label covers issues related to logging into accounts, including forgotten passwords, incorrect credentials, account lockouts, and failed multi-factor authentication.',
  },
];

export const addLabelErrorMessages = {
  trainingPhrases: 'At least two phrases are required',
};

export const DUPLICATE_ACTION_TEXT = 'Duplicate to edit';

export const HEADER_HEIGHT = 160;

export const DEFAULT_LABEL_TOOLTIP =
  'If the model does not produce  a prediction to a ticket for any reason (e.g., below confidence threshold), the defined default label will be automatically written to the ticket.';

export const GROUND_TRUTH_TOOLTIP =
  'The Ground Truth Field represents the source of truth from your support ticket, used to measure accuracy. It is compared with the  value in the"Output Field" to evaluate how well predictions align with the actual data.';

export const EXACT_MATCH_TOOLTIP = `<strong>Exact Match:</strong> The prediction value must exactly match the ground truth value to be considered accurate.
 
<strong>Ground Truth Contains Prediction:</strong> The prediction is considered accurate if the ground truth value contains the prediction.

<strong>Prediction Contains Ground Truth:</strong> The prediction is considered accurate if it contains the   ground truth value.

All three criteria mentioned above are not case sensitive when it comes to matching.`;

export const MATCHING_METHOD_OPTIONS = [
  {
    label: 'Exact Match',
    value: 'exact_match',
  },
  {
    label: 'Ground Truth Contains Prediction',
    value: 'contains',
  },
  {
    label: 'Prediction Contains Ground Truth',
    value: 'shadow_field',
  },
];
