import React, { useCallback, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import Box from '@mui/material/Box';

import {
  Button,
  Drawer,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import TableDownloadButton from '../../common/table-download-button';
import AddLabelForm from '../drawers-and-dialogs/AddLabelForm';
import {
  convertFormPhrasesToTrainingPhrases,
  convertFormTagToTagDefinition,
  getTagFormValuesFromModel,
  isModelReadOnly,
  mergeLabelIntoExistingLabels,
} from '../helpers';
import { TagFormValue, TagPhrase } from '../types';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import {
  PatchTriageModelRequest,
  TagDefinitionResponse,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';
import {
  usePatchModelLabelsAndPhrasesMutation,
  usePatchSelfServeTriageModelConfigMutation,
} from 'src/services/triage/triageApi';
import { DateRange } from 'src/types/types';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

export interface LabelControlOptions {
  selectTagId: (tagId: string | null) => void;
  tagId: string | null;
}

interface LabelsTableControlProps {
  dateRange?: DateRange;
  labelControlOptions: LabelControlOptions;
  model: VersionedTriageModel;
}

const LabelsTableControl: React.FC<LabelsTableControlProps> = ({
  dateRange,
  labelControlOptions,
  model,
}) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });
  const { selectTagId, tagId } = labelControlOptions;
  const {
    formal_model_name,
    labels,
    model_id,
    model_name,
    phrases,
    version_id,
    version_name,
  } = model;
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      return;
    }
    if (tagId) {
      selectTagId(null);
    }
  }, [selectTagId, tagId, isOpen, setIsOpen]);

  useEffect(() => {
    if (tagId) {
      emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.VIEW_LABEL_DRAWER, {
        mode: 'edit',
        tag_id: tagId,
      });
    }
  }, [
    tagId,
    model_id,
    version_id,
    model_name,
    version_name,
    emitTrackingEventCallback,
  ]);

  const [patchModel] = usePatchModelLabelsAndPhrasesMutation();
  const [patchConfig] = usePatchSelfServeTriageModelConfigMutation();

  const updateValueMapping = useCallback(
    async (labelsAndPhrases: {
      labels: TagDefinitionResponse[];
      phrases: TagPhrase[];
    }) => {
      const updatedValueMapping = labelsAndPhrases.labels.reduce<
        Record<string, string>
      >((acc, label) => {
        acc[label.title] = label.output_field_value ?? '';
        return acc;
      }, {});

      const updatedModel = {
        accuracy_check_method:
          model.model.model_output_formatter.accuracy_check_method,
        default_label: null,
        ground_truth_field: {
          id: model.model.true_value[0].field_id,
          name: model.model.true_value[0].field_id,
        },
        helpdesk_data_source: model.helpdesk,
        model_name: model.model_name,
        output_field_config: {
          is_create_new_field: false,
          new_output_field: null,
        },
        value_mapping: updatedValueMapping,
      };

      await patchConfig({
        body: updatedModel,
        modelId: model_id,
        versionId: version_id,
      }).unwrap();
    },
    [model, model_id, patchConfig, version_id],
  );

  const handleDeleteLabel = useCallback(() => {
    const newLabels = labels.filter(label => label.tag_id !== tagId);
    const newPhrases = phrases.filter(phrase => phrase.tag_id !== tagId);
    const labelsAndPhrases = {
      labels: newLabels,
      phrases: newPhrases,
    };
    const requestBody: PatchTriageModelRequest = {
      body: labelsAndPhrases,
      modelId: model_id,
      versionId: version_id,
    };
    patchModel(requestBody)
      .unwrap()
      .then(async () => {
        await updateValueMapping(labelsAndPhrases);
        handleClose();
      });
  }, [
    patchModel,
    model_id,
    labels,
    phrases,
    version_id,
    tagId,
    handleClose,
    updateValueMapping,
  ]);

  const handleAddLabelSuccess = useCallback(
    (values: TagFormValue) => {
      const newLabel = convertFormTagToTagDefinition(
        values.name,
        values.description,
        labels,
        formal_model_name,
        tagId,
        model,
      );
      const insertedLabel = mergeLabelIntoExistingLabels(newLabel, labels);
      const newPhrases = convertFormPhrasesToTrainingPhrases(
        [values.addTrainingPhrase, ...values.trainingPhrases],
        newLabel.tag_id,
        phrases,
      );
      const labelsAndPhrases = {
        labels: insertedLabel,
        phrases: newPhrases,
      };
      const requestBody: PatchTriageModelRequest = {
        body: labelsAndPhrases,
        modelId: model_id,
        versionId: version_id,
      };
      patchModel(requestBody)
        .unwrap()
        .then(async () => {
          await updateValueMapping(labelsAndPhrases);
          handleClose();
        });
    },
    [
      handleClose,
      model_id,
      patchModel,
      formal_model_name,
      labels,
      model,
      phrases,
      version_id,
      tagId,
      updateValueMapping,
    ],
  );
  if (!model) {
    return null;
  }

  const initialFormState = getTagFormValuesFromModel(model, tagId);
  const isReadOnly = isModelReadOnly(model);
  const tagIdIsValid = Boolean(
    tagId && labels.find(label => label.tag_id === tagId),
  );
  const drawerIsOpen = isOpen || tagIdIsValid;

  return (
    <Box columnGap={1} display='flex'>
      <Tooltip
        tooltipContent={
          isReadOnly
            ? 'Published versions cannot be edited. Please duplicate a version to make changes'
            : ''
        }
      >
        <Button
          color='primary'
          disabled={isReadOnly}
          onClick={() => {
            setIsOpen(true);
            emitTrackingEventCallback(
              TRIAGE_LLM_TRACKING_EVENTS.VIEW_LABEL_DRAWER,
              {
                mode: 'create',
                tag_id: tagId,
              },
            );
          }}
          startIcon={<Add />}
          variant='main'
        >
          Create label
        </Button>
      </Tooltip>
      {dateRange && (
        <TableDownloadButton
          data_export_type='triage_llm_labels_table'
          requestData={{
            end: Math.floor(Number(dateRange.to) / 1000),
            model_id,
            start: Math.floor(Number(dateRange.from) / 1000),
          }}
        />
      )}
      <Drawer isOpen={drawerIsOpen} onClose={handleClose} width='434px'>
        <AddLabelForm
          initialValues={initialFormState}
          model={model}
          onDelete={handleDeleteLabel}
          onSuccess={handleAddLabelSuccess}
          tagId={tagId}
        />
      </Drawer>
    </Box>
  );
};

export default LabelsTableControl;
