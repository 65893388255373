import { labelInfoMap } from '../../triage-model-detail-page/constants';
import { TOOLTIPS } from '../constants';
import { SankeyOverviewData } from 'src/components/sankey-diagram-section/types';
import {
  LineGraphDataType,
  LineGraphValueData,
} from 'src/services/insights/types';
import { TriageInsightsModelOverviewResponse } from 'src/services/triage/types';

export const createSankeyData = (
  metricsData: TriageInsightsModelOverviewResponse['overview'] | undefined,
) => {
  if (!metricsData) {
    return [];
  }

  return [
    {
      from: 'Total tickets',
      to: 'Eligible',
      weight: metricsData.eligible_count,
    },
    {
      from: 'Total tickets',
      negative: true,
      to: 'Ineligible',
      weight: metricsData.ineligible_count,
    },
    {
      from: 'Eligible',
      to: 'Predicted',
      weight: metricsData.prediction_count,
    },
    {
      from: 'Eligible',
      negative: true,
      to: 'Non-predicted',
      weight: metricsData.non_predicted_count,
    },
    {
      from: 'Predicted',
      to: 'Finalized',
      weight: metricsData.finalized_count,
    },
    {
      color: '#7b91b1',
      from: 'Predicted',
      id: 'Pending',
      to: 'Pending',
      transitionColor: ['#A66AFECC', '#7b91b1'],
      weight: metricsData.pending_count,
    },
    {
      from: 'Finalized',
      to: 'Correct',
      weight: metricsData.correct_count,
    },
    {
      from: 'Finalized',
      negative: true,
      to: 'Incorrect',
      weight: metricsData.incorrect_count,
    },

    {
      from: 'Correct',
      to: 'Correct predictions',
      weight: 0,
    },
    {
      from: 'Incorrect',
      to: 'Incorrect predictions',
      weight: 0,
    },
  ];
};

export const createOverviewMetrics = ({
  data,
}: {
  data: TriageInsightsModelOverviewResponse['overview'] | undefined;
  isLoading: boolean;
}): SankeyOverviewData[] => {
  const totalTickets = data?.total_count || 0;
  const eligibleTickets = data?.eligible_count || 0;
  const predictedTickets = data?.prediction_count || 0;
  const finalizedTickets = data?.finalized_count || 0;

  return [
    {
      items: [
        {
          rate: data?.eligible_rate,
          title: 'Eligible',
          value: data?.eligible_count,
        },
        {
          rate: data?.ineligible_rate,
          title: 'Ineligible',
          value: data?.ineligible_count,
        },
      ],
      metricType: 'count',
      title: 'Total tickets',
      tooltip: labelInfoMap.total_count.tooltip,
      value: {
        count: totalTickets,
      },
    },
    {
      items: [
        {
          rate: data?.prediction_rate,
          title: 'Predicted',
          value: data?.prediction_count,
        },
        {
          rate: data?.non_predicted_rate,
          title: 'Non-predicted',
          tooltip: TOOLTIPS.non_predicted,
          value: data?.non_predicted_count,
        },
      ],
      metricType: 'count',
      title: 'Eligible tickets',
      tooltip: TOOLTIPS.eligible,
      value: {
        compareTo: data?.eligible_rate,
        count: eligibleTickets,
        text: 'total',
      },
    },
    {
      items: [
        {
          rate: data?.finalized_rate,
          title: 'Finalized',
          value: data?.finalized_count,
        },
        {
          rate: data?.pending_rate,
          title: 'Pending',
          tooltip: TOOLTIPS.pending,
          value: data?.pending_count,
        },
      ],
      metricType: 'count',
      title: 'Predicted tickets',
      tooltip: TOOLTIPS.predicted,
      value: {
        compareTo: data?.prediction_rate,
        count: predictedTickets,
        text: 'eligible',
      },
    },
    {
      items: [
        {
          rate: data?.correct_rate,
          title: 'Correct',
          value: data?.correct_count,
        },
        {
          rate: data?.incorrect_rate,
          title: 'Incorrect',
          value: data?.incorrect_count,
        },
      ],
      metricType: 'count',
      title: 'Finalized tickets',
      tooltip: TOOLTIPS.finalized,
      value: {
        compareTo: data?.finalized_rate,
        count: finalizedTickets,
        text: 'predicted',
      },
    },
    {
      metricType: 'count',
      title: 'Correct predictions',
      tooltip: labelInfoMap.correct_count.tooltip,
      value: {
        compareTo: data?.correct_rate,
        count: data?.correct_count,
        text: 'finalized',
      },
    },
  ];
};

export const createLineChartData = ({
  enabledLegends,
  metricChartData,
  ticketCountMetrics,
}: {
  enabledLegends: string[];
  metricChartData?: LineGraphDataType;
  ticketCountMetrics?: LineGraphValueData;
}) => {
  const isTotalTicketsEnabled = enabledLegends.includes('Total tickets');
  if (!metricChartData) {
    return;
  }
  const metricChartDataValues = metricChartData.values.map(item => {
    if ((item.label as string) === 'Accuracy') {
      return {
        ...item,
        type: 'percentage',
        yAxis: 1,
      };
    }
    return {
      ...item,
      yAxis: 0,
    };
  });
  if (!isTotalTicketsEnabled) {
    return {
      ...metricChartData,
      values: [
        { label: 'Total tickets', value: [], yAxis: 0 },
        ...metricChartDataValues,
      ],
    };
  }
  return {
    ...metricChartData,
    values: [
      {
        label: 'Total tickets',
        value: ticketCountMetrics?.value || [],
        yAxis: 0,
      },
      ...metricChartDataValues,
    ],
  };
};
