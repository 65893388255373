import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useGetSelectedBrand } from '../hooks';
import {
  useFilterOutCommonIntents,
  useGetIntents,
  useGetIntentsForBrand,
} from '../hooks/useGetIntents';
import ManuallySelectedIntent from './ManuallySelectedIntent';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { IntentData } from 'src/types/workflowBuilderAPITypes';

export default function ManuallySelectedIntents({
  handleManualIntentButtonConfigurationChange,
  handleManualIntentChange,
}: {
  handleManualIntentButtonConfigurationChange: (
    intentId: string,
    href: string | null,
    callback: () => void,
  ) => void;
  handleManualIntentChange: (intents: string[]) => void;
}): JSX.Element {
  const { palette } = useTheme();
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const intentsWithoutCommonIntents = useFilterOutCommonIntents({
    intents,
  });
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const { manually_selected_top_intents: manuallySelectedTopIntents } =
    widgetConfiguration;

  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrand = useGetSelectedBrand();
  const intentsForBrand = useGetIntentsForBrand({
    brand_id: selectedBrand ? selectedBrand.brand_id : '',
    solve_widget_product: 'workflow_builder',
  });

  const intentsToUse = isMultibrandEnabled
    ? intentsForBrand.map(intent => ({
        ...intent,
        intent_definition_id: intent.intent_id,
        intent_name: intent.title,
      }))
    : intentsWithoutCommonIntents;

  const selectedIntents = manuallySelectedTopIntents
    .map(
      id =>
        intents.find(
          intent => intent.intent_definition_id === id,
        ) as IntentData,
    )
    .filter(Boolean);

  const unselectedIndents = intentsToUse.filter(
    intent => !manuallySelectedTopIntents.includes(intent.intent_definition_id),
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display='flex' flexDirection='column'>
        {selectedIntents.map((intent, index) => {
          return (
            <ManuallySelectedIntent
              handleManualIntentButtonConfigurationChange={
                handleManualIntentButtonConfigurationChange
              }
              handleManualIntentChange={handleManualIntentChange}
              index={index}
              intentId={intent.intent_definition_id}
              intentName={intent.intent_name}
              key={intent.intent_definition_id}
            />
          );
        })}
        {unselectedIndents.map((intent, index) => {
          return (
            <ManuallySelectedIntent
              handleManualIntentButtonConfigurationChange={
                handleManualIntentButtonConfigurationChange
              }
              handleManualIntentChange={handleManualIntentChange}
              index={index}
              intentId={intent.intent_definition_id}
              intentName={intent.intent_name}
              key={intent.intent_definition_id}
            />
          );
        })}
        {intentsToUse.length === 0 && (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            No intent/workflow was found. You have not yet created any
            workflows.
          </Typography>
        )}
      </Box>
    </DndProvider>
  );
}
