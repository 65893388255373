import { useMemo, useState } from 'react';
import { MRT_Row, MRT_RowData } from 'material-react-table';
import Box from '@mui/material/Box';

import EmptyTabState from '../components/EmptyTabState';
import { filterByBrand, handleBrandColumn } from '../helpers';
import BrandFilter from './BrandFilter';
import { POLICY_TABLE_COLUMNS } from './constants';
import { toPolicyTableData } from './helpers';
import { PolicyRecommendationTableData } from './types';
import DashboardTable from 'src/components/dashboard-table';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

interface DiscoverPolicyRecommendationTableProps {
  data?: PolicyRecommendation[];
  hasBrands?: boolean;
  isError: boolean;
  loading: boolean;
  onMouseEnter: (
    row: MRT_Row<PolicyRecommendationTableData & MRT_RowData>,
  ) => void;
  onScroll: (scroll: number) => void;
  onSelect: (row: MRT_Row<PolicyRecommendationTableData>) => void;
}

const DiscoverPolicyRecommendationTable = ({
  data,
  hasBrands,
  isError,
  loading,
  onMouseEnter,
  onScroll,
  onSelect,
}: DiscoverPolicyRecommendationTableProps) => {
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<string>('policy');
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const rows = useMemo(() => {
    if (!searchText) {
      return data
        ?.filter(row => filterByBrand(selectedBrands, row.api_data_filter))
        .map(toPolicyTableData);
    }
    return data
      ?.filter(row =>
        searchType === 'policy'
          ? row.policy_description
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : row.topic.topic_name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
      )
      .filter(row => filterByBrand(selectedBrands, row.api_data_filter))
      .map(toPolicyTableData);
  }, [searchText, data, searchType, selectedBrands]);

  const columns = useMemo(
    () =>
      handleBrandColumn<PolicyRecommendationTableData>(
        data?.map(toPolicyTableData),
        POLICY_TABLE_COLUMNS,
        hasBrands,
      ),
    [hasBrands, data],
  );

  return (
    <DashboardTable<PolicyRecommendationTableData>
      columns={columns}
      data={rows || []}
      FilterComponent={
        <Box display='flex' gap={1}>
          {hasBrands && (
            <BrandFilter
              data={data || []}
              onChange={setSelectedBrands}
              selectedTab='policies'
              value={selectedBrands}
            />
          )}
        </Box>
      }
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'cost' }]}
      isError={isError}
      isLoading={loading}
      onSearch={setSearchText}
      onSearchTypeChange={setSearchType}
      searchOptions={[
        {
          text: 'Policy',
          value: 'policy',
        },
        {
          text: 'Topic',
          value: 'topic',
        },
      ]}
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={0}
      tableOptions={{
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => onSelect(row),
          onMouseEnter: () => onMouseEnter(row),
          sx: {
            cursor: 'pointer',
          },
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll(event.currentTarget.scrollTop),
          sx: {
            height: '100%',
            minHeight: rows?.length ? undefined : 'calc(100vh - 200px)',
          },
        }),
        muiTablePaperProps: {
          sx: {
            boxShadow: 'none',
            display: 'grid',
          },
        },
        renderEmptyRowsFallback: () => <EmptyTabState type='recommendation' />,
      }}
    />
  );
};

export default DiscoverPolicyRecommendationTable;
