import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Separator } from '../ForethoughtLiveChatHandoffCustomizationPanel';
import ConversationAttributes from './ConversationAttributes';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { IntercomLiveChatActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface IntercomLiveChatProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const IntercomLiveChatCustomizationPanel = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}: IntercomLiveChatProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as IntercomLiveChatActionSettings;

  const defaultCustomizationData = {
    conversation_attributes: [],
    user_email: null,
    user_id: null,
    user_name: null,
  };

  const initialCustomizationData: IntercomLiveChatActionSettings = addingAction
    ? defaultCustomizationData
    : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<IntercomLiveChatActionSettings>(initialCustomizationData);

  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  const emptyConversationAttributesValue =
    customizationData?.conversation_attributes?.some(
      conversationAttribute =>
        conversationAttribute.name === '' || conversationAttribute.value === '',
    );

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '24px',
          mb: 6.25,
        }}
      >
        <Typography variant='font24'>Intercom Live Chat Handoff</Typography>
        <Typography color={palette.colors.grey[700]} variant='font14'>
          Live Chat transition feature enables a unified agent handoff
          experience, allowing your customers to remain in a single chat for a
          seamless transition.
        </Typography>
        <Separator />
        <ContextVariableSelectDropdown
          label='User Email'
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              user_email: value,
            });
          }}
          shouldIncludeSystemContextVariables
          value={customizationData.user_email || ''}
        />
        <ContextVariableSelectDropdown
          label='User Name'
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              user_name: value,
            });
          }}
          shouldIncludeSystemContextVariables
          value={customizationData.user_name || ''}
        />
        <ConversationAttributes
          conversationAttributes={customizationData.conversation_attributes}
          setConversationAtributes={conversationAttributes =>
            setCustomizationData(customizationData => ({
              ...customizationData,
              conversation_attributes: conversationAttributes,
            }))
          }
        />
      </Box>
      <Box display='flex' gap={2}>
        <Button onClick={onDiscard} size='large' variant='secondary'>
          Cancel
        </Button>
        <Button
          disabled={
            !customizationData.user_email ||
            !customizationData.user_name ||
            isPristine ||
            emptyConversationAttributesValue ||
            hasWorkflowConflict
          }
          fullWidth
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type: ActionBuilderActionTypes.INTERCOM_LIVE_CHAT,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.INTERCOM_LIVE_CHAT,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default IntercomLiveChatCustomizationPanel;
