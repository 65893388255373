import HTMLCell from '../components/HTMLCell';
import TopicNameCell from '../components/TopicNameCell';
import { PolicyRecommendationTableData } from './types';
import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

export const TABLE_COLUMNS: {
  key: keyof DiscoverTopic;
  size: number;
  target?: 'cost' | 'volume';
  title: string;
}[] = [
  {
    key: 'topic_name',
    size: 300,
    title: 'Topic',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'volume',
    title: 'Ticket reduction/year',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'cost',
    title: 'Cost reduction/year',
  },
];

export const POLICY_TABLE_COLUMNS: DashboardTableProps<PolicyRecommendationTableData>['columns'] =
  [
    {
      Component: TopicNameCell,
      key: 'topic_name',
      size: 260,
      title: 'Topic for generated policy',
    },
    {
      key: 'api_data_filter',
      size: 260,
      title: 'Brand',
    },
    {
      Component: HTMLCell,
      key: 'policy_description',
      size: 300,
      title: 'AutoFlow policy',
    },
    {
      headerWrapText: true,
      key: 'volume',
      size: 175,
      title: 'Ticket coverage/year',
      valueType: 'number',
    },
    {
      headerWrapText: true,
      key: 'cost',
      size: 175,
      title: 'Potential saving/year',
      valueType: 'currency',
    },
    {
      key: 'created_date',
      size: 175,
      title: 'Time created',
      valueType: 'timestamp',
    },
  ];
