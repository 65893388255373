import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import RelatedTopics from './RelatedTopics';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';
import {
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface RelatedTopicsProps {
  rowData?: KnowledgeGapArticleRecommendation;
}

const TableRelatedTopics = ({ rowData }: RelatedTopicsProps) => {
  const topicNames = rowData?.topic_name;
  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const handleTopicClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      emitTrackingEventCallback('discover-clicked-on-topic', {
        page: 'Knowledge Gap',
        topic: rowData?.topic_name,
      });

      navigate(
        overrideDiscoverSearchParams(
          replaceIdInRoute(linkWithSearch, rowData?.topic_id as string),
          {
            name: rowData?.topic_name,
            source: 'knowledge gap',
          },
        ),
      );
    },
    [navigate, emitTrackingEventCallback, linkWithSearch, rowData],
  );

  return (
    <RelatedTopics
      onTopicClick={handleTopicClick}
      topicNames={topicNames as string}
    />
  );
};

export default TableRelatedTopics;
