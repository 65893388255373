import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TicketResponse from 'src/components/global-discover/article/article-drawer/TicketResponse';
import { DiscoverTicketReply } from 'src/reducers/discoverReducer/types';

interface TicketResponseListProps {
  replies: DiscoverTicketReply[];
}

const TicketResponseList = ({ replies }: TicketResponseListProps) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography variant='font11'>Ticket</Typography>
      <Box
        borderRadius={1}
        display='flex'
        flexDirection='column'
        p={2}
        rowGap={2}
        sx={{ border: `1px solid ${palette.colors.slate[200]}` }}
      >
        {replies.map(reply => (
          <TicketResponse
            authorType={reply.author_type}
            body={reply.body}
            key={reply.reply_id}
            timeStamp={reply.source_created_date}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TicketResponseList;
