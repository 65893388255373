import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';
import { IconHeart } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { csatOneToFiveOptions, csatOneToTwoOptions } from '../constants';
import IntentButton from '../intent-button/IntentButton';
import { WidgetContentContainer } from '../styledComponents';
import WidgetPrimaryInstruction from '../widget-primary-instruction/WidgetPrimaryInstruction';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const CsatWidgetContent = () => {
  const theme = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const isCsatTriggerPointEnabled = featureFlags.includes('csat_trigger_point');

  const {
    is_enabled: isEnabled,
    no_resolve_option: noResolveOption,
    one_to_five_labels: oneToFiveLabels,
    question_for_additional_feedback: questionForAdditionalFeedback,
    question_for_high_ratings: questionForHighRatings,
    question_for_low_ratings: questionForLowRatings,
    question_for_resolution_confirmation: questionForResolutionConfirmation,
    rating_question: ratingQuestion,
    reasons_for_high_ratings: reasonsForHightRatings,
    reasons_for_low_ratings: reasonsForLowRatings,
    scale,
    should_request_additional_feedback: shouldRequestAdditionalFeedback,
    should_request_feedback_for_high_ratings:
      shouldRequestFeedbackForHighRatings,
    should_request_feedback_for_low_ratings: shouldRequestFeedbackForLowRatings,
    should_request_resolution_confirmation: shouldRequestResolutionConfirmation,
    should_use_rating_based_submit_message: shouldUseRatingBasedSubmitMessage,
    show_on_header: showOnHeader,
    show_when_widget_minimizes: showWhenWidgetMinimizes,
    style,
    submit_confirmation_message: submitConfirmationMessage,
    submit_message_for_high_ratings: submitMessageForHighRatings,
    submit_message_for_low_ratings: submitMessageForLowRatings,
    yes_resolve_option: yesResolveOption,
  } = widgetConfiguration.csat_config;

  const { theme_color: themeColor } = widgetConfiguration;
  const legibleTextColor = useSelector(selectLegibleTextColor);

  // based on scale chosen and rating selected, show different contents
  const [selectedRatingIndex, setSelectedRatingIndex] = useState(
    scale === 'one_to_five' ? 2 : 1,
  );
  const isSelectedRatingPositive =
    scale === 'one_to_five'
      ? selectedRatingIndex >= 3
      : selectedRatingIndex === 0;

  const csatStyleOptions =
    scale === 'one_to_five' ? csatOneToFiveOptions : csatOneToTwoOptions;
  const chosenStyleOption = csatStyleOptions.find(
    option => option.value === style,
  );

  const InstructionComponent = (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '32px 32px 0px 32px',
      }}
    >
      <WidgetPrimaryInstruction>
        <Typography variant='font16'>
          Personalize your CSAT survey using the menu on the right
        </Typography>
      </WidgetPrimaryInstruction>
      <Typography color={theme.palette.colors.grey[400]} variant='font16'>
        Check the preview for the correct resolution
      </Typography>
    </Box>
  );

  const CsatBodyComponent = (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        '.MuiTypography-root': {
          textAlign: 'center',
        },
      }}
    >
      <RatingQuestionSection>
        <Typography variant='font16'>{ratingQuestion}</Typography>
        <Box display='flex' flexWrap='wrap' gap='16px'>
          {chosenStyleOption &&
            chosenStyleOption.icons.map((icon, index) => {
              return (
                <RatingButton
                  key={index}
                  onClick={() => {
                    setSelectedRatingIndex(index);
                  }}
                  selected={
                    chosenStyleOption.value === 'star'
                      ? index <= selectedRatingIndex
                      : selectedRatingIndex < 0
                      ? true
                      : index === selectedRatingIndex
                  }
                >
                  <StyleCardImg
                    src={
                      chosenStyleOption.value === 'star' &&
                      index <= selectedRatingIndex
                        ? chosenStyleOption.selectedIcon
                        : icon
                    }
                  />
                  <Typography variant='font12'>
                    {oneToFiveLabels[index]}
                  </Typography>
                </RatingButton>
              );
            })}
        </Box>
      </RatingQuestionSection>

      {shouldRequestFeedbackForHighRatings && isSelectedRatingPositive && (
        <Section>
          <Typography variant='font16'>{questionForHighRatings}</Typography>
          <OptionsContainer>
            {reasonsForHightRatings.map(
              reason =>
                reason.trim() !== '' && (
                  <IntentButton intentName={reason} key={reason} />
                ),
            )}
          </OptionsContainer>
        </Section>
      )}

      {shouldRequestFeedbackForLowRatings && !isSelectedRatingPositive && (
        <Section>
          <Typography variant='font16'>{questionForLowRatings}</Typography>
          <OptionsContainer>
            {reasonsForLowRatings.map(
              reason =>
                reason.trim() !== '' && (
                  <IntentButton intentName={reason} key={reason} />
                ),
            )}
          </OptionsContainer>
        </Section>
      )}

      {shouldRequestResolutionConfirmation && (
        <Section>
          <Typography variant='font16'>
            {questionForResolutionConfirmation}
          </Typography>
          <OptionsContainer>
            <IntentButton intentName={yesResolveOption} />
            <IntentButton intentName={noResolveOption} />
          </OptionsContainer>
        </Section>
      )}

      {shouldRequestAdditionalFeedback && (
        <Section>
          <Typography variant='font16'>
            {questionForAdditionalFeedback}
          </Typography>
          <FakeInputBox />
        </Section>
      )}

      <SubmitButton backgroundColor={themeColor}>
        <Typography color={legibleTextColor} variant='font14'>
          Submit
        </Typography>
      </SubmitButton>

      <ThankYouSection>
        {shouldUseRatingBasedSubmitMessage ? (
          isSelectedRatingPositive ? (
            <Typography variant='font16'>
              <HtmlComponent
                content={submitMessageForHighRatings}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Typography>
          ) : (
            <Typography variant='font16'>
              <HtmlComponent
                content={submitMessageForLowRatings}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Typography>
          )
        ) : (
          <Typography variant='font16'>{submitConfirmationMessage}</Typography>
        )}
        <IconHeart size={20} />
      </ThankYouSection>
    </Box>
  );

  return (
    <WidgetContentContainer>
      {InstructionComponent}
      {isEnabled &&
        (showWhenWidgetMinimizes ||
          showOnHeader ||
          isCsatTriggerPointEnabled) &&
        CsatBodyComponent}
    </WidgetContentContainer>
  );
};

const RatingButton = styled('button')<{ selected: boolean }>`
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  ${props => !props.selected && 'opacity: 0.4;'}
  transition: transform 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    opacity: 1;
    transform: scale(1.1);
  }
`;

const StyleCardImg = styled('img')`
  height: 40px;
  width: 40px;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.palette.colors.slate[100]};
  padding: 24px 40px;
  gap: 12px;
  align-items: center;
  width: 100%;
`;

const RatingQuestionSection = styled(Section)`
  border-top: None;
  padding-top: 0;
  gap: 24px;
`;

const ThankYouSection = styled(Section)`
  border-top: None;
  margin: 48px 0;
`;

const OptionsContainer = styled('div')`
  display: flex;
  gap: 4px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

const FakeInputBox = styled('div')`
  width: 90%;
  height: 72px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.colors.grey[200]};
`;

const SubmitButton = styled('div')<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 24px;
  border-radius: 4px;
  background: ${props => props.backgroundColor};
  margin: 32px 0;
`;
