import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import {
  Button,
  Dialog,
  SelectDropdown,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useGetWorkflowTags } from 'src/hooks/hooks';
import { getIsBrandPublishedTooltipText } from 'src/pages/solve-config/brand-sidebar/utils';
import { useGetBrandsQuery } from 'src/services/brand/brandApi';
import {
  useCreateWorkflowTagsMutation,
  useDeleteWorkflowTagMutation,
  useSwapWorkflowTagsMutation,
} from 'src/services/workflow-builder-metrics';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export const DeleteTagDialog = ({
  onClose,
  onSuccess,
  tagToDelete,
}: {
  onClose: () => void;
  onSuccess?: () => void;
  tagToDelete: string;
}) => {
  const dispatch = useAppDispatch();
  const [deleteWorkflowTagMutation, { isLoading }] =
    useDeleteWorkflowTagMutation();

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              deleteWorkflowTagMutation({
                tagName: tagToDelete,
              })
                .unwrap()
                .then(() => {
                  onSuccess?.();
                  onClose();

                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 2000,
                      title: 'Deleted brand tag',
                      variant: 'main',
                    }),
                  );
                })
                .catch(error => {
                  const errorData = error['data'];
                  const errorType = errorData['error_type'] ?? '';
                  let errorMessage =
                    'Something went wrong, please try again later.';
                  let subtitle = undefined;

                  if (errorType === 'MANUALLY_SELECTED_INTENT') {
                    const tagName = errorData['error_data']['workflow_tag'];
                    const brandName = errorData['error_data']['brand_name'];
                    const intentName = errorData['error_data']['intent_name'];

                    errorMessage = `Cannot delete tag "${tagName}"`;
                    subtitle = `Intent "${intentName}" is a manually selected intent option in brand "${brandName}", and tag "${tagName}" is the only tag that connects this intent and this tag's brand. Please remove the intent from the manually selected intents.`;
                  }
                  dispatch(
                    setGlobalToastOptions({
                      subtitle: subtitle,
                      title: errorMessage,
                      variant: 'danger',
                    }),
                  );
                });
            }}
            variant='danger'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={Boolean(tagToDelete)}
      title='Delete tag'
    >
      <Box maxWidth='550px'>
        <Typography variant='font14' whiteSpace='pre-wrap'>
          Deleting this tag will remove it from the branded widget and any
          associated workflows, but the widget and workflows themselves will
          remain unchanged.{'\n\n'}Are you sure you want to delete{' '}
          <strong>{tagToDelete}</strong>? This action cannot be undone.
        </Typography>
      </Box>
    </Dialog>
  );
};

export const CreateTagDialog = ({
  brandId,
  isOpen,
  onClose,
  onSuccess,
}: {
  brandId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (newWorkflowTagName: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const [newWorkflowTagName, setNewWorkflowTagName] = useState('');
  const [createWorkflowTagsMutation, { isLoading }] =
    useCreateWorkflowTagsMutation();
  const workflowTags = useGetWorkflowTags();
  const { data: brandData } = useGetBrandsQuery();
  const doesTagExist = new Set(workflowTags).has(newWorkflowTagName);
  const brandName = brandData?.find(
    brand => brand.brand_id === brandId,
  )?.brand_name;

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={doesTagExist || !newWorkflowTagName}
            isLoading={isLoading}
            onClick={async () => {
              try {
                await createWorkflowTagsMutation({
                  brandId,
                  tags: [newWorkflowTagName],
                }).unwrap();
                onClose();
                setNewWorkflowTagName('');
                onSuccess?.(newWorkflowTagName);
                dispatch(
                  setGlobalToastOptions({
                    autoHideDuration: 2000,
                    title: 'Created brand tag',
                    variant: 'main',
                  }),
                );
              } catch (err) {
                dispatch(
                  setGlobalToastOptions({
                    autoHideDuration: 3000,
                    title: 'Something went wrong creating the tag',
                    variant: 'danger',
                  }),
                );
              }
            }}
            variant='main'
          >
            Save
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title={`Create new tag for "${brandName}"`}
    >
      <Box minWidth='300px' mt='1px'>
        <TextField
          aria-label='Create tag'
          error={doesTagExist && 'Tag already exists'}
          onChange={e => setNewWorkflowTagName(e.target.value)}
          placeholder='Tag name'
          value={newWorkflowTagName}
        />
      </Box>
    </Dialog>
  );
};

export const ReassignTagDialog = ({
  currentBrandId,
  isOpen,
  onClose,
  tag,
}: {
  currentBrandId: string;
  isOpen: boolean;
  onClose: () => void;
  tag: string;
}) => {
  const dispatch = useAppDispatch();
  const [swapWorkflowTagMutation, { isLoading }] =
    useSwapWorkflowTagsMutation();
  const { data: brandData = [] } = useGetBrandsQuery();
  const [selectedBrandId, setSelectedBrandId] = useState(currentBrandId);

  const isError = () => {
    const selectedBrand = brandData.find(
      brand => brand.brand_id === selectedBrandId,
    );

    if (!selectedBrand || selectedBrandId === currentBrandId) {
      return '';
    }

    if (
      !selectedBrand.published_version_exists ||
      !selectedBrand.published_version_is_active
    ) {
      return `The tag can't be reassigned to '${selectedBrand.brand_name}' because the brand is not published or not active.`;
    }
  };

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={Boolean(isError()) || selectedBrandId === currentBrandId}
            isLoading={isLoading}
            onClick={async () => {
              swapWorkflowTagMutation({
                brandId: selectedBrandId,
                tag,
              })
                .unwrap()
                .then(() => {
                  onClose();
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 2000,
                      title: 'Reassigned brand tags',
                      variant: 'main',
                    }),
                  );
                })
                .catch(error => {
                  const errorData = error['data'];
                  const errorType = errorData['error_type'] ?? '';

                  let errorMessage =
                    'Something went wrong, please try again later.';
                  let subtitle = undefined;

                  if (errorType === 'MANUALLY_SELECTED_INTENT') {
                    const tagName = errorData['error_data']['workflow_tag'];
                    const brandName = errorData['error_data']['brand_name'];
                    const intentName = errorData['error_data']['intent_name'];

                    errorMessage = `Cannot reassign tag "${tagName}"`;
                    subtitle = `Intent "${intentName}" is a manually selected intent option in brand "${brandName}", and tag "${tagName}" is the only tag that connects this intent and this tag's brand. Please remove the intent from the manually selected intents.`;
                  }
                  dispatch(
                    setGlobalToastOptions({
                      subtitle: subtitle,
                      title: errorMessage,
                      variant: 'danger',
                    }),
                  );
                });
            }}
            variant='main'
          >
            Save
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title={`Reassign tag "${tag}" to`}
    >
      <Box mt='1px' width='500px'>
        <SelectDropdown
          error={isError()}
          id='reassign-tag'
          label='Reassign tag to a different brand'
          onChange={e => setSelectedBrandId(e.target.value)}
          options={brandData?.map(brand => ({
            disabled: brand.brand_id === currentBrandId,
            label:
              brand.brand_id === currentBrandId
                ? `${brand.brand_name} (currently assigned to)`
                : brand.brand_name,
            optionStartAdornment: (
              <Tooltip tooltipContent={getIsBrandPublishedTooltipText(brand)}>
                <Box
                  alignItems='center'
                  display='flex'
                  height='16px'
                  justifyContent='center'
                  width='16px'
                >
                  <Box
                    bgcolor={theme =>
                      brand.published_version_exists &&
                      brand.published_version_is_active
                        ? theme.palette.colors.green[500]
                        : theme.palette.colors.grey[300]
                    }
                    borderRadius={100}
                    height='8px'
                    width='8px'
                  />
                </Box>
              </Tooltip>
            ),
            value: brand.brand_id,
          }))}
          value={selectedBrandId}
        />
      </Box>
    </Dialog>
  );
};
