import { styled } from '@mui/material';

const StatusIcon = styled('div')<{ isEnabled: boolean }>(
  ({ isEnabled, theme }) => ({
    backgroundColor: isEnabled
      ? theme.palette.colors.green[500]
      : theme.palette.colors.grey[400],
    borderRadius: '50%',
    height: '12px',
    marginTop: '3px',
    width: '12px',
  }),
);

export default StatusIcon;
