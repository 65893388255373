import { useMemo } from 'react';

import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';

export const useGetFilterCount = ({
  channels,
  cvFilters,
  multiSelected,
  workflowTags,
}: {
  channels: string[];
  cvFilters: ContextVariableFilterItemValue[];
  multiSelected: string[];
  workflowTags: string[];
}) => {
  const filterCount = useMemo(() => {
    /**
     * Business logic for how to count filters
     * If all options are selected filter is not counted
     * If no options are selected filter is not counted
     * Only count filters once per category (Date, channel, chat id, etc)
     * Others - each parent option is a category
     */
    const workflowTypesCount =
      multiSelected.filter(filter => filter.includes('autonomous_agent_used'))
        .length === 1
        ? 1
        : 0;
    const workflowTagsOptionsCount = workflowTags.length + 1;
    const workflowTagsFilter = multiSelected.filter(filter =>
      filter.includes('workflow_tags'),
    ).length;
    const workflowTagsCount =
      workflowTagsFilter === workflowTagsOptionsCount ||
      workflowTagsFilter === 0
        ? 0
        : 1;
    const workflowIdsCount =
      multiSelected.filter(filter => filter.includes('workflow_id')).length > 0
        ? 1
        : 0;
    const csatRatingCount = multiSelected.filter(filter =>
      filter.includes('csat_ratings'),
    ).length;
    const csatCount = csatRatingCount === 0 || csatRatingCount === 6 ? 0 : 1;
    const queryExistFilter = multiSelected.filter(filter =>
      filter.includes('query_exists'),
    );
    const queryExistCount =
      queryExistFilter.length === 0 || queryExistFilter.length === 2 ? 0 : 1;
    multiSelected.filter(filter => filter.includes('workflow_id')).length > 0
      ? 1
      : 0;
    const cvFiltersCount = cvFilters.length;
    const channelsCount =
      channels.length === 3 || channels.length === 0 ? 0 : 1;
    const deflectionsCount =
      multiSelected.filter(filter => filter.includes('deflection')).length === 1
        ? 1
        : 0;
    return (
      workflowTypesCount +
      workflowTagsCount +
      workflowIdsCount +
      csatCount +
      queryExistCount +
      channelsCount +
      cvFiltersCount +
      deflectionsCount
    );
  }, [workflowTags, channels, multiSelected, cvFilters]);

  return filterCount;
};
