import Box from '@mui/material/Box';

import {
  DateRangeFilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ModelSelect, { ModelSelectProps } from './ModelSelect';
import { DateRange } from 'src/types/types';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface HeaderFiltersProps extends ModelSelectProps {
  onDateRangeChange: (dateRange: DateRange) => void;
  selectedDateRange: DateRange;
}

const HeaderFilters = ({
  models,
  modelsLoading,
  onDateRangeChange,
  onModelChange,
  selectedDateRange,
  selectedModel,
}: HeaderFiltersProps) => {
  const model = models.find(model => model.model_name === selectedModel);

  return (
    <Box columnGap={1} display='flex'>
      <Typography variant='font24'>{model?.model_name}</Typography>
      <DateRangeFilterButton
        initialValue={selectedDateRange}
        onChange={onDateRangeChange}
        options={datePickerRangeOptionsRevamp}
        value={selectedDateRange}
      />
      <ModelSelect
        models={models}
        modelsLoading={modelsLoading}
        onModelChange={onModelChange}
        selectedModel={selectedModel}
      />
    </Box>
  );
};

export default HeaderFilters;
