import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { KnowledgeGapArticleRecommendations } from 'src/reducers/discoverReducer/types';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

const BrandFilter = ({
  data,
  onChange,
  selectedTab,
  value,
}: {
  data: PolicyRecommendation[] | KnowledgeGapArticleRecommendations['articles'];
  onChange: (value: string[]) => void;
  selectedTab: 'policies' | 'knowledge';
  value?: string[];
}) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const options = [...new Set(data.map(item => item.api_data_filter))]
    .filter(Boolean)
    .map(item => ({
      label: item || '',
      value: item || '',
    }));

  const handleChange = (value: string[]) => {
    emitTrackingEventCallback('discover-brand-filter-changed', {
      brands: value,
      tab: selectedTab,
    });
    onChange(value);
  };

  return (
    <MultiSelectFilter
      onChange={handleChange}
      options={options}
      placeholder='Filter by brand'
      value={value || []}
    />
  );
};

export default BrandFilter;
