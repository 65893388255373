import { useMemo } from 'react';
import Box from '@mui/material/Box';

import FilterSelect from '../common/FilterSelect';
import {
  FILTER_KEY_FINALIZED_LABEL,
  FILTER_KEY_PREDICTED_LABEL,
  FILTER_OPTIONS,
  FILTER_OPTIONS_STATUS_ITEM,
  TABLE_COLUMNS,
} from './constants';
import { createLabelFiltersOptions } from './helpers';
import omit from 'lodash/omit';
import DashboardTable from 'src/components/dashboard-table';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { createFilterBody } from 'src/services/triage/helpers';
import { TriageTicket } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

interface TicketsTableProps {
  dateRange: DateRange;
  filters: string[];
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  model: VersionedTriageModel;
  onDateRangeChange: (value: DateRange) => void;
  onFilterChange: (filters: string[]) => void;
  onLoadMore: () => void;
  onSearch: (text: string) => void;
  onSearchTypeChange?: (value: string) => void;
  searchOptions?: { text: string; value: string }[];
  searchText: string;
  searchType?: string;
  tickets?: TriageTicket[];
}

const TicketsTable = ({
  dateRange,
  filters,
  hasNextPage,
  isFetching,
  isLoading,
  model,
  onDateRangeChange,
  onFilterChange,
  onLoadMore,
  onSearch,
  onSearchTypeChange,
  searchOptions,
  searchText,
  searchType,
  tickets,
}: TicketsTableProps) => {
  const dateRangeFilter = {
    onChange: onDateRangeChange,
    value: dateRange,
  };

  const filterOptions = useMemo(() => {
    const finalizedLabel = {
      label: 'Finalized label',
      options: createLabelFiltersOptions(model, FILTER_KEY_FINALIZED_LABEL),
      value: 'finalized_label_option',
    };

    const predictedLabel = {
      label: 'Predicted label',
      options: createLabelFiltersOptions(model, FILTER_KEY_PREDICTED_LABEL),
      value: 'predicted_label_option',
    };

    return [FILTER_OPTIONS_STATUS_ITEM, finalizedLabel, predictedLabel].concat(
      FILTER_OPTIONS,
    );
  }, [model]);

  const keywordSearch = searchType === 'keyword' ? searchText : undefined;
  const ticketId = searchType === 'ticket_id' ? searchText : undefined;

  return (
    <DashboardTable<TriageTicket>
      columns={TABLE_COLUMNS}
      data={tickets || []}
      dateRangeFilter={dateRangeFilter}
      downloadExportType='triage_llm_tickets_table'
      downloadRequestData={omit(
        createFilterBody({
          endDate: Number(dateRangeFilter.value.to),
          filters,
          keywordSearch,
          modelId: model.model_id,
          page: 0,
          startDate: Number(dateRangeFilter.value.from),
          ticketId,
        }),
        ['page'],
      )}
      FilterComponent={
        <FilterSelect
          disabled={isLoading || isFetching}
          maxHeight={400}
          menuTitle='Filter by'
          onChange={onFilterChange}
          options={filterOptions}
          value={filters}
        />
      }
      hasNextPage={hasNextPage}
      initialSorting={[{ desc: true, id: 'timestamp' }]}
      isFetching={isFetching}
      isLoading={isLoading}
      onLoadMore={onLoadMore}
      onSearch={onSearch}
      onSearchTypeChange={onSearchTypeChange}
      searchOptions={searchOptions}
      searchPlaceholderText='Search ticket'
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={160}
      tableOptions={{
        initialState: {
          columnPinning: {
            left: ['timestamp'],
          },
        },
        renderEmptyRowsFallback: () => {
          if (!tickets?.length && !isFetching && !isLoading) {
            return (
              <Box alignItems='center' display='flex'>
                No search results found.
              </Box>
            );
          }
          return <div></div>;
        },
      }}
    />
  );
};

export default TicketsTable;
