import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { AgathaMessageContainer } from './StyledComponents';
import { RelatedArticlesTranscriptComponent } from './types';

export const RelatedArticles = ({
  transcriptComponent,
}: {
  transcriptComponent: RelatedArticlesTranscriptComponent;
}) => {
  const theme = useTheme();

  if (transcriptComponent.articles.length === 0) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap='4px'>
      <Box mb='8px'>
        <Typography variant='font16'>Sources:</Typography>
      </Box>
      {transcriptComponent.articles.map(article => {
        return (
          <AgathaMessageContainer
            backgroundColor={theme.palette.colors.slate[100]}
            key={article.document_id}
          >
            <StyledArticleLink
              href={article.link}
              rel='noreferrer'
              target='_blank'
            >
              {article.title}
            </StyledArticleLink>
          </AgathaMessageContainer>
        );
      })}
    </Box>
  );
};

const StyledArticleLink = styled('a')`
  color: ${props => props.theme.palette.colors.black};
  font-size: 16px;
  display: block;
  text-align: initial;
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
`;
