import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { generateWordingByOperator } from 'src/pages/workflow-builder-config/email/automation-list-item/utils';
import useHelpdeskTicketFields from 'src/pages/workflow-builder-config/hooks/useHelpdeskTicketFields';
import { TicketFilterItemValue } from 'src/reducers/triageSettingsReducer/types';

const displayListOfValues = (values: (string | boolean | number)[]) => {
  return (
    <Box>
      Values:
      <ul>
        {values?.map(value => (
          <li key={value.toString()}>{value}</li>
        ))}
      </ul>
    </Box>
  );
};

const ExpresssionText = ({ items }: { items: TicketFilterItemValue[] }) => {
  const { customFields, isLoading, mappedFields, staticFields } =
    useHelpdeskTicketFields();

  if (!items || !items.length || isLoading) {
    return null;
  }

  const ticketFields = [...staticFields, ...customFields];

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography variant='font16Bold'>Additional ticket filter</Typography>
      {items?.map(({ booleanOperator, expression }, index) => {
        const expressionValues = expression.values || [];
        const shouldDisplayTooltip =
          expression.operator === 'contains_one_of' &&
          expressionValues.length > 1;
        const expressionsLength = items.length;
        const shouldAddWhiteSpace = index === expressionsLength - 1;
        const shouldAddBooleanOperator = index === expressionsLength - 2;
        const endOfExpression = shouldAddWhiteSpace ? (
          ' '
        ) : shouldAddBooleanOperator ? (
          <Typography variant='font16Bold'>
            {` ${booleanOperator?.toUpperCase()} `}
          </Typography>
        ) : (
          ', '
        );

        return (
          <Typography key={index} variant='font16'>
            <Tooltip
              tooltipContent={
                shouldDisplayTooltip
                  ? displayListOfValues(expressionValues)
                  : ''
              }
            >
              <span>
                {generateWordingByOperator(expression, ticketFields, [
                  ...mappedFields,
                ])}
              </span>
            </Tooltip>
            {endOfExpression}
          </Typography>
        );
      })}
    </Box>
  );
};

export default ExpresssionText;
