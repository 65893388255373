import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';

import {
  DateRangeFilterButton,
  FilterButton,
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ArticlesTable from '../solve-insights/articles/ArticlesTable';
import ChatsTable from '../solve-insights/chats/ChatsTable';
import TabHeader from '../solve-insights/common/TabHeader';
import {
  initialInsightParams,
  INTENT_FILTER_PARAM_NAME,
} from '../solve-insights/constants';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import useTabs from '../solve-insights/hooks/useTabs';
import { InsightPeriod } from '../solve-insights/types';
import { WorkflowDetailHeader } from './WorkflowDetailHeader';
import { WorkflowDetailLineChart } from './WorkflowDetailLineChart';
import dynamicArticleSuggestionIcon from 'src/assets/images/dynamic-article-suggestion-revamped.svg';
import iconFallbackColorful from 'src/assets/images/fallback-colorful.svg';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { periodicalFilterOptions } from 'src/constants/discover';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import {
  FALLBACK_INTENT_DEF_ID,
  KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID,
} from 'src/pages/solve-config/constants';
import { useGetWorkflowDetailHeaderQuery } from 'src/services/insights';
import { InsightWorkflowDetail } from 'src/services/insights/types';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  genericParameterValidator,
  genericSerializeAndDeserialize,
  isTimePeriodValid,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const getWorkflowIcon = (workflow?: InsightWorkflowDetail) => {
  if (!workflow) {
    return null;
  }

  if (workflow.intent_definition_id === KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID) {
    return (
      <img
        alt='article suggestion icon'
        height='24'
        src={dynamicArticleSuggestionIcon}
        width='24'
      />
    );
  }

  if (workflow.intent_definition_id === FALLBACK_INTENT_DEF_ID) {
    return (
      <img
        alt='fallback icon'
        height='24'
        src={iconFallbackColorful}
        width='24'
      />
    );
  }

  return null;
};

export const SolveInsightsWorkflowDetail = () => {
  const { palette } = useTheme();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { workflowId = '' } = useParams<'workflowId'>();
  // custom hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  // scroll logic
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const scrollToTop = useCallback(
    (yOffset: number, smooth = false) => {
      if (!containerRef.current) {
        return;
      }
      if (containerRef.current.scrollTop > yOffset) {
        containerRef.current.scrollTo({
          behavior: smooth ? 'smooth' : 'auto',
          top: yOffset,
        });
        emitTrackingEventCallback('insight-scroll-to-top-clicked', {
          scope: 'detail',
          tab: 'workflow',
        });
      }
    },
    [emitTrackingEventCallback],
  );
  // state params
  const [activeWorkflowTab, setActiveWorkflowTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'tableTab',
    serialize: state => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [periodicalFilter, setPeriodicalFilter] = useStateParams<InsightPeriod>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => InsightPeriod,
      initialState: initialInsightParams.period,
      paramsName: 'period',
      serialize: genericSerializeAndDeserialize,
      validator: genericParameterValidator(periodicalFilterOptions),
    },
  );
  const timeFilter = dateRangeToTimeFilter(dateRange);
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  // requests
  const { data: workflowHeaderData, isFetching: workflowHeaderIsFetching } =
    useGetWorkflowDetailHeaderQuery({
      end: backendReadyTimestamps.end_timestamp,
      start: backendReadyTimestamps.start_timestamp,
      workflow_id: workflowId,
    });
  // misc
  const workflowTitle = workflowHeaderData?.intent_title ?? NA;

  const { tabIdToIndexMap } = useTabs();

  return (
    <>
      {/* Navbar */}
      <NavbarV2
        currentTabOverride={
          <Box
            display='flex'
            onClick={() => {
              navigate({
                pathname: Routes.SOLVE_INSIGHTS_MAIN,
                search: `?tab=${tabIdToIndexMap.workflow}`,
              });
              emitTrackingEventCallback('insight-detail-back-button-clicked', {
                scope: 'detail',
                tab: 'workflow',
              });
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <IconArrowNarrowLeft
              color={palette.colors.purple[500]}
              size='20px'
            />
            <Typography color={palette.colors.purple[500]} variant='font14Bold'>
              All Workflows
            </Typography>
          </Box>
        }
      />
      <Box
        bgcolor={palette.colors.white}
        flex='1'
        height='100%'
        onScroll={e => {
          setScrollTop(e.currentTarget.scrollTop);
        }}
        overflow='auto'
        ref={containerRef}
        width='100%'
      >
        <Box
          bgcolor={palette.colors.white}
          display='flex'
          flexDirection='column'
          gap='24px'
          px='40px'
          py='24px'
          zIndex={5}
        >
          {/* header */}
          <TabHeader
            channel={workflowHeaderData?.channel}
            isLoading={workflowHeaderIsFetching}
            scope='detail'
            tabName={workflowTitle}
            tabNameIcon={getWorkflowIcon(workflowHeaderData)}
            workflowDetail={workflowHeaderData}
          />
          {/* filters */}
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            justifyContent='start'
          >
            <DateRangeFilterButton
              explicitLabel={true}
              initialValue={initialInsightParams.date}
              onChange={value => {
                setDateRange(value);
                const timeStamp = dateRangeToTimestamp(value);
                emitTrackingEventCallback('insight-date-change', {
                  from: timeStamp.start_timestamp,
                  scope: 'detail',
                  tab: 'workflow',
                  to: timeStamp.end_timestamp,
                });
              }}
              options={datePickerRangeOptionsRevamp}
              value={dateRange}
            />
            <FilterButton
              aria-label='time period filter'
              disabledTooltipMessage='Time period not valid for selected time range'
              initialValue={initialInsightParams.period}
              onChange={value => {
                setPeriodicalFilter(value as InsightPeriod);
                emitTrackingEventCallback('insight-period-change', {
                  scope: 'detail',
                  tab: 'workflow',
                  value,
                });
              }}
              options={periodicalFilterOptions.map(option => {
                const isDisabled = !isTimePeriodValid(option.value, timeFilter);
                return { ...option, disabled: isDisabled };
              })}
              startAdornment={<FilterButtonIcon dropdownType='time-period' />}
              value={periodicalFilter}
            />
          </Box>
          {/* metrics */}
          <WorkflowDetailHeader
            data={workflowHeaderData}
            isLoading={workflowHeaderIsFetching}
          />
          {/* charts */}
          <Box>
            <WorkflowDetailLineChart
              end={backendReadyTimestamps.end_timestamp}
              period={periodicalFilter}
              start={backendReadyTimestamps.start_timestamp}
              workflowId={workflowId}
            />
          </Box>
          {/* chats / knowledge article tabs */}
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: palette.colors.white,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 5,
              }}
            >
              <Tabs
                a11yProps={index => ({
                  'aria-controls': `tabpanel-${index}`,
                  id: `tab-${index}`,
                })}
                aria-label='tabs to pick data table below'
                onChange={(_, tab) => {
                  const tabValue = tab === 0 ? 'chat' : 'article';
                  setActiveWorkflowTab(tab);
                  emitTrackingEventCallback('insight-table-tab-change', {
                    scope: 'detail',
                    tab: 'workflow',
                    value: tabValue,
                  });
                  // Clear table query when switching tabs
                  const key = `${INTENT_FILTER_PARAM_NAME}_${tabValue}`;
                  const newSearchParams = new URLSearchParams();
                  newSearchParams.delete(key);
                  setSearchParams(newSearchParams);
                }}
                tabs={['Chats', 'Knowledge Articles']}
                typographyVariant='font18'
                value={activeWorkflowTab}
              />
            </Box>
            {/* tab subtitle */}
            <Box paddingTop='16px'>
              <Typography color={palette.colors.grey[600]} variant='font16'>
                {activeWorkflowTab === 0
                  ? `Chats applied the workflow “${workflowTitle}”`
                  : `Knowledge articles surfaced in the workflow “${workflowTitle}”`}
              </Typography>
            </Box>
            {/* tables */}
            {activeWorkflowTab === 0 ? (
              <ChatsTable
                dateRange={dateRange}
                isDetail={true}
                period={periodicalFilter}
                scope='detail'
                scrollTop={scrollTop}
                scrollToTop={scrollToTop}
                setDateRange={setDateRange}
                setPeriodicalFilter={setPeriodicalFilter}
                view='workflows'
              />
            ) : null}
            {activeWorkflowTab === 1 ? (
              <ArticlesTable
                dateRange={dateRange}
                period={periodicalFilter}
                scope='detail'
                setDateRange={setDateRange}
                setPeriodicalFilter={setPeriodicalFilter}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};
